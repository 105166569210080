import { DialogController } from "aurelia-dialog";
import { autoinject, Disposable } from "aurelia-framework";
import { SharedDto } from "project/project-shared";
import { ValidationError } from "shared/controls/validation-error";
import { ValidationBase } from "base-class";
import { ControllerValidateResult, ValidationControllerFactory, ValidationRules } from "aurelia-validation";
import { SharedRequestAdditionalInformationServices } from "../shared-request-additional-information-services";

export interface IRequestAdditionalInformationModalParams {
    grantId: number,
    roundId: number,
    applicationId: number,
    additionalInformationRequestId : number|string,
}

@autoinject
export class RequestAdditionalInformationModal extends ValidationBase {
    grantId: number;
    roundId: number;
    formInstanceId: number;
    applicationId: number;
    additionalInformationRequestId : number|string;

    model: SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestSharedDto;
    question: SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestItemSharedDto[] = [];
    questionTypes: SharedDto.ICodeListItemDto[];

    subscriptions: Disposable[] = [];
    validationErrors: ValidationError;

    isBusy: boolean = false;
    isSubmitting: boolean = false;

    constructor(public readonly dialogController: DialogController,
        private readonly additionalInfoService: SharedRequestAdditionalInformationServices,
        validationControllerFactory: ValidationControllerFactory) {
        super(validationControllerFactory);
    }

    activate(params: IRequestAdditionalInformationModalParams) {
        this.grantId = params.grantId;
        this.roundId = params.roundId;
        this.applicationId = params.applicationId;
        this.additionalInformationRequestId = params.additionalInformationRequestId;
      
        var promises = [];

        if(params.additionalInformationRequestId == "new"){
            this.model = {
                additionalInformationRequestId: null,
                description: '',
                additionalInformationRequestItems: []
            } as SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestSharedDto;
        } else {
            promises.push(this.additionalInfoService.getAdditionalInfoRequest(this.applicationId, +this.additionalInformationRequestId)
                .then((data) => {
                    this.model = data;
                }));
        }

        Promise.all(promises)
    }

    bind() {
        this.setupValidation();
    }

    private setupValidation() {
        ValidationRules
            .ensure((x: SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestSharedDto) => x.description)
                .required()
                .maxLength(400)
            .on(this.model);
    }

    send() {
        this.controller.validate().then((result: ControllerValidateResult) => {
            if (result.valid) {
                this.model.visibleToAssessors = true;
                this.model.additionalInformationRequestItems.push({
                    requestQuestionTypeDescription: this.model.description,
                    questionText: this.model.description,
                    requestQuestionType: 'FileUploadOrText',
                } as SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestItemSharedDto)

                this.dialogController.ok(this.model)
            }
        });
    }

    deactivate() {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }
}
