import { bindable, customElement, bindingMode } from 'aurelia-framework';
import emailMisspelled, { top100 } from "email-misspelled";
import { Result } from "email-misspelled/typings"

@customElement('email-input')
export class EmailInput {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: string;
    results: Result[];
    
    @bindable() disabled:boolean = false;
    @bindable() readonly:boolean = false;

    attached() {
        // add ntg email
        top100.push("nt.gov.au");
    }
    
    valueChanged(newValue) {
        const emailChecker = emailMisspelled({
            maxMisspelled: 5,
            lengthDiffMax: 4,
            domains: top100,
        });

        var topResults = [];

        var checkEmails = emailChecker(newValue);

        if(checkEmails.length > 0)
        for (let index = 0; index < 3; index++) {
            topResults.push(checkEmails[index]) ;
        }

        this.results = topResults;
    }

    correctValue(correctEmail:string) {
        this.value = correctEmail;
    }
}