import { inject, DOM, bindable, customElement, bindingMode, Disposable, BindingEngine } from 'aurelia-framework';
import { ValidationControllerFactory } from 'aurelia-validation';

import { ValidationBase } from '../../../base-class';
import { ControlIdGenerator } from "../../utils/control-id-generator";

@inject(ValidationControllerFactory, DOM.Element, ControlIdGenerator, BindingEngine)
@customElement('yesnomaybe')
export class YesNoMaybe extends ValidationBase {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) id: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) name: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) model: number;
    @bindable({ defaultBindingMode: bindingMode.toView }) disabled: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) yesText: string = "Yes";
    @bindable({ defaultBindingMode: bindingMode.toView }) noText: string = "No";
    @bindable({ defaultBindingMode: bindingMode.toView }) maybeText: string = "Prefer not to answer";

    private subscriptions: Disposable[] = [];

    // Note: autoinject not used.
    constructor(controllerFactory: ValidationControllerFactory,
        private readonly element: Element,
        private readonly controlIdGenerator: ControlIdGenerator,
        private readonly bindingEngine: BindingEngine) {
        super(controllerFactory);
        this.element = element;
    }

    bind() {
        if (!this.id) {
            this.id = this.controlIdGenerator.getNextId().toString();
        }
    }

    attached() {
        this.subscriptions.push(
            this.bindingEngine.propertyObserver(this, "name").subscribe(
                () => {
                    this.forceUiUpdate();
                }
            )
        );
    }
    
    detached() {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }

    setValue(value: number) {
        this.model = value;
        return this.element.dispatchEvent(DOM.createCustomEvent("blur", null));
    }

    forceUiUpdate() {
        var val = this.model;
        this.setValue(val < 0 ? val + 1 : val - 1);
        this.setValue(val);
    }
        
}
