import { AggregateError } from 'aurelia-pal';
import * as moment from 'moment';

export class DateFormatValueConverter {
  toView(value, format?, noValueString: string = "No date specified") {
    var formatString: string;
    switch (format) {
      case "time":
        formatString = "h:mm A";
        break;
      case "time24hr":
        formatString = "HH:mm";
        break;
      case "date":
        formatString = "D/MM/YYYY";
        break;
      case "date-long":
        formatString = "Do MMMM YYYY";
        break;
      case "datetime-long":
        formatString = "D MMMM YYYY h:mm A";
        break;
      case "datetime-long-acst":
        formatString = "D MMMM YYYY h:mm A [(ACST)]";
        break;
      case "ago":
        return this.ago(value);        
      case "datetime":
      default:
        formatString = "D/MM/YYYY h:mm A";
        break;
    }
    if (value == null) {
      return noValueString;
    }

    var momentDate = moment(value);
    if (momentDate.isValid()) {
      return momentDate.format(formatString);
    }
    else {
      console.error("Invalid date provided to date format value converter: " + value);
      return "";
    }
  }

  ago(value) {
    let now = moment();
    let then = moment(value);
    if(now.diff(then, 'years') >= 1) {
      return `${now.diff(then, 'years')} years ago`;
    }
    if(now.diff(then, 'months') >= 1) {
      return `${now.diff(then, 'months')} months ago`;
    }
    if(now.diff(then, 'days') >= 1) {
      return `${now.diff(then, 'days')} days ago`;
    }
    return `${now.diff(then, 'hours')} hours ago`;
  }
}