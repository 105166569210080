import { autoinject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";

import { SharedDto } from "project/project-shared";
import { Dto } from "project/project";

export interface IValidateAddressModalParams {
    addresstype: string,
    address: SharedDto.IAddressDto,
    matches: SharedDto.IAddressDto[]
}

@autoinject
export class ValidateAddressModal implements IValidateAddressModalParams {
    addresstype: string = "unknown";
    address: SharedDto.IAddressDto;
    matches: SharedDto.IAddressDto[];

    constructor(public dialogController: DialogController) { }
    
    activate(params: IValidateAddressModalParams) {
        Object.assign(this, params);
    }

    addressLiner(address: SharedDto.IAddressDto): string {
        var addressLine: string = address.line1;

        if (address.line2) {
            addressLine += ", " + address.line2;
        }
        addressLine += ", " + address.suburb + " ";

        if (address.australianStateTerritory != 0) {
            addressLine += SharedDto.Constants.StateTerritory[address.australianStateTerritory];
        }
        else {
            addressLine += address.nonAustralianState;
        }
        addressLine += ", " + address.postcode;
        return addressLine;
    }
}