import { autoinject, templateController, bindable,containerless } from 'aurelia-framework';
import "./gnt-notification.less";
import { SharedDto } from 'project/project-shared';
import { SystemNotificationService } from 'services/system-notification-service';
import { bind } from 'bluebird';

@autoinject
@containerless
export class Banner {
    @bindable() usage: string;
    
    messages: Array<SharedDto.ISystemNotificationDto>;

    constructor(private readonly systemNotificationService: SystemNotificationService) {

    }

    bind() {
    }

    attached() {
        return this.systemNotificationService.getSystemNotifications().then(res => this.messages = res);
    }
}
