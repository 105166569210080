import { autoinject, bindable, BindingEngine, bindingMode, computedFrom } from "aurelia-framework";
import { ValidationController, ValidationRules } from "aurelia-validation";
import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";
import { DataService } from "services/data-service";
import { ControlIdGenerator } from "shared/utils/control-id-generator";
import { HasElementsRule } from "../custom-validation";

@autoinject
export class Location {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedType: SharedDto.Constants.LocationType;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedLocations: number[] | number;
    @bindable({ defaultBindingMode: bindingMode.toView }) multiple: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView }) stacked: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView }) disabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView }) required: boolean = false;

    id: number;
    locationTypes: SharedDto.ICodeListItemDto[];
    locations: SharedDto.ILocalityOptionDto[];
    loadingLocationTypes: boolean;
    loadingLocations: boolean;

    constructor(private readonly dataService: DataService,
        private readonly bindingEngine: BindingEngine,
        private readonly validationController: ValidationController,
        idGenerator: ControlIdGenerator) {
        this.id = idGenerator.getNextId();
    }

    attached() {
        ValidationRules
            .ensure((l: Location) => l.selectedType)
            .required()
            .when((l) => l.required)

            .ensure((l: Location) => l.selectedLocations)
            //multi select rules
            .satisfiesRule(HasElementsRule)
            .when(l => {
                return l.multiple && l.selectedType && l.selectedType !== SharedDto.Constants.LocationType.All
            })
            .withMessage("You must select a location")
            //single select rules
            .required()
            .when(l => !l.multiple && l.selectedType && l.selectedType !== SharedDto.Constants.LocationType.All)
            .withMessage("You must select a location")

            .on(this);

        if (this.selectedLocations instanceof Array) {
            this.bindingEngine.collectionObserver(this.selectedLocations).subscribe(() => {
                this.validationController.validate({ object: this });
            });
        }
    }

    selectedLocationsChanged() {
        this.validationController.validate({ object: this });
    }

    detached() {
        ValidationRules.off(this);
    }

    bind() {
        let promises = [];
        this.loadingLocationTypes = true;
        promises.push(this.dataService.getLocationTypes().then(types => {
            this.locationTypes = types;
            this.loadingLocationTypes = false;
        }));

        if (this.selectedType != null) {
            this.loadingLocations = true;
            promises.push(this.dataService.getLocationsByType(this.selectedType).then(locations => {
                this.locations = locations;
                this.loadingLocations = false;
            }));
        }
    }

    selectedTypeChanged(newVal?: SharedDto.Constants.LocationType, oldVal?: SharedDto.Constants.LocationType) {

        //reset location selections
        // yeah we can splice an empty array no problem so lets not fall through to the case that breaks the world. TP-665
        if (this.selectedLocations instanceof Array) {
            this.selectedLocations.splice(0, this.selectedLocations.length);
        } else {
            // this will break every observer and every binding if its called.
            this.selectedLocations = null;
        }

        this.validationController.validate({ object: this });
        
        //if null or all, no list to load, return early
        if (!newVal || newVal === SharedDto.Constants.LocationType.All) {
            return;
        }

        this.loadingLocations = true;
        this.dataService.getLocationsByType(this.selectedType)
            .then(locations => this.locations = locations)
            .finally(() => this.loadingLocations = false);
    }

    @computedFrom("selectedType")
    get allNtSelected(): boolean {
        return this.selectedType === SharedDto.Constants.LocationType.All;
    }

    @computedFrom("allNtSelected", "selectedType", "disabled")
    get locationSelectDisabled() {
        return this.allNtSelected || !this.selectedType || this.disabled;
    }
}
