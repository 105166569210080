import { FrameworkConfiguration, PLATFORM } from "aurelia-framework";

export function configure(aurelia: FrameworkConfiguration) {
    aurelia
        // controls    
        .globalResources(PLATFORM.moduleName("./gnt-textarea/gnt-textarea"))
        .globalResources(PLATFORM.moduleName("./optional-attribute"))
        .globalResources(PLATFORM.moduleName("./checkbox-list/checkbox-list"))
        .globalResources(PLATFORM.moduleName("./radio-button-list/radio-button-list"))
        .globalResources(PLATFORM.moduleName("./abn/abn"))
        .globalResources(PLATFORM.moduleName("./acn/acn"))
        .globalResources(PLATFORM.moduleName("./email-input/email-input"))
        .globalResources(PLATFORM.moduleName("./date-picker/date-picker"))
        .globalResources(PLATFORM.moduleName("./date-picker/date-time-picker"))
        .globalResources(PLATFORM.moduleName("./wizard/wizard"))
        .globalResources(PLATFORM.moduleName("./wizard/wizard-tab"))
        .globalResources(PLATFORM.moduleName("./yesno/yesno"))
        .globalResources(PLATFORM.moduleName("./select-input/select-input"))
        .globalResources(PLATFORM.moduleName("./yesnomaybe/yesnomaybe"))
        .globalResources(PLATFORM.moduleName("./validation-summary/validation-summary"))
        .globalResources(PLATFORM.moduleName("./help-text/help-text"))
        .globalResources(PLATFORM.moduleName("./currency/currency"))
        .globalResources(PLATFORM.moduleName("./decimal/decimal"))
        .globalResources(PLATFORM.moduleName("./aurelia-file-uploader/file-uploader"))
        .globalResources(PLATFORM.moduleName("./featured-grants/featured-grants"))
        //this is a heavy resource and should be <required></required> in the template to enable proper chunking
        //.globalResources(PLATFORM.moduleName("./text-editor/text-editor"))
        .globalResources(PLATFORM.moduleName("./location/location"))
        .globalResources(PLATFORM.moduleName("./table/inline-table-field/inline-table-field"))
        // attributes
        .globalResources(PLATFORM.moduleName("../attributes/grants-tooltip"))
        .globalResources(PLATFORM.moduleName("../attributes/gnt-element-height"))
        // value converters
        .globalResources(PLATFORM.moduleName("../value-converters/date-format-value-converter"))
        .globalResources(PLATFORM.moduleName("../value-converters/currency-value-converter"))
        .globalResources(PLATFORM.moduleName("../value-converters/highlight-value-converter"))
        .globalResources(PLATFORM.moduleName("../value-converters/truthy-value-converter"))
        .globalResources(PLATFORM.moduleName("../value-converters/numeric-value-converter"))
        .globalResources(PLATFORM.moduleName("../value-converters/length-value-converter"))
        .globalResources(PLATFORM.moduleName("../value-converters/strip-html-value-converter"))
        .globalResources(PLATFORM.moduleName("../value-converters/abn-value-converter"))
        .globalResources(PLATFORM.moduleName("../value-converters/file-size-value-converter"))
        .globalResources(PLATFORM.moduleName("../value-converters/no-protocol-value-converter"))
        .globalResources(PLATFORM.moduleName("../value-converters/ensure-http-value-converter"))
        .globalResources(PLATFORM.moduleName("../value-converters/description-value-converter"))
        .globalResources(PLATFORM.moduleName("../value-converters/title-case-value-converter"))
        .globalResources(PLATFORM.moduleName("../value-converters/lower-case-value-converter"))
        .globalResources(PLATFORM.moduleName("../value-converters/synopsis-value-converter"))
        .globalResources(PLATFORM.moduleName("../value-converters/blob-to-url-value-converter"))
        .globalResources(PLATFORM.moduleName("../value-converters/duration-value-converter"))
        .globalResources(PLATFORM.moduleName("./notification/banner"))
        .globalResources(PLATFORM.moduleName("./currency/currency-change"))
        ;
}
