import { PLATFORM } from "aurelia-pal";
import { Router, RouterConfiguration } from "aurelia-router";

export class AssessmentsModule {

    router: Router;

    configureRouter(config: RouterConfiguration, router: Router, params) {
        config.map([
            {
                route: '', name: "AssessmentsList", moduleId: PLATFORM.moduleName("assessments/list"), title: "Assessments List",
            },
            {
                route: [':assessmentCheckId'], name: "AssessmentsView", moduleId: PLATFORM.moduleName("assessments/view"), title: "View Assessment",
                settings: { breadcrumb: true }
            },
            {
                route: [':assessmentCheckId/edit'], name: "AssessmentsEdit", moduleId: PLATFORM.moduleName("assessments/edit"), title: "Edit Assessment",
                settings: { breadcrumb: true }
            },
            {
                route: ['application/:applicationId'], name: "ApplicationView", moduleId: PLATFORM.moduleName("assessments/application-view"), title: "Application View",
                settings: { breadcrumb: true }
            }
        ]);
        this.router = router;
    }
}