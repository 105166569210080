export class ValidationError extends Error {
    
    errors: string[];
    message: string;

    constructor(message?: string, validationErrors?: string[]) {
        super(message); // 'Error' breaks prototype chain here
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
        this.errors = validationErrors;
        this.message = message;
    }

}