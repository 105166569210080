//used for listing all constants of broad cast events
export class BroadcastEvents {
    static loginStateChanged = "login-state-changed";
    
    static profileChanged = "profile-changed";//for logic of profile swapping
    static profileRefreshed = "profile-refreshed";//for views to re-render
    static profileDataChanged = "profile-data-changed";//for sub components to let others know they've updated details stored in profiles

    static voucherDataChanged = "voucher-data-changed";//for sub components to let others know they've updated details stored in profiles
}
