import { DOM, bindable, customElement, bindingMode, observable, autoinject, TaskQueue } from 'aurelia-framework';

@customElement('gnt-textarea')
@autoinject
export class GntTextArea {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: string;
    @observable() textAreaElement: HTMLElement;
    @bindable() disabled: boolean = false;
    @bindable() id: string;
    @bindable() rows: number = 3;

    constructor(private readonly taskQueue: TaskQueue) {
    }

    valueChanged(newValue, oldValue) {
        this.updateStyle();
    }

    textAreaElementChanged() {
        this.updateStyle();
    }

    private updateStyle() {
        if (this.textAreaElement) {
            this.textAreaElement.style.height = 'auto';
            this.textAreaElement.style.height = (this.textAreaElement.scrollHeight) + 'px';
            this.textAreaElement.style.overflowY = 'hidden';
        }
    }

    bind() {
    }

    attached() {
        this.taskQueue.queueTask(() => { this.updateStyle(); });
    }
}
