import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, bindingMode, computedFrom } from "aurelia-framework";
import { SharedDto } from "project/project-shared";
import { SharedBroadcastEvents } from "shared/utils/SharedBroadcastEvents";

@autoinject
export class RequestAdditionalInformation {
    @bindable({ defaultBindingMode: bindingMode.toView }) additionalInformationRequest: SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestSharedDto[];

    @bindable({ defaultBindingMode: bindingMode.twoWay }) formInstanceAnswerId: number;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) formInstanceId: number;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionId: number;

    responded: SharedDto.Constants.AdditionalInformationRequestStatus = SharedDto.Constants.AdditionalInformationRequestStatus.Responded;

    constructor(private eventAggregator: EventAggregator) {

    }

    hasAnswer(item: SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestItemSharedDto): boolean {
        return item.answerText != null || item.actionedByAgencyDate != null;
    }

    getAnswer(item: SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestItemSharedDto, responded: string): string {
        return item.answerText
            ? item.answerText
            : item.actionedByAgencyComment
    }

    hasAttachment(item: SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestItemSharedDto, responded: string): boolean {
        return item.attachment != null || item.actionedByAgencyFileStorage != null;
    }

    getAttachment(item: SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestItemSharedDto): SharedDto.IFilePropertiesDto {
        return item.attachment != null
            ? item.attachment
            : item.actionedByAgencyFileStorage
    }

    downloadAdditionalInformationDocument(additionalInformationRequestId: number, item: SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestItemSharedDto): void {
        // let applicationId = this.router.currentInstruction.parentInstruction.params.applicationId;
        // return this.additionalInfoService.getAttachmentDocumentUrl(applicationId, additionalInformationRequestId, item.additionalInformationRequestItemId, item.attachment.fileStorageId);
        this.eventAggregator.publish(SharedBroadcastEvents.additionalInformationDocumentDownload, { additionalInformationRequestId, item });
    }

    @computedFrom('additionalInformationRequest', 'formInstanceAnswerId')
    get additionalInformations(): SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestSharedDto[] {
        return this.additionalInformationRequest 
            ? this.additionalInformationRequest.filter(x => {
                return x.formInstanceAnswerId == this.formInstanceAnswerId
            })
            : this.additionalInformationRequest
    }
}