import { autoinject, bindable, bindingMode, Disposable } from "aurelia-framework";
import { Router } from "aurelia-router";
import { SharedDto } from "project/project-shared";

@autoinject
export class FormSection {
    @bindable({ defaultBindingMode: bindingMode.toView }) sectionTemplate: SharedDto.OnlineForm.Form.ISectionDto;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) sectionInstance: SharedDto.OnlineForm.Application.IFormInstanceSectionDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) stepVisibility: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) sectionVisibility: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) onSubmitValidation: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView }) canDelete: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView }) formInstance: SharedDto.OnlineForm.Application.IFormInstanceDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) formTemplate: SharedDto.OnlineForm.Form.IFormTemplateDto;
    @bindable() sectionIndex: number = 0;
    @bindable() delete: () => void;
    @bindable() formControlId: number = null;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) router: Router;
    @bindable({ defaultBindingMode: bindingMode.toView }) additionalInformationRequest: SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestSharedDto[];
    @bindable({ defaultBindingMode: bindingMode.twoWay }) allowAdditionalInfoRequests: boolean = false;

    questionGroupTemplates: SharedDto.OnlineForm.Form.IQuestionDto[] = [];

    subscriptions: Disposable[] = [];

    bind() {
        this.sectionInstance.questionGroups.sort((a, b) => a.sortOrder - b.sortOrder);
        this.buildQuestion();
    }

    detached() {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }

    private buildQuestion() {
        var qgt: SharedDto.OnlineForm.Form.IQuestionDto[] = [];

        for (let i = 0; i < this.sectionInstance.questionGroups.length; i++) {
            let questionGroupInstance = this.sectionInstance.questionGroups[i];
            qgt.push(this.sectionTemplate.questions.find(q => q.questionId == questionGroupInstance.templateQuestionId));
        }

        this.questionGroupTemplates = qgt;
    }
}
