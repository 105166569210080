import { bindable, bindingMode, customElement } from 'aurelia-framework';
import { ValidationController } from 'aurelia-validation';
import Inputmask from 'inputmask';
import { ControlIdGenerator } from 'shared/utils/control-id-generator';

@customElement('decimal-input')
export class DecimalInput {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: string;
    @bindable() precision: number;
    @bindable() currencyInput: HTMLInputElement;
    @bindable() id: string;
    @bindable() disabled: boolean;
    @bindable() tabindex: number;
    @bindable() placeholder: string;

    constructor(private readonly controlIdGenerator: ControlIdGenerator) { }

    bind() {
        if (!this.id) {
            this.id = this.controlIdGenerator.getNextId().toString();
        }
    }

    attached() {
        Inputmask({
            alias: 'decimal',
            autoUnmask: true,
            prefix: '',
            digits: this.precision,
            rightAlign: false,
            clearMaskOnLostFocus: true,
            postValidation: () => {
                var evt = document.createEvent("HTMLEvents");
                evt.initEvent("change", false, true);
                this.currencyInput.dispatchEvent(evt);
                return true;
            }
        } as any).mask(this.currencyInput);
    }

    dispatchEvent(value) {
        var customEvent = new CustomEvent('change', {
            detail: {
                value: value
            },
            bubbles: true
        });
        this.currencyInput.dispatchEvent(customEvent);
        customEvent = new CustomEvent('update', {
            detail: {
                value: value
            },
            bubbles: true
        });
        this.currencyInput.dispatchEvent(customEvent);
    }
}
