import { inject, DOM, bindable, customElement, bindingMode, computedFrom } from 'aurelia-framework';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { SharedDto } from "project/project-shared";
import { QuestionBase } from "../question-base";
import { ControlIdGenerator } from "../../../../utils/control-id-generator";
import * as moment from 'moment';
import { UtilityService } from 'services/utility-service';

@customElement('form-abn-lookup')
export class FormAbnLookup extends QuestionBase {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.IAbnLookupAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;
    
    checkingAbr: boolean = false;
    abnValidated: boolean = false;
    notActive: boolean = false;

    abnResult: SharedDto.IAbnDetailsDto;

    constructor(private controller: ValidationController, private controlIdGenerator: ControlIdGenerator, private readonly utilsService: UtilityService) {
        super(controlIdGenerator);
    }

    bind() {
        super.bind();
    }
    
    attached(){
        super.attached();
        if(this.questionInstance.details) {
            this.abnValidated = true;
            this.notActive = !this.questionInstance.details.active;
            this.abnResult = this.questionInstance.details;
        }
    }

    setupValidation(){
        ValidationRules.off(this.questionInstance);

        ValidationRules
            .ensure((m: SharedDto.OnlineForm.Application.IAbnLookupAnswerDto) => m.abn)
            .required()
            .when(() => this.visibility && this.onSubmitValidation && this.isMandatory)
            .on(this.questionInstance);
    }

    async validateAbn() {
        this.checkingAbr = true;
        try {
            let result = await this.utilsService.abrLookup(this.questionInstance.abn);
            if(result) {
                this.abnValidated = true;
                this.notActive = !result.active;
                this.abnResult = result;
                this.questionInstance.key = result.key;
                this.questionInstance.retrievedBy = null;
                this.questionInstance.retrievedOn = null;
            }  
            this.checkingAbr = false;
        }
        catch(ex) {
            // the lookup failed for some reason, cleanup and let the user enter manually            
            this.notActive = true;
            this.abnValidated = false;
            this.checkingAbr = false;
            this.abnResult = null;
        }
    }
}
