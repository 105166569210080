import { bindable, bindingMode, customElement } from 'aurelia-framework';
import { ValidationController, ValidationRules } from 'aurelia-validation';
import { SharedDto } from "project/project-shared";
import { CheckboxListOption } from 'shared/controls/checkbox-list/checkbox-list';
import { HasElementsRule } from 'shared/controls/custom-validation';
import { CodeListService, FormOption } from 'shared/controls/online-form/question/code-list-service';
import { ControlIdGenerator } from "../../../../utils/control-id-generator";
import { QuestionBase } from "../question-base";

@customElement('form-multi-select-list')
export class FormMultiSelectList extends QuestionBase {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.IMultiSelectListAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView }) formInstance: SharedDto.OnlineForm.Application.IFormInstanceDto;

    codeOptions: FormOption[];
    checkBoxOptions: CheckboxListOption[];

    loading = true;

    showDropDownList: boolean = false;
    showCheckBoxList: boolean = false;

    constructor(private controlIdGenerator: ControlIdGenerator, private controller: ValidationController,
        private codeListService: CodeListService) {
        super(controlIdGenerator);
    }

    bind() {
        super.bind();

        switch (this.questionTemplate.questionType) {
            case SharedDto.Constants.QuestionType.CheckBoxList:
                this.showCheckBoxList = true;
                break;
            case SharedDto.Constants.QuestionType.MultiSelectList:
                this.showDropDownList = true;
                break;
        }

        var optionList = this.questionTemplate.configurationOptions.find(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.OptionsList);
        if (!optionList || !optionList.questionCodeListType) {
            throw new Error("Error with option list for questionId " + this.questionTemplate.questionId);
        }

        if (!this.questionInstance.questionCodeListType) {
            this.questionInstance.questionCodeListType = optionList.questionCodeListType;
        }

        var sortAlphabeticallyRule = this.questionTemplate.configurationOptions.find(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.SortAlphabetically);
        var boolValue = (sortAlphabeticallyRule) ? sortAlphabeticallyRule.boolValue : undefined;
        this.codeListService.getDataList(this.questionTemplate.questionId, optionList.questionCodeListType, boolValue).then((data) => {
            this.codeOptions = data;
            if (this.showCheckBoxList) {
                this.checkBoxOptions = this.codeOptions.map((option) => {
                    var intOption = new CheckboxListOption();
                    intOption.id = option.value;
                    intOption.description = option.description;
                    return intOption;
                })
            }
            this.loading = false;
        });
    }

    attached() {
        super.attached();
    }

    setupValidation() {
        this.controller.removeObject(this.questionInstance);

        let rules = [];

        var minSelectedOptions = this.questionTemplate.configurationOptions.find(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.MinSelectedOptions);
        if (minSelectedOptions) {
            rules.push(ValidationRules.ensure((model: SharedDto.OnlineForm.Application.IMultiSelectListAnswerDto) => model.selectedOptionItemIds)
                .satisfies((value, model) => model.selectedOptionItemIds && model.selectedOptionItemIds.length >= minSelectedOptions.numberValue)
                .when((model) => model.selectedOptionItemIds && model.selectedOptionItemIds.length > 0 && this.visibility)
                .withMessage('You must select a minimum of ' + minSelectedOptions.numberValue + ' options')
                .rules[0]);
        }

        var maxSelectedOptions = this.questionTemplate.configurationOptions.find(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.MaxSelectedOptions);
        if (maxSelectedOptions) {
            rules.push(ValidationRules.ensure((model: SharedDto.OnlineForm.Application.IMultiSelectListAnswerDto) => model.selectedOptionItemIds)
                .satisfies((value, model) => model.selectedOptionItemIds && model.selectedOptionItemIds.length <= maxSelectedOptions.numberValue)
                .when((x) => this.visibility)
                .withMessage('You must select a maximum of ' + maxSelectedOptions.numberValue + ' options')
                .rules[0]);
        }

        rules.push(ValidationRules
            .ensure((model: SharedDto.OnlineForm.Application.IMultiSelectListAnswerDto) => model.selectedOptionItemIds)
            .satisfiesRule(HasElementsRule)
            .when(() => (this.visibility && this.onSubmitValidation && this.isMandatory))
            .withMessage("You must select at least one option.")
            .rules[0]);

        if (rules.length > 0) {
            this.controller.addObject(this.questionInstance, rules);
        }
    }
}
