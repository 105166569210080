import { bindable, bindingMode } from "aurelia-framework";
import { RenderedError } from "aurelia-validation";

export class WizardTab {

    @bindable({ defaultBindingMode: bindingMode.oneTime }) tabName: string;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) defaultSelected: boolean;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) hidden: boolean = false;

    public selected: boolean;
    public errors: RenderedError[];
}
