import { Disposable, BindingEngine } from 'aurelia-framework';
import { bindingMode } from "aurelia-binding";
import { autoinject } from "aurelia-dependency-injection";
import { bindable } from "aurelia-templating";
import { ControlIdGenerator } from "../../utils/control-id-generator";

@autoinject
export class RadioButtonList {

    @bindable({ defaultBindingMode: bindingMode.toView }) options: any[];
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) name = "rb";
    @bindable({ defaultBindingMode: bindingMode.twoWay }) disabled: boolean;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) inline: boolean = false;
    @bindable() displayProperty: string = "description";
    @bindable() idProperty: string = "id";

    private subscriptions: Disposable[] = [];

    constructor(private readonly controlIdGenerator: ControlIdGenerator,
        private readonly bindingEngine: BindingEngine) {

    }

    created() {
        if (this.name == 'rb') {
            this.name = this.controlIdGenerator.getNextId().toString();
        }
    }

    attached() {
        this.subscriptions.push(
            this.bindingEngine.propertyObserver(this, "name").subscribe(
                () => {
                    this.forceUiUpdate();
                }
            )
        );
    }
    
    detached() {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }

    forceUiUpdate() {
        var val = this.value;
        this.value = null;
        this.value = val;
    }
}
