//takes an option list and tries to resolve the key into its description from that list
export class DescriptionValueConverter {
    toView(value: any, optionList: any[], keyProp, descProp) {
        if (!value || !optionList || optionList.length == 0) {
            return "";
        }
        if (!keyProp) {
            keyProp = "id";
        }
        if (!descProp) {
            descProp = "description";
        }
        for (let option of optionList) {
            if (value == option[keyProp]) {
                return option[descProp];
            }
        }
    }
}
