import {FrameworkConfiguration, PLATFORM} from "aurelia-framework";

export function configure(aurelia: FrameworkConfiguration) {
    aurelia
        .globalResources(PLATFORM.moduleName("./recaptcha"))
        .globalResources(PLATFORM.moduleName("./table/table-data-attribute"))
        .globalResources(PLATFORM.moduleName("./table/table-data-sort-attribute"))
        .globalResources(PLATFORM.moduleName("./table/table-data-pagination"))
        .globalResources(PLATFORM.moduleName("./panel.html"))
        .globalResources(PLATFORM.moduleName("./card.html"))
        .globalResources(PLATFORM.moduleName("./password-strength"))
        .globalResources(PLATFORM.moduleName("./modal.html"))
        .globalResources(PLATFORM.moduleName("./file-view.html"));
}
