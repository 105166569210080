import { customElement, bindable, bindingMode } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";
import { SharedDto } from "project/project-shared";
import { OnlineFormService } from "services/online-form-service";

@customElement('document-declaration')
export class DocumentDeclaration {
    @bindable({ defaultBindingMode: bindingMode.toView }) onSubmitValidation: boolean;
    @bindable() formInstance: SharedDto.OnlineForm.Application.IApplicationFormDto;
    @bindable({defaultBinding: bindingMode.twoWay}) document: SharedDto.IFilePropertiesDto;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) readonly: boolean = false;

    constructor(
        private readonly formService: OnlineFormService){
    }

    bind() {
        this.setupValidation();
    }

    setupValidation() {
        ValidationRules
            .ensure((fi: SharedDto.OnlineForm.Application.IApplicationFormDto) => fi.documentDeclaration)
            .required()
            .when(() => this.onSubmitValidation)
            .on(this.formInstance);
    }

    download() {
        this.formService.getDeclarationDocument(this.formInstance.applicationId);
    }
}
