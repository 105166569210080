import { autoinject, bindable, bindingMode, computedFrom, Disposable } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { SharedDto } from 'project/project-shared';
import { DataService } from "services/data-service";
import { addressToSingleLineString } from 'shared/utils/address-utils';
import { ControlIdGenerator } from 'shared/utils/control-id-generator';
import { QuestionBase } from "../question-base";

@autoinject
export class FormAddress extends QuestionBase {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.IAddressAnswerDto;

    id: number;
    states: SharedDto.ICodeListItemDto[] = [];
    countries: SharedDto.ICodeListItemDto[] = [];
    australia: SharedDto.ICodeListItemDto;
    nt: SharedDto.ICodeListItemDto;
    loadingStates = true;
    loadingCountries = true;

    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;

    private subscriptions: Disposable[] = [];

    constructor(controlIdGenerator: ControlIdGenerator,
        private readonly dataService: DataService) {
        super(controlIdGenerator);
        this.id = controlIdGenerator.getNextId();
    }

    bind() {
        super.bind();
        this.dataService.getStates().then(data => {
            this.states = data;
            this.nt = data.find(s => s.code === "NT");
            this.loadingStates = false;
        });
        this.dataService.getCountries().then(data => {
            this.countries = data;
            this.australia = this.countries.find(c => c.code === "au");
            if (this.questionInstance.countryId == null) {
                this.questionInstance.countryId = this.australia.id;
                if (this.questionInstance.australianStateTerritory == null) {
                    this.questionInstance.australianStateTerritory = SharedDto.Constants.StateTerritory.NT;
                    this.questionInstance.nonAustralianState = null;
                }
            }
            this.loadingCountries = false;
        });
    }

    unbind() {
        while (this.subscriptions.length) {
            this.subscriptions.pop().dispose();
        }
    }

    setupValidation() {
        ValidationRules.off(this.questionInstance);

        var builder = ValidationRules
            .ensure((q: SharedDto.OnlineForm.Application.IAddressAnswerDto) => q.line1)
            .required()
            .when(() => this.mandatory && this.visibility)
            .ensure(q => q.postCode)
            .minLength(4)
            .maxLength(4)
            .required()
            .when(() => this.mandatory && this.visibility)
            .ensure(q => q.australianStateTerritory)
            .required()
            .when(q => this.mandatory && this.australia && q.countryId == this.australia.id && this.visibility)
            .ensure(q => q.nonAustralianState)
            .displayName("State")
            .required()
            .when(q => this.mandatory && this.australia && q.countryId != this.australia.id && this.visibility)
            .ensure(q => q.suburb)
            .required()
            .when(() => this.mandatory && this.visibility);

        if (this.onSubmitValidation) {
            // already handled by this.mandatory
        }
        builder.on(this.questionInstance);
    }

    @computedFrom("australia", "countries", "questionInstance.countryId")
    get stateSelect(): boolean {
        if (this.questionInstance == null || this.australia == null) { return true; }
        let showStateSelect = this.questionInstance.countryId == this.australia.id;
        if (showStateSelect) {
            this.questionInstance.nonAustralianState == null;
        }
        else {
            this.questionInstance.australianStateTerritory = null;
        }
        return showStateSelect;
    }


    @computedFrom("questionTemplate.mandatory")
    get mandatory(): boolean {
        // some address fields are mandatory when:
        // 1. submitting, if questionTemplate.mandatory is true
        // 2. saving or submitting, if some fields are filled out (i.e. you can't enter line 1 without a suburb)
        return (
            (this.isMandatory && this.onSubmitValidation) ||
            (!this.isNullOrEmpty(this.questionInstance.line1) ||
                !this.isNullOrEmpty(this.questionInstance.line2) ||
                !this.isNullOrEmpty(this.questionInstance.suburb) ||
                !this.isNullOrEmpty(this.questionInstance.postCode))
        );
    }

    private isNullOrEmpty(str: string) {
        return str == null || str.length == 0;
    }

    @computedFrom("questionInstance", "states", "countries")
    get singleLineAddress(): string {
        return addressToSingleLineString(this.questionInstance, this.states, this.countries);
    }
}
