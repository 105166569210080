import {autoinject, singleton} from "aurelia-framework";
import { Confirm } from "shared/controls/confirm-modal/confirm";
import {Config} from "../../config/config";

@autoinject
export class VersionMismatchService {

    versionMismatchLastNotification: Date;
    
    constructor(private readonly confirm: Confirm) {};
    
    private async notify(){
        await this.confirm.confirm("A new version of GrantsNT is available. Please click 'Update Now' to get the latest version or click 'Continue' to complete any unsaved changes.",
            "New Version Available", "Update Now", "Continue").then(ok => {
            if (ok) {
                location.reload(true);
            }
        });
    }
    
    /**
     * Verify the client is not using an outdated version of the Site Javascript, this happens when the browser is left open during a deployment
     * 
     * @param response
     * @private
     */
    async checkVersion(response: Response) : Promise<Response> {
        let VersionNumber = response.headers.get("VersionNumber");

        if (VersionNumber != undefined && Config.applicationVersion != VersionNumber) {

            if (this.versionMismatchLastNotification == undefined){
                this.versionMismatchLastNotification = new Date();
                await this.notify();
            }else {
                let timeSinceLastMismatchNotification = Math.abs(new Date().getTime() - this.versionMismatchLastNotification.getTime());
                let timeSinceLastMismatchNotificationInSeconds = timeSinceLastMismatchNotification / 1000

                if (timeSinceLastMismatchNotificationInSeconds > 60) {
                    this.versionMismatchLastNotification = new Date();
                    await this.notify();
                }
            }
        }

        return response;
    }
}