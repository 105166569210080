import { Disposable } from 'aurelia-binding';
import { Factory, inject } from 'aurelia-dependency-injection';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, bindingMode, computedFrom, TaskQueue } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ControllerValidateResult, RenderedError, validateTrigger, ValidationController, ValidationControllerFactory } from "aurelia-validation";
import { SharedDto } from "project/project-shared";
import scrollIntoView from "scroll-into-view";
import { ApplicationFormService } from 'services/application-form-service';
import { ControlIdGenerator } from 'shared/utils/control-id-generator';
import { SharedBroadcastEvents } from 'shared/utils/SharedBroadcastEvents';
import { ValidationRenderer } from "shared/utils/validation-renderer";
import "./application-form.less";
import { FormDependencyService } from './form-dependency-service';
import { FormNavigationServices } from './form-navigation-services';
import { SharedRequestAdditionalInformationServices } from './request-additional-information/shared-request-additional-information-services';

interface IButtonNavigation {
    previous: "Introduction" | "RequiredDocuments" | "Declaration" | "Step" | null;
    previousStepIndex?: number;
    next: "Introduction" | "RequiredDocuments" | "Declaration" | "Step" | null;
    nextStepIndex?: number;
}

@inject(EventAggregator, 
    ValidationControllerFactory, 
    Factory.of(FormDependencyService), 
    TaskQueue, 
    FormNavigationServices, 
    ApplicationFormService, 
    ControlIdGenerator,
    SharedRequestAdditionalInformationServices
)

export class ApplicationFormCustomElement {
    @bindable() formTemplate: SharedDto.OnlineForm.Form.IApplicationFormTemplateDto;
    @bindable() applicationForm: SharedDto.OnlineForm.Application.IApplicationFormDto;
    @bindable() showSubmit: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly = true;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) navOffset: string;
    @bindable() activeStep?: SharedDto.OnlineForm.Application.IFormInstanceStepDto;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) router: Router;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) allowAdditionalInfoRequests: boolean = false;

    stepTemplates: SharedDto.OnlineForm.Form.IStepDto[] = [];

    //option is passed all the way down to the question contols. This helps determine which validation rules need to be run.
    onSubmitValidation: boolean = false;
    showDeclaration: boolean;
    showIntro: boolean;
    showApplicationNumber: boolean;

    buttonNavigationState: IButtonNavigation;

    private controller: ValidationController;
    showValidationErrorCount: boolean;

    errors: RenderedError[][] = [];
    declarationErrors: RenderedError[] = [];
    requiredDocumentErrors: RenderedError[] = [];
    mainContent: HTMLElement;
    sideBar: JQuery;
    subscriptions: Disposable[] = [];

    formControlId: number = null;

    formInstance: SharedDto.OnlineForm.Application.IFormInstanceDto;
    showRequiredDocuments: boolean;

    private dependencyServices: FormDependencyService[];

    constructor(private readonly eventAggregator: EventAggregator,
        validationControllerFactory: ValidationControllerFactory,
        private readonly formDependencyFactory,
        private readonly taskQueue: TaskQueue,
        private readonly formNavigationServices: FormNavigationServices,
        private readonly applicationFormService: ApplicationFormService,
        private readonly controlIdGenerator: ControlIdGenerator,
        private readonly additionalInfoService: SharedRequestAdditionalInformationServices
    ) {
        this.controller = validationControllerFactory.createForCurrentScope();
        this.controller.validateTrigger = validateTrigger.changeOrBlur;
        this.controller.addRenderer(new ValidationRenderer());
        this.showValidationErrorCount = false;
        this.showDeclaration = false;
        this.showIntro = false;
        this.formControlId = this.controlIdGenerator.getNextId();
    }

    bind() {
        this.formInstance = this.applicationForm.formInstance;

        return this.setupNav();
    }

    setupNav() {
        // Sort the steps so they come out in the proper order.
        this.formInstance.steps.sort((a, b) => a.sortOrder - b.sortOrder);
        // is it necessary to sort form template steps too?
        this.formTemplate.steps.sort((a, b) => a.sortOrder - b.sortOrder);

        // we need to match step templates with the same index as formInstance.steps
        for (let i = 0; i < this.formInstance.steps.length; i++) {
            let stepInstance = this.formInstance.steps[i];
            this.stepTemplates.push(this.formTemplate.steps.find(stepTemplate => stepTemplate.stepId == stepInstance.templateStepId));
        }

        //Set the active step as the first step in the list.
        if (this.formTemplate.showIntroPage) {
            this.showIntroPage();
        } else {
            this.navToSection(this.formInstance.steps[0], this.formInstance.steps[0].sectionGroups[0], false
            );
        }

        this.determineSectionNavigation();
        this.showApplicationNumber = this.applicationForm.applicationNumber && this.applicationForm.applicationNumber.length > 0;
    }

    attached() {
        this.subscriptions.push(
            this.eventAggregator.subscribe(SharedBroadcastEvents.allFilesSaved, () => {
                return this.setupNav();
            }),
            this.eventAggregator.subscribe(SharedBroadcastEvents.downloadApplicationDocument, (data: { fileStorageId: number }) => {
                this.applicationFormService
                    .generateAttachmentDownloadUrl(this.applicationForm.applicationNumber, data.fileStorageId)
                    .then(link => window.open(link, '_blank', ''));
            }),
            this.eventAggregator.subscribe(SharedBroadcastEvents.additionalInformationDocumentDownload, (data: { additionalInformationRequestId: number, item: SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestItemSharedDto}) => {
            
                if(data.item.attachment) {
                    return this.additionalInfoService
                        .downloadAdditionalInformationRequestAttachment(this.applicationForm.applicationId, 
                            data.additionalInformationRequestId, 
                            data.item.additionalInformationRequestItemId, 
                            data.item.attachment.fileStorageId) 
                }
                
                if(data.item.actionedByAgencyFileStorage) {
                    return this.additionalInfoService
                        .downloadAdditionalInformationRequestSubmittedByAgencyAttachment(this.applicationForm.applicationId, 
                            data.additionalInformationRequestId, 
                            data.item.additionalInformationRequestItemId, 
                            data.item.actionedByAgencyFileStorage.fileStorageId) 
                }
                                   
            })
        )

        this.generateDependencyServices();
        // get Side Navigation of the form
        this.sideBar = $("#leftCol > #application-inpage-nav > ul > li");
        // hightlight sub navigation when user is scrolling to a section
        //this.formNavigationServices.subNavigationScrollSpy();
        this.formNavigationServices.removeAllHightlight(this.sideBar);
        this.formNavigationServices.highlightFirstStep(this.sideBar);
    }

    private generateDependencyServices() {
        var questionTemplateArray = [];
        this.formTemplate.steps.forEach((step) => {
            step.sections.forEach((section) => {
                if (!section.repeatable || true) {
                    section.questions.forEach((question) => {
                        questionTemplateArray.push({ sectionRepeatable: section.repeatable, question: question });
                    });
                }
            });
        });

        this.dependencyServices = [];
        this.formTemplate.dependencyRules.forEach((dependencyRule) => {
            var a: FormDependencyService = this.formDependencyFactory(this.formInstance, dependencyRule, questionTemplateArray, this.formControlId);
            this.dependencyServices.push(a);
        });
    }

    private disposeDependencyServices() {
        if (this.dependencyServices) {
            while (this.dependencyServices.length > 0) {
                this.dependencyServices.pop().dispose();
            }
        }
    }

    sectionAddedCallback() {
        this.disposeDependencyServices();
        this.generateDependencyServices();
    }

    detached() {
        this.disposeDependencyServices();
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }

    //
    // Parent interface (start)
    //
    validateForSave(): Promise<boolean> {
        this.onSubmitValidation = false;
        return this.controller.validate().then((result: ControllerValidateResult) => {
            if (!result.valid) {
                this.jumpToErrorSection();
                this.showValidationErrorCount = true;
                return false;
            }
            return true;
        });
    }

    validateForSubmit(): Promise<boolean> {
        this.onSubmitValidation = true;
        return this.controller.validate().then((result: ControllerValidateResult) => {
            if (!result.valid) {
                this.jumpToErrorSection();
                this.showValidationErrorCount = true;
                return false;
            }
            return true;
        });
    }
    //
    // Parent interface (end)
    //

    navToSection(stepInstance: SharedDto.OnlineForm.Application.IFormInstanceStepDto,
        sectionGroup: SharedDto.OnlineForm.Application.IFormInstanceSectionGroupDto,
        scroll: boolean = true) {
        this.setActiveStep(stepInstance, false);

        var sectionName = 'section-' + sectionGroup.templateSectionId;
        var sectionElement = document.getElementById(sectionName);

        this.determineSectionNavigation();
        if (scroll) {
            setTimeout(() => {
                if (stepInstance.sectionGroups[0] === sectionGroup) {
                    this.scrollToElement(this.mainContent);
                } else {
                    this.scrollToElement(sectionElement);
                }
            }, 200);
        }
    }

    scrollToElement(element: HTMLElement) {
        //use microtask to ensure scrolling calculations happen after DOM is updated, stops weird results
        this.taskQueue.queueMicroTask(() => {
            scrollIntoView(element, { align: { top: 0, topOffset: 130 } });
        });

    }

    setActiveStep(step: SharedDto.OnlineForm.Application.IFormInstanceStepDto, scroll: boolean = true) {
        this.showDeclaration = false;
        this.showIntro = false;
        this.showRequiredDocuments = false;
        this.activeStep = step;
        this.determineSectionNavigation();
    }

    showIntroPage() {
        this.activeStep = null;
        this.showIntro = true;
        this.showDeclaration = false;
        this.showRequiredDocuments = false;
        this.determineSectionNavigation();
        this.scrollToElement(this.mainContent);
    }

    showDeclarationPage() {
        this.activeStep = null;
        this.showDeclaration = true;
        this.showIntro = false;
        this.showRequiredDocuments = false;
        this.determineSectionNavigation();
        this.scrollToElement(this.mainContent);
    }

    showRequiredDocumentsPage() {
        this.activeStep = null;
        this.showDeclaration = false;
        this.showIntro = false;
        this.showRequiredDocuments = true;
        this.determineSectionNavigation();
        this.scrollToElement(this.mainContent)
    }

    private jumpToErrorSection() {
        var index = this.formInstance.steps.indexOf(this.activeStep);
        if (index > -1 && this.errors[index].length > 0) {
            // don't jump to another section if this section has errors
            return;
        }
        // need to match error array index to step array index...
        var index = this.errors.findIndex(e => e.length > 0);
        if (index >= 0) {
            this.setActiveStep(this.formInstance.steps[index], false);
        }
        // if no steps have an error, try the declaration
        else if (this.declarationErrors.length > 0) {
            this.showDeclarationPage();
        }
    }

    determineSectionNavigation() {
        var nav: IButtonNavigation = {
            previous: null,
            next: null
        };

        // are we in the intro?
        if (this.showIntro) {
            if (this.formInstance.steps.length > 0) {
                nav.next = "Step";
                nav.nextStepIndex = 0;
            }
            else {
                nav.next = "Declaration";
            }

            this.formNavigationServices.removeAllHightlight(this.sideBar)
            this.formNavigationServices.highlightFirstStep(this.sideBar)
        }
        else if (this.showRequiredDocuments) {
            nav.next = "Declaration";
            if (this.formInstance.steps.length > 0) {
                nav.previous = "Step";
                nav.previousStepIndex = this.formInstance.steps.length - 1;
            }
            else {
                nav.previous = "Introduction";
            }
        }
        // are we on the declaration
        else if (this.showDeclaration) {
            if (this.formInstance.steps.length > 0) {
                nav.previous = "Step";
                nav.previousStepIndex = this.formInstance.steps.length - 1;
            } else {
                nav.previous = this.formTemplate.showIntroPage ? "Introduction" : null;
            }
            this.formNavigationServices.removeAllHightlight(this.sideBar)
            this.formNavigationServices.highlightLastStep(this.sideBar)
        } else {
            // is there only 1 step?
            if (this.formInstance.steps.length == 1) {
                nav.previous = this.formTemplate.showIntroPage ? "Introduction" : null;
                nav.next = "Declaration";

                if (this.formTemplate.showIntroPage) {
                    this.formNavigationServices.removeAllHightlight(this.sideBar)
                    this.formNavigationServices.highlightLastStep(this.sideBar)
                } else {
                    this.formNavigationServices.removeAllHightlight(this.sideBar)
                    this.formNavigationServices.highlightFirstStep(this.sideBar)
                }
            } else {
                var index = this.formInstance.steps.indexOf(this.activeStep);
                // are we at the start?
                if (index == 0) {
                    nav.previous = this.formTemplate.showIntroPage ? "Introduction" : null;
                    nav.next = "Step";
                    nav.nextStepIndex = index + 1;
                } else if (index == this.formInstance.steps.length - 1) {
                    nav.previous = "Step";
                    nav.previousStepIndex = index - 1;
                    nav.next = "Declaration";
                } else {
                    nav.previous = "Step";
                    nav.previousStepIndex = index - 1;
                    nav.next = "Step";
                    nav.nextStepIndex = index + 1;
                }
                this.formNavigationServices.highlightJumpStep(nav, this.sideBar, this.formTemplate.showIntroPage)
            }
        }
        this.buttonNavigationState = nav;
    }

    navigateForm(type: string) {
        switch (type) {
            case 'next':
                this.formNavigationServices.highlightNextStep(this.buttonNavigationState, this.sideBar, this.formTemplate.showIntroPage);
                this.navigateNextStep();
                break;
            case 'previous':
                this.formNavigationServices.highlightPreviousStep(this.buttonNavigationState, this.sideBar);
                this.navigatePreviousStep();
                break;
        }
    }

    navigateNextStep() {
        // perform navigation
        if (!this.buttonNavigationState.next) {
            return;
        }

        if (this.buttonNavigationState.next == "Introduction") {
            this.showIntroPage();
        } else if (this.buttonNavigationState.next == "Declaration") {
            this.showDeclarationPage();
        } else if (this.buttonNavigationState.next == "RequiredDocuments") {
            this.showRequiredDocumentsPage();
        } else {
            this.navToSection(
                this.formInstance.steps[this.buttonNavigationState.nextStepIndex],
                this.formInstance.steps[this.buttonNavigationState.nextStepIndex].sectionGroups[0]);
        }
    }

    navigatePreviousStep() {
        // perform navigation
        if (!this.buttonNavigationState.previous) {
            return;
        }

        if (this.buttonNavigationState.previous == "Introduction") {
            this.showIntroPage();
        } else if (this.buttonNavigationState.previous == "Declaration") {
            this.showDeclarationPage();
        } else if (this.buttonNavigationState.previous == "RequiredDocuments") {
            this.showRequiredDocumentsPage();
        } else {
            this.navToSection(
                this.formInstance.steps[this.buttonNavigationState.previousStepIndex],
                this.formInstance.steps[this.buttonNavigationState.previousStepIndex].sectionGroups[0]);
        }
    }

    @computedFrom("formInstance")
    get standardDeclaration() {
        return !this.applicationForm.createdByAgencyId;
    }

}
