import { Disposable } from 'aurelia-binding';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, bindingMode, computedFrom, customElement, PLATFORM } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SharedDto } from "project/project-shared";
import { Notifier } from 'services/notifier';
import { SharedBroadcastEvents } from 'shared/utils/SharedBroadcastEvents';
import { IRequestAdditionalInformationModalParams } from '../request-additional-information/request-additional-information-modal/request-additional-information-modal';
import { SharedRequestAdditionalInformationServices } from '../request-additional-information/shared-request-additional-information-services';
import { QuestionVisibilityChannelName } from "../form-utils";

@customElement('form-question-group')
export class FormQuestionGroup {
    @bindable({ defaultBindingMode: bindingMode.toView }) formInstance: SharedDto.OnlineForm.Application.IFormInstanceDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) formTemplate: SharedDto.OnlineForm.Form.IFormTemplateDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) questionTemplate: SharedDto.OnlineForm.Form.IQuestionDto;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionGroup: SharedDto.OnlineForm.Application.IFormInstanceQuestionGroupDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) stepVisibility: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) sectionVisibility: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) onSubmitValidation: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;
    @bindable() sectionIndex: number = 0;
    @bindable() sectionRepeatable: boolean = null;
    @bindable() formControlId: number = null;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) router: Router;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) additionalInformationRequest: SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestSharedDto[];
    @bindable({ defaultBindingMode: bindingMode.twoWay }) allowAdditionalInfoRequests: boolean = false;

    //Used to determine if you are allowed to add in additional instances of the section.
    canAddQuestion: boolean;
    canDeleteQuestion: boolean;

    qSingleLineText: SharedDto.Constants.QuestionType.SingleLineText;

    showSingleLineText: boolean = false;
    showMultiLineText: boolean = false;
    showYesNoToggle: boolean = false;
    showNumber: boolean = false;
    showDecimal: boolean = false;
    showAddress: boolean = false;
    showDate: boolean = false;
    showDateRange: boolean = false;
    showSingleSelectList: boolean = false;
    showMultiSelectList: boolean = false;
    showLocation: boolean = false;
    showFileUpload: boolean = false;
    showRequestedFundingAmounts: boolean = false;
    showContact: boolean = false;
    showChild: boolean = false;
    showOpeningHours: boolean = false;
    showText: boolean = false;
    showPerson: boolean = false;
    showBusiness: boolean = false;
    showAbnLookup: boolean = false;

    showRequestAdditionalInformation: boolean = false;

    private subscriptions: Disposable[] = [];

    questionIndex: number;

    constructor(private eventAggregator: EventAggregator,
        private readonly dialogService: DialogService,
        private readonly notifier: Notifier,
        private readonly requestAdditionalInfoService: SharedRequestAdditionalInformationServices) {

    }

    bind() {
        switch (this.questionTemplate.questionType) {
            case SharedDto.Constants.QuestionType.SingleLineText:
                this.showSingleLineText = true;
                break;
            case SharedDto.Constants.QuestionType.URL:
                this.showSingleLineText = true;
                break;
            case SharedDto.Constants.QuestionType.SocialMediaLink:
                this.showSingleLineText = true;
                break;
            case SharedDto.Constants.QuestionType.Email:
                this.showSingleLineText = true;
                break;
            case SharedDto.Constants.QuestionType.PhoneNumber:
                this.showSingleLineText = true;
                break;
            case SharedDto.Constants.QuestionType.MultiLineText:
                this.showMultiLineText = true;
                break;
            case SharedDto.Constants.QuestionType.YesNoToggle:
                this.showYesNoToggle = true;
                break;
            case SharedDto.Constants.QuestionType.Number:
                this.showNumber = true;
                break;
            case SharedDto.Constants.QuestionType.Money:
                this.showDecimal = true;
                break;
            case SharedDto.Constants.QuestionType.Decimal:
                this.showDecimal = true;
                break;
            case SharedDto.Constants.QuestionType.Address:
                this.showAddress = true;
                break;
            case SharedDto.Constants.QuestionType.Date:
                this.showDate = true;
                break;
            case SharedDto.Constants.QuestionType.DateRange:
                this.showDateRange = true;
                break;
            case SharedDto.Constants.QuestionType.RadioButton:
                this.showSingleSelectList = true;
                break;
            case SharedDto.Constants.QuestionType.SingleSelectList:
                this.showSingleSelectList = true;
                break;
            case SharedDto.Constants.QuestionType.MultiSelectList:
                this.showMultiSelectList = true;
                break;
            case SharedDto.Constants.QuestionType.CheckBoxList:
                this.showMultiSelectList = true;
                break;
            case SharedDto.Constants.QuestionType.Location:
                this.showLocation = true;
                break;
            case SharedDto.Constants.QuestionType.FileUpload:
            case SharedDto.Constants.QuestionType.RequiredDocument:
                this.showFileUpload = true;
                break;
            case SharedDto.Constants.QuestionType.RequestedFundingAmount:
                this.showRequestedFundingAmounts = true;
                break;
            case SharedDto.Constants.QuestionType.Contact:
                this.showContact = true;
                break;
            case SharedDto.Constants.QuestionType.Child:
                this.showChild = true;
                break;
            case SharedDto.Constants.QuestionType.OpeningHours:
                this.showOpeningHours = true;
                break;
            case SharedDto.Constants.QuestionType.TextOnly:
                this.showText = true;
                break;
            case SharedDto.Constants.QuestionType.Person:
                this.showPerson = true;
                break;
            case SharedDto.Constants.QuestionType.Business:
                this.showBusiness = true;
                break;
            case SharedDto.Constants.QuestionType.ABNLookup:
                this.showAbnLookup = true;
                break;
            default:
                throw new Error('Question Type Not Supported!');
        }

        if (this.questionTemplate.questionId != this.questionGroup.templateQuestionId) {
            throw new Error('QuestionsId from template and group do not match!');
        }

        if (!this.questionGroup.questions) {
            this.questionGroup.questions = [];
        }

        if (this.questionTemplate.repeatable) {
            if (this.questionTemplate.repeatMin && this.questionTemplate.repeatMin > this.questionGroup.questions.length) {
                //See we we need some dummy instance as there may be a min of 3.
                var instancesNeededToBeCreated = this.questionTemplate.repeatMin - this.questionGroup.questions.length;
                for (var i = 0; i < instancesNeededToBeCreated; i++) {
                    this.addQuestionInstance();
                }
            }
        }
        if (this.questionGroup.questions.length == 0) {
            this.addQuestionInstance();
        }

        this.updateQuestionValues();
    }

    attached() {
        let channelName;
        if (this.sectionRepeatable) {
            channelName = QuestionVisibilityChannelName(this.formControlId, this.questionTemplate.questionId, this.sectionIndex);
        } else {
            channelName = QuestionVisibilityChannelName(this.formControlId, this.questionTemplate.questionId)
        }

        this.subscriptions.push(this.eventAggregator.subscribe(channelName, (visibleStatus: boolean) => {
            this.questionGroup.visible = visibleStatus;
        }));
    }

    detached() {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }

    private updateQuestionValues() {
        this.canAddQuestion = !this.readonly && this.questionTemplate.repeatable
            && ((this.questionTemplate.repeatMax && this.questionTemplate.repeatMax > this.questionGroup.questions.length)
                || this.questionTemplate.repeatMax == null);

        this.canDeleteQuestion = !this.readonly && this.questionTemplate.repeatable
            && ((this.questionTemplate.repeatMin && this.questionTemplate.repeatMin < this.questionGroup.questions.length)
                || !this.questionTemplate.repeatMin);
    }

    addQuestionInstance() {
        this.questionGroup.questions.push({
            templateQuestionId: this.questionGroup.templateQuestionId,
            questionType: this.questionTemplate.questionType
        } as SharedDto.OnlineForm.Application.IAnswerDto);
        this.updateQuestionValues();
    }

    removeQuestionInstance(questionInstance: SharedDto.OnlineForm.Application.IAnswerDto) {
        var found = this.questionGroup.questions.indexOf(questionInstance);
        if (found >= 0) {
            this.questionGroup.questions.splice(found, 1);
        }

        this.updateQuestionValues();
    }

    async requestAdditionalInformationModal(questionIndex: number) {
        // check if allowed to request additional information
        let grantId = this.router.currentInstruction.parentInstruction.parentInstruction.params.grantIdOrNew;
        let roundId = this.router.currentInstruction.parentInstruction.parentInstruction.params.roundIdOrNew;
        let applicationId = this.router.currentInstruction.parentInstruction.params.applicationId;

        return await this.dialogService.open({
            viewModel: PLATFORM.moduleName('shared/controls/online-form/request-additional-information/request-additional-information-modal/request-additional-information-modal'),
            model: {
                grantId: grantId,
                roundId: roundId,
                applicationId: applicationId,
                additionalInformationRequestId: 'new'
            } as IRequestAdditionalInformationModalParams
        }).whenClosed(result => {
            if (result.wasCancelled) {
                return;
            }

            result.output.formInstanceId = this.formInstance.formInstanceId;
            result.output.formQuestionId = this.questionTemplate.questionId;
            result.output.formInstanceAnswerId = this.questionGroup.questions[questionIndex].questionId;

            this.requestAdditionalInfoService.createAndSubmitAdditionalInfoRequest(applicationId, result.output).then(x => {
                this.eventAggregator.publish(SharedBroadcastEvents.additionalInformationResquested);
                this.notifier.success("Additional information requested")
            });

            this.additionalInformationRequest.push(result.output);

            return result.output;
        });
    }

    toggleRequestAdditionalInformation(state: boolean) {
        this.showRequestAdditionalInformation = state && this.allowAdditionalInfoRequests;
    }

    @computedFrom("questionTemplate.readOnly", "readonly")
    get isReadOnly(): boolean {
        if (this.formInstance) {
            if (this.questionTemplate.lockAnswer) {
                return true;
            }
        }
        //parent element readonly state
        return this.readonly;
    }

    // All of the rules below should be true for Request additional informaiton to show up
    // 1. it was indicated in the configuration Additional information is allowed << if we will in the future restrict some of the question
    // 2. the question group was hovered by the user 
    // 3. user has the correct permission to request additional information << TODO
    // 4. and is in the internal site
    @computedFrom('showRequestAdditionalInformation', 'allowAdditionalInfoRequests')
    get showAdditionalInformation(): boolean {
        return this.showRequestAdditionalInformation && this.allowAdditionalInfoRequests;
    }
}
