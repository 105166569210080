import { autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { ValidationControllerFactory, ValidationRules } from "aurelia-validation";
import { ValidationModuleBase } from "base-class";
import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";
import { AssessmentService } from "services/assesment-service";
import { DataService } from "services/data-service";
import { Notifier } from "services/notifier";
import { Confirm } from "shared/controls/confirm-modal/confirm";
import { ValidationError } from "shared/controls/validation-error";
import { ClientValidationError } from "shared/utils/client-validation-error";
import { Messages } from "utils/messages";

@autoinject
export class AssessmentEdit extends ValidationModuleBase {

    saving: boolean;
    submitting: boolean;
    assessmentCheckId: number;
    model: Dto.Assessment.IAssessmentCheckResponseDto;
    fundingRecommendations: SharedDto.ICodeListItemDto[];

    validationError: ValidationError;

    constructor(private readonly router: Router,
        validationControllerFactory: ValidationControllerFactory,
        private readonly notifier: Notifier,
        private readonly confirm: Confirm,
        private readonly dataService: DataService,
        private readonly assessmentService: AssessmentService) {
        super(validationControllerFactory)
    }

    activate(params: { assessmentCheckId: number }) {
        this.assessmentCheckId = params.assessmentCheckId;

        let promises = [];

        promises.push(
            this.dataService.getFundingRecommendationOutcomes().then((result) => {
                this.fundingRecommendations = result;
            })
        );
        promises.push(
            this.assessmentService.getAssessment(this.assessmentCheckId).then(data => {
                this.model = data;
            })
        );
        return Promise.all(promises);
    }

    attached() {
        ValidationRules
            .ensure((x: Dto.Assessment.IAssessmentCheckResponseDto) => x.comments).maxLength(4000)
            .ensure((x: Dto.Assessment.IAssessmentCheckResponseDto) => x.comments).required().when(() => this.submitting)
            .ensure((x: Dto.Assessment.IAssessmentCheckResponseDto) => x.fundingRecommendationOutcome).required().when(() => this.model.requiresFundingReccomendation && this.submitting)
            .on(this.model);

        if (this.model.numericTypeList) {
            this.model.numericTypeList.forEach(numericQuestion => {
                ValidationRules
                    .ensure((x: Dto.Assessment.IAssessmentCheckResponseNumericDto) => x.comment).maxLength(4000)
                    .ensure((x: Dto.Assessment.IAssessmentCheckResponseNumericDto) => x.numericResult).required().when(() => this.submitting)
                    .on(numericQuestion);
            });
        }

        if (this.model.questionTypeList) {
            this.model.questionTypeList.forEach(questionType => {
                ValidationRules
                    .ensure((x: Dto.Assessment.IAssessmentCheckResponseQuestionDto) => x.result).maxLength(4000)
                    .ensure((x: Dto.Assessment.IAssessmentCheckResponseQuestionDto) => x.result).required().when(() => this.submitting)
                    .on(questionType);
            });
        }
    }

    bind() {
    }

    downloadHelpDocument(helpAttachment: SharedDto.IFilePropertiesDto): void {
        this.assessmentService.downloadHelpDocument(this.assessmentCheckId, helpAttachment.fileStorageId);
    }

    cancel() {
        this.router.navigateToRoute("AssessmentsView", { assessmentCheckId: this.assessmentCheckId });
    }

    submit() {
        this.submitting = true;
        this.controller.validate().then(result => {
            if (!result.valid) {
                throw new ClientValidationError(result);
            }
            return this.confirm.confirm("By submitting this outcome, you will not be able to edit your assessment. If you are not finished click on No, then use Save instead. To confirm that you are finished and to submit your assessment, click on Yes.", "Submit Assessment").then(ok => {
                if (ok) {
                    return this.assessmentService.saveAssessment(this.assessmentCheckId, this.model, true).then(() => { });
                }
                else {
                    var error = new Error();
                    (<any>error).wasCancelled = true;
                    throw error;
                }
            });
        }).then(() => {
            this.notifier.success("Assessment saved and submitted successfully");
            this.router.navigateToRoute("AssessmentsView", { assessmentCheckId: this.assessmentCheckId });
            this.saving = false;
        }).catch(error => {
            if (error.hasOwnProperty('wasCancelled')) {
                return;
            }
            if (error instanceof ClientValidationError) {
                this.notifier.standardMessage(Messages.validationHasErrors);
            }
            else if (error instanceof ValidationError) {
                this.validationError = error;
                this.notifier.error("There were errors performing this operation. Please correct any errors and try again");
            }
            else {
                this.notifier.standardMessage(Messages.serverError);
            }
        });
    }

    save() {
        this.submitting = false;
        this.saving = true;
        this.controller.validate().then(result => {
            if (!result.valid) {
                throw new ClientValidationError(result);
            }
            return this.assessmentService.saveAssessment(this.assessmentCheckId, this.model, false).then(() => { });
        }).then(() => {
            this.notifier.success("Assessment saved successfully");
            this.router.navigateToRoute("AssessmentsView", { assessmentCheckId: this.assessmentCheckId });
        }).catch(error => {
            if (error instanceof ClientValidationError) {
                this.notifier.standardMessage(Messages.validationHasErrors);
            }
            else if (error instanceof ValidationError) {
                this.validationError = error;
                this.notifier.error("There were errors performing this operation. Please correct any errors and try again");
            }
            else {
                this.notifier.standardMessage(Messages.serverError);
            }
        }).then(() => {
            this.saving = false;
        });
    }

    viewApplication() {
        window.open(this.router.generate("ApplicationView", { applicationId: this.model.applicationId }), '_blank');
    }
}
