import { customAttribute, autoinject, bindable } from 'aurelia-framework';
import { bindingMode } from "aurelia-binding";

@customAttribute('gnt-tooltip')
@autoinject
export class GrantsTooltip {

    @bindable({ defaultBindingMode: bindingMode.toView }) title: string;
    @bindable({ defaultBindingMode: bindingMode.toView }) template: string;
    @bindable({ defaultBindingMode: bindingMode.toView }) placement: string = "auto";

    constructor(private element: Element) {
    }

    titleChanged(newValue, oldValue) {
        //updating tooltips without destroying taken from: https://stackoverflow.com/questions/9501921/change-twitter-bootstrap-tooltip-content-on-click
        $(this.element)
            .attr('title', this.title)
            .tooltip('fixTitle')
            .tooltip('setContent');
    }

    attached() {
        if (this.title) {
            $(this.element).tooltip({
                title: this.title,
                placement: this.placement,
                template: this.template
            });
        } else {
            $(this.element).tooltip();
        }
    }

    unbind() {
        $(this.element).tooltip('destroy');
    }
}
