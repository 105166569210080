import { autoinject } from 'aurelia-framework';
import { RequestHandler } from "./request-handler";
import { Dto } from 'project/project';
import { SharedDto } from 'project/project-shared';
import { Config } from 'config/config';

@autoinject
export class FeaturedGrantService {
    constructor(private requestHandler: RequestHandler) {
    }

    getFeaturedGrant(limit: number = 5, previewMode: boolean): Promise<SharedDto.FeaturedGrant.IExternalHomePageFeaturedGrantDto[]> {
        return this.requestHandler.get<SharedDto.FeaturedGrant.IExternalHomePageFeaturedGrantDto[]>(`featured-grant/view/${limit}/${previewMode}`);
    }   

    generateImageUrl(featuredGrantId: number) {
        return `${Config.baseUrl}/featured-grant/image/${featuredGrantId}`
    }
}

