import { PLATFORM } from "aurelia-pal";
import { Router, RouterConfiguration, NavigationInstruction } from "aurelia-router";
import { Dto } from "project/project";
import ExternalPageRoutes = Dto.Constants.ExternalPageRoutes;

export class IndividualBankDetailsModule {

    configureRouter(config: RouterConfiguration, router: Router, params) {
        config.map([
            {
                route: ['','bank-details/list'],
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.IndividualBankDetailsList],
                moduleId: PLATFORM.moduleName("profile/individual/bank-details/list/bank-details-list"),
                navigationStrategy: this.editRedirectNavStrategy,
                title: "Personal bank details",
                nav: false,
                settings: {
                    breadcrumb: true,
                    parent: ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.IndividualProfile]
                }
            },
            {
                route: ['bank-details/edit'],
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.IndividualBankDetailsEdit],
                moduleId: PLATFORM.moduleName("profile/individual/bank-details/edit/bank-details-edit"),
                title: "Edit personal bank details",
                nav: false,
                settings: {
                    breadcrumb: true,
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.IndividualBankDetailsList],
                }
            }
        ]);
    }

    private editRedirectNavStrategy = (instruction: NavigationInstruction) => {
        if (instruction.parentInstruction.parentInstruction.params.individualProfileId === "new") {
            instruction.config.redirect = "edit";
        }
    }
}