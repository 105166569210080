export class Store {

    private localStorage: Storage;
    private sessionStorage: Storage;
    private cacheStorage: Storage;

    static keyAccessToken = "gms-auth-access-token";
    static keyRefreshToken = "gms-auth-refresh-token";
    static keyRememberMe = "gms-auth-remember-me";
    static keyActiveProfile = "gms-active-profile";

    constructor() {
        this.initialise();
    }

    /**
     * Retrieves value from Local (Persistant) Storage if available, otherwise retrieves from Session (Transient) Storage.
     * If neither are available then a local cache is used.
     * @param key
     */
    getl<T>(key: string): T {
        const storage = this.getStorage("l");
        if (storage != null) {
            const obj = storage.getItem(key);
            return (JSON.parse(obj)) as T;
        }
        return null;
    }

    /**
     * Stores value into Local (Persistant) Storage if available, otherwise stores to Session (Transient) Storage.
     * If neither are available then a local cache is used.
     * @param key
     */
    setl<T>(key: string, value: T): any {
        const storage = this.getStorage("l");
        if (storage != null) {
            return storage.setItem(key, JSON.stringify(value));
        }
        return null;
    }

    /**
     * Removes stored value from Local (Persistant) Storage if available, otherwise removes from Session (Transient) Storage.
     * If neither are available then a local cache is used.
     * @param key
     */
    removel(key: string): any {
        const storage = this.getStorage("l");
        if (storage != null) {
            storage.removeItem(key);
        }
        return null;
    }

    /**
     * Retrieves value from Session (Transient) Storage.
     * If not available then a local cache is used.
     * @param key
     */
    gets<T>(key: string): T {
        const storage = this.getStorage("s");
        if (storage != null) {
            const obj = storage.getItem(key);
            return (JSON.parse(obj)) as T;
        }
        return null;
    }

    /**
     * Stores value into Session (Transient) Storage.
     * If not available then a local cache is used.
     * @param key
     */
    sets<T>(key: string, value: T): any {
        const storage = this.getStorage("s");
        if (storage != null) {
            return storage.setItem(key, JSON.stringify(value));
        }
        return null;
    }

    /**
     * Removes stored value from Session (Transient) Storage.
     * If not available then a local cache is used.
     * @param key
     */
    removes(key: string): any {
        const storage = this.getStorage("s");
        if (storage != null) {
            storage.removeItem(key);
        }
        return null;
    }

    private getStorage(type: string): Storage {
        switch (type) {
            case "l":
                if (this.localStorage != null) {
                    return this.localStorage;
                } else if (this.sessionStorage != null) {
                    return this.sessionStorage;
                } else {
                    return this.cacheStorage;
                }

            case "s":
                if (this.sessionStorage != null) {
                    return this.sessionStorage;
                } else {
                    return this.cacheStorage;
                }

            default:
                return undefined;
        }
    }

    private initialise(): void {
        this.localStorage = null;
        this.sessionStorage = null;
        this.cacheStorage = null;

        if ("localStorage" in window && window.localStorage !== null) {
            this.localStorage = window.localStorage;
        }
        if ("sessionStorage" in window && window.sessionStorage !== null) {
            this.sessionStorage = window.sessionStorage;
        }

        if (this.localStorage == null && this.sessionStorage == null) {
            this.cacheStorage = new CacheStore();
        }
    }
}

interface ICacheItem {
    key: string;
    value: string;
}

class CacheStore implements Storage {

    private cache: ICacheItem[];

    constructor () {
        this.cache = [];
    }

    [index: number]: string;

    clear (): void {
        this.cache.splice(0, this.cache.length);
    }

    getItem (key: string): any {
        const obj = this.cache.find((item: ICacheItem): boolean => {
            return item.key === key;
        });

        if (obj != null) {
            return obj.value;
        }

        return undefined;
    }

    key(index: number): string {
        if (index < this.cache.length - 1) {
            return this.cache[index].key;
        }

        return undefined;
    }

    removeItem (key: string): void {
        const idx = this.cache.findIndex((item: ICacheItem): boolean => {
            return item.key === key;
        });

        if (idx != null) {
            this.cache.splice(idx, 1);
        }
    }

    setItem (key: string, data: string): void {
        const obj = this.cache.find((item: ICacheItem): boolean => {
            return item.key === key;
        });

        if (obj != null) {
            obj.value = data;
        } else {
            this.cache.push({ key: key, value: data });
        }
    }

    get length (): number {
        return this.cache.length;
    }
}
