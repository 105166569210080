import {SharedDto} from "./project-shared";
//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export module Dto {
	export interface IAcceptVoucherRequestDto
	{
		voucherNumber: string;
		redeemedAmount: number;
	}
	export interface IApplicantVoucherListItemDto
	{
		voucherNumber: string;
		voucherValidFrom?: string;
		voucherExpiry: string;
		dateOfIssue?: string;
		voucherStatus: Dto.Constants.ExternalVoucherStatus;
		voucherStatusDescription: string;
		amountIsFixed: boolean;
		amount?: number;
		redeemedAmount?: number;
		voucherName: string;
		issuedFor: string;
		grantRoundId: number;
		grantRoundTitle: string;
		grantTitle: string;
		applicationFormId: number;
		acceptedBySupplierId?: number;
		acceptedBySupplierName: string;
		grantRoundVoucherRedemptionSettingsId?: number;
		confirmCompletionDeclarationHtml: string;
		canConfirmCompletion: boolean;
		associatedSupplierName: string;
		worksCompletedInformationRequired?: boolean;
		worksCompletedInformationRequiredHtml: string;
		applicationNumber: string;
	}
	export interface IApplicantVoucherSearchRequestDto extends SharedDto.IPagingFilter
	{
		individualProfileId?: number;
		organisationId?: number;
		showAll: boolean;
		keyword: string;
		externalVoucherStatus: Dto.Constants.ExternalVoucherStatus[];
	}
	export interface IExternalRedemptionSupplierDto
	{
		supplierId: number;
		contactEmail: string;
		contactPhone: string;
		supplierName: string;
		supplierDescription: string;
		supplierStatusCode: Dto.Constants.SupplierStatus;
		organisationId: number;
		grantDetailId: number;
		externalGrantTitle: string;
		grantRoundId: number;
		externalRoundTitle: string;
		localities: string[];
		categories: string[];
	}
	export interface IExternalSupplierRoundDto
	{
		supplierId: number;
		grantDetailId: number;
		grantRoundId: number;
		grantRoundVoucherRedemptionSettingsId: number;
		multipleVouchersPerRequest: boolean;
		supplierDeclarationRequired: boolean;
		supplierDeclarationHtml: string;
		activeFormId?: number;
		redemptionTitle: string;
		redemptionOverviewHtml: string;
		acceptedVoucherCount: number;
		acceptedVoucherValue: number;
		submittedVoucherCount: number;
		submittedVoucherValue: number;
		paidVoucherCount: number;
		paidVoucherValue: number;
		redemptionClosedVoucherCount: number;
		redemptionClosedVoucherValue: number;
		canAcceptVouchers: boolean;
		prePopulatedRedemptionAmount: boolean;
		enableCommentsAndDocuments: boolean;
		enableCommentsAndDocumentsHtml: string;
		totalCostOfWorksRequired: boolean;
		totalCostOfWorksHelpText: string;
		preventSupplierManualAccept: boolean;
	}
	export interface IExternalSupplierRoundSearchRequestDto extends SharedDto.IPagingFilter
	{
		supplierId: number;
	}
	export interface IExternalVoucherRedemptionRequestFilterDto extends SharedDto.IPagingFilter
	{
		supplierId: number;
		redemptionSettingsId: number;
	}
	export interface IExternalVoucherRedemptionRequestListItemDto
	{
		reportingScheduleRequirementId: number;
		reportingRequirementStatusCode: string;
		supplierId?: number;
		submissionDate?: string;
		voucherCount?: number;
		submittedById?: number;
		submittedByName: string;
		reportingScheduleRequirementSubmissionId?: number;
		grantRoundVoucherRedemptionSettingsId?: number;
		reportingRequirementOutcome?: Dto.Constants.ReportingRequirementOutcome;
		reportingRequirementOutcomeDescription: string;
		formInstanceId?: number;
		externalComments: string;
		invoiceNumber: string;
		redemptionDeclarationDate?: string;
		redemptionDeclarationHtml: string;
		voucherAmountTotal?: number;
		totalCostOfWork?: number;
	}
	export interface ISupplierUpdateVoucherRequestDto
	{
		redeemedAmount?: number;
	}
	export interface ISupplierDto
	{
		supplierId: number;
		supplierListId: number;
		organisationId: number;
		applicationFormId: number;
		supplierName: string;
		contactPhone: string;
		contactEmail: string;
	}
	export interface ISupplierVoucherListItemDto
	{
		voucherName: string;
		voucherNumber: string;
		voucherExpiry: string;
		voucherStatus: Dto.Constants.ExternalVoucherStatus;
		voucherStatusDescription: string;
		issuedFor: string;
		applicantName: string;
		dateRedeemed?: string;
		dateAccepted?: string;
		datePaid?: string;
		amount?: number;
		amountFixed: boolean;
		redeemedAmount?: number;
		approvedAmount?: number;
	}
	export interface ISupplierVoucherListExportItemDto
	{
		voucherName: string;
		voucherNumber: string;
		amount?: number;
		voucherExpiry: string;
		voucherStatus: string;
		issuedFor: string;
		applicantName: string;
		dateAccepted?: string;
		dateRedeemed?: string;
		datePaid?: string;
		redeemedAmount?: number;
	}
	export interface ISupplierVoucherSearchRequestDto extends SharedDto.IPagingFilter
	{
		keyword: string;
		supplierId: number;
		redemptionSettingsId: number;
		voucherStatus?: Dto.Constants.ExternalVoucherStatus;
		reportingRequirementSubmissionId?: number;
		includeExpired: boolean;
	}
	export interface IWorkCompleteRequestDto
	{
		redemptionSettingId: number;
		voucherNumber: string;
		declarationChecked: boolean;
		declarationContent: string;
		attachment: SharedDto.IFilePropertiesDto;
	}
	export interface IVoucherRedemptionRequestDto
	{
		voucherRedemptionSettingId: number;
		supplierId: number;
		voucherNumbers: string[];
		allPendingVouchers: boolean;
		declarationChecked: boolean;
		declarationContent: string;
		supplierComment: string;
		totalCostOfWork?: number;
		supplierAttachDocuments: SharedDto.IFilePropertiesDto[];
	}
	export interface IVoucherRedemptionStatusDto
	{
		allowRedemption: boolean;
		applicationId: number;
		documentsExpiring: Dto.IVoucherRedemptionRequiredDocumentDto[];
		documentsExpired: Dto.IVoucherRedemptionRequiredDocumentDto[];
		documentsInvalid: Dto.IVoucherRedemptionRequiredDocumentDto[];
	}
	export interface IVoucherRedemptionRequiredDocumentDto
	{
		documentTypeName: string;
		expiry: string;
		checkId: number;
		reportId: number;
	}
	export interface IAddressValidationDto
	{
		matches: SharedDto.IAddressDto[];
		matchResult: Dto.Constants.AddressValidationMatchResult;
	}
	export interface ISubscriptionOptionsDto
	{
		organisationId?: number;
		receiveEmails: boolean;
		categoryIds: number[];
		subscribeIndividual: boolean;
		subscribeOrganisation: boolean;
	}
	export interface IUserOrganisationProfile
	{
		organisationId: number;
		email: string;
		legalName: string;
		businessName: string;
		abn: string;
		acn: string;
		isOrganisationAdministrator: boolean;
		allowedRoutes: Dto.Constants.ExternalPageRoutes[];
		activities: Dto.Constants.OrgUserAccessActivity[];
	}
	export interface IChangePasswordFromResetRequest
	{
		password: string;
		recaptchaResponse: string;
	}
	export interface IPasswordResetRequest
	{
		username: string;
		recaptchaResponse: string;
	}
	export interface IUserResponse
	{
		accountId: number;
		individualProfileId: number;
		username: string;
		emailAddress: string;
		givenName: string;
		familyName: string;
		fullName: string;
		authenticationType: string;
		accountStatus: Dto.Constants.AccountStatus;
		userOrganisationProfiles: Dto.IUserOrganisationProfile[];
		isAssessor: boolean;
		isValid: boolean;
	}
	export interface IExternalUserInviteValidationResponseDto
	{
		tokenValid: boolean;
		emailAddress: string;
	}
	export interface ICompleteRegistrationRequestDto
	{
		code: string;
		emailAddress: string;
		givenName: string;
		familyName: string;
		password: string;
		confirmPassword: string;
		recaptchaResponse: string;
	}
	export interface IExternalUserRegisterRequestDto
	{
		emailAddress: string;
		recaptchaResponse: string;
	}
	export interface IApplyNowCheckResponseDto
	{
		canApplyNow: boolean;
		existingApplicationId?: number;
		hasValidIndividualProfile: boolean;
	}
	export interface IPublicContentEnquiryDto
	{
		contactEmail: string;
		contactName: string;
		contactPhone: string;
		enquiry: string;
		recaptchaResponse: string;
		organisationId?: number;
	}
	export interface IPublicContentSearchResultItemDto
	{
		grantId: number;
		contentTitle: string;
		grantTitle: string;
		externalGrantTitle: string;
		overviewHtml: string;
		agency: string;
		isIndividualEligible: boolean;
		isOrganisationEligible: boolean;
		isOrganisationForProfitEligible: boolean;
		isOrganisationNonProfitEligible: boolean;
		eligibilityDisplay: string;
		grantContactName: string;
		contactType: string;
		grantContactPhone: string;
		grantContactEmail: string;
		availableFunding: number;
		grantMaximumFunding: number;
		applicationsOpen?: string;
		applicationsClose?: string;
		publicContentType: Dto.Constants.PublicContentType;
		publicContentTypeDescription: string;
		thirdParty: boolean;
	}
	export interface IPublicContentSearchResultsDto
	{
		totalRecords: number;
		results: SharedDto.PublicContent.IPublicContentSummaryViewDto[];
	}
	export interface IPublicContentSearchFilterRequestDto extends SharedDto.IPagingFilter
	{
		publishDateFrom?: string;
		publishDateTo?: string;
		applicationsOpenFrom?: string;
		applicationsOpenTo?: string;
		contentSlug: string;
		agencies: number[];
		beneficiaries: number[];
		categories: number[];
		individualsCanApply?: boolean;
		nonProfitCanApply?: boolean;
		forProfitCanApply?: boolean;
		keywords: string;
		publicContentTypes: Dto.Constants.PublicContentType[];
	}
	export interface IAssessorInviteValidationResponseDto
	{
		grantName: string;
		invitedByGivenName: string;
		invitedByFamilyName: string;
		tokenValid: boolean;
	}
	export interface IPersonalProfileDto
	{
		titleId?: number;
		title: string;
		givenName: string;
		familyName: string;
		middleName: string;
		residentialAddress: SharedDto.IAddressDto;
		postalAddress: SharedDto.IAddressDto;
		postalSameAsResidentialAddress: boolean;
		aborigionalTsiFlag: number;
		citizenPermResidentFlag?: boolean;
		mobilePhoneNumber: string;
		homePhoneNumber: string;
		workPhoneNumber: string;
		lastModifiedBy: string;
		lastModifiedTime: string;
		emailAddress: string;
	}
	export interface IOrganisationUserDto
	{
		organisationUserId: number;
		organisationId: number;
		givenName: string;
		familyName: string;
		fullName: string;
		emailAddress: string;
		organisationUserStatus: Dto.Constants.OrganisationUserStatus;
		organisationUserStatusDesc: string;
		inviteCodeExpiry?: string;
		access: Dto.IOrganisationUserAccessDto[];
		lastModifiedBy: string;
		lastModifiedTime: string;
		isOrganisationAdministrator: boolean;
		canViewPayment: boolean;
	}
	export interface IOrganisationValidationDto
	{
		errors: string[];
		bankDetails: SharedDto.IBankDetailsDto;
	}
	export interface IOrganisationApproverDto
	{
		organisationUserId: number;
		givenName: string;
		familyName: string;
		fullName: string;
		organisationUserStatus: Dto.Constants.OrganisationUserStatus;
		organisationUserStatusDesc: string;
	}
	export interface IOrganisationAuditResponseDto
	{
		results: Dto.IOrganisationAuditResponseItemDto[];
		totalRecords: number;
	}
	export interface IOrganisationAuditResponseItemDto
	{
		organisationAuditCategoryCode: string;
		createdOn: string;
		actionDescription: string;
		fileStorage: SharedDto.IFilePropertiesDto;
	}
	export interface IOrganisationAuditRequestDto extends SharedDto.IPagingFilter
	{
		organisationId: number;
		organisationAuditCategories: Dto.Constants.OrganisationAuditCategory[];
	}
	export interface IOrganisationDto
	{
		organisationId?: number;
		australianBusinessNumber: string;
		australianCompanyNumber: string;
		indigenousCorporationNumber: string;
		incorporatedAssociationNumber: string;
		vendorId: string;
		registeredTrainingOrganisationNumber: string;
		legalName: string;
		businessName: string;
		notForProfitFlag: boolean;
		legalEntityTypeId: number;
		legalEntityType: Dto.Constants.LegalEntityType;
		gstRegistered: boolean;
		phoneNumber: string;
		emailAddress: string;
		fax: string;
		website: string;
		streetAddress: SharedDto.IAddressDto;
		postalSameAsStreetAddress: boolean;
		postalAddress: SharedDto.IAddressDto;
		primaryContact: SharedDto.Profiles.IOrganisationContactDto;
		primaryActivityId?: number;
		subActivityId?: number;
		primaryLocationType?: any;
		primaryLocationTypeDescription: string;
		primaryLocationId?: number;
		secondaryLocationType?: any;
		secondaryLocationTypeDescription: string;
		secondaryLocations: number[];
		primaryLocationDescription: string;
		secondaryLocationsDescription: string;
		durationOperatingInNTId: number;
		lastModifiedBy: string;
		lastModifiedTime: string;
	}
	export interface IOrganisationInviteValidationResponseDto
	{
		organisationId: number;
		legalName: string;
		tradingName: string;
		invitedByGivenName: string;
		invitedByFamilyName: string;
		tokenValid: boolean;
	}
	export interface IOrganisationUserAccessDto
	{
		organisationUserAccessId?: number;
		orgUserAccessActivity: Dto.Constants.OrgUserAccessActivity;
		orgUserAccessActivityDesc: string;
		canCreateEditSubmit: boolean;
		canApprove: boolean;
	}
	export interface IOrganisationUserInviteDto
	{
		organisationId: number;
		emailAddress: string;
		isOrganisationAdministrator: boolean;
		canViewPayment: boolean;
		access: Dto.IOrganisationUserAccessDto[];
	}
	export interface IProfileDashboardInfoDto
	{
		awardedGrantCount: number;
		supplierRegistrationCount: number;
		reportingCount: number;
		acquittalCount: number;
		paymentCount: number;
		inviteCount: number;
		correspondenceCount: number;
		requestForInformationCount: number;
	}
	export interface IChangePasswordRequest
	{
		oldPassword: string;
		newPassword: string;
	}
	export interface IGlobalConfigurationDto
	{
		outstandingAcquittalsEnabled: boolean;
	}
	export interface ITermsOfAccessAndUseDto
	{
		version: number;
		content: string;
	}
	export interface IAccountDto
	{
		code: string;
		emailAddress: string;
		title: number;
		givenName: string;
		familyName: string;
		phoneNumber: string;
		residentialAddress: SharedDto.IAddressDto;
		postalAddress: SharedDto.IAddressDto;
		password: string;
		confirmPassword: string;
		recaptchaResponse: string;
		acceptedTermsOfAccessAndUse: boolean;
		acceptedTermsOfAccessAndUseVersion: string;
	}
	export interface INotification
	{
		successful: boolean;
		errors: string[];
		warnings: string[];
	}
}
export module Dto.Voucher {
	export interface IVoucherCheckResponseDto
	{
		issuedVoucher: Dto.IApplicantVoucherListItemDto;
		voucherNumber: string;
		checkResult: Dto.Constants.VoucherCheckResult;
	}
	export interface IExportVoucherCheckResponseDto
	{
		voucherNumber: string;
		checkResult: Dto.Constants.VoucherCheckResult;
		details: string;
	}
}
export module Dto.Variations {
	export interface IVariationRequestDto extends Dto.Variations.IVariationRequestUpdateDto
	{
		variationRequestId: number;
		variationReferenceNumber: string;
		variationStatus: Dto.Constants.ExternalVariationStatus;
		variationStatusDescription: string;
		recipientHelpText: string;
		requestedByInternalUserId?: number;
		requestedByInternalUserName: string;
		requestedByExternalUserId?: number;
		requestedByExternalUserName: string;
		outcomeComment: string;
		variationOutcome?: Dto.Constants.VariationAssessmentOutcome;
		variationOutcomeDescription: string;
		requestDocuments: SharedDto.IFilePropertiesDto[];
		requestOutcomeDocuments: SharedDto.IFilePropertiesDto[];
		recipientHelpDocuments: SharedDto.IFilePropertiesDto[];
		grantDetailVariationVariationTypes: Dto.Constants.VariationType[];
	}
	export interface IVariationRequestSaveRequestDto
	{
		variation: Dto.Variations.IVariationRequestUpdateDto;
		submitting: boolean;
	}
	export interface IVariationRequestUpdateDto
	{
		applicationFormId: number;
		requestDetails: string;
		requestedVariationTypes: Dto.Constants.VariationType[];
		requestDocuments: SharedDto.IFileUpdateDto[];
	}
	export interface IVariationSearchResultItemDto
	{
		applicationFormId: number;
		applicationReferenceNumber: string;
		projectServiceTitle: string;
		grantDetailId: number;
		variationRequestId: number;
		variationReferenceNumber: string;
		requestedVariationTypes: Dto.Constants.VariationType[];
		requestedVariationTypeDescriptions: string[];
		submittedDate: string;
		requestDetails: string;
		variationStatus: Dto.Constants.ExternalVariationStatus;
		variationStatusDescription: string;
		assignedUserId?: number;
		assignedUserName: string;
		requestedByInternalUserName: string;
		requestedByExternalUserId?: number;
		requestedByExternalUserName: string;
	}
	export interface IVariationsSearchFilterDto extends SharedDto.IPagingFilter
	{
		applicationFormId?: number;
		variationNumber: string;
		applicationReferenceNumber: string;
	}
}
export module Dto.OnlineForm.Application {
	export interface IApplicationFormSubmissionDto
	{
		application: SharedDto.OnlineForm.Application.IApplicationFormDto;
		organisationId?: number;
	}
}
export module Dto.Reporting {
	export interface IAcquittalRequirementDto extends Dto.Reporting.IPerformanceRequirementDto
	{
		acquittalScheduleId: number;
		acquittalDeliverableType: Dto.Constants.AcquittalDeliverableType;
		acquittalDeliverableTypeDescription: string;
		financialYearId?: number;
		financialYearDescription: string;
		frequency: any;
		frequencyDescription: string;
		status: Dto.Constants.ExternalAcquittalRequirementStatus;
		statusDescription: string;
		currentSubmission: Dto.Reporting.IAcquittalSubmissionDto;
		submissions: Dto.Reporting.IAcquittalSubmissionDto[];
		template: SharedDto.IFilePropertiesDto;
		actions: SharedDto.ICodeListItemDto[];
		reportName: string;
	}
	export interface IAcquittalSubmissionDto extends Dto.Reporting.IAcquittalSubmissionUpdateDto
	{
		documents: SharedDto.IFilePropertiesDto[];
		submittedByName: string;
		acquittalDeliverableType: Dto.Constants.AcquittalDeliverableType;
		submissionDate?: string;
		acquittalRequirementOutcome?: any;
		externalComments: string;
		acquittalRequirementOutcomeDescription: string;
	}
	export interface IAcquittalSubmissionUpdateDto
	{
		documents: SharedDto.IFileUpdateDto[];
		comments: string;
		acquittalAmount?: number;
	}
	export interface IExternalAcquittalViewDto extends Dto.Reporting.IExternalPerformanceViewDto
	{
		acquittalScheduleId: number;
		acquittalRequirementTypeId: number;
		acquittalDeliverableType: Dto.Constants.AcquittalDeliverableType;
		acquittalDeliverableTypeDescription: string;
		acquittalRequirementStatus: Dto.Constants.ExternalAcquittalRequirementStatus;
		acquittalRequirementStatusDescription: string;
	}
	export interface IExternalAcquittalViewFilterDto extends Dto.Reporting.IExternalPerformanceFilterDto
	{
		acquittalRequirementStatus?: Dto.Constants.ExternalAcquittalRequirementStatus;
		acquittalDeliverableType?: Dto.Constants.AcquittalDeliverableType;
	}
	export interface IExternalPerformanceFilterDto extends SharedDto.IPagingFilter
	{
		organisationId?: number;
		applicationNumber: string;
		awardedGrantId?: number;
		financialYearId: number[];
	}
	export interface IExternalPerformanceViewDto
	{
		organisationId?: number;
		applicationId: number;
		applicationNumber: string;
		grantName: string;
		grantRoundTitle: string;
		agencyName: string;
		projectServiceTitle: string;
		applicationSubject: string;
		dueDate?: string;
		grantDetailId: number;
		grantRoundId: number;
		agencyId: number;
		awardedGrantId: number;
		individualProfileId?: number;
		adminBodyOrganisationId?: number;
		adminBodyOriginalExternalUserName: string;
		adminBodyOriginalOrganisationName: string;
		reportName: string;
	}
	export interface IExternalReportingViewDto extends Dto.Reporting.IExternalPerformanceViewDto
	{
		reportingRequirementId: number;
		reportingRequirementTypeId: number;
		reportingRequirementType: Dto.Constants.ReportingRequirementType;
		reportingRequirementTypeDescription: string;
		reportingRequirementGroup: Dto.Constants.ReportingRequirementGroup;
		reportingRequirementGroupDescription: string;
		reportingRequirementStatus: Dto.Constants.ExternalReportingRequirementStatus;
		reportingRequirementStatusDescription: string;
	}
	export interface IExternalReportingViewFilterDto extends Dto.Reporting.IExternalPerformanceFilterDto
	{
		reportingRequirementStatus?: Dto.Constants.ExternalReportingRequirementStatus;
		reportingTypeId?: number;
	}
	export interface IPerformanceRequirementDto
	{
		projectServiceTitle: string;
		grantName: string;
		grantRoundTitle: string;
		grantId: number;
		agencyName: string;
		description: string;
		periodFrom?: string;
		periodTo?: string;
		dueDate?: string;
		linkedPayments: Dto.Reporting.IReportingRequirementLinkedPaymentDto[];
		lastModifiedBy: string;
		lastModifiedTime: string;
		canEdit: boolean;
		reportName: string;
		documentTypeName: string;
	}
	export interface IReportingRequirementDocumentDto extends Dto.Reporting.IReportingRequirementDto
	{
		template: SharedDto.IFilePropertiesDto;
	}
	export interface IReportingRequirementDocumentSubmissionDto extends Dto.Reporting.IReportingRequirementDocumentSubmissionUpdateDto
	{
		documents: SharedDto.IFilePropertiesDto[];
		submittedComments: string;
	}
	export interface IReportingRequirementDocumentSubmissionUpdateDto extends Dto.Reporting.IReportingRequirementSubmissionDto
	{
		documents: SharedDto.IFileUpdateDto[];
		invoiceNumber: string;
		gasInvoiceNumberLength: number;
		invoiceDate?: string;
		comments: string;
	}
	export interface IReportingRequirementDto extends Dto.Reporting.IPerformanceRequirementDto
	{
		reportingRequirementId: number;
		type: SharedDto.IReportingRequirementTypeDto;
		frequency: Dto.Constants.ReportingFrequency;
		frequencyDescription: string;
		status: Dto.Constants.ExternalReportingRequirementStatus;
		statusDescription: string;
		currentSubmission: Dto.Reporting.IReportingRequirementSubmissionDto;
		submissions: Dto.Reporting.IReportingRequirementSubmissionDto[];
		actions: SharedDto.ICodeListItemDto[];
	}
	export interface IReportingRequirementLinkedPaymentDto
	{
		dueDate: string;
		amountExclGst: number;
		paymentScheduleItemStatus: string;
		conditions: Dto.Reporting.IRequirementConditionDto[];
	}
	export interface IReportingRequirementOutcomeDto extends Dto.Reporting.IReportingRequirementDto
	{
		outcomeMeasures: SharedDto.Reporting.IOutcomeMeasureDto[];
	}
	export interface IReportingRequirementOutcomeMeasuresDto extends Dto.Reporting.IReportingRequirementOutcomeMeasuresUpdateDto
	{
		submittedDocument: SharedDto.IFilePropertiesDto;
		unitOfMeasure: SharedDto.Reporting.IOutcomeUnitOfMeasureDto;
	}
	export interface IReportingRequirementOutcomeMeasuresUpdateDto
	{
		reportingScheduleRequirementSubmissionOutcomeMeasureId: number;
		reportingScheduleRequirementOutcomeMeasureId: number;
		reportingScheduleRequirementOutcomeUnitOfMeasureId: number;
		submittedDocument: SharedDto.IFileUpdateDto;
		numberOfUnits?: number;
		submittedComments: string;
	}
	export interface IReportingRequirementOutputDto extends Dto.Reporting.IReportingRequirementDto
	{
		outputMeasures: SharedDto.Reporting.IOutputMeasureDto[];
	}
	export interface IReportingRequirementOutputMeasuresDto extends Dto.Reporting.IReportingRequirementOutputMeasuresUpdateDto
	{
		submittedDocument: SharedDto.IFilePropertiesDto;
		unitOfMeasure: SharedDto.Reporting.IOutputMeasureDto;
	}
	export interface IReportingRequirementOutputMeasuresUpdateDto
	{
		reportingScheduleRequirementOutputMeasureId: number;
		numberOfUnits?: number;
		submittedComments: string;
		submittedDocument: SharedDto.IFileUpdateDto;
	}
	export interface IReportingRequirementOutputSubmissionDto extends Dto.Reporting.IReportingRequirementOutputSubmissionUpdateDto
	{
		outputMeasures: Dto.Reporting.IReportingRequirementOutputMeasuresDto[];
	}
	export interface IReportingRequirementOutputSubmissionUpdateDto extends Dto.Reporting.IReportingRequirementSubmissionDto
	{
		outputMeasures: Dto.Reporting.IReportingRequirementOutputMeasuresUpdateDto[];
	}
	export interface IReportingRequirementSubmissionDto
	{
		submittedByName: string;
		submissionDate?: string;
		reportingRequirementOutcome?: Dto.Constants.ReportingRequirementOutcome;
		reportingRequirementOutcomeDescription: string;
		externalComments: string;
	}
	export interface IRequirementConditionDto
	{
		description: string;
		dueDate?: string;
		status: string;
		outcome: string;
	}
	export interface IReportingRequirementOutcomeSubmissionDto extends Dto.Reporting.IReportingRequirementOutcomeSubmissionUpdateDto
	{
		outcomeMeasures: Dto.Reporting.IReportingRequirementOutcomeMeasuresDto[];
	}
	export interface IReportingRequirementOutcomeSubmissionUpdateDto extends Dto.Reporting.IReportingRequirementSubmissionDto
	{
		outcomeMeasures: Dto.Reporting.IReportingRequirementOutcomeMeasuresUpdateDto[];
	}
}
export module Dto.Payments {
	export interface IExternalPaymentDetailViewDto
	{
		applicationNumber: string;
		grantTitle: string;
		projectServiceTitle: string;
		totalApprovedFundingExclGst: number;
		paymentsMade: number;
		remaining: number;
		fundingPeriodFrom: string;
		fundingPeriodTo: string;
		paymentAmountExclGst: number;
		plannedPaymentDate: string;
		paymentStatus: string;
		conditions: Dto.Payments.IPaymentDetailConditionDto[];
		contacts: Dto.Payments.IPaymentDetailContactDto[];
	}
	export interface IPaymentDetailContactDto
	{
		contactName: string;
		emailAddress: string;
		phoneNumber: string;
	}
	export interface IPaymentDetailConditionDto
	{
		conditionTitle: string;
		conditionStatus: string;
		acquittalScheduleId?: number;
		reportingScheduleRequirementId?: number;
	}
	export interface IExternalPaymentsViewDto
	{
		awardedGrantId: number;
		paymentScheduleItemId: number;
		organisationId?: number;
		applicationId: number;
		applicationNumber: string;
		grantName: string;
		grantRoundTitle: string;
		agencyName: string;
		projectServiceTitle: string;
		applicationSubject: string;
		paymentScheduleItemStatusDescription: string;
		dueDate: string;
		amountExcludingGst: number;
		grantDetailId: number;
		grantRoundId: number;
		agencyId: number;
		hasNop: boolean;
		hasRcti: boolean;
		displayDocumentDownloads: boolean;
		hasConditon: boolean;
		adminBodyOrganisationId?: number;
		adminBodyOriginalExternalUserName: string;
		adminBodyOriginalOrganisationName: string;
	}
	export interface IExternalPaymentsViewFilterDto extends SharedDto.IPagingFilter
	{
		organisationId?: number;
		awardedGrantId?: number;
		applicationNumber: string;
		status?: any;
	}
}
export module Dto.Invitation {
	export interface IInvitationListDto
	{
		invitationId: number;
		publicContentId: number;
		grantName: string;
		roundName: string;
		agency: string;
		closeDate?: string;
		status: any;
		statusDescription: string;
		type: any;
		typeDescription: string;
		dateCreated: string;
		dateAccepted?: string;
		dateDeclined?: string;
		accepted: boolean;
		declined: boolean;
		allowedTransitions: SharedDto.ICodeListItemDto[];
	}
}
export module Dto.Correspondence {
	export interface IExternalCorrespondenceDetailDto
	{
		externalCorrespondenceId: number;
		grantRoundId: number;
		isEmail: boolean;
		correspondenceSubject: any;
		emailRecipients: SharedDto.IEmailRecipientDto[];
		emailSubject: string;
		emailBody: string;
		sentDate?: string;
		receivedDate?: string;
		attachments: SharedDto.IFilePropertiesDto[];
	}
	export interface IExternalCorrespondenceSearchRequestDto extends SharedDto.IPagingFilter
	{
		applicationFormId?: number;
		applicationNumber: string;
		organisationId?: number;
		correspondenceSubject?: any;
	}
	export interface IExternalCorrespondenceSearchResponseDto
	{
		correspondenceId: number;
		isEmail: boolean;
		applicationNumber: string;
		projectServiceTitle: string;
		applicationSubject: string;
		correspondenceSubject?: any;
		sentDate?: string;
		receivedDate?: string;
	}
}
export module Dto.Assessment {
	export interface IApplicationAssessmentSearchFilterDto extends SharedDto.IPagingFilter
	{
		applicationNumber: string;
	}
	export interface IApplicationAssessmentSearchResultItemDto
	{
		assessmentCheckResponseId: number;
		applicationId: number;
		applicationReference: string;
		projectServiceTitle: string;
		grantName: string;
		roundTitle: string;
		applicantName: string;
		assessmentCheckStatus: any;
		assessmentCheckStatusDescription: string;
		assessmentCheckResponseStatus: any;
		assessmentCheckResponseStatusDescription: string;
		canViewApplication: boolean;
		showAdditionalInformation: boolean;
	}
	export interface IAssessmentCheckResponseDto extends Dto.Assessment.IAssessmentCheckResponseUpdateDto
	{
		applicationId: number;
		assessmentCheckResponseStatus: any;
		assessmentCheckResponseStatusDescription: string;
		fundingRecommendationOutcomeDescription: string;
		requiresFundingReccomendation: boolean;
		conflictOfInterestComment: string;
		helpText: string;
		helpAttachments: SharedDto.IFilePropertiesDto[];
		questionTypeList: Dto.Assessment.IAssessmentCheckResponseQuestionDto[];
		numericTypeList: Dto.Assessment.IAssessmentCheckResponseNumericDto[];
		canEdit: boolean;
		assessorLastUpdatedBy: string;
		assessorLastUpdatedTime?: string;
	}
	export interface IAssessmentCheckResponseFundingRecommendationUpdateDto
	{
		financialYear: SharedDto.IFinancialYearDto;
		recommendedAmount?: number;
	}
	export interface IAssessmentCheckResponseNumericDto extends Dto.Assessment.IAssessmentCheckResponseNumericUpdateDto
	{
		assessmentCriteria: string;
		helpText: string;
		mandatory: boolean;
		minimumScore?: number;
		weighting: number;
		attachment: SharedDto.IFilePropertiesDto;
		questionScoreList: Dto.Assessment.INumericQuestionScoreDto[];
	}
	export interface IAssessmentCheckResponseNumericUpdateDto
	{
		assessmentCheckResponseNumericId: number;
		comment: string;
		attachment: SharedDto.IFileUpdateDto;
		numericResult?: number;
	}
	export interface IAssessmentCheckResponseQuestionDto extends Dto.Assessment.IAssessmentCheckResponseQuestionUpdateDto
	{
		questionText: string;
		helpText: string;
		sortOrder: number;
	}
	export interface IAssessmentCheckResponseQuestionUpdateDto
	{
		assessmentCheckResponseQAId: number;
		result: string;
		attachment: SharedDto.IFilePropertiesDto;
	}
	export interface IAssessmentCheckResponseUpdateDto
	{
		assessmentCheckResponseId: number;
		fundingRecommendationOutcome?: any;
		comments: string;
		attachment: SharedDto.IFilePropertiesDto;
		fundingRecommendations: Dto.Assessment.IAssessmentCheckResponseFundingRecommendationUpdateDto[];
		questionTypeList: Dto.Assessment.IAssessmentCheckResponseQuestionUpdateDto[];
		numericTypeList: Dto.Assessment.IAssessmentCheckResponseNumericUpdateDto[];
	}
	export interface INumericQuestionScoreDto
	{
		scoreNumber: number;
		questionText: string;
	}
}
export module Dto.Administer {
	export interface IExternalApplicationAdministerDto
	{
		codeExpiryDate: string;
		code: string;
		expired: boolean;
		status: any;
		administeringBodyBusinessName: string;
		submittedByOrganisationLegalName: string;
		submittedByOrganisationBusinessName: string;
		submittedByIndividualName: string;
		grantTitle: string;
		roundTitle: string;
		isIndividual: boolean;
	}
}
export module Dto.AdditionalInfo {
	export interface IAdditionalInfoRequestListDto
	{
		additionalInformationRequestId: number;
		applicationId: number;
		additionalInformationRequestStatus: Dto.Constants.AdditionalInformationRequestStatus;
		additionalInformationRequestStatusDescription: string;
		projectServiceTitle: string;
		applicationSubject: string;
		applicationReferenceNumber: string;
		applicantName: string;
		description: string;
		submittedDate?: string;
		responseDate?: string;
		dueDate?: string;
	}
	export interface IAdditionalInfoRequestFilterDto extends SharedDto.IPagingFilter
	{
		applicationId?: number;
		organisationId?: number;
	}
	export interface IAdditionalInformationRequestDto extends Dto.AdditionalInfo.IAdditionalInformationRequestUpdateDto
	{
		description: string;
		requestStatusDescription: string;
		requestStatus: Dto.Constants.AdditionalInformationRequestStatus;
		submittedDate?: string;
		submittedBy: string;
		responseDate?: string;
		respondedBy: string;
		formInstanceId?: number;
		formQuestionId?: number;
		dueDate?: string;
		additionalInformationRequestItems: Dto.AdditionalInfo.IAdditionalInformationRequestItemDto[];
		canEdit: boolean;
	}
	export interface IAdditionalInformationRequestUpdateDto
	{
		additionalInformationRequestId: number;
		additionalInformationRequestItems: Dto.AdditionalInfo.IAdditionalInformationRequestItemUpdateDto[];
	}
	export interface IAdditionalInformationRequestItemDto extends Dto.AdditionalInfo.IAdditionalInformationRequestItemUpdateDto
	{
		requestQuestionTypeDescription: string;
		requestQuestionType: Dto.Constants.AdditionalInformationRequestQuestionType;
		questionText: string;
		actionedByAgencyDisplayName: string;
		actionedByAgencyComment: string;
		actionedByAgencyDate?: string;
		actionedByAgencyFileStorage: SharedDto.IFilePropertiesDto;
	}
	export interface IAdditionalInformationRequestItemUpdateDto
	{
		additionalInformationRequestItemId: number;
		answerText: string;
		answerBoolean?: boolean;
		attachment: SharedDto.IFilePropertiesDto;
	}
}
export module Dto.AwardedGrants {
	export interface IExternalAwardedSupplierViewDto
	{
		awardedGrantId: number;
		organisationId?: number;
		applicationId: number;
		applicationNumber: string;
		grantName: string;
		grantRoundTitle: string;
		agencyName: string;
		projectServiceTitle: string;
		applicationSubject: string;
		grantDetailId: number;
		grantRoundId: number;
		agencyId: number;
		adminBodyOrganisationId?: number;
		adminBodyOriginalExternalUserName: string;
		adminBodyOriginalOrganisationName: string;
		supplierId: number;
	}
	export interface IGrantAgreementContactDto
	{
		grantAgreementContactId: number;
		isPrimary: boolean;
		contactName: string;
		emailAddress: string;
		phoneNumber: string;
		applicationContactRole?: any;
	}
	export interface IExternalAwardedGrantsViewDto
	{
		awardedGrantId: number;
		organisationId?: number;
		applicationId: number;
		applicationNumber: string;
		grantName: string;
		grantRoundTitle: string;
		agencyName: string;
		projectServiceTitle: string;
		applicationSubject: string;
		fundedFrom: string;
		fundedTo: string;
		commencementDate?: string;
		endDate?: string;
		grantDetailId: number;
		grantRoundId: number;
		agencyId: number;
		adminBodyOrganisationId?: number;
		adminBodyOriginalExternalUserName: string;
		adminBodyOriginalOrganisationName: string;
	}
	export interface IExternalAwardedGrantsViewFilterDto extends SharedDto.IPagingFilter
	{
		organisationId?: number;
		applicationNumber: string;
		awardedGrantId?: number;
	}
	export interface IProjectDetailsDto
	{
		title: string;
		commencementDate?: string;
		endDate?: string;
		description: string;
		fundedFrom: string;
		fundedTo: string;
		keyPersonnelName: string;
		keyPersonnelRole: string;
		keyPersonnelPhone: string;
		keyPersonnelEmail: string;
	}
	export interface IAwardedGrantFundingInformationDto
	{
		applicationFormId: number;
		financialYearDescription: string;
		fundedAmount: number;
		paymentsMade: number;
		acquittedFunds: number;
		nonAcquittedFunds: number;
	}
}
export module Dto.Application {
	export interface IApplicationSubmissionResponseDto
	{
		responseType: Dto.Constants.ApplicationSubmissionResponseType;
		issuedVouchers: Dto.IApplicantVoucherListItemDto[];
		furtherProcessing: string[];
		declinedVouchers: string[];
		success: boolean;
		errors: string[];
	}
	export interface IApplicationActionsDto
	{
		canEdit: boolean;
		canView: boolean;
	}
	export interface IApplicationUpdateResponseDto
	{
		rowVersion: number[];
		applicationForm: Dto.Application.IExternalApplicationFormDto;
	}
	export interface IExternalApplicationFormDto extends SharedDto.OnlineForm.Application.IApplicationFormDto
	{
		actions: SharedDto.ICodeListItemDto[];
		externalStatus: Dto.Constants.ExternalApplicationFormStatus;
	}
	export interface IApplicationFormHistoryDto
	{
		applicationFormHistoryId: number;
		applicationFormStatus: Dto.Constants.ExternalApplicationFormStatus;
		applicationFormStatusDescription: string;
		action: string;
		date: string;
		description: string;
		createdByName: string;
		comments: string;
	}
	export interface IExternalExistingApplicationDto extends SharedDto.OnlineForm.Application.IExistingApplicationDto
	{
		applicationForm: Dto.Application.IExternalApplicationFormDto;
	}
	export interface IApplicationTransitionRequestDto
	{
		transition: Dto.Constants.ExternalApplicationFormTransition;
		organisationId?: number;
		comments: string;
	}
	export interface IExternalApplicationRequestDto extends SharedDto.IPagingFilter
	{
		organisationId?: number;
		applicationNumber: string;
		roundId?: number;
		applicationStatus?: Dto.Constants.ExternalApplicationFormStatus;
	}
	export interface IExternalApplicationResponseDto
	{
		grantApplicationId: number;
		applicationNumber: string;
		grantTitle: string;
		roundTitle: string;
		projectServiceTitle: string;
		applicationSubject: string;
		applicationFormStatus: Dto.Constants.ExternalApplicationFormStatus;
		applicationFormStatusDescription: string;
		canEdit: boolean;
		canView: boolean;
		agencyId: number;
		agencyName: string;
		dateSubmitted?: string;
		adminBodyOrganisationId?: number;
		adminBodyOriginalExternalUserName: string;
		adminBodyOriginalOrganisationName: string;
	}
}
export module Dto.Constants {
	export enum ExternalPageRoutes { 
		ManageAccount = 1, 
		ManageAccountDetails = 2, 
		ManageAccountChangePassword = 3, 
		ManageAccountChangeUsername = 4, 
		ManageAccountChangeUsernameConfirm = 5, 
		ResetPassword = 6, 
		IndividualProfile = 7, 
		IndividualProfileView = 8, 
		IndividualProfileEdit = 9, 
		IndividualSubscriptions = 10, 
		GrantsDetail = 11, 
		GrantsList = 12, 
		GrantsSearch = 13, 
		Help = 14, 
		MoreInfo = 15, 
		Apply = 16, 
		Preview = 17, 
		Login = 18, 
		RecoverPassword = 19, 
		RegisterRequest = 20, 
		Register = 21, 
		ProfileSelect = 22, 
		OrganisationInviteAccept = 23, 
		OrganisationInviteReject = 24, 
		OrganisationCreate = 25, 
		OrganisationEdit = 26, 
		OrganisationView = 27, 
		OrganisationUser = 28, 
		OrganisationProfile = 29, 
		OrganisationProfileDetailModule = 30, 
		OrganisationDocumentsModule = 31, 
		OrganisationUserAccessModule = 32, 
		OrganisationBankDetailsModule = 33, 
		OrganisationAuditHistoryModule = 34, 
		Applications = 35, 
		ApplicationsInProgress = 36, 
		ApplicationsAwardedGrants = 37, 
		ApplicationsSupplierRegistrations = 38, 
		ApplicationsReporting = 39, 
		ApplicationsPayments = 40, 
		ApplicationsAcquittal = 41, 
		ApplicationsCorrespondence = 42, 
		ApplicationsCorrespondenceView = 43, 
		ApplicationsRequestForInformation = 44, 
		ApplicationsHistory = 45, 
		ApplicationsInvite = 46, 
		AdminBodyResponse = 47, 
		Application = 48, 
		AwardedGrant = 49, 
		AwardedGrantDetails = 50, 
		AwardedGrantPaymentList = 51, 
		AwardedGrantReportingList = 52, 
		AwardedGrantAcquittalList = 53, 
		AwardedGrantVariationsList = 54, 
		AwardedGrantCorrespondenceList = 55, 
		AwardedGrantCorrespondenceView = 56, 
		AwardedGrantRequestForInformationList = 57, 
		AwardedGrantApplicationContacts = 58, 
		AwardedSupplier = 59, 
		AwardedSupplierDetails = 60, 
		AwardedSupplierPaymentList = 61, 
		AwardedSupplierReportingList = 62, 
		AwardedSupplierAcquittalList = 63, 
		AwardedSupplierVariationsList = 64, 
		AwardedSupplierCorrespondenceList = 65, 
		AwardedSupplierCorrespondenceView = 66, 
		AwardedSupplierRequestForInformationList = 67, 
		AwardedSupplierApplicationContacts = 68, 
		Reporting = 69, 
		ReportingView = 70, 
		ReportingEdit = 71, 
		RequestForInformation = 72, 
		RequestForInformationView = 73, 
		RequestForInformationEdit = 74, 
		Acquittal = 75, 
		AcquittalView = 76, 
		AcquittalEdit = 77, 
		Payment = 78, 
		PaymentView = 79, 
		PaymentEdit = 80, 
		VariationsList = 81, 
		VariationsView = 82, 
		VariationsEdit = 83, 
		Invite = 84, 
		BankDetailsView = 85, 
		BankDetailsUpdate = 86, 
		IndividualBankDetailsList = 87, 
		IndividualBankDetailsEdit = 88, 
		AssessorInviteAccept = 89, 
		AssessorInviteDecline = 90, 
		Verify = 91, 
		Welcome = 92, 
		News = 93, 
		FAQ = 94, 
		PasswordAndComputerProblems = 95, 
		HelpIntroduction = 96, 
		SetupAccount = 97, 
		AccountRegistration = 98, 
		ManageProfileAccess = 99, 
		CreateManageOrgAccess = 100, 
		OrgUserAccess = 101, 
		JoinAnOrg = 102, 
		GrantApplication = 103, 
		GrantSearch = 104, 
		GrantEnquiry = 105, 
		SubmitApplication = 106, 
		Subscription = 107, 
		ManageGrant = 108, 
		ApplicationManagement = 109, 
		ViewAwardedGrants = 110, 
		Reports = 111, 
		UpdateCorrespondence = 112, 
		ROI = 113, 
		SlugGrantsDetail = 114, 
		SlugApply = 115, 
		SlugPreview = 116, 
		Vouchers = 117, 
		ChangePasswordRequired = 118, 
	}
	export enum StateTerritory { 
		NT = 1, 
		ACT = 2, 
		NSW = 3, 
		QLD = 4, 
		SA = 5, 
		TAS = 6, 
		VIC = 7, 
		WA = 8, 
	}
	export enum AddressValidationMatchResult { 
		NoMatch = 1, 
		NonStreetAddressMatch = 2, 
		PartialMatches = 3, 
		SingleMatch = 4, 
		ExactMatch = 5, 
	}
	export enum OrgUserAccessActivity { 
		Application = 1, 
		ProgressPerformanceReport = 2, 
		FinancialReportAndAcquittal = 3, 
		Variation = 4, 
		Vouchers = 5, 
	}
	export enum OrganisationUserStatus { 
		Invited = 1, 
		Active = 2, 
		Inactive = 3, 
		ResendInvite = 4, 
	}
	export enum LocalityType { 
		Region = 1, 
		SubRegion = 2, 
		Locality = 3, 
		Suburb = 4, 
		AboriginalCommunity = 5, 
		Municipality = 6, 
		Shire = 7, 
		GovernmentRegion = 8, 
	}
	export enum OrganisationAuditCategory { 
		OrganisationCreated = 1, 
		OrganisationProfileUpdated = 2, 
		OrganisationUserInvited = 3, 
		OrganisationUserInviteAccepted = 4, 
		OrganisationUserInviteRejected = 5, 
		OrganisationUserUpdated = 6, 
		OrganisationUserDeleted = 7, 
		OrganisationDocumentUpdated = 8, 
		OrganisationDocumentDelete = 9, 
		OrganisationDocumentCreated = 10, 
		OrganisationBankDetailsCreated = 11, 
		OrganisationBankDetailsUpdated = 12, 
		OrganisationUserResendInvitation = 13, 
		OrganisationUserResetPassword = 14, 
	}
	export enum ExternalApplicationFormTransition { 
		Withdraw = 1, 
		Submit = 2, 
		Edit = 3, 
		Delete = 4, 
		SubmitViaAdminBody = 5, 
		AdminBodyDecline = 6, 
		AdminBodyRequireUpdate = 7, 
		AdminBodyAccept = 8, 
		WithdrawFromAdminBody = 9, 
		ExternalSubmitForApproval = 10, 
		ExternalWithdrawFromApproval = 11, 
		ExternalUpdatesRequired = 12, 
		ExternalApproved = 13, 
		Expire = 14, 
	}
	export enum ExternalApplicationFormStatus { 
		Draft = 1, 
		Withdrawn = 2, 
		Expired = 3, 
		Submitted = 4, 
		RequiresAdminBodyEndorsement = 5, 
		AdminBodyEndorsed = 6, 
		AdminBodyRequiresUpdates = 7, 
		ExternalSubmittedForApproval = 8, 
		ExternalApproved = 9, 
		AssessmentInProgress = 10, 
		Approved = 11, 
		GrantOffered = 12, 
		Unsuccessful = 13, 
		Successful = 14, 
		Declined = 15, 
		NotApproved = 16, 
		CompleteFullyAcquitted = 17, 
		CompleteApplicantWithdrawn = 18, 
		CompleteFundingWithdrawn = 19, 
		CompleteOutstandingAcquittal = 20, 
		Complete = 21, 
		OnHold = 22, 
	}
	export enum ReportingRequirementType { 
		Invoice = 1, 
		SignedAgreement = 2, 
		ProgressReport = 3, 
		DeliveryMaterials = 4, 
		PerformanceReport = 5, 
		BeneficiarySurvey = 6, 
		QuarterlyMeeting = 7, 
		AnnualMeeting = 8, 
		FinalMeeting = 9, 
		SiteVisit = 10, 
		OutcomeMeasures = 11, 
		OutputMeasures = 12, 
		VoucherRedemption = 13, 
		RequiredDocument = 14, 
	}
	export enum ReportingRequirementGroup { 
		Deliverable = 1, 
		Meeting = 2, 
		Outcome = 3, 
		Output = 4, 
	}
	export enum ReportingRequirementOutcome { 
		MeetsRequirement = 1, 
		DoesNotMeetRequirement = 2, 
		AdditionalInformationRequired = 3, 
		Withdrawn = 4, 
		Draft = 5, 
		Paid = 6, 
	}
	export enum ExternalReportingRequirementStatus { 
		NotStarted = 1, 
		Draft = 2, 
		Overdue = 3, 
		Submitted = 4, 
		UnderAssessment = 5, 
		AdditionalInformationRequired = 6, 
		AssessmentComplete = 7, 
	}
	export enum ReportingFrequency { 
		OneOff = 1, 
		Monthly = 2, 
		Quarterly = 3, 
		SixMonthly = 4, 
		Annually = 5, 
	}
	export enum ReportingRequirementTransition { 
		Start = 1, 
		Submit = 2, 
		Overdue = 3, 
		StartAssessment = 4, 
		CompleteAssessment = 5, 
		EndorseOutcome = 6, 
		RejectOutcome = 7, 
		AssignForReview = 8, 
		RequestAdditionalInformation = 9, 
		Cancel = 10, 
		Withdraw = 11, 
		PutOnHold = 12, 
		TakeOffHold = 13, 
		AutoCancel = 14, 
		MarkAsPaid = 15, 
		AutoProcess = 16, 
		Recall = 17, 
	}
	export enum ExternalAcquittalRequirementStatus { 
		NotStarted = 1, 
		Draft = 2, 
		Overdue = 3, 
		Submitted = 4, 
		UnderAssessment = 5, 
		AssessmentComplete = 6, 
		AdditionalInformationRequired = 7, 
	}
	export enum AcquittalRequirementTransition { 
		Start = 1, 
		Submit = 2, 
		Overdue = 3, 
		StartAssessment = 4, 
		CompleteAssessment = 5, 
		AssignForReview = 6, 
		EndorseOutcome = 7, 
		RejectOutcome = 8, 
		RequestAdditionalInformation = 9, 
		Cancel = 10, 
		Withdraw = 11, 
		PutOnHold = 12, 
		TakeOffHold = 13, 
		AutoCancel = 14, 
	}
	export enum AcquittalDeliverableType { 
		FinancialStatement = 1, 
		AnnualAuditedFinancials = 2, 
		FundingAcquittal = 3, 
		AcquittalForm = 4, 
		Report = 5, 
	}
	export enum AdditionalInformationRequestQuestionType { 
		FileUpload = 1, 
		Text = 2, 
		FileUploadOrText = 3, 
		YesNo = 4, 
	}
	export enum AdditionalInformationRequestStatus { 
		Saved = 1, 
		NotStarted = 2, 
		Draft = 3, 
		Responded = 4, 
		OverDue = 5, 
	}
	export enum LegalEntityType { 
		AC = 1, 
		IC = 2, 
		LGE = 3, 
		PropCo = 4, 
		PubCo = 5, 
		OIE = 6, 
		T = 7, 
		UE = 8, 
		ST = 9, 
		P = 10, 
		TE = 11, 
	}
	export enum VariationType { 
		ScopeOrPurpose = 1, 
		Budget = 2, 
		Timeframe = 3, 
		SpecialConditions = 4, 
	}
	export enum VariationAssessmentOutcome { 
		Successful = 1, 
		NotSuccessful = 2, 
		AdditionalInformationRequired = 3, 
	}
	export enum ExternalVariationStatus { 
		Draft = 1, 
		SubmittedForAssessment = 2, 
		AdditionalInformationRequired = 3, 
		Successful = 4, 
		NotSuccessful = 5, 
	}
	export enum SystemPrepopulatedField { 
		ProjectServiceTitle = 1, 
		SportsVoucherOnboardId = 2, 
		SportsVoucherAutomatchRank = 3, 
	}
	export enum PublicContentType { 
		Applicant = 1, 
		ThirdPartySupplier = 2, 
		ThirdPartyApplicant = 3, 
	}
	export enum SlugType { 
		PublicContent = 1, 
		GrantRound = 2, 
	}
	export enum ExternalVoucherStatus { 
		Issued = 1, 
		Accepted = 2, 
		SubmittedForPayment = 3, 
		Paid = 4, 
		Expired = 5, 
		Cancelled = 6, 
		RedemptionClosed = 7, 
	}
	export enum VoucherCheckResult { 
		Ready = 1, 
		SupplierAlreadyAccepted = 2, 
		AcceptedByAnotherSupplier = 3, 
		VoucherExpired = 4, 
		VoucherCancelled = 5, 
		VoucherNumberDoesNotExist = 6, 
		InvalidRedemptionPortal = 7, 
		RepeatedVoucherNumber = 8, 
		InvalidVoucherNumber = 9, 
		SupplierNotTheNominatedSupplier = 10, 
		ApplicantMustConfirm = 11, 
		BeforeValidityDate = 12, 
	}
	export enum ApplicationSubmissionResponseType { 
		Voucher = 1, 
		FurtherProcessing = 2, 
		Other = 3, 
	}
	export enum SupplierStatus { 
		Draft = 1, 
		Approved = 2, 
		Suspended = 3, 
		SupplierWithdrawn = 4, 
		AgencyCancelled = 5, 
	}
	export enum AccountStatus { 
		Inactive = 1, 
		Registered = 2, 
		ChangePassword = 3, 
	}
	export enum ReviewOutcomeResult { 
		Passed = 1, 
		ConditionallyPassed = 2, 
		AdditionalInformationRequired = 3, 
		Failed = 4, 
		DoNotProceed = 5, 
		SkipStep = 6, 
	}
	export enum EligibilityOutcome { 
		Eligible = 1, 
		ConditionallyEligible = 2, 
		AdditionalInformationRequired = 3, 
		Ineligible = 4, 
		DoNotProceed = 5, 
		SkipStep = 6, 
	}
}
