import { CurrencyFormatter } from "shared/utils/utils";
import { Decimal } from "decimal.js-light";

export class CurrencyValueConverter {
    toView(value: number, decimalPoints?: number, inclGst: boolean = false, getOnlyGst: boolean = false) {
        if (value === null || value === undefined) { return value; }
        
        if(inclGst)
        {
            let amountExclGst = new Decimal(value || 0);
            let gstValue =  new Decimal(0);
            if (amountExclGst.greaterThan(0)) {
                gstValue = amountExclGst.times(0.10).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);                      
                return CurrencyFormatter(gstValue.add(amountExclGst).toNumber(), decimalPoints || 2);
                }
        }  
        
        if(getOnlyGst)
        {
            let amountExclGst = new Decimal(value || 0);
            let gstValue =  new Decimal(0);
            if (amountExclGst.greaterThan(0)) {
                gstValue = amountExclGst.times(0.10).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);                      
                return CurrencyFormatter(gstValue.toNumber(), decimalPoints || 2);
                }
        }  
        

        return CurrencyFormatter(value, decimalPoints || 2);
    }
}