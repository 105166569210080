import { autoinject, BindingEngine } from "aurelia-framework";
import { Disposable } from "aurelia-binding";
import debounce from 'debounce-promise';

@autoinject
export class DeepObserver {

    private bindingEngine: BindingEngine;

    constructor(bindingEngine: BindingEngine) {
        this.bindingEngine = bindingEngine;
    }

    observeWithDebounce(target: Object, property: string, callback: (newValue: any, oldValue: any, name: string) => void, debouceMs: number = 100): Disposable {
        let debouncedCallback = debounce(callback, debouceMs);
        return this.observe(target, property, () => {
            debouncedCallback();
        });
    }

    observe(target: Object, property: string, callback: (newValue: any, oldValue: any, name: string) => void): Disposable {

        const subscriptions: { root: any, children: any[] } = { root: null, children: [] };

        subscriptions.root = (this.bindingEngine.propertyObserver(target, property).subscribe((newValue, oldValue) => callback(newValue, oldValue, name)));
        this.recurse(target, property, subscriptions.children, callback, property);

        return {
            dispose: () => {
                this.disconnect(subscriptions.children);
                subscriptions.root.dispose();
            }
        };
    }

    private disconnect(subscriptions) {
        while (subscriptions.length) {
            subscriptions.pop().dispose();
        }
    }

    private recurse(target, property, subscriptions, callback, path) {
        let sub = target[property];

        if (typeof sub === "object") {
            for (let p in sub) {
                if (sub.hasOwnProperty(p)) {
                    this.recurse(sub, p, subscriptions, callback, `${path}${sub instanceof Array ? "[" + p + "]" : "." + p}`);
                }
            }
        }

        if (sub instanceof Array) {
            subscriptions.push(this.bindingEngine.collectionObserver(sub).subscribe((changedRecords) => callback(null, null, path)));
        }

        if (target != property) {
            subscriptions.push(this.bindingEngine.propertyObserver(target, property).subscribe((n, o) => callback(n, o, path)));
        }
    }
}
