export class TruthyValueConverter {
    toView(value: boolean) {
        if (value == undefined)
            return value;    
        return value ? "true" : "false";
    }

    fromView(value: string) {
        if (value == undefined)
            return value;
        return value == "true" ? true : false;
    }
}
