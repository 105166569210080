export class PermissionError extends Error {
    
    errors: string[];
    message: string;

    constructor(message?: string) {
        super(message); // 'Error' breaks prototype chain here
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
        this.message = message;
    }

}