import { autoinject } from "aurelia-framework";

import { RequestHandler } from "./request-handler";
import { SharedDto } from "project/project-shared";
import { IDataService } from "shared/interfaces/idata-service";

@autoinject
export class DataService implements IDataService {
    private readonly cacheDuration = 3600; //1 hour, these codes should not be changing enough to warrant a shorter cache duration
    constructor(private readonly requestHandler: RequestHandler) {
    }

    getRoundVouchersByQuestion(questionId: number): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>(`code/round-vouchers-question/${questionId}`, this.cacheDuration);
    }

    getSupplierLocationTypes(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>(`code/supplier-location-types`, this.cacheDuration);
    }

    getSupplierStatuses(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>(`code/supplier-statuses`, this.cacheDuration);
    }

    getSupplierCategories(questionId: number): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>(`code/supplier-categories/${questionId}`, this.cacheDuration);
    }

    getSupplierLocations(questionId: number): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>(`code/supplier-locations/${questionId}`, this.cacheDuration);
    }

    getSuppliers(questionId: number, publicOnly: boolean = true) {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>(`code/suppliers/${questionId}`);
    }
    
    getBeneficiaries(): Promise<Array<SharedDto.IBeneficiaryDto>> {
        return this.requestHandler.get<Array<SharedDto.IBeneficiaryDto>>(`code/beneficiaries`, this.cacheDuration);
    }

    getReviewOutcomes(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>("code/review-outcomes", this.cacheDuration);
    }

    getBeneficiariesTopLevel(): Promise<Array<SharedDto.ICodeListItemDto>> {
        return this.getBeneficiaries().then(data => {
            return data.map(x => {
                return <SharedDto.ICodeListItemDto>{ id: x.beneficiaryId, description: x.display, code: null }
            })
        });
    }

    getEligibilityOutcomes(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>("code/eligibility-outcomes", this.cacheDuration);
    }

    getAgencies(): Promise<Array<SharedDto.ICodeListItemDto>> {
        return this.requestHandler.get<Array<SharedDto.ICodeListItemDto>>(`code/agencies`, this.cacheDuration);
    }

    getFinancialYearList(): Promise<SharedDto.IFinancialYearDto[]> {
        return this.requestHandler.get<SharedDto.IFinancialYearDto[]>("code/financial-year");
    }

    checkRouteAccess(route: string): Promise<void> {
        return Promise.resolve();//this.requestHandler.get<void>(`security/canaccess/${Config.clientId}/${route}`);
    }

    getCategoryByLevel(level: number): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>(`code/category-by-level/${level}`, this.cacheDuration);
    }

    getCategoryChildren(categoryId: number): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>(`code/category-children/${categoryId}`, this.cacheDuration);
    }

    getRecaptchaKey(): Promise<{ value: string, enabled: boolean }> {
        return this.requestHandler.get<{ value: string, enabled: boolean }>("code/recaptchakey");
    }

    getTitles(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>(`code/title`, this.cacheDuration);
    }

    getStates(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>(`code/state`, this.cacheDuration);
    }

    getCountries(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>(`code/country`, this.cacheDuration);
    }

    getLegalEntities(): Promise<SharedDto.ILegalEntityTypeDto[]> {
        return this.requestHandler.get<SharedDto.ILegalEntityTypeDto[]>(`code/legal-entity-type`, this.cacheDuration);
    }

    getVoucherStatuses(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>(`code/voucher-statuses`, this.cacheDuration);
    }

    getTaxExemptionCategories(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>(`code/tax-exemption-category`, this.cacheDuration);
    }

    getDurationOperatingInNt(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>(`code/operating-in-the-nt`, this.cacheDuration);
    }

    getOrganisationUserAccessActivities(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>(`code/organisation-user-access-activity`, this.cacheDuration);
    }

    getDocumentTypes(): Promise<SharedDto.IDocumentTypeDto[]> {
        return this.requestHandler.get<SharedDto.IDocumentTypeDto[]>(`code/document-types`, this.cacheDuration);
    }

    getOrganisationRoles(): Promise<SharedDto.IOrganisationContactRoleDto[]> {
        return this.requestHandler.get<SharedDto.IOrganisationContactRoleDto[]>(`code/organisation-contact-role`, this.cacheDuration);
    }

    getLocationTypes(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>("code/locations", this.cacheDuration);
    }

    getLocationsByType(type: SharedDto.Constants.LocationType): Promise<SharedDto.ILocalityOptionDto[]> {
        return this.requestHandler.get<SharedDto.ILocalityOptionDto[]>(`code/locations/${type}`, this.cacheDuration);
    }

    getLocalityTypes(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>(`code/locality-types`, this.cacheDuration);
    }

    getFilteredLocalityOptions(model: SharedDto.ILocalityFilterDto): Promise<SharedDto.ILocalityOptionDto[]> {
        return this.requestHandler.post<SharedDto.ILocalityFilterDto, SharedDto.ILocalityOptionDto[]>(
            'code/localities', model);
    }

    getOrganisationAuditCategories(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>("code/organisation-audit-categories", this.cacheDuration);
    }

    getDocumentSources(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>("code/document-sources", this.cacheDuration);
    }

    getSchools(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>("code/schools", this.cacheDuration);
    }

    getApplicationStatuses(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>("code/application-statuses", this.cacheDuration);
    }

    getPaymentStatuses(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>("code/payment-statuses", this.cacheDuration);
    }

    getReportingTypes(): Promise<SharedDto.IReportingRequirementTypeDto[]> {
        return this.requestHandler.get<SharedDto.IReportingRequirementTypeDto[]>("code/monitoring-reporting-types", this.cacheDuration);
    }

    getReportingGroups(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>("code/monitoring-reporting-groups", this.cacheDuration);
    }

    getReportingRequirementStatuses(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>("code/reporting-requirement-statuses", this.cacheDuration);
    }


    getAcquittalTypes(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>("code/acquittal-types", this.cacheDuration);
    }

    getAcquittalRequirementStatuses(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>("code/acquittal-statuses", this.cacheDuration);
    }

    getDurationsOperatingInNt(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>("code/durations-operating-in-nt", this.cacheDuration);
    }

    getOrganisationContactRoles(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>("code/organisation-contact-role", this.cacheDuration);
    }

    getFundingRecommendationOutcomes(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>("code/funding-recommendations", this.cacheDuration);
    }

    getGrantVariationTypes(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>("code/variations", this.cacheDuration);
    }

    getApplicationContactRoles(): Promise<SharedDto.ICodeListItemDto[]> {
        return this.requestHandler.get<SharedDto.ICodeListItemDto[]>("code/application-contact-roles", this.cacheDuration);
    }
}
