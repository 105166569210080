import { isValidABN } from "abnacn-validator";
import { bindable, bindingMode, customElement } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import Inputmask from 'inputmask';

@customElement('abn')
export class Abn {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: string;
    @bindable() abnInput: HTMLInputElement;
    @bindable() disabled: boolean = false;

    attached() {
        Inputmask("99 999 999 999", {
            autoUnmask: true,
            postValidation: () => {
                var evt = document.createEvent("HTMLEvents");
                evt.initEvent("change", false, true);
                this.abnInput.dispatchEvent(evt);
                return true;
            }
        }).mask(this.abnInput);
    }

    bind() {
        ValidationRules
            .ensure((model: Abn) => model.value).displayName("ABN")
            .matches(/^(\d *?){11}$/).withMessage("The entered ABN needs to be 11 digits.").when((model: Abn) => model.value && model.value.length > 0)
            .satisfies(isValidABN).withMessage("The entered ABN failed validation.").when((model: Abn) => model.value && model.value.length == 11)
            .on(this);
    }
}
