import { autoinject } from "aurelia-dependency-injection";
import { SharedDto } from "project/project-shared";
import "./featured-grants.less";
import { bindable } from "aurelia-framework";
import { FeaturedGrantService } from "services/featured-grant-service";


@autoinject
export class FeaturedGrants {
    @bindable() featuredGrants: SharedDto.FeaturedGrant.IExternalHomePageFeaturedGrantDto[];
    @bindable() isFeaturedGrantsActive: boolean;

    constructor(private readonly featuredGrantService: FeaturedGrantService) {}

    generateGrantUrl(contentIdentifier: string | number): string {
        return`/grants/${contentIdentifier}`;
    }

    generateImageUrl(featuredGrant: SharedDto.FeaturedGrant.IExternalHomePageFeaturedGrantDto) {
        return this.featuredGrantService.generateImageUrl(featuredGrant.featuredGrantId);
    }
}
