import { autoinject } from "aurelia-dependency-injection";
import { bindable } from "aurelia-templating";
import { customElement } from "aurelia-framework";
import { bindingMode, computedFrom } from "aurelia-binding";
import * as moment from 'moment';
import Flatpickr from 'flatpickr';
import { ControlIdGenerator } from "shared/utils/control-id-generator";

const defaultOptions = {
    allowInput: true,
    clickOpens: true,
    dateFormat: 'd/m/Y',
    enableTime: true,
    static: true
}

@autoinject
@customElement('date-time-picker')
export class DatePicker {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) dateValue: Date;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: string;
    @bindable() readonly: boolean = false;
    @bindable() disabled: boolean = false;
    @bindable() id: string;
    @bindable() name: string;
    @bindable() pickerOptions: any = defaultOptions;
    @bindable() minDate: string;

    pickerInput: HTMLInputElement;

    public readonlyFieldClass: string;
    private _flatpickr: any;
    private _momentDateFormat: string = 'DD/MM/YYYY';
    private _momentDateTimeFormat: string = 'DD/MM/YYYY hh:mm a';

    constructor(private readonly element: Element, private readonly controlIdGenerator: ControlIdGenerator) { }

    bind() {
        if (!this.id) {
            this.id = this.controlIdGenerator.getNextId().toString();
        }
    }

    attached() {
        // Apply and override default options
        this.pickerOptions = Object.assign({}, defaultOptions, this.pickerOptions);
        this.pickerOptions.onChange =
            this.pickerOptions.onValueUpdate =
            this.pickerOptions.onClose = this.onChange;

        if (this.pickerOptions.enableTime) {
            this.pickerOptions.dateFormat += ' h:i K';
        }

        if (this.minDate) {
            var minDate = moment(this.minDate, this.pickerOptions.enableTime ? this._momentDateTimeFormat : this._momentDateFormat);
            this.pickerOptions.minDate = minDate.toDate();
        }

        if (!this.readonly) {
            this._loadPicker();
        }
    }

    detached() {
        if (this._flatpickr) {
            this._flatpickr.destroy();
            this._flatpickr = null;
        }
    }

    // Actions
    show() {
        if (!!this._flatpickr)
            this._flatpickr.open();
    }

    // Events
    readonlyChanged(newValue: boolean, oldValue: boolean) {
        // Setup or destory flatpickr reference
        if (!!this._flatpickr && newValue) {
            this._flatpickr = null;
        } else if (!newValue) {
            this._loadPicker();
        }
    }

    onChange = (selectedDates, dateStr, instance) => {
        // Triggered by the flatpickr itself, check and update value based on differences
        const inputDate = this._stringToDate(dateStr);
        if ((!!this.value || !this._isNullOrEmpty(dateStr)) && !this._dateSyncedWithInput(moment(this.value), dateStr)) {
            if (inputDate.isValid()) this.value = inputDate.toISOString();
            else {
                this.value = undefined;
                this._flatpickr.setDate(this.value);
            }
        }
        else if (!this._dateSyncedWithFlatPickr(moment(this.value), selectedDates)) {
            if (selectedDates.length == 0) {
                this.value = undefined;
                this._flatpickr.setDate(this.value);
            }
            else if (selectedDates.length == 1)
                this.value = this._cloneDate(selectedDates[0]).toISOString();
            else
                this.value = selectedDates.map(d => this._cloneDate(d));
        }
    }

    valueChanged() {
        // Triggered on value change, check if difference with flatpicker and then update
        if (!this._flatpickr) return;
        if (this._dateSyncedWithFlatPickr(moment(this.value), this._flatpickr.selectedDates)) return;

        let newDate: moment.Moment | moment.Moment[];
        if (!this.value) {
            newDate = undefined;
            this._flatpickr.setDate(newDate);
        }
        else if (!Array.isArray(this.value)) {
            newDate = this._cloneDate(this.value);
            this._flatpickr.setDate(newDate.toDate());
        }
        else {
            newDate = this.value.map(d => this._cloneDate(d));
            this._flatpickr.setDate(newDate.map(m => m.toDate()));
        }
    }

    // Helpers
    private _loadPicker() {
        this._flatpickr = Flatpickr(this.pickerInput, this.pickerOptions);
        this.valueChanged();
    }

    private _dateSyncedWithFlatPickr(model: moment.Moment | moment.Moment[], view: Date[]) {
        model = model || <moment.Moment[]>[];
        let modelDates = Array.isArray(model) ? model.map(m => m.toDate()) : [model.toDate()];
        for (let d = 0; d < modelDates.length; d++) {
            let modelDate = modelDates[d];
            if (view.findIndex(v => v.valueOf() === modelDate.valueOf()) > -1) {
                continue;
            }
            return false;
        }
        for (let d = 0; d < view.length; d++) {
            let viewDate = view[d];
            if (modelDates.findIndex(m => m.valueOf() === viewDate.valueOf()) > -1) {
                continue;
            }
            return false;
        }
        return true;
    }

    private _isNullOrEmpty(str: string): boolean {
        return str == null || str.trim() == '';
    }

    private _cloneDate(d): moment.Moment {
        return moment(d.getTime ? d.valueOf() : d);
    }

    private _dateSyncedWithInput(date: moment.Moment, inputStr: string) {
        if (inputStr == null || inputStr == '' && (!!date && date.isValid())) {
            return false;
        } else {
            const inputDate = this._stringToDate(inputStr);
            return !!date && inputDate.isValid() && date.isSame(inputDate);
        }
    }

    private _stringToDate(str: string): moment.Moment {
        const format = this.pickerOptions.enableTime ? this._momentDateTimeFormat : this._momentDateFormat;
        return moment(str, format);
    }

    // Computations
    @computedFrom("value")
    get valueFormatted() {
        this.readonlyFieldClass = 'form-control-static';
        if (this.value) {
            return moment(this.value).format(this.pickerOptions.enableTime ? this._momentDateTimeFormat : this._momentDateFormat);
        } else {
            if (this.disabled) {
                return ""
            }
            this.readonlyFieldClass += 'form-control-static text-muted';
            return "Not provided"
        }
    }
}
