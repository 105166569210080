export class SynopsisValueConverter {
    toView(value: string, stringCount: number = 50): string {
        if (value === null || value === undefined) { return value; }
        //strip out any other formatting
        let trimmedString = value.substring(0, stringCount);
        let valueStringCount = value.length;

        if(valueStringCount > stringCount) {
            trimmedString = trimmedString + "...";
        }
        
        return trimmedString;
    }
}