import { bindingMode, computedFrom } from "aurelia-binding";
import { autoinject } from "aurelia-framework";
import { bindable } from "aurelia-templating";
import { ValidationRules } from "aurelia-validation";
import { SharedDto } from "project/project-shared";
import { ControlIdGenerator } from "shared/utils/control-id-generator";
import { QuestionBase } from "../question-base";

@autoinject
export class FormPerson extends QuestionBase {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.IPersonAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;
    id: string;

    constructor(controlIdGenerator: ControlIdGenerator) {
        super(controlIdGenerator);

        this.id = controlIdGenerator.getNextId().toString();
    }

    setupValidation() {
        ValidationRules
            .ensure((q: SharedDto.OnlineForm.Application.IPersonAnswerDto) => q.givenName)
            .required()
            .when(() => this.visibility && this.onSubmitValidation && this.isMandatory)
            .maxLength(150)

            .ensure((q: SharedDto.OnlineForm.Application.IPersonAnswerDto) => q.middleName)
            .maxLength(150)

            .ensure((q: SharedDto.OnlineForm.Application.IPersonAnswerDto) => q.lastName)
            .required()
            .when(() => this.visibility && this.onSubmitValidation && this.isMandatory)
            .maxLength(150)

            .on(this.questionInstance);


        ValidationRules
            .ensure((vm: FormPerson) => vm.questionInstance)
            .satisfies(() => {
                if (this.oneFieldEntered && !this.allFieldsEntered) {
                    return false;
                }
                return true;
            })
            .when(() => this.visibility && this.onSubmitValidation && !this.isMandatory)
            .withMessage("You must provide both a given name and last name, or clear them.")
            .on(this)
    }

    detached() {
        super.detached();
        ValidationRules.off(this);
    }

    @computedFrom("questionInstance.givenName", "questionInstance.lastName")
    get oneFieldEntered(): boolean {
        return !!this.questionInstance.givenName || !!this.questionInstance.lastName;
    }

    @computedFrom("questionInstance.contactName", "questionInstance.emailAddress", "questionInstance.phoneNumber", "questionInstance.applicationContactRoleId")
    get allFieldsEntered(): boolean {
        return !!this.questionInstance.givenName && !!this.questionInstance.lastName;
    }

}
