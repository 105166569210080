import { DialogController, DialogService } from "aurelia-dialog";
import { autoinject, PLATFORM } from "aurelia-framework";
import { Router } from "aurelia-router";
import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";
import { IApplyCheckResult } from "services/application-service";
import { generateApplyUrl } from "services/url-generators";
import { IApplyNowModalConditionsEnum } from "../../modals/apply-now-modal/apply-now-modal";

export interface ILoginOrRegisterModalParams {
    applicationFormState: IApplyNowModalConditionsEnum;
    content: SharedDto.PublicContent.IPublicContentDetailViewDto;
}

@autoinject
export class LoginOrRegisterModal {
    dividerWord: string;
    applicationFormState: IApplyNowModalConditionsEnum;
    redirect: string;
    content: SharedDto.PublicContent.IPublicContentDetailViewDto;
    hideModal: boolean = false;

    constructor(public readonly dialogController: DialogController,
        private readonly router: Router,
        private readonly dialogService: DialogService) { }

    activate(params: ILoginOrRegisterModalParams) {
        this.dividerWord = "OR";
        this.applicationFormState = params.applicationFormState;
        this.content = params.content;
        this.redirect = generateApplyUrl(params.content.contentSlug, params.content.grantRoundSlug);
    }

    async login() {
        let loginLink = this.router.generate(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Login], { redirect: this.redirect });
        let checkResult: IApplyCheckResult = {
            result: 'redirect',
            redirectUrl: loginLink
        }
        this.dialogController.ok(checkResult);
    }

    async register() {
        if (this.applicationFormState == IApplyNowModalConditionsEnum.OrganisationIndividualCanApply) {
            //hide current modal... a bit hacky but need to prevent overlaying modals
            this.hideModal = true;
            this.dialogService.open({
                viewModel: PLATFORM.moduleName('grants/modals/organisation-individual-can-apply-modal/organisation-individual-can-apply-modal'),
                model: {
                    applicationFormState: IApplyNowModalConditionsEnum.OnlyIndividualCanApply, // applicationFormState // << this is the real value
                    content: this.content
                } as ILoginOrRegisterModalParams,
            }).whenClosed((result) => {
                if (result.wasCancelled) {
                    this.dialogController.cancel();
                }
                this.dialogController.ok(result.output);
            });
        } else {
            let registerUrl = this.router.generate(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Register],
                {
                    redirect: this.redirect,
                    application: this.applicationFormState.toString(),
                    isNew: 1
                });
            let checkResult: IApplyCheckResult = {
                result: 'redirect',
                redirectUrl: registerUrl
            }
            this.dialogController.ok(checkResult);
        }
    }

    cancel() {
        this.dialogController.cancel();
    }
}
