import { SharedDto } from "project/project-shared";

export class DocumentSourceValueConverter {
    toView(value: SharedDto.ICodeListItemDto[], isIndividual: boolean, isMandatory: boolean, questionType: SharedDto.Constants.QuestionType) {
        if (value == null) {
            return;
        }
        return value.filter(source => {
            switch (source.id) {
                case SharedDto.Constants.ApplicationDocumentSource.Profile:
                case SharedDto.Constants.ApplicationDocumentSource.ExternalSystem:
                    return !isIndividual;
                case SharedDto.Constants.ApplicationDocumentSource.Upload:
                case SharedDto.Constants.ApplicationDocumentSource.Comment:
                    return true;
                case SharedDto.Constants.ApplicationDocumentSource.NoSelection:
                    return !isMandatory;
            }
        }).filter(source => {
            switch(questionType)
            {
                case SharedDto.Constants.QuestionType.FileUpload:
                    return true;
                case SharedDto.Constants.QuestionType.RequiredDocument:
                    return source.id != SharedDto.Constants.ApplicationDocumentSource.Comment
                        && source.id != SharedDto.Constants.ApplicationDocumentSource.ExternalSystem
                        && source.id != SharedDto.Constants.ApplicationDocumentSource.NoSelection;
            }
        })
    }
}
