import { autoinject, singleton, Disposable } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator"
import { Router, RedirectToRoute } from "aurelia-router";

import { RequestHandler } from "./request-handler";
import { Dto } from "project/project";
import { ActiveProfile } from "models/active-profile";
import { Store } from "utils/store";
import { BroadcastEvents } from "models/broadcast-events"
import { AuthManager } from "auth/auth-manager";
import { Notifier } from "./notifier";
import {SharedDto} from "../project/project-shared";
import IBankDetailsDto = SharedDto.IBankDetailsDto;

@autoinject
@singleton()
export class ProfileService {
    loginStateListener: Disposable;

    cachedAccount: {
        expiry: Date;
        account: Promise<Dto.IUserResponse>;
    } = null;

    constructor(private readonly requestHandler: RequestHandler,
        private readonly store: Store,
        private readonly eventAggregator: EventAggregator,
        private readonly authManager: AuthManager,
        private notifier: Notifier,
        private readonly router: Router) {

        this.loginStateListener = this.eventAggregator.subscribe(BroadcastEvents.loginStateChanged, () => { this.getAccount(true); });
        this.loginStateListener = this.eventAggregator.subscribe(BroadcastEvents.profileDataChanged, () => { this.getAccount(); });
    }

    //todo.. this should probably be abstracted to just providing an Identifier and Profile Type - let the profile service handle the details instead of the calling code.
    setActiveProfile(profile: ActiveProfile): Promise<void> {
        return this.getAccount().then(account => {
            var profileKey = Store.keyActiveProfile + "-" + account.accountId;
            this.store.setl(profileKey, profile);
            this.eventAggregator.publish(BroadcastEvents.profileChanged);
        });
    }

    // everything should really call this rather than the above
    determineActiveProfile(userDetails: Dto.IUserResponse, organisationId: number = null): Promise<void> {
        if (organisationId) {
            let organisation = userDetails.userOrganisationProfiles.find(x => x.organisationId == organisationId);
            if (!organisation) {
                return;
            }
            return this.setActiveProfile({
                profileType: "Organisation",
                profileIdentifier: organisation.organisationId,
                profileDisplayName: organisation.legalName,
                profileUserFullname: userDetails.fullName,
                profileUserId: userDetails.accountId,
                profileAccountStatus: userDetails.accountStatus,
                allowedRoutes: organisation.allowedRoutes
            });
        }
        return this.setActiveProfile({
            profileType: "Individual",
            profileIdentifier: userDetails.individualProfileId,
            profileDisplayName: null,
            profileUserFullname: userDetails.fullName,
            profileUserId: userDetails.accountId,
            profileAccountStatus: userDetails.accountStatus,
            allowedRoutes: null
        });

    }

    async getActiveProfile(bustCache: boolean = false): Promise<ActiveProfile> {
        if (this.authManager.isLoggedIn) {
            return this.getAccount(bustCache).then<ActiveProfile>(account => {
                var profileKey = Store.keyActiveProfile + "-" + account.accountId;
                return this.store.getl(profileKey) as ActiveProfile;
            });
        } else {
            return Promise.resolve(null);
        }
    }

    async getProfileNavigation(): Promise<Dto.IProfileDashboardInfoDto> {
        let profile = await this.getActiveProfile(true);
        return this.requestHandler.get<Dto.IProfileDashboardInfoDto>(`profile/account/menu/${profile.profileType}/${profile.profileIdentifier}`);
    }
    
    selectDefaultProfile(redirect?: string, bustCache: boolean = false) {
        let continueRoute = () => {
            if (redirect) {
                this.router.navigate(redirect);
            } else {
                this.router.navigateToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Welcome]);
            }
        }
        //based on stored profile, decide whether to redirect, go to welcome page, or profile selection page.
        this.getAccount(bustCache).then(account => {
            if (account.userOrganisationProfiles && account.userOrganisationProfiles.length > 0) {
                this.getActiveProfile().then(activeProfile => {
                    if (activeProfile == null) {
                        //set current profile as individual and send to selection
                        this.setActiveProfile({
                            profileType: "Individual",
                            profileIdentifier: account.individualProfileId,
                            profileDisplayName: null,
                            profileUserFullname: account.fullName,
                            profileUserId: account.accountId,
                            profileAccountStatus: account.accountStatus,
                            allowedRoutes: null
                        });
                        this.eventAggregator.publish(BroadcastEvents.profileChanged);
                        this.router.navigateToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ProfileSelect], { redirect: redirect });
                    } else if (activeProfile.profileType === "Organisation") {
                        var org = account.userOrganisationProfiles.find(x => x.organisationId === activeProfile.profileIdentifier);
                        if (org !== null) {
                            continueRoute();
                        } else {
                            //they've been removed from an organisation since last login and will need to select a new profile
                            this.eventAggregator.publish(BroadcastEvents.profileChanged);
                            this.setActiveProfile(null);
                            this.router.navigateToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ProfileSelect], { redirect: redirect });
                        }
                    } else {
                        continueRoute();
                    }
                });
            } else {
                //user only has an individual profile. select it and continue with redirect or welcome page.
                this.setActiveProfile({
                    profileType: "Individual",
                    profileIdentifier: account.individualProfileId,
                    profileDisplayName: null,
                    profileUserFullname: account.fullName,
                    profileUserId: account.accountId,
                    profileAccountStatus: account.accountStatus,
                    allowedRoutes: null
                });
                this.eventAggregator.publish(BroadcastEvents.profileChanged);
                continueRoute();
            }
        });
    }

    private refreshActiveProfile(account: Dto.IUserResponse): void {
        if (account) {
            this.getActiveProfile().then(activeProfile => {
                if (activeProfile !== null) {     
                    if (activeProfile.profileType === "Individual") {
                        this.setActiveProfile({
                            profileType: "Individual",
                            profileIdentifier: account.individualProfileId,
                            profileDisplayName: null,
                            profileUserFullname: account.fullName,
                            profileUserId: account.accountId,
                            profileAccountStatus: account.accountStatus,
                            allowedRoutes: null
                        });
                    } else if (activeProfile.profileType === "Organisation") {
                        const org = account.userOrganisationProfiles.find(x => x.organisationId === activeProfile.profileIdentifier);
                        if (org) {
                            this.setActiveProfile({
                                profileType: "Organisation",
                                profileIdentifier: org.organisationId,
                                profileDisplayName: org.legalName,
                                profileUserFullname: account.fullName,
                                profileUserId: account.accountId,
                                profileAccountStatus: account.accountStatus,
                                allowedRoutes: org.allowedRoutes
                            });
                        } else {
                            //for now, set as individual profile.
                            this.setActiveProfile({
                                profileType: "Individual",
                                profileIdentifier: account.individualProfileId,
                                profileDisplayName: null,
                                profileUserFullname: account.fullName,
                                profileUserId: account.accountId,
                                profileAccountStatus: account.accountStatus,
                                allowedRoutes: null
                            });
                        }
                    } else {
                        throw new Error("A new profile type is not being handled in the ProfileService.refreshActiveProfile() function.");
                    }
                    this.eventAggregator.publish(BroadcastEvents.profileRefreshed);
                }
            });
        } else {
            this.eventAggregator.publish(BroadcastEvents.profileRefreshed);
        }
    }

    getAccount(bustCache = false): Promise<Dto.IUserResponse> {
        //note:getting the account has it's own caching implemented here because we need to know when the cache doesn't resolve the account.
        if (bustCache) {
            this.cachedAccount = null;
        }
        if (this.cachedAccount != null && this.cachedAccount.expiry >= new Date()) {
            return this.cachedAccount.account;
        } else {
            if (this.authManager.isLoggedIn) {
                var accPromise = this.requestHandler.get<Dto.IUserResponse>("security/user").then((data: Dto.IUserResponse) => {
                    this.refreshActiveProfile(data);
                    return data;
                });

                var newExpiry = new Date();
                newExpiry.setSeconds(newExpiry.getSeconds() + 60);
                this.cachedAccount = {
                    expiry: newExpiry,
                    account: accPromise
                };
                return accPromise;
            } else {
                this.cachedAccount = null;
                this.refreshActiveProfile(null);
                return Promise.resolve(null);
            }
        }
    }

    getIndividualProfile(): Promise<Dto.IPersonalProfileDto> {
        return this.requestHandler.get<Dto.IPersonalProfileDto>("profile/individual");
    }

    updateIndividualProfile(model: Dto.IPersonalProfileDto): Promise<void> {
        return this.requestHandler.put<Dto.IPersonalProfileDto, void>(`profile/individual`, model);
    }

    getIndividualBankDetails(): Promise<SharedDto.IBankDetailsDto[]> {
        return this.requestHandler.get(`profile/individual/bank-details`);
    }

    getIndividualBankDetailsById(bankDetailsId: number): Promise<SharedDto.IBankDetailsDto> {
        return this.requestHandler.get(`profile/individual/bank-details/${bankDetailsId}`);
    }

    addIndividualBankDetails(request: SharedDto.IBankDetailsDto) {
        return this.requestHandler.post(`profile/individual/bank-details`, request);
    }

    updateIndividualBankDetails(bankDetailsId: number, request: SharedDto.IBankDetailsDto) {
        return this.requestHandler.put(`profile/individual/bank-details/${bankDetailsId}`, request);
    }

    deleteIndividualBankDetails(bankDetailsId: number) {
        return this.requestHandler.delete(`profile/individual/bank-details/${bankDetailsId}`);
    }
    
    updateUsername(newUserName: string): Promise<void> {
        return this.requestHandler.post<string, void>(`profile/account/update-username`, newUserName);
    }

    verifyNewUsername(verificationCode: string): Promise<string> {
        return this.requestHandler.post<string, string>(`profile/account/verify-new-username`, verificationCode);
    }

    
    
    deactivate() {
        this.loginStateListener.dispose();
    }

    async hasOrgActivity(...activities: Dto.Constants.OrgUserAccessActivity[]) {
        if (!activities || activities.length === 0) {
            throw new Error("Invalid arguments");
        }

        let profile = await this.getActiveProfile();
        if (profile.profileType !== "Organisation") {
            return false;
        }

        let account = await this.getAccount();
        if (!account.userOrganisationProfiles) {
            return;
        }

        let orgProfile = account.userOrganisationProfiles.find(x => x.organisationId == profile.profileIdentifier);
        if (!orgProfile || !orgProfile.activities) {
            return false;
        }

        for (let activity of activities) {
            if (orgProfile.activities.indexOf(activity) > -1) {
                return true;
            }
        }
        return false;
    }
}
