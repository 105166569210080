import { BindingEngine, computedFrom, Disposable, observable } from "aurelia-binding";
import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { Router } from "aurelia-router";
import { ControllerValidateResult, ValidationControllerFactory, ValidationRules } from "aurelia-validation";
import { ValidationModuleBase } from "base-class";
import { BroadcastEvents } from "models/broadcast-events";
import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";
import { DataService } from "services/data-service";
import { Notifier } from "services/notifier";
import { ProfileService } from "services/profile-service";
import { AddressInput } from "shared/controls/address/address";
import { NotAPOBox, NumericRule, PersonNameRule } from "shared/controls/custom-validation";
import { ValidationError } from "shared/controls/validation-error";
import { addressArrayToString } from 'shared/utils/address-utils';
import { DeepObserver } from "shared/utils/deep-observer";

@autoinject
export class ContactInformationEdit extends ValidationModuleBase {

    profile: Dto.IPersonalProfileDto;
    titleList: SharedDto.ICodeListItemDto[];

    dateOfBirth: string;
    isBusy: boolean;
    validationError: ValidationError;

    subscriptions: Disposable[] = [];
    residentialAddressChanged: boolean = false;
    residentialAddressManualInput: boolean = false;
    postalAddressChanged: boolean = false;
    postalAddressManualInput: boolean = false;

    @observable() postalAddressIsDifferent: boolean = false;

    residentialAddressLabel: string = "Residential address";
    postalAddressLabel: string = "Postal address";
    redirect: string;

    @bindable({ defaultBindingMode: bindingMode.twoWay }) postalAddressModel: AddressInput;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) residentialAddressModel: AddressInput;

    constructor(controllerFactory: ValidationControllerFactory,
        private readonly profileService: ProfileService,
        private readonly dataService: DataService,
        private readonly notifier: Notifier,
        private readonly bindingEngine: BindingEngine,
        private readonly eventAggregator: EventAggregator,
        private readonly deepObserver: DeepObserver,
        private readonly router: Router) {
        super(controllerFactory);

        this.isBusy = false;
    }

    activate(params: { redirect: string }) {
        this.redirect = params.redirect;

        var titlePromise = this.dataService.getTitles().then((data: SharedDto.ICodeListItemDto[]) => {
            this.titleList = data;
        }).catch((error: Error) => {
            this.notifier.error(error.message);
        });

        var profilePromise = this.profileService.getIndividualProfile().then(data => {
            this.profile = data;
            this.postalAddressIsDifferent = !this.profile.postalSameAsResidentialAddress;
        }).catch((error: Error) => {
            this.notifier.error(error.message);
        });
        return Promise.all([titlePromise, profilePromise]);
    }

    postalAddressIsDifferentChanged() {
        if (this.profile) {
            this.profile.postalSameAsResidentialAddress = !this.postalAddressIsDifferent;
        }
    }

    revalidatePhoneNumbers() {
        this.controller.validate({ object: this.profile, propertyName: "workPhoneNumber" });
        this.controller.validate({ object: this.profile, propertyName: "mobilePhoneNumber" });
        this.controller.validate({ object: this.profile, propertyName: "homePhoneNumber" });
    }

    bind() {
        ValidationRules
            .ensure((m: Dto.IPersonalProfileDto) => m.titleId).displayName("Title").required()
            .ensure((m: Dto.IPersonalProfileDto) => m.familyName).required().maxLength(150).satisfiesRule(PersonNameRule)
            .ensure((m: Dto.IPersonalProfileDto) => m.givenName).required().maxLength(150).satisfiesRule(PersonNameRule)
            .ensure((m: Dto.IPersonalProfileDto) => m.middleName).maxLength(150).satisfiesRule(PersonNameRule)
            .ensure((m: Dto.IPersonalProfileDto) => m.mobilePhoneNumber).required()
            .ensure((m: Dto.IPersonalProfileDto) => m.residentialAddress).required()
            .ensure((m: Dto.IPersonalProfileDto) => m.postalAddress)
                .required()
                .when(m => !m.postalSameAsResidentialAddress)
            .on(this.profile);
    }


    detached() {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }

    async saveIndividualProfile() {
        this.isBusy = true;

        var valid = await this.controller.validate();
        if(!valid.valid) {
            this.isBusy = false;
            return;
        }
    
        return this.profileService.updateIndividualProfile(this.profile)
        .then(() => {
            this.notifier.success("Profile changes successfully saved.");
            this.eventAggregator.publish(BroadcastEvents.profileDataChanged);
            this.validationError = null;
            this.postalAddressChanged = false;
            this.residentialAddressChanged = false;

            // if redirect is defined then redirect once profile is finish editing
            if(this.redirect) {
                this.router.navigate(this.redirect);
            } else {

                this.router.navigateToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.IndividualProfileView]);
            }
        }).catch((error: Error) => {
            if (error instanceof ValidationError) {
                this.validationError = error;
            }
        }).finally(() => {
            this.isBusy = false;
        });
    }

    cancel() {
        this.router.navigateToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.IndividualProfileView]);
    }

    @computedFrom("profile.residentialAddress")
    get residentialAddress(): string {
        return addressArrayToString(this.profile.residentialAddress);
    }

    @computedFrom("profile.postalAddress")
    get postalAddress(): string {
        return addressArrayToString(this.profile.postalAddress);
    }

    @computedFrom("profile.workPhoneNumber", "profile.homePhoneNumber")
    get contactLabel(): string {
        var contactLabel = "Contact number";

        if (this.profile.workPhoneNumber) {
            contactLabel = "Mobile";
        }

        if (this.profile.homePhoneNumber) {
            contactLabel = "Mobile";
        }

        return contactLabel;
    }
}
