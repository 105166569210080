import { toTitleCase } from "shared/utils/to-title-case";

export class TitleCaseValueConverter {
    toView(value: string) {
        if (!value) {
            return "";
        }
        return toTitleCase(value);
    }
}
