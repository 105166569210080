import { BindingEngine, Disposable } from "aurelia-binding";
import { DialogService } from "aurelia-dialog";
import { autoinject, bindable, bindingMode, PLATFORM } from "aurelia-framework";
import { ValidateInstruction, ValidationController, ValidationRules } from "aurelia-validation";
import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";
import { AddressService } from "services/address-service";
import { DataService } from "services/data-service";
import { IValidateAddressModalParams, ValidateAddressModal } from "shared/controls/address/validate-address-modal";
import { NonNegativeNumberRule, NotAPOBox, NumericRule } from "shared/controls/custom-validation";
import { ClientValidationError } from "shared/utils/client-validation-error";
import { ControlIdGenerator } from "shared/utils/control-id-generator";


// webpack issue with modals:
PLATFORM.moduleName("shared/controls/address/validate-address-modal")

@autoinject
export class AddressInput {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) model: SharedDto.IAddressDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) addresstype: string;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) noPoBox: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) clearReadonly: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView }) required: boolean = false;//just used for styling

    //Used for the drop down lists. 
    countryList: SharedDto.ICodeListItemDto[] = [];
    stateList: SharedDto.ICodeListItemDto[];

    showStateSelect: boolean;
    private subscriptions: Disposable[] = [];
    id: number;
    private australia: SharedDto.ICodeListItemDto;
    countryListLoaded: boolean = false;
    stateListLoaded: boolean = false;

    constructor(
        private readonly controller: ValidationController,
        private readonly dataService: DataService,
        private readonly addressService: AddressService,
        private readonly bindingEngine: BindingEngine,
        private readonly dialogService: DialogService,
        private readonly controlId: ControlIdGenerator) {
    }

    bind() {
        this.id = this.controlId.getNextId();
        var countryCodes = this.dataService.getCountries().then((countryData: SharedDto.ICodeListItemDto[]) => {
            this.countryList = countryData;
            this.australia = this.countryList.find(c => c.code === "au");
            this.countryListLoaded = true;
        });
        var stateCodes = this.dataService.getStates().then((stateData: SharedDto.ICodeListItemDto[]) => {
            this.stateList = stateData;
            this.stateListLoaded = true;
        });

        if (!this.model) {
            this.model = {} as SharedDto.IAddressDto;
        }

        ValidationRules
            .ensure((m: SharedDto.IAddressDto) => m.line1).maxLength(150).required().when(m => { return !this.readonly })
            .ensure(m => m.line2).maxLength(150)
            .ensure(m => m.suburb).maxLength(50).required().when(m => { return !this.readonly })
            .ensure(m => m.postcode).minLength(4).maxLength(4).required().satisfiesRule(NumericRule).when(m => { return !this.readonly })
            .ensure(m => m.countryId).required()
            .ensure(m => m.australianStateTerritory)
            .displayName("State")
            .required()
            .when(m => {
                return this.australia && m.countryId == this.australia.id && !this.readonly;
            })
            .ensure(m => m.nonAustralianState)
            .displayName("State")
            .required()
            .when(m => {
                return this.australia && m.countryId != this.australia.id && !this.readonly;
            })
            .ensure(m => m.line1)
            .displayName("Line 1")
            .satisfiesRule(NotAPOBox)
            .when(m => this.noPoBox && !this.readonly)
            .ensure(m => m.line2)
            .displayName("Line 2")
            .satisfiesRule(NotAPOBox)
            .when(m => this.noPoBox && !this.readonly)
            .on(this.model);

        Promise.all([countryCodes, stateCodes]).then(() => {

            //set country to australia if new address
            if (this.model != undefined && (this.model.countryId == undefined || <any>this.model.countryId == "")) {
                var found = this.countryList.find((country) => country.description === "Australia");
                if (found) {
                    this.model.countryId = found.id;
                }
            }

            this.subscriptions.push(this.bindingEngine
                .propertyObserver(this.model, "countryId")
                .subscribe(() => {
                    this.countryUpdated();
                })
            );

            this.countryUpdated();
        });
    }

    detached() {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
        ValidationRules.off(this.model);
    }

    ensureAddressValid(): Promise<boolean> {
        return this.controller.validate({ object: this.model }).then(result => {
            if (!result.valid) {
                throw new ClientValidationError(result);
            }
            return this.addressService.validateAddresses(this.model).catch(error => {
                //if address validation network call fails just say it's an exact match to allow the user to still save.
                return { matchResult: Dto.Constants.AddressValidationMatchResult.ExactMatch };
            });
        }).then((data: Dto.IAddressValidationDto) => {
            if (data.matchResult == Dto.Constants.AddressValidationMatchResult.ExactMatch) {
                this.model.isVerified = true;
                return this.model;
            }
            var model: IValidateAddressModalParams = {
                addresstype: this.addresstype,
                address: this.model,
                matches: data.matches
            };
            return this.dialogService.open({
                viewModel: ValidateAddressModal,
                model: model,
                rejectOnCancel: true
            }).whenClosed(response => {
                return response.output; // the selected address
            });
        }).then((pickedAddress: SharedDto.IAddressDto) => {
            Object.assign(this.model, pickedAddress);
            return true;
        }).catch((error) => {
            if (!(error instanceof ClientValidationError || error.hasOwnProperty("wasCancelled"))) {
                console.log(error);
            }
            return false;
        });
    }

    countryUpdated() {
        if (this.model) {
            var found = this.countryList.find((country) => country.id == this.model.countryId);
            if (found) {
                if ((found.description === "Australia")) {
                    this.showStateSelect = true;
                    this.model.nonAustralianState = null;
                } else {
                    this.showStateSelect = false;
                    this.model.australianStateTerritory = null;
                }
            }
        }
    }

    readonlyChanged() {
        if (this.readonly) {
            if (this.clearReadonly) {
                this.model.line1 = null;
                this.model.line2 = null;
                this.model.suburb = null;
                this.model.postcode = null;
                this.model.australianStateTerritory = null;
                this.model.nonAustralianState = null;
            }

            this.controller.reset(<ValidateInstruction>{
                object: this.model
            });
        }
    }
}
