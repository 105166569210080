import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";
import { Dto } from "project/project";

export let SupplierRoute: RouteConfig[] = [
    {
        route: "grants/:contentIdentifier/suppliers",
        name: 'supplier-list',
        nav: false,
        title: "Supplier list",
        moduleId: PLATFORM.moduleName("grants/suppliers/supplier-portal", "supplier-portal"),
        settings: {
            breadcrumb: true,
            parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.GrantsDetail],
            auth: false
        }
    },
    {
        route: "grants/:contentIdentifier/suppliers/:supplierId",
        name: "supplier-profile",
        nav: false,
        title: "Supplier information",
        moduleId: PLATFORM.moduleName("grants/suppliers/supplier-details", "supplier-portal"),
        settings: {
            breadcrumb: true,
            parent: 'supplier-list',
            auth: false
        }
    }
];

var layoutView = PLATFORM.moduleName("layouts/default-layout.html"); // has to be relative to src/
var layoutViewModel = PLATFORM.moduleName("layouts/default-layout"); // has to be relative to src/

SupplierRoute.forEach(x => {
    x.layoutView = layoutView;
    x.layoutViewModel = layoutViewModel;
})