import { BindingEngine, computedFrom } from 'aurelia-binding';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, bindingMode, customElement, Disposable } from 'aurelia-framework';
import { ValidationController, ValidationRules } from 'aurelia-validation';
import { SharedDto } from "project/project-shared";
import { ApplicationFormService } from 'services/application-form-service';
import { DataService } from 'services/data-service';
import { Confirm } from 'shared/controls/confirm-modal/confirm';
import { ControlIdGenerator } from 'shared/utils/control-id-generator';
import { SharedBroadcastEvents } from 'shared/utils/SharedBroadcastEvents';
import { Logger } from '../../../../../../node_modules/aurelia-logging';
import { QuestionBase } from "../question-base";

@customElement('form-file-upload')
export class FormFileUpload extends QuestionBase {
    @bindable({ defaultBindingMode: bindingMode.toView }) questionInstance: SharedDto.OnlineForm.Application.IFileUploadAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) formTemplate: SharedDto.OnlineForm.Form.IFormTemplateDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;

    documentSources: SharedDto.ICodeListItemDto[];
    documentTypes: SharedDto.IDocumentTypeDto[];

    canUpload: boolean;
    internal: boolean;
    individual: boolean;
    loadingDocuments = true;
    documents: object[] = null;
    private subscriptions: Disposable[] = [];
    fromProfile: boolean;
    fromComment: boolean;
    fromUpload: boolean;
    fromExternalSystem: boolean;

    constructor(private readonly controller: ValidationController,
        public readonly controlIdGenerator: ControlIdGenerator,
        private readonly bindingEngine: BindingEngine,
        private readonly logger: Logger,
        private readonly applicationFormService: ApplicationFormService,
        private readonly dataService: DataService,
        private readonly confirm: Confirm,
        private readonly eventAggregator: EventAggregator) {
        super(controlIdGenerator);
        this.canUpload = this.applicationFormService.allowFileUploads;
        this.internal = this.applicationFormService.isPreview;
    }

    bind() {
        super.bind();

        var checkDocumentSource = (newValue, oldValue) => {
            // determine whether there was a file upload and a document attached to it.
            // if so, confirm that the user wants this file removed
            if (oldValue == SharedDto.Constants.ApplicationDocumentSource.Upload &&
                !!this.questionInstance.file) {
                this.confirm.confirm("Are you sure you want to delete the uploaded file?", "Confirm File Delete").then(
                    (val) => {
                        if (val) {
                            this.questionInstance.file = null;
                            this.fromProfile = newValue == SharedDto.Constants.ApplicationDocumentSource.Profile;
                            this.fromExternalSystem = newValue == SharedDto.Constants.ApplicationDocumentSource.ExternalSystem;
                            this.fromUpload = newValue == SharedDto.Constants.ApplicationDocumentSource.Upload;
                            this.fromComment = newValue == SharedDto.Constants.ApplicationDocumentSource.Comment;
                        } else {
                            this.questionInstance.documentSource = oldValue;
                            return;
                        }
                    }
                );
            } else {
                this.fromProfile = newValue == SharedDto.Constants.ApplicationDocumentSource.Profile;
                this.fromExternalSystem = newValue == SharedDto.Constants.ApplicationDocumentSource.ExternalSystem;
                this.fromUpload = newValue == SharedDto.Constants.ApplicationDocumentSource.Upload;
                this.fromComment = newValue == SharedDto.Constants.ApplicationDocumentSource.Comment;
                // lets delay this until we need it, rather than load it EVERY TIME.
                if (this.fromProfile && this.documents == null) {
                    (this.individual ? Promise.resolve([]) : this.applicationFormService.organisationDocuments())
                        .then(documents => {
                            this.documents = documents;
                            this.loadingDocuments = false;
                        });
                }
            }
        }

        this.subscriptions.push(
            this.bindingEngine.propertyObserver(this.questionInstance, "file").subscribe((newValue, oldValue) => {
                this.controller.validate({ object: this.questionInstance, propertyName: "comment" });
            }),
            this.bindingEngine.propertyObserver(this.questionInstance, "documentSource").subscribe(checkDocumentSource)
        );

        checkDocumentSource(this.questionInstance.documentSource, null);

        if(!this.readonly) {
            this.individual = this.applicationFormService.isIndividual;
        } else {
            this.individual = false;
        }

        this.dataService.getDocumentSources().then(d => this.documentSources = d);
        this.dataService.getDocumentTypes().then(d => this.documentTypes = d);
    }

    unbind() {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }

    attached() {
        super.attached();
        if (!this.questionInstance.documentSource && !this.isMandatory) {
            this.questionInstance.documentSource = SharedDto.Constants.ApplicationDocumentSource.NoSelection;
        }
        else if (!this.questionInstance.documentSource) {
            this.questionInstance.documentSource = SharedDto.Constants.ApplicationDocumentSource.Comment;
        }
    }

    setupValidation() {
        ValidationRules.off(this.questionInstance);
        var message = "A file or comment is required";

        ValidationRules
            .ensure((q: SharedDto.OnlineForm.Application.IFileUploadAnswerDto) => q.comment)
            .required()
            .when(q => {
                let res = q.file == null && q.comment == null && this.visibility && this.sectionVisibility && this.stepVisibility && this.onSubmitValidation && this.isMandatory
                return res;
            })
            .withMessage("You must provide a comment")

            .ensure((q: SharedDto.OnlineForm.Application.IFileUploadAnswerDto) => q.file)
            .required()
            .when(q => q.documentSource == SharedDto.Constants.ApplicationDocumentSource.Upload && this.onSubmitValidation && this.visibility && this.sectionVisibility && this.stepVisibility && this.isMandatory)
            .withMessage("You must upload a file")

            .ensure((q: SharedDto.OnlineForm.Application.IFileUploadAnswerDto) => q.profileFileId)
            .required()
            .when(q => q.documentSource == SharedDto.Constants.ApplicationDocumentSource.Profile && this.onSubmitValidation && this.visibility && this.sectionVisibility && this.stepVisibility && this.isMandatory)
            .withMessage("Please select your profile document")
            .on(this.questionInstance);
    }

    @computedFrom("questionTemplate")
    get allowedExtensions(): string[] {
        var extensions = [];
        var fileCategoryConfigs = this.questionTemplate.configurationOptions.filter(x => x.configurationType === SharedDto.Constants.QuestionConfigurationType.FileUploadCategory);
        if (fileCategoryConfigs.length === 0) {
            return extensions;
        } else {
            for (let config of fileCategoryConfigs) {
                switch (config.fileUploadCategory) {
                    case SharedDto.Constants.FileUploadCategory.Document:
                        extensions.push(...['doc', 'docx', 'odt', 'pdf', 'rtf', 'txt']);
                        break;
                    case SharedDto.Constants.FileUploadCategory.Image:
                        extensions.push(...['bmp', 'gif', 'ico', 'jpeg', 'jpg', 'png', 'svg', 'tif', 'tiff']);
                        break;
                    case SharedDto.Constants.FileUploadCategory.Audio:
                        extensions.push(...['aif', 'cda', 'mid', 'midi', 'mp3', 'mpa', 'ogg', 'wav', 'wma']);
                        break;
                    case SharedDto.Constants.FileUploadCategory.Video:
                        extensions.push(...['avi', 'mov', 'mp4', 'wmv']);
                        break;
                    case SharedDto.Constants.FileUploadCategory.Presentation:
                        extensions.push(...['key', 'odp', 'pps', 'ppt', 'pptx']);
                        break;
                    case SharedDto.Constants.FileUploadCategory.Compressed:
                        extensions.push(...['7z', 'arj', 'rar', 'tar.gz', 'z', 'zip']);
                        break;
                    case SharedDto.Constants.FileUploadCategory.Spreadsheet:
                        extensions.push(...['ods', 'xlr', 'xls', 'xlsx']);
                        break;
                    default:
                        let error = new Error(`File upload category ${config.fileUploadCategory} not implemented`);
                        this.logger.error(error as any);
                        throw error;
                }
            }
        }

        return extensions;
    }

    rechoose() {
        this.questionInstance.profileDocumentTitle = null;
        this.questionInstance.profileDocumentTypeDesc = null;
    }

    download() {
        //a parent element (application-form/online-form) should handle this and trigger the download
        this.eventAggregator.publish(SharedBroadcastEvents.downloadApplicationDocument, { fileStorageId: this.questionInstance.file.fileStorageId });
    }
}
