import { Dto } from "project/project";

export class ActiveProfile {
    profileType: "Individual" | "Organisation";
    profileIdentifier: number;
    profileDisplayName: string;
    profileUserFullname: string;
    profileUserId: number;
    profileAccountStatus: Dto.Constants.AccountStatus;
    allowedRoutes: Dto.Constants.ExternalPageRoutes[];
}