import { computedFrom, Disposable } from "aurelia-binding";
import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, bindable, bindingMode, DOM } from "aurelia-framework";
import { SharedDto } from "project/project-shared";
import { FileService } from "services/file-service";
import { Notifier } from "services/notifier";
import { SharedBroadcastEvents } from "shared/utils/SharedBroadcastEvents";
import { Confirm } from "../confirm-modal/confirm";
import "./file-uploader.less";


// don't export this. To use the FileUploader element, bind a IFilePropertiesDto object
interface IFileDetail extends SharedDto.IFilePropertiesDto {
    uploadError?: boolean;
    file?: File;
}

@autoinject
export class FileUploader {
    @bindable({ defaultBindingMode: bindingMode.oneTime }) multiple: boolean | string = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) uploadInProgress: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) files: IFileDetail | Array<IFileDetail>;
    @bindable() fileMode: "keep" = "keep";
    @bindable() buttonLabel: string
    @bindable() finishedUploadingCallback: Function
    @bindable() allowedExtensions: string[] = [];
    @bindable() allowUploadLimit: boolean = false;
    @bindable() uploadLimit: number = 26214400;
    @bindable() readonly: boolean = false;
    @bindable() securityContext: SharedDto.IFileSecurityDto = null;

    uploader: any;
    fileList: FileList;
    fileId: string;
    busy: boolean = false;
    private subscriptions: Disposable[] = [];
    parent: any;

    constructor(
        private readonly fileService: FileService,
        private readonly element: Element,
        private readonly notifier: Notifier,
        private readonly confirm: Confirm,
        private readonly eventAggregator: EventAggregator) {
        this.fileId = Math.random().toString();
    }

    bind(bindingContext, overrideContext) {
        this.parent = bindingContext;        
        this.multiple = this.multiple === true || this.multiple === "true";
        if (this.multiple && !this.files) {
            this.files = [];
        }

        if (this.uploader.files.length > 0 && !this.files) {
            this.uploader.value = null;
        }
    }

    attached() {

    }

    detached() {
        // clean up files that haven't been saved
        if (this.files == null) { return; }
        if (this.fileMode !== "keep") {
            // if you specify "keep" then you must cleanup non-saved files in some other place
            (this.multiple ? this.files as IFileDetail[] : [this.files as IFileDetail])
                .forEach(file => {
                    if (file && file.guid) {
                        this.fileService.deleteFile(file.guid);
                    }
                });
        }
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }

    delete(file: IFileDetail): Promise<void> {
        var cancel: boolean = false;
        return this.confirm.confirm("Are you sure you want to delete this file?", "Delete File").then(ok => {
            if (ok) {
                this.busy = true;
                return this.deleteFile(file);
            }
        });
    }

    deleteWithoutQuestion(file: IFileDetail): Promise<void> {
        this.busy = true;
        return this.deleteFile(file);
    }

    private deleteFile(file: IFileDetail): Promise<void> {
        var fileDelete: Promise<void> = file.guid ? this.fileService.deleteFile(file.guid) : Promise.resolve();

        return fileDelete.then(() => {
            this.busy = false;
            if (this.multiple) {
                var index = (this.files as IFileDetail[]).findIndex(f => f === file);
                if (index >= 0) {
                    (this.files as IFileDetail[]).splice(index, 1);
                }
            }
            else {
                this.files = null;
            }
            
            this.uploader.value = '';
            this.fireInputEvents();
        }).catch(error => {
            this.notifier.error("Failed to delete the file. Please try again or contact your system administrator.")
        });
    }

    retry(file: IFileDetail) {
        this.busy = true;
        this.uploadInProgress = true;
        this.fileService.uploadFile(file.file)
            .then(guid => {
                file.guid = guid;
                file.uploadError = false;
                this.fireInputEvents();
            })
            .catch(() => {
                file.uploadError = true;
            })
            .then(() => {
                this.busy = false;
                this.uploadInProgress = false;
                this.fireInputEvents();
            });
    }

    fileListChanged() {
        if (this.multiple) {
            this.uploadMultipleFiles(this.fileList);
        } else {
            this.uploadSingleFile(this.fileList.item(0));
        }
        this.fileList = null;
    }

    deleteFileOnReplace() {
        if (!this.multiple && this.files != null)
        {
            this.deleteFile((this.files as IFileDetail));
        }
        
        return true;
    }
    
    private uploadMultipleFiles(fileList: FileList) {
        this.busy = true;
        this.uploadInProgress = true;
        var filePromises: Array<Promise<void>> = [];
        // convert to an array:
        var fileArray: File[] = [];
        for (let i = 0; i < fileList.length; i++) {
            fileArray.push(fileList.item(i));
        }

        let aFileWasRemoved = false;
        let totalFileSize = 0;
        for (let file of fileArray) {
            totalFileSize += file.size;

            if (!this.isAllowedFileExtension(file as File)) {
                fileArray.splice(fileArray.indexOf(file), 1);
                aFileWasRemoved = true;
            }
        }

        if(this.allowUploadLimit) {
            if(totalFileSize > this.uploadLimit) {
                this.notifyOfFileSizeError()
                for (let file of fileArray) {
                    fileArray.splice(fileArray.indexOf(file), 1);
                    aFileWasRemoved = true;
                }
                this.uploadInProgress = false;
                this.busy = false;

                return
            }
        }

        if (aFileWasRemoved) {
            this.notifier.warning(`Some of the selected files did not match the allowed file extensions (${this.allowedExtensions.join(', ')}) and were cancelled.`);
        }

        fileArray.forEach(file => {
            // it's necessary to do this as a forEach loop and not a for(;;) loop
            // because forEach gives local variables their own scope
            var thisFile: IFileDetail = {
                guid: null,
                fileStorageId: null,
                fileName: file.name,
                fileSizeBytes: file.size,
                lastModifiedBy: null,
                //lastModifiedTime: file.lastModifiedDate.toISOString(),
                lastModifiedTime: new Date().toISOString(),
                file: file
            };
            (this.files as IFileDetail[]).push(thisFile);
            var p =
                this.fileService.uploadFile(file)
                    .then(guid => {
                        thisFile.guid = guid;
                        this.fireInputEvents();
                    })
                    .catch(() => {
                        thisFile.uploadError = true;
                    });
            filePromises.push(p);
        });
        Promise.all(filePromises).then(() => {
            this.busy = false;
            this.uploadInProgress = false;
        });
    }

    private uploadSingleFile(file: File) {

        if (!this.isAllowedFileExtension(file)) {
            this.notifyOfExtensionError();
            return;
        }

        if(this.allowUploadLimit) {
            if (!this.isAllowedFileSize(file)) {
                this.notifyOfFileSizeError();
                return;
            }
        }
        
        // this is mostly caused by deleting a file and the event triggering this to be called...
        if(!file){
            return;
        }

        this.busy = true;
        this.uploadInProgress = true;
        this.files = {
            guid: null,
            fileStorageId: null,
            fileName: file.name,
            fileSizeBytes: file.size,
            lastModifiedBy: null,
            //lastModifiedTime: file.lastModifiedDate.toISOString(),
            lastModifiedTime: new Date().toISOString(),
            file: file
        };
        return this.fileService.uploadFile(file).then(guid => {
            (this.files as IFileDetail).guid = guid;
            this.fireInputEvents();
        }).catch(() => {
            (this.files as IFileDetail).uploadError = true;
        }).then(() => {
            this.busy = false;
            this.uploadInProgress = false;
            this.fireInputEvents();
            if (this.finishedUploadingCallback) {
                this.finishedUploadingCallback();
            }
        });
    }

    private deleteMultiple(file: IFileDetail): Promise<void> {
        var index = (this.files as IFileDetail[]).findIndex(f => f === file);
        if (index < 0) {
            return Promise.resolve();
        }
        this.busy = true;
        var p: Promise<void> = this.files[index].guid ? this.fileService.deleteFile(this.files[index].guid) : Promise.resolve();
        return p
            .catch(() => {
                // catch before then() makes then() a finally(), i.e. run regardless of outcome
            })
            .then(() => {
                this.busy = false;
                (this.files as IFileDetail[]).splice(index, 1);
                this.fireInputEvents();
            });
    }

    private deleteSingle(): Promise<void> {
        var remove = this.files[0];
        if (!remove) {
            return Promise.resolve();
        }
        this.busy = true;
        var p: Promise<void> = remove.guid ? this.fileService.deleteFile(remove.guid) : Promise.resolve();
        return p
            .catch(() => {
                console.log("delete error for", remove);
                // catch before then() makes then() a finally(), i.e. run regardless of outcome
            })
            .then(() => {
                this.busy = false;
                this.files = null;
                this.fireInputEvents();
            });
    }

    //this is purely to get validation to trigger, assuming the <file-uploader> element is the target of a binding.
    private fireInputEvents() {
        //not sure how to fire change events, to get validation controllers to revalidate... 
        //they don't listen for change events, but hook into the binding and intercept it when it  updates the property it is bound to..
        this.element.dispatchEvent(DOM.createCustomEvent("blur", null));
    }

    private notifyOfExtensionError() {
        if (!this.allowedExtensions || this.allowedExtensions.length === 0) {
            return;
        } else {
            if (this.allowedExtensions.length === 1) {
                this.notifier.error("The file must have the following extension: " + this.allowedExtensions[0]);
            } else {
                this.notifier.error("The file must have one of the following extensions: " + this.allowedExtensions.join(", "));
            }
        }
    }

    private notifyOfFileSizeError() {
        if (!this.multiple) {
            this.notifier.error("The file size must best below " + this.uploadLimit / 1048576 + "MB");
        } else {
            this.notifier.error("The total file sizes must best below " + this.uploadLimit / 1048576 + "MB");
        }       
    }

    private isAllowedFileExtension(file: File): boolean {
        if (!this.allowedExtensions || this.allowedExtensions.length === 0) {
            return true;
        }
        if (!file || !file.name || file.name.indexOf(".") === -1) {
            return true;
        }
        let parts = file.name.split(".");
        let extension = parts[parts.length - 1];
        //case insensitive comparison
        return this.allowedExtensions.map(x => x.toLowerCase()).indexOf(extension.toLowerCase()) > -1;
    }

    private isAllowedFileSize(file: File): boolean {
        return file.size < this.uploadLimit;
    }

    @computedFrom("allowedExtensions")
    get fileAcceptFilter(): string {
        if (this.allowedExtensions && this.allowedExtensions.length > 0) {
            //['doc', 'docx'] >> ".doc, .docx"
            return this.allowedExtensions.map(x => "." + x).join(", ");
        }
        return "";
    }
}
