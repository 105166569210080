import { bindable, bindingMode, containerless, customElement } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import Inputmask from 'inputmask';
import { GenericPhoneNumberRule } from "shared/controls/custom-validation";

@customElement('phone-number')
@containerless()
export class phoneNumber {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: string;
    @bindable() phoneInput: HTMLInputElement;
    @bindable() displayName: string;
    @bindable() phoneNumberType: "landline" | "mobile" | "either" = "landline";
    @bindable() placeholder: string;
    @bindable() readonly: boolean = false;
    @bindable() disabled: boolean = false;

    attached() {
        let inputMask;
        switch (this.phoneNumberType) {
            case "mobile":
                inputMask = "9999 999 999";
                break;
            case "landline":
                inputMask = "99 9999 9999";
                break;
            default:
                inputMask = "9999999999";
                break;
        }
        Inputmask(inputMask, {
            autoUnmask: true,
            postValidation: () => {
                var evt = document.createEvent("HTMLEvents");
                evt.initEvent("change", false, true);
                this.phoneInput.dispatchEvent(evt);
                return true;
            }
        }).mask(this.phoneInput);
    }

    bind() {
        ValidationRules
            .ensure((model: phoneNumber) => model.value).displayName(this.displayName)
            .minLength(10).maxLength(10).satisfiesRule(GenericPhoneNumberRule)
            .on(this);
    }
}
