import { bindable, bindingMode, autoinject, computedFrom } from 'aurelia-framework';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { SharedDto } from "project/project-shared";
import { QuestionBase } from "../question-base";
import { ControlIdGenerator } from 'shared/utils/control-id-generator';
import { CurrencyFormatter } from 'shared/utils/utils';

@autoinject
export class FormDecimalCustomElement extends QuestionBase {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.IDecimalAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;

    showMoney: boolean = false;
    showDecimal: boolean = false;

    constructor(private controller: ValidationController, private controlIdGenerator: ControlIdGenerator) {
        super(controlIdGenerator);
    }

    bind() {
        super.bind();
    }

    attached() {
        super.attached();

        switch (this.questionTemplate.questionType) {
            case SharedDto.Constants.QuestionType.Money:
                this.showMoney = true;
                break;
            case SharedDto.Constants.QuestionType.Decimal:
                this.showDecimal = true;
                break;
            default:
                throw new Error('Question type (' + SharedDto.Constants.QuestionType[this.questionTemplate.questionType] + ') not found');
        }
    }

    setupValidation() {
        ValidationRules.off(this.questionInstance);

        var builder = ValidationRules.ensure((a: any) => a.a).required().when(a => false);
        var decimalPrefix = '';
        if (this.showMoney) {
            decimalPrefix = '$'
        }

        var minDecimalRule = this.questionTemplate.configurationOptions.find(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.MinDecimal);
        if (minDecimalRule) {
            builder.ensure((model: SharedDto.OnlineForm.Application.IDecimalAnswerDto) => model.answer)
                .satisfies((value: number, object: SharedDto.OnlineForm.Application.IDecimalAnswerDto) => {
                    if (!value) {
                        return true;
                    }
                    return value >= minDecimalRule.decimalValue
                })
                .when((x) => this.visibility)
                .withMessage("Answer is less than minimum amount of " + decimalPrefix + CurrencyFormatter(minDecimalRule.decimalValue));
        }

        var maxDecimalRule = this.questionTemplate.configurationOptions.find(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.MaxDecimal);
        if (maxDecimalRule) {
            builder.ensure((model: SharedDto.OnlineForm.Application.IDecimalAnswerDto) => model.answer)
                .satisfies((value: number, object: SharedDto.OnlineForm.Application.IDecimalAnswerDto) => {
                    if (!value) {
                        return true;
                    }
                    return value <= maxDecimalRule.decimalValue
                })
                .when((x) => this.visibility)
                .withMessage("Answer is more than maximum amount of " + decimalPrefix + CurrencyFormatter(maxDecimalRule.decimalValue));
        }

        builder.ensure((model: SharedDto.OnlineForm.Application.IDecimalAnswerDto) => model.answer)
            .required()
            .when(() => this.visibility && this.onSubmitValidation && this.isMandatory);

        builder.on(this.questionInstance);
    }

    @computedFrom("questionTemplate")
    get decimalPrecision(): number {
        var config = this.questionTemplate.configurationOptions.find(x => x.configurationType === SharedDto.Constants.QuestionConfigurationType.DecimalPlaces);
        if (config && config.numberValue) {
            return config.numberValue;
        }
        return 2;
    }
}
