import { inject, DOM, bindable, customElement, bindingMode, computedFrom, observable, Disposable, BindingEngine } from 'aurelia-framework';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { SharedDto } from "project/project-shared";
import { QuestionBase } from "../question-base";
import { ControlIdGenerator } from "../../../../utils/control-id-generator";
import { Decimal } from '../../../../../../node_modules/decimal.js-light';

@customElement('form-requested-amount')
export class FormRequestedAmount extends QuestionBase {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.IRequestedFundingAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;
    totalRequestedAmount: number = 0;
    subscriptions: Disposable[] = [];

    constructor(private readonly controller: ValidationController,
        private readonly bindingEngine: BindingEngine,
        private readonly controlIdGenerator: ControlIdGenerator) {
        super(controlIdGenerator);
    }

    bind() {
        super.bind();
        this.recalculateTotal();
    }

    attached() {
        super.attached();

        this.questionInstance.answers.sort((a, b) => (a.financialYearDescription > b.financialYearDescription ? 1 : -1));

        for (let answer of this.questionInstance.answers) {
            this.subscriptions.push(
                this.bindingEngine.propertyObserver(answer, "amount").subscribe(newValue => {
                    this.controller.validate({ object: answer, propertyName: "financialYearId" });
                    this.recalculateTotal();
                })
            );
            this.subscriptions.push(
                this.bindingEngine.propertyObserver(answer, "recurringAmount").subscribe(newValue => {
                    this.controller.validate({ object: answer, propertyName: "financialYearId" });
                    this.recalculateTotal();
                })
            );
        }
    }

    setupValidation() {
        this.controller.removeObject(this.questionInstance);
        for (let answer of this.questionInstance.answers) {
            this.controller.removeObject(answer);
            //this.controller.addObject(answer, rules);
            ValidationRules
                //note: proxy VM property since ensureObject() doesn't work.
                .ensure((answer: SharedDto.OnlineForm.Application.IRequestedFundingAmountDto) => answer.financialYearId)
                .satisfies((finId: number, answer: SharedDto.OnlineForm.Application.IRequestedFundingAmountDto) => {
                    return !!+answer.amount || !!+answer.recurringAmount;
                })
                .when(() => (this.visibility && this.onSubmitValidation && this.isMandatory))
                .withMessage("An amount must be specified.")
                .on(answer);
        }
    }

    detached() {
        for (let answer of this.questionInstance.answers) {
            this.controller.removeObject(answer);
        }

        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }

    private recalculateTotal() {
        var total = new Decimal(0);

        for(let requestedAmount of this.questionInstance.answers){
            total = total.add(requestedAmount.amount || 0);
            total = total.add(requestedAmount.recurringAmount || 0);
        }

        this.totalRequestedAmount = total.toNumber();
    }

    @computedFrom("questionInstance.answers")
    get answer() {
        if (!this.questionInstance || !this.questionInstance.answers || this.questionInstance.answers.length === 0) {
            return null;
        }
        return this.questionInstance.answers[0];
    }

    @computedFrom("questionInstance.answers")
    get multipleFiscalYears(): boolean {
        return this.questionInstance.answers.length > 1;
    }
}
