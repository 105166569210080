import { bindable, customElement, bindingMode } from 'aurelia-framework';

//attribution: this code was taken from teh aurelia-bs-grid project and modified to work independantly.
@customElement('table-data-pagination')
export class TableDataPagination {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) page;
    @bindable() recordCount = 0;
    @bindable() pageSize = 10;

    //button configuration
    @bindable() numberOfPageButtons = 5;
    @bindable() showFirstLastButtons = true;
    @bindable() showJumpButtons = false;
    @bindable() canNavigate: () => Promise<boolean> = () => Promise.resolve(true);

    // Disable/enable
    nextDisabled = false;
    prevDisabled = false;

    // Total number of items in the dataset
    pageCount = 0;
    pages = [];

    constructor() {
    }

    changePage(page) {
        return this.canNavigate().then(can => {            
            if(can) {
                this.page = this.cap(page);
                this.createPages();
            }
        });
    }

    recordCountChanged() {
        this.resetPageIfNotEnoughResults()
        this.createPages();
    }

    pageChanged() {
        this.createPages();
    }

    cap(page) {
        if (page < 1) {
            return 1;
        }
        if (page > this.pageCount) {
            return this.pageCount;
        }

        return page;
    }

    bind() {
        this.createPages();
    }

    createPages() {
        // Calc the max page number
        if (this.recordCount > 0 && this.pageSize > 0) {
            this.pageCount = Math.ceil(this.recordCount / this.pageSize);
        } else {
            this.pageCount = 1;
        }

        // Cap the number of pages to render if the count is less than number to show at once
        var numToRender = this.pageCount < this.numberOfPageButtons ? this.pageCount : this.numberOfPageButtons;

        // The current page should try to appear in the middle, so get the median 
        // of the number of pages to show at once - this will be our adjustment factor
        var indicatorPosition = Math.ceil(numToRender / 2);

        // Subtract the pos from the current page to get the first page no
        var firstPageNumber = this.page - indicatorPosition + 1;

        // If the first page is less than 1, make it 1
        if (firstPageNumber < 1) {
            firstPageNumber = 1;
        }

        // Add the number of pages to render
        // remember to subtract 1 as this represents the first page number
        var lastPageNumber = firstPageNumber + numToRender - 1;

        // If the last page is greater than the page count
        // add the difference to the first/last page
        if (lastPageNumber > this.pageCount) {
            var dif = this.pageCount - lastPageNumber;

            firstPageNumber += dif;
            lastPageNumber += dif;
        }

        var pages = [];

        for (var i = firstPageNumber; i <= lastPageNumber; i++) {
            pages.push(i);
        };

        this.pages = pages;

        this.updateButtons();
    }

    updateButtons() {
        this.nextDisabled = this.page === this.pageCount;
        this.prevDisabled = this.page === 1;
    }

    next() {
        if (this.nextDisabled) {
            return;
        }
        this.changePage(this.page + 1);
    }
    last() {
        if (this.nextDisabled) {
            return;
        }
        this.changePage(this.pageCount);
    }
    nextJump() {
        if (this.nextDisabled) {
            return;
        }
        this.changePage(this.page + this.numberOfPageButtons);
    }

    prev() {
        if (this.prevDisabled) {
            return;
        }
        this.changePage(this.page - 1);
    }
    prevJump() {
        if (this.prevDisabled) {
            return;
        }
        this.changePage(this.page - this.numberOfPageButtons);
    }
    first() {
        if (this.prevDisabled) { return; }
        this.changePage(1);
    }

    private resetPageIfNotEnoughResults() {
        if (this.recordCount < (this.page * this.pageSize)) {
            this.page = 1;
        }
    }
}
