import { isValidACN } from "abnacn-validator";
import { bindable, bindingMode, customElement } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import Inputmask from "inputmask";

@customElement('acn')
export class Acn {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: string;
    @bindable() acnInput: HTMLInputElement;

    attached() {
        Inputmask("999 999 999", {
            autoUnmask: true,
            postValidation: () => {
                var evt = document.createEvent("HTMLEvents");
                evt.initEvent("change", false, true);
                this.acnInput.dispatchEvent(evt);
                return true;
            }
        }).mask(this.acnInput);
    }

    bind() {
        ValidationRules
            .ensure((model: Acn) => model.value).displayName("ACN")
            .matches(/^(\d *?){9}$/).withMessage("The entered ACN needs to be 9 digits.").when((model: Acn) => model.value && model.value.length > 1)
            .satisfies((value: string) => isValidACN(value)).withMessage("The entered ACN failed validation.").when((model: Acn) => model.value && model.value.length == 9)
            .on(this);
    }
}
