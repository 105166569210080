import { autoinject, bindable, customAttribute, BindingEngine, Disposable } from "aurelia-framework";
import { SharedDto } from "project/project-shared";
import { TableDataAttribute } from "./table-data-attribute";

@customAttribute("table-data-sort")
@autoinject
export class TableDataSortAttribute {

    filterDirectionObserver: Disposable;
    filterColumnObserver: Disposable;

    @bindable({ primaryProperty: true }) key: string;
    tableElement: any;

    filter: SharedDto.IPagingFilter;

    constructor(private element: Element,
        private readonly bindingEngine: BindingEngine,
        private readonly tableData: TableDataAttribute) {

        if (element.nodeName != 'TH') {
            throw new Error("table-data-sort-attribute must be applied to a TH element!");
        }

        if (tableData == undefined) {
            throw new Error("table-data-sort attribute applied to a TH element, but cannot find a table-data on the parent table element.");
        }

        //class: sortable-header, asc, desc
        this.element.classList.add("sortable-header");
    }

    bind() {
        //get the filter from the parent view model then create some bindings to know when we have to update css classes
        //unsure as to whether creating a local reference to the viewModel.filter is bad or not..
        this.filterColumnObserver = this.bindingEngine.propertyObserver(this.tableData.filter, "sortColumn").subscribe(() => {
            this.refreshCssClass();
        });
        this.filterDirectionObserver = this.bindingEngine.propertyObserver(this.tableData.filter, "sortDirection").subscribe(() => {
            this.refreshCssClass();
        });

        this.element.addEventListener("click", () => {
            var currentColumn = this.getSortColumn();
            var currentDirection = this.getSortDirection();

            if (currentColumn == this.key) {
                if (currentDirection == SharedDto.Constants.SortDirection.Asc) {
                    this.setSortDirection(SharedDto.Constants.SortDirection.Desc);
                }
                if (currentDirection == SharedDto.Constants.SortDirection.Desc) {
                    //user cycled past desc, which probably means they want to remove sorting.
                    this.setSortColumn(null);
                    this.setSortDirection(SharedDto.Constants.SortDirection.None);
                }
            } else {
                this.setSortColumn(this.key);
                this.setSortDirection(SharedDto.Constants.SortDirection.Asc);
            }
        })
    }

    attached() {
        this.refreshCssClass();
    }

    private refreshCssClass() {
        var currentColumn = this.getSortColumn();
        var currentDirection = this.getSortDirection();
        if (currentColumn != this.key) {
            this.element.classList.remove("asc");
            this.element.classList.remove("desc");
        } else {
            if (currentDirection == SharedDto.Constants.SortDirection.Desc) {
                this.element.classList.remove("asc");
                this.element.classList.add("desc");
            }
            else if (currentDirection == SharedDto.Constants.SortDirection.Asc) {
                this.element.classList.remove("desc");
                this.element.classList.add("asc");
            } else {
                this.element.classList.remove("asc");
                this.element.classList.remove("desc");
            }
        }
    }

    private getSortColumn = (): string => {
        return this.tableData.filter.sortColumn;
    }

    private setSortColumn = (key: string) => {
        this.tableData.filter.sortColumn = key;
    }

    private getSortDirection = (): SharedDto.Constants.SortDirection => {
        return this.tableData.filter.sortDirection as SharedDto.Constants.SortDirection;
    }

    private setSortDirection = (direction: SharedDto.Constants.SortDirection) => {
        this.tableData.filter.sortDirection = direction;
    }

    detached() {
        this.element.classList.remove("sortable-header");
        this.filterColumnObserver.dispose();
        this.filterDirectionObserver.dispose();
    }
}
