import { SharedDto } from "project/project-shared";
import { DataService } from 'services/data-service';
import { OnlineFormService } from 'services/online-form-service';
import { autoinject } from "aurelia-framework";
import { Logger } from "../../../../../node_modules/aurelia-logging";
import { Dto } from "project/project";

@autoinject
export class CodeListService {

    constructor(private readonly dataService: DataService,
        private readonly logger: Logger,
        private readonly onlineFormService: OnlineFormService) {
    }

    public getDataList(questionId: number, codeListType: SharedDto.Constants.QuestionCodeListType, sortAlphabeticallyRule: boolean): Promise<FormOption[]> {
        switch (codeListType) {
            case SharedDto.Constants.QuestionCodeListType.OptionItems:
                return this.getOptionItemList(questionId, sortAlphabeticallyRule).then(this.mapForeignKeyList);
            case SharedDto.Constants.QuestionCodeListType.Title:
                return this.getTitlelist(sortAlphabeticallyRule).then(this.mapForeignKeyList);
            case SharedDto.Constants.QuestionCodeListType.DurationInNT:
                return this.getDurationsInNt(sortAlphabeticallyRule).then(this.mapForeignKeyList);
            case SharedDto.Constants.QuestionCodeListType.OrganisationContactRole:
                return this.getOrganisationContactRoles(sortAlphabeticallyRule).then(this.mapForeignKeyList);
            case SharedDto.Constants.QuestionCodeListType.LegalEntityType:
                return this.getLegalEntityTypes(sortAlphabeticallyRule).then(this.mapForeignKeyList);
            case SharedDto.Constants.QuestionCodeListType.BusinessActivityCategory:
                return this.getBusinessCategories(sortAlphabeticallyRule).then(this.mapForeignKeyList);
            case SharedDto.Constants.QuestionCodeListType.BusinessActivityCategoryMoreSpecific:
                return this.getBusinessCategoriesMoreSpecific(sortAlphabeticallyRule).then(this.mapForeignKeyList);
            case SharedDto.Constants.QuestionCodeListType.Beneficiary:
                return this.getBeneficiaries(sortAlphabeticallyRule).then(this.mapForeignKeyList);
            case SharedDto.Constants.QuestionCodeListType.ApplicationContactRole:
                return this.getApplicationContactRoles(sortAlphabeticallyRule).then(this.mapForeignKeyList);
            case SharedDto.Constants.QuestionCodeListType.Schools:
                return this.getSchools(sortAlphabeticallyRule).then(this.mapForeignKeyList);
            case SharedDto.Constants.QuestionCodeListType.Suppliers:
                return this.getSuppliers(questionId, sortAlphabeticallyRule).then(this.mapForeignKeyList);
            case SharedDto.Constants.QuestionCodeListType.SupplierCategories:
                return this.getSupplierCategories(questionId, sortAlphabeticallyRule).then(this.mapForeignKeyList);
            case SharedDto.Constants.QuestionCodeListType.SupplierLocations:
                return this.getSupplierLocations(questionId, sortAlphabeticallyRule).then(this.mapForeignKeyList);
            case SharedDto.Constants.QuestionCodeListType.RoundVouchers:
                return this.getRoundVouchers(questionId, sortAlphabeticallyRule).then(this.mapForeignKeyList);
            case SharedDto.Constants.QuestionCodeListType.ReviewOutcomes:
                // we don't want to return 'Skip Step' as a selectable option in a question.
                return this.getReviewOutcomes(questionId, sortAlphabeticallyRule)
                    .then(x => x.filter(y => y.id != Dto.Constants.ReviewOutcomeResult.SkipStep))
                    .then(this.mapEnumList);
            case SharedDto.Constants.QuestionCodeListType.EligibilityOutcomes:
                // we don't want to return 'Skip Step' as a selectable option in a question.
                return this.getEligibilityOutcomes(questionId, sortAlphabeticallyRule)
                    .then(x => x.filter(y => y.id != Dto.Constants.EligibilityOutcome.SkipStep))
                    .then(this.mapEnumList);
            default:
                let error = new Error("Code list not implemented in form controls!");
                this.logger.error(error as any);
                throw error;
        }
    }


    private mapForeignKeyList(list: SharedDto.ICodeListItemDto[]): FormOption[] {
        return list.map(x => {
            return {
                value: x.id.toString(),
                description: x.description,
                active: x.active,
            }
        });
    }

    private mapEnumList(list: SharedDto.ICodeListItemDto[]): FormOption[] {
        return list.map(x => {
            return {
                value: x.code,
                description: x.description,
                active: x.active
            }
        });
    }

    private getOptionItemList(questionId: number, sortAlphabeticallyRule: boolean): Promise<SharedDto.ICodeListItemDto[]> {
        return this.onlineFormService.getOptionItems(questionId).then((data) => {
            if (sortAlphabeticallyRule) {
                data = data.sort((a, b) => {
                    if (a.description < b.description) return -1;
                    if (a.description > b.description) return 1;
                    return 0;
                });
            }
            else {
                data = data.sort((a, b) => a.sortOrder - b.sortOrder);
            }
            return data.map((code) => {
                let c = {} as SharedDto.ICodeListItemDto;
                c.id = code.optionItemId;
                c.description = code.description;
                c.active = true;
                return c;
            })
        });
    }

    private getRoundVouchers(questionId: number, sortAlphabeticallyRule: boolean): Promise<SharedDto.ICodeListItemDto[]> {
        return this.dataService.getRoundVouchersByQuestion(questionId).then((data) => {
            if (sortAlphabeticallyRule) {
                data = data.sort((a, b) => {
                    if (a.description < b.description) return -1;
                    if (a.description > b.description) return 1;
                    return 0;
                });
            }
            return data;
        });
    }

    private getReviewOutcomes(questionId: number, sortAlphabeticallyRule: boolean): Promise<SharedDto.ICodeListItemDto[]> {
        return this.dataService.getReviewOutcomes().then((data) => {
            if (sortAlphabeticallyRule) {
                data = data.sort((a, b) => {
                    if (a.description < b.description) return -1;
                    if (a.description > b.description) return 1;
                    return 0;
                });
            }
            return data;
        });
    }

    private getEligibilityOutcomes(questionId: number, sortAlphabeticallyRule: boolean): Promise<SharedDto.ICodeListItemDto[]> {
        return this.dataService.getEligibilityOutcomes().then((data) => {
            if (sortAlphabeticallyRule) {
                data = data.sort((a, b) => {
                    if (a.description < b.description) return -1;
                    if (a.description > b.description) return 1;
                    return 0;
                });
            }
            return data;
        });
    }

    private getSupplierCategories(questionId: number, sortAlphabeticallyRule: boolean): Promise<SharedDto.ICodeListItemDto[]> {
        return this.dataService.getSupplierCategories(questionId).then((data) => {
            if (sortAlphabeticallyRule) {
                data = data.sort((a, b) => {
                    if (a.description < b.description) return -1;
                    if (a.description > b.description) return 1;
                    return 0;
                });
            }
            return data;
        });
    }

    private getSupplierLocations(questionId: number, sortAlphabeticallyRule: boolean): Promise<SharedDto.ICodeListItemDto[]> {
        return this.dataService.getSupplierLocations(questionId).then((data) => {
            if (sortAlphabeticallyRule) {
                data = data.sort((a, b) => {
                    if (a.description < b.description) return -1;
                    if (a.description > b.description) return 1;
                    return 0;
                });
            }
            return data;
        });
    }

    private getSuppliers(questionId: number, sortAlphabeticallyRule: boolean): Promise<SharedDto.ICodeListItemDto[]> {
        return this.dataService.getSuppliers(questionId, false).then((data) => {
            if (sortAlphabeticallyRule) {
                data = data.sort((a, b) => {
                    if (a.description < b.description) return -1;
                    if (a.description > b.description) return 1;
                    return 0;
                });
            }
            return data;
        });
    }

    private getTitlelist(sortAlphabeticallyRule: boolean): Promise<SharedDto.ICodeListItemDto[]> {
        return this.dataService.getTitles().then((data) => {
            if (sortAlphabeticallyRule) {
                data = data.sort((a, b) => {
                    if (a.description < b.description) return -1;
                    if (a.description > b.description) return 1;
                    return 0;
                });
            }
            return data;
        });
    }

    private getBeneficiaries(sortAlphabeticallyRule: boolean): Promise<SharedDto.ICodeListItemDto[]> {
        return this.dataService.getBeneficiariesTopLevel().then((data) => {
            if (sortAlphabeticallyRule) {
                data = data.sort((a, b) => {
                    if (a.description < b.description) return -1;
                    if (a.description > b.description) return 1;
                    return 0;
                });
            }
            return data;
        });
    }

    public getSchools(sortAlphabeticallyRule: boolean): Promise<SharedDto.ICodeListItemDto[]> {
        return this.dataService.getSchools().then(data => {
            if (sortAlphabeticallyRule) {
                data = data.sort((a, b) => {
                    if (a.description < b.description) return -1;
                    if (a.description > b.description) return 1;
                    return 0;
                });
            }
            return data;
        });
    }

    private getDurationsInNt(sortAlphabeticallyRule: boolean): Promise<SharedDto.ICodeListItemDto[]> {
        return this.dataService.getDurationsOperatingInNt().then((data) => {
            if (sortAlphabeticallyRule) {
                data = data.sort((a, b) => {
                    if (a.description < b.description) return -1;
                    if (a.description > b.description) return 1;
                    return 0;
                });
            }
            return data;
        });
    }

    private getOrganisationContactRoles(sortAlphabeticallyRule: boolean): Promise<SharedDto.ICodeListItemDto[]> {
        return this.dataService.getOrganisationContactRoles().then((data) => {
            if (sortAlphabeticallyRule) {
                data = data.sort((a, b) => {
                    if (a.description < b.description) return -1;
                    if (a.description > b.description) return 1;
                    return 0;
                });
            }
            return data;
        });
    }

    private getLegalEntityTypes(sortAlphabeticallyRule: boolean): Promise<SharedDto.ICodeListItemDto[]> {
        return this.dataService.getLegalEntities().then((data) => {
            if (sortAlphabeticallyRule) {
                data = data.sort((a, b) => {
                    if (a.description < b.description) return -1;
                    if (a.description > b.description) return 1;
                    return 0;
                });
            }
            return data;
        });
    }

    private getBusinessCategories(sortAlphabeticallyRule: boolean): Promise<SharedDto.ICodeListItemDto[]> {
        return this.dataService.getCategoryByLevel(1).then((data) => {
            if (sortAlphabeticallyRule) {
                data = data.sort((a, b) => {
                    if (a.description < b.description) return -1;
                    if (a.description > b.description) return 1;
                    return 0;
                });
            }
            return data;
        });
    }

    private getBusinessCategoriesMoreSpecific(sortAlphabeticallyRule: boolean): Promise<SharedDto.ICodeListItemDto[]> {
        return this.dataService.getCategoryByLevel(2).then((data) => {
            if (sortAlphabeticallyRule) {
                data = data.sort((a, b) => {
                    if (a.description < b.description) return -1;
                    if (a.description > b.description) return 1;
                    return 0;
                });
            }
            return data;
        });
    }

    public getApplicationContactRoles(sortAlphabeticallyRule: boolean): Promise<SharedDto.ICodeListItemDto[]> {
        return this.dataService.getApplicationContactRoles().then((data) => {
            if (sortAlphabeticallyRule) {
                data = data.sort((a, b) => {
                    if (a.description < b.description) return -1;
                    if (a.description > b.description) return 1;
                    return 0;
                });
            }
            return data;
        });
    }
}

export interface FormOption {
    value: string;
    description: string;
    active: boolean;
}
