import { BindingEngine, Disposable } from 'aurelia-binding';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, bindingMode, customElement } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationController } from 'aurelia-validation';
import { SharedDto } from "project/project-shared";
import { SectionVisibilityChannelName } from "../form-utils";


@customElement('form-section-group')
export class FormSectionGroup {
    @bindable({ defaultBindingMode: bindingMode.toView }) sectionTemplate: SharedDto.OnlineForm.Form.ISectionDto;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) sectionGroup: SharedDto.OnlineForm.Application.IFormInstanceSectionGroupDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) stepVisibility: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) onSubmitValidation: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView }) formInstance: SharedDto.OnlineForm.Application.IFormInstanceDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) formTemplate: SharedDto.OnlineForm.Form.IFormTemplateDto;
    @bindable() sectionAddedCallback: () => void;
    @bindable() formControlId: number = null;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) router: Router;
    @bindable({ defaultBindingMode: bindingMode.toView }) additionalInformationRequest: SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestSharedDto[];
    @bindable({ defaultBindingMode: bindingMode.twoWay }) allowAdditionalInfoRequests: boolean = false;

    //Used to determine if you are allowed to add in additional instances of the section. 
    canAddInstance: boolean;
    canDeleteInstance: boolean;

    addSectionButtonText: string = "Add another section";
    private subscriptions: Disposable[] = [];

    constructor(private validationController: ValidationController, private eventAggregator: EventAggregator, private readonly bindingEngine: BindingEngine) {

    }

    bind() {        
        if (!this.sectionTemplate) {
            throw new Error('Section template was not specified!');
        }

        if (!this.sectionGroup) {
            throw new Error('Section Group was not specified!');
        }

        if (this.sectionTemplate.sectionId != this.sectionGroup.templateSectionId) {
            throw new Error(`SectionId from template ${this.sectionTemplate.sectionId} and section group ${this.sectionGroup.templateSectionId} do not match!`);
        }

        if (this.sectionTemplate.repeatable) {
            if (this.sectionTemplate.addSectionButtonOverrideText) {
                this.addSectionButtonText = this.sectionTemplate.addSectionButtonOverrideText;
            }

            if (this.sectionTemplate.repeatMin && this.sectionTemplate.repeatMin > this.sectionGroup.sections.length) {
                //See we we need some dummy instance as there may be a min of 3. 
                var instancesNeededToBeCreated = this.sectionTemplate.repeatMin - this.sectionGroup.sections.length;
                for (var i = 0; i < instancesNeededToBeCreated; i++) {
                    this.addSectionInstance();
                }
            }
        }
        if (this.sectionGroup.sections.length == 0) {
            this.addSectionInstance();
        }


        this.updateInstanceValues();
    }

    attached() {
        this.subscriptions.push(this.eventAggregator.subscribe(SectionVisibilityChannelName(this.formControlId, this.sectionGroup.templateSectionId), (visibleStatus: boolean) => {
            this.sectionGroup.visible = visibleStatus;
            if (visibleStatus == false) {
                this.sectionGroup.sections.forEach((section) => {
                    section.questionGroups.forEach((questionGroup) => {
                        questionGroup.questions.forEach((question) => {
                            this.validationController.reset({ object: question });
                        });
                    });
                });
            }
        }));
    }

    detached() {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }

    private updateInstanceValues() {
        //Called when adding or removing a seciont instance from the section group. This is used to updates to bools of canAddInstance and canDeleteInstance.
        this.canAddInstance = this.sectionTemplate.repeatable
            && ((this.sectionTemplate.repeatMax && this.sectionTemplate.repeatMax > this.sectionGroup.sections.length)
                || this.sectionTemplate.repeatMax == null);

        this.canDeleteInstance = this.sectionTemplate.repeatable
            && ((this.sectionTemplate.repeatMin && this.sectionTemplate.repeatMin < this.sectionGroup.sections.length)
                || !this.sectionTemplate.repeatMin);

        this.sectionGroup.sections.sort((a, b) => a.sortOrder - b.sortOrder);
    }

    addSectionInstance() {
        var newSortOrder = 1;
        if (this.sectionGroup.sections.length !== 0) {
            var newSortOrder = this.sectionGroup.sections.sort((a, b) => b.sortOrder - a.sortOrder)[0].sortOrder + 1;
        }

        var newSectionInstance: SharedDto.OnlineForm.Application.IFormInstanceSectionDto = {
            templateSectionId: this.sectionTemplate.sectionId,
            sectionId: null,
            visible: true,
            sortOrder: newSortOrder,
            questionGroups: []
        };

        this.sectionTemplate.questions.forEach(questionTemplate => {
            var questionGroup: SharedDto.OnlineForm.Application.IFormInstanceQuestionGroupDto = {
                templateQuestionId: questionTemplate.questionId,
                sortOrder: questionTemplate.sortOrder,
                visible: questionTemplate.visible,
                allowAdditionalInformation: true,
                questions: [],
            };
            newSectionInstance.questionGroups.push(questionGroup);
        });

        this.sectionGroup.sections.push(newSectionInstance);

        this.updateInstanceValues();
        this.sectionAddedCallback();
    }

    removeSectionInstance(sectionInstance: SharedDto.OnlineForm.Application.IFormInstanceSectionDto) {
        var found = this.sectionGroup.sections.indexOf(sectionInstance);
        if (found >= 0) {
            this.sectionGroup.sections.splice(found, 1);
        }

        this.sectionGroup.sections.filter(x => x.sortOrder > sectionInstance.sortOrder).forEach(sectionInstance => sectionInstance.sortOrder--);

        this.updateInstanceValues();
        this.sectionAddedCallback();
    }
}
