import { ValidationError } from "../validation-error";
import { bindable, customElement } from "aurelia-framework";
import scrollIntoView from "scroll-into-view";

@customElement('validation-summary')
export class ValidationSummaryComponent {

    @bindable() validationError: ValidationError;

    constructor(private element: Element){
        
    }

    validationErrorChanged(newValue, oldValue){
        if(newValue){
            scrollIntoView(this.element);
        }
    }
}