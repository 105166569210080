import { RouterConfiguration, Router } from "aurelia-router";
import { autoinject, PLATFORM } from "aurelia-framework";
import { Dto } from "project/project";


@autoinject
export class AccountDetailsModule {
    router: Router;
    toggle:boolean = false; 

    showSidebar() {
        this.toggle = !this.toggle;
    }

    configureRouter(config: RouterConfiguration, router: Router, params) {
        config.map([
            { route: '', redirect: 'contact-information' },
            {
                route: 'contact-information', 
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.IndividualProfileView],
                moduleId: PLATFORM.moduleName("profile/individual/contact-information/view/contact-information-view"),
                title: "Contact information",
                nav: true,
                settings: {
                    breadcrumb: true
                }
                
            },
            {
                route: ['contact-information/edit'], 
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.IndividualProfileEdit],
                moduleId: PLATFORM.moduleName("profile/individual/contact-information/edit/contact-information-edit"),
                title: "Edit contact information",
                nav: false,
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.IndividualProfileView],
                    breadcrumb: true
                }
            },

            {
                route: ['bank-details'], 
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.BankDetailsView],
                moduleId: PLATFORM.moduleName("profile/individual/bank-details/bank-details"),
                title: "Personal bank details",
                nav: true,
                settings: {
                    breadcrumb: true
                }
            },
            {
                route: ['change-password'], 
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ManageAccountChangePassword],
                moduleId: PLATFORM.moduleName("profile/individual/change-password/edit/change-password-edit"),
                title: "Change password",
                nav: true,
                settings: {
                    breadcrumb: true
                }
            },
            {
                route: ['change-username'], 
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ManageAccountChangeUsername],
                moduleId: PLATFORM.moduleName("profile/individual/change-username/edit/change-username-edit"),
                title: "Change username",
                nav: true,
                settings: {
                    breadcrumb: true
                }
            },

            {
                route: ['subscription'], 
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Subscription],
                moduleId: PLATFORM.moduleName("profile/individual/subscription/edit/subscription-edit"),
                title: "Subscription",
                nav: true,
                settings: {
                    breadcrumb: true
                }
            },
        ]);

        this.router = router;
    }
}
