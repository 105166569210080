import { autoinject, bindable, bindingMode, computedFrom, Disposable } from 'aurelia-framework';
import { SharedDto } from 'project/project-shared';
import { ControlIdGenerator } from 'shared/utils/control-id-generator';
import { ValidationController, ValidationRules } from '../../../../../../node_modules/aurelia-validation';
import { DeepObserver } from '../../../../utils/deep-observer';
import { QuestionBase } from "../question-base";

@autoinject
export class FormOpeningHours extends QuestionBase {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.IOpeningHoursAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;
    id: number;

    private subscriptions: Disposable[] = [];
    schools: SharedDto.ICodeListItemDto[];

    constructor(private controlIdGenerator: ControlIdGenerator,
        private readonly deepObserver: DeepObserver,
        private readonly validationController: ValidationController) {
        super(controlIdGenerator);

        this.id = controlIdGenerator.getNextId();
    }

    bind() {
        super.bind();

    }

    attached() {
        super.attached();
        this.setupValidation();
        this.subscriptions.push(
            this.deepObserver.observe(this, "questionInstance", () => {
                //triger revalidation of the rules attached to 'this' (all fields mandatory when one field entered)
                //this is mostly to hide the validation message if it ever appears, as soon as it's no longer the case.
                if (this.validationController.errors.findIndex(x => x.object === this)) {
                    this.validationController.validate({
                        object: this
                    });
                }
            })
        );
    }

    unbind() {
        while (this.subscriptions.length) {
            this.subscriptions.pop().dispose();
        }
    }

    setupValidation() {
        ValidationRules.off(this.questionInstance);

        ValidationRules
            .ensure((q: SharedDto.OnlineForm.Application.IOpeningHoursAnswerDto) => q.sunday)
                .maxLength(100)
            .ensure((q: SharedDto.OnlineForm.Application.IOpeningHoursAnswerDto) => q.monday)
                .maxLength(100)
            .ensure((q: SharedDto.OnlineForm.Application.IOpeningHoursAnswerDto) => q.tuesday)
                .maxLength(100)
            .ensure((q: SharedDto.OnlineForm.Application.IOpeningHoursAnswerDto) => q.wednesday)
                .maxLength(100)
            .ensure((q: SharedDto.OnlineForm.Application.IOpeningHoursAnswerDto) => q.thursday)
                .maxLength(100)
            .ensure((q: SharedDto.OnlineForm.Application.IOpeningHoursAnswerDto) => q.friday)
                .maxLength(100)
            .ensure((q: SharedDto.OnlineForm.Application.IOpeningHoursAnswerDto) => q.saturday)
                .maxLength(100)            
            .on(this.questionInstance)

    }


}
