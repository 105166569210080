import { ValidationRules } from "aurelia-validation";
import { partiallyValidateVoucherNumber, valdiateVoucherParts, validateVoucherNumber } from "shared/utils/voucher-number-validation";

export var WebsiteAddressRule = "websiteAddressRule";
export var StartsWithHttpOrHttps = "startsWithHttpOrHttps";
export var VendorIdRule = "vendorIdRule";
export var PersonNameRule = "personName";
export var BusinessNameRule = "businessName";
export var DateOfBirthRule = "dateOfBirth";
export var GenericPhoneNumberRule = "genericPhoneNumberRule"
export var NotAPOBox = "notPoBoxRule";
export var NumericRule = "numericRule";
export var NonNegativeNumberRule = "nonNegativeNumberRule";
export var PositiveNumberRule = "positiveNumberRule";
export var PositiveWholeNumberRule = "positiveWholeNumberRule";
export var DomainFormatRule = "domainFormatRule";
export var DateComparison = "dateComparison";
export var AlphaNumericRule = "alphaNumericRule";
export var HasElementsRule = "hasElements";
export var FutureDateRule = "futureDateRule";
export var FutureTimeRule = "futureTimeRule";
export var TodayOrLaterRule = "todayOrLaterRule";
export var HtmlNotEmpty = "htmlNotEmpty";
export var WorkingDays = "workingDays";
export var VoucherNumberRule = "voucherNumberRule";

export function SetupCustomValidation() {
    ValidationRules.customRule(AlphaNumericRule, (value) => {
        if (value === undefined || value === null || value === '') {
            return true;
        }
        return /^[A-Za-z0-9]*$/.test(value);
    }, "${$displayName} can only contain letters and numbers.");

    ValidationRules.customRule(WebsiteAddressRule, (value) => {
        if (value === undefined || value === null || value === '') {
            return true;
        }
        return /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/.test(value);
    }, "Not a valid URL");

    ValidationRules.customRule(VendorIdRule, (value) => {
        if (value == undefined || value == null || value == '') {
            return true;
        }
        return /^[A-Za-z0-9]*$/.test(value);
    }, "${$displayName} can only contain characters and numbers.");

    ValidationRules.customRule(PersonNameRule, (value) => {
        if (value === undefined || value === null || value === '') {
            return true;
        }
        return /^[A-Za-z'\s-]*$/.test(value);
    }, "${$displayName} can only contain characters, spaces, apostrophes, and hyphens.");

    ValidationRules.customRule(BusinessNameRule, (value) => {
        if (value === undefined || value === null || value === '') {
            return true;
        }
        return /^[\dA-Za-z'\s\-!\"#\$\%\&\(\)\*\,\.\/\{\|\}\:\;\=\?\@\\\\_]*$/.test(value);
    }, "${$displayName} can only contain spaces, alphanumeric characters or the following symbols: ! \" # $ % & ' ( ) * , - . / : ; = ? @ \ _ { | }");

    ValidationRules.customRule(DateOfBirthRule, (value) => {
        if (value === undefined || value === null || value === '') {
            return true;
        }
        var dateOfBirth = new Date(value);
        var now = new Date();
        return dateOfBirth < now;
    }, "${$displayName} can not be a future date.");

    ValidationRules.customRule(GenericPhoneNumberRule, (value) => {
        if (value === undefined || value === null || value === '') {
            return true;
        }
        return /^[0-9]+$/.test(value);
    }, "${$displayName} must be digits.");

    ValidationRules.customRule(NotAPOBox, (value: string) => {
        //checks for a match of P, followed by optional period, followed by O, optional period, space, and "Box".
        //e.g. PO Box, P.O. Box, P.O Box
        //note: apply to line1 and line2 of an address.
        if (!value) {
            return true;
        }
        return !/\bP(ost|ostal)?([ \.]*(O|0)(ffice)?)?([ \.]*Box)?\b/i.test(value);
    }, "${$displayName} cannot be a Post Office Box.");

    ValidationRules.customRule(NumericRule, (value) => {
        if (value === undefined || value === null || value === '') {
            return true;
        } else {
            return !isNaN(value);
        }
    }, "${$displayName} must only contain numbers.");

    ValidationRules.customRule(PositiveNumberRule, (value) => {
        if (value === undefined || value === null || value === '') {
            return true;
        } else {
            return +value > 0;
        }
    }, "${$displayName} must be a positive number.");

    ValidationRules.customRule(NonNegativeNumberRule, (value) => {
        if (value === undefined || value === null || value === '') {
            return true;
        } else {
            return +value >= 0;
        }
    }, "${$displayName} must not be a negative number.");

    ValidationRules.customRule(PositiveWholeNumberRule, (value) => {
        if (value === undefined || value === null || value === '') {
            return true;
        } else {
            return value % 1 === 0 && +value > 0;
        }
    }, "${$displayName} must be a positive whole number.");

    ValidationRules.customRule(DomainFormatRule, (value) => {
        if (value === undefined || value === null || value === '') {
            return true;
        } else {
            return value.indexOf("\\") > -1;
        }
    }, "${$displayName} must be prefixed with a domain and a backward slash. Example: \"domain\\username\"");

    /**
     * Usage:
     * .ensure(r => r.dateA)
     *          .displayName("display name for date A")
     *          .satisfiesRule(DateComparison, "<=", o => o.dateB, "display name for date B")
     *
     * This would ensure that dateA is less than or equal to dateB. Comparitors are <, <=, =, >=, >.
     */
    ValidationRules.customRule(DateComparison,
        (value: string, object: object, comparitor: string, otherDateSelector: (object: object) => string, otherDisplayName) => {
            if (value == null || otherDateSelector == null || otherDateSelector(object) == null || comparitor == null || comparitor === "") {
                return true;
            }
            let thisDate = new Date(value);
            let otherDate = new Date(otherDateSelector(object));
            console.log(thisDate, comparitor, otherDate);
            switch (comparitor) {
                case "<": return thisDate < otherDate;
                case ">": return thisDate > otherDate;
                case "=": return thisDate.getTime() === otherDate.getTime();
                case "<=": return thisDate <= otherDate;
                case ">=": return thisDate >= otherDate;
                default:
                    throw new Error(`unknown comparitor (${comparitor} in date comparison rule`);
            }
        }, "${$displayName} must be ${$config.words} ${$config.otherDisplayName}",
        (comparitor, otherDateSelector, otherDisplayName) => {
            var words: string;
            switch (comparitor) {
                case "<": words = "before"; break;
                case ">": words = "after"; break;
                case "=": words = "equal to"; break;
                case "<=": words = "on or before"; break;
                case ">=": words = "on or after"; break;
                default:
                    throw new Error(`unknown comparitor (${comparitor} in date comparison rule`);
            }
            return { words, otherDisplayName };
        });

    ValidationRules.customRule(HasElementsRule,
        (value: any[], object) => {
            return value && value.length > 0;
        },
        `At least one \${$displayName} is required`);

    ValidationRules.customRule(StartsWithHttpOrHttps,
        (value: string, object) => {
            if (value === undefined || value === null || value === '') {
                return true;
            }
            return value.toLocaleLowerCase().startsWith("http://") || value.toLocaleLowerCase().startsWith("https://");
        },
        `The URL must begin with http:// or https://`);

    ValidationRules.customRule(FutureDateRule,
        (value: string, object) => {
            if (value === undefined || value === null || value === '') {
                return true;
            }
            var inputDate = new Date(value);
            var now = new Date();
            var midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
            return inputDate >= midnight;
        },
        "${$displayName} must be a future date.");


    ValidationRules.customRule(FutureTimeRule,
        (value: string, object) => {
            if (value === undefined || value === null || value === '') {
                return true;
            }
            var inputDate = new Date(value);
            var now = new Date();
            return inputDate >= now;
        },
        "${$displayName} must be in the future.");


    ValidationRules.customRule(TodayOrLaterRule,
        (value: string, object) => {
            if (value === undefined || value === null || value === '') {
                return true;
            }
            var inputDate = new Date(value);
            var now = new Date();
            var startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            return inputDate >= startOfToday;
        },
        "${$displayName} must be a today or later.");

    ValidationRules.customRule(HtmlNotEmpty,
        (value: string, object) => {
            //A check against having an empty string in a rich text editor
            if (value === undefined || value === null) {
                return true;
            }
            return value.indexOf("<") > -1 && value.indexOf(">") > -1;
        }, "${$displayName} must not be empty.");


    ValidationRules.customRule(WorkingDays,
        (value: string, object) => {
            //A check against having an empty string in a rich text editor
            if (value === undefined || value === null) {
                return true;
            }
            var myDate = new Date(value);

            return !(myDate.getDay() == 6 || myDate.getDay() == 0);
        }, "${$displayName} payment date can not be set to a Saturday or Sunday");

    ValidationRules.customRule(VoucherNumberRule,
        (value: string) => {
            //A check against having an empty string in a rich text editor
            if (value === undefined || value === null || value.length === 0) {
                return true;
            }
            return partiallyValidateVoucherNumber(value);
        }, "${$displayName} is not a valid voucher number and may have been entered incorrectly");

}
