import { Disposable } from "aurelia-binding";
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, bindingMode, children } from "aurelia-framework";
import { TaskQueue } from "aurelia-task-queue";
import { ValidateEvent, ValidationController } from "aurelia-validation";
import scrollIntoView from "scroll-into-view";
import { SharedBroadcastEvents } from "shared/utils/SharedBroadcastEvents";
import { WizardTab } from "./wizard-tab";


@autoinject
export class Wizard {
    @children("wizard-tab") tabs: WizardTab[];
    @bindable({ defaultBindingMode: bindingMode.oneTime }) wizardStyle: string;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) navClass: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) activeTab: string;

    subscription: Disposable;

    constructor(private readonly controller: ValidationController,
        private readonly eventAggregator: EventAggregator,
        private readonly taskQueue: TaskQueue) {
    }

    activeTabChanged() {
        this.eventAggregator.publish(SharedBroadcastEvents.tabVisibilityChanged);
    }

    tabsChanged() {
        this.setSelected();
    }

    bind() {
        this.setSelected();
    }

    attached() {
        this.subscription = this.controller.subscribe((event: ValidateEvent) => {
            if (event.type == 'validate' && event.errors.length > 0 && event.instruction == undefined) {
                this.jumpToFirstTabWithErrors();
            }
        });
    }

    detached() {
        this.subscription.dispose();
    }

    setSelected() {
        if (this.tabs && this.tabs.length > 0) {
            var tab: WizardTab;
            if (this.activeTab) {
                tab = this.tabs.find(x => x.tabName == this.activeTab);
            }
            if (tab) {
                this.selectTab(tab);
            } else {
                this.selectTab(this.tabs[0]);
            }
        }
    }

    private jumpToFirstTabWithErrors() {
        var selected = this.tabs.find((tab: WizardTab) => tab.selected);

        //if selected has errors, dont navigate away
        if (selected != null && selected.errors.length > 0) {
            if (selected.errors[0].targets.length > 0) {
                scrollIntoView(selected.errors[0].targets[0]);
            }
        } else {
            var firstTabWithError = this.tabs.find((tab: any) => tab.errors && tab.errors.length);
            if (firstTabWithError) {
                this.selectTab(firstTabWithError);

                if (firstTabWithError.errors[0].targets.length > 0) {
                    this.taskQueue.queueTask(() => {
                        scrollIntoView(firstTabWithError.errors[0].targets[0]);
                    });
                }
            }
        }
    }

    selectTab(tab) {
        this.tabs.forEach((t: WizardTab) => t.selected = false);
        tab.selected = true;
        this.activeTab = tab.tabName;
    }
}
