import { autoinject, bindable, bindingMode, Disposable } from 'aurelia-framework';
import { SharedDto } from 'project/project-shared';
import { DateOfBirthRule } from 'shared/controls/custom-validation';
import { ControlIdGenerator } from 'shared/utils/control-id-generator';
import { ValidationRules } from '../../../../../../node_modules/aurelia-validation';
import { CodeListService } from '../code-list-service';
import { QuestionBase } from "../question-base";

@autoinject
export class FormChild extends QuestionBase {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.IChildAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;
    id: number;

    private subscriptions: Disposable[] = [];
    schools: SharedDto.ICodeListItemDto[];

    loading: boolean = true;

    constructor(controlIdGenerator: ControlIdGenerator,
        private readonly codeListService: CodeListService) {
        super(controlIdGenerator);

        this.id = controlIdGenerator.getNextId();
    }

    bind() {
        super.bind();
        this.codeListService.getSchools(true).then(data => {
            this.schools = data;
            this.loading = false;
        })

    }

    attached() {
        super.attached();
        // this.subscriptions.push(
        //     this.deepObserver.observe(this, "questionInstance", () => {
        //         //triger revalidation of the rules attached to 'this' (all fields mandatory when one field entered)
        //         //this is mostly to hide the validation message if it ever appears, as soon as it's no longer the case.
        //         if (this.validationController.errors.findIndex(x => x.object === this)) {
        //             this.validationController.validate({
        //                 object: this
        //             });
        //         }
        //     })
        // );
    }

    unbind() {
        while (this.subscriptions.length) {
            this.subscriptions.pop().dispose();
        }
    }

    setupValidation() {
        ValidationRules.off(this.questionInstance);

        var rules =
            ValidationRules
                .ensure((a: SharedDto.OnlineForm.Application.IChildAnswerDto) => a.givenName)
                .required()
                .when(() => this.visibility && this.onSubmitValidation)
                .maxLength(150)
                .ensure((a: SharedDto.OnlineForm.Application.IChildAnswerDto) => a.lastName)
                .required()
                .when(() => this.visibility && this.onSubmitValidation)
                .maxLength(150)
            ;

        if (this.schoolRequired) {
            rules = rules
                .ensure((a: SharedDto.OnlineForm.Application.IChildAnswerDto) => a.schoolId)
                .required()
                .when(() => this.visibility && this.onSubmitValidation)
                ;
        }

        if (this.middleNameRequired) {
            rules = rules
                .ensure((a: SharedDto.OnlineForm.Application.IChildAnswerDto) => a.middleName)
                .maxLength(150)
                ;
        }

        if (this.dateOfBirthRequired) {
            rules = rules
                .ensure((a: SharedDto.OnlineForm.Application.IChildAnswerDto) => a.dateOfBirth)
                .required()
                .when(() => this.visibility && this.onSubmitValidation)
                .satisfiesRule(DateOfBirthRule)
                ;
            let maxAge = this.maxAgeRequired;
            if (maxAge) {
                rules = rules
                    .ensure((a: SharedDto.OnlineForm.Application.IChildAnswerDto) => a.dateOfBirth)
                    .satisfies((a, o) => {
                        let dob = new Date(a);
                        let baseline = new Date(new Date().setFullYear(new Date().getFullYear() - maxAge));
                        return dob > baseline;
                    })
                    .withMessage(`Must be under ${maxAge} years old.`);
            }
            let minAge = this.minAgeRequired;
            if (minAge) {
                rules = rules
                    .ensure((a: SharedDto.OnlineForm.Application.IChildAnswerDto) => a.dateOfBirth)
                    .satisfies((a, o) => {
                        let dob = new Date(a);
                        let baseline = new Date(new Date().setFullYear(new Date().getFullYear() - minAge));
                        return dob <= baseline;
                    })
                    .withMessage(`Must be at least ${minAge} years old.`);
            }
        }

        rules.on(this.questionInstance);

    }

    get schoolRequired(): boolean {
        return this.questionTemplate.configurationOptions.findIndex(x => x.configurationType === SharedDto.Constants.QuestionConfigurationType.ShowSelectSchool && x.boolValue == true) > -1;
    }

    get middleNameRequired(): boolean {
        return this.questionTemplate.configurationOptions.findIndex(x => x.configurationType === SharedDto.Constants.QuestionConfigurationType.ShowSelectMiddleName && x.boolValue == true) > -1;
    }

    get dateOfBirthRequired(): boolean {
        return this.questionTemplate.configurationOptions.findIndex(x => x.configurationType === SharedDto.Constants.QuestionConfigurationType.ShowSelectDateOfBirth && x.boolValue == true) > -1;
    }

    get upnRequired(): boolean {
        return this.questionTemplate.configurationOptions.findIndex(x => x.configurationType === SharedDto.Constants.QuestionConfigurationType.ShowSelectUPN && x.boolValue == true) > -1;
    }

    get previousSchoolRequired(): boolean {
        return this.questionTemplate.configurationOptions.findIndex(x => x.configurationType === SharedDto.Constants.QuestionConfigurationType.ShowSelectPreviousSchool && x.boolValue == true) > -1;
    }

    get maxAgeRequired(): number {
        let config = this.questionTemplate.configurationOptions.find(x => x.configurationType === SharedDto.Constants.QuestionConfigurationType.MaximumAge);
        return config ? config.numberValue : 0;
    }

    get minAgeRequired(): number {
        let config = this.questionTemplate.configurationOptions.find(x => x.configurationType === SharedDto.Constants.QuestionConfigurationType.MinimumAge);
        return config ? config.numberValue : 0;
    }

}
