// this is just a formatting feature to display website links without the protocol (http/https)
export class NoProtocolValueConverter {
    toView(value: string) {
        if (!value) { return value; }

        let index = value.indexOf("://");
        if (index === -1) {
            return value;
        }

        return value.substr(index + 3, value.length - index - 2);
    }
}
