import { BindingEngine, Disposable, observable } from 'aurelia-binding';
import { bindable, bindingMode, computedFrom, customElement } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { SharedDto } from "project/project-shared";
import { DataService } from 'services/data-service';
import { Logger } from '../../../../../../node_modules/aurelia-logging';
import { ControlIdGenerator } from "../../../../utils/control-id-generator";
import { QuestionBase } from "../question-base";
import { HasElementsRule } from '../../../custom-validation';

@customElement('form-location')
export class FormLocation extends QuestionBase {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.ILocationAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;
    @observable() singleAnswer: number;

    locationTypeList: SharedDto.ICodeListItemDto[] = [];
    localityList: SharedDto.ILocalityOptionDto[] = [];

    private observers: Disposable[] = [];
    loadingLocations: boolean;
    loadingTypes = true;

    constructor(private readonly logger: Logger,
        private readonly dataService: DataService,
        private readonly controlIdGenerator: ControlIdGenerator,
        private readonly bindingEngine: BindingEngine) {
        super(controlIdGenerator);
    }

    singleAnswerChanged(newValue, oldValue) {
        if (newValue) {
            this.questionInstance.answers = [+newValue];
        } else {
            this.questionInstance.answers = [];
        }
    }

    bind() {
        super.bind();

        // Bind single answer 
        if (this.showSingleSelect && this.questionInstance != null && this.questionInstance.answers != null) {
            this.singleAnswer = this.questionInstance.answers[0];
        }

        //pull locality types from configs
        let locationTypes = this.questionTemplate.configurationOptions.filter(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.AllowedLocalityType);

        //load locality types and find hte ones we need
        this.dataService.getLocationTypes().then((data) => {
            //only add locality types specified in config to our code list
            this.locationTypeList = data.filter(x => locationTypes.findIndex(y => y.locationType === x.id) > -1);
            this.loadingTypes = false;
        });

        this.observers.push(
            this.bindingEngine.propertyObserver(this.questionInstance, "locationType").subscribe(() => {
                this.questionInstance.answers = [];
                this.singleAnswer = null;
                this.refreshLocations();
            })
        );

        this.setupValidation();
        this.refreshLocations();
    }

    private refreshLocations() {
        if (this.questionInstance.locationType && +this.questionInstance.locationType !== SharedDto.Constants.LocationType.All) {
            this.loadingLocations = true;
            this.dataService.getLocationsByType(+this.questionInstance.locationType).then(data => {
                this.localityList = data;
            }).catch(error => {
                this.logger.error(error);
            }).then(() => {
                this.loadingLocations = false;
            });
        } else {
            //no locality type selected, clear the list
            this.localityList = [];
        }
    }

    attached() {
        super.attached();
    }

    setupValidation() {
        ValidationRules.off(this.questionInstance);

        var builder = ValidationRules.ensure((a: any) => a.a).required().when(a => false);

        var minSelectedOptionsRule = this.questionTemplate.configurationOptions.find(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.MinSelectedOptions);
        if (minSelectedOptionsRule) {
            builder.ensure((model: SharedDto.OnlineForm.Application.ILocationAnswerDto) => model.answers)
                .satisfies((value, model) => {
                    if (model.locationType === SharedDto.Constants.LocationType.All) {
                        return true; //multi select not required when All locations are applicable.
                    }
                    if (!model.answers) {
                        return false;
                    }
                    return model.answers.length >= minSelectedOptionsRule.numberValue;
                })
                .when((model: SharedDto.OnlineForm.Application.ILocationAnswerDto) => model.answers && model.answers.length > 0 && this.visibility)
                .withMessage('You must select a minimum of ' + minSelectedOptionsRule.numberValue + ' options');
        }

        var maxSelectedOptionsRule = this.questionTemplate.configurationOptions.find(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.MaxSelectedOptions);
        if (maxSelectedOptionsRule) {
            builder.ensure((model: SharedDto.OnlineForm.Application.ILocationAnswerDto) => model.answers)
                .satisfies((value, model) => {
                    if (model.locationType === SharedDto.Constants.LocationType.All) {
                        return true; //multi select not required when All locations are applicable.
                    }
                    return model.answers.length <= maxSelectedOptionsRule.numberValue;
                })
                .when((x) => this.visibility)
                .withMessage('You must select a maximum of ' + maxSelectedOptionsRule.numberValue + ' options');
        }

        builder.ensure((model: SharedDto.OnlineForm.Application.ILocationAnswerDto) => model.answers)
            .displayName("Location")
            .satisfiesRule(HasElementsRule).withMessage("Location is required.")
            .when(() => {
                return (this.visibility && this.onSubmitValidation && this.isMandatory
                    && +this.questionInstance.locationType !== SharedDto.Constants.LocationType.All)
            });

        builder.ensure((model: SharedDto.OnlineForm.Application.ILocationAnswerDto) => model.locationType)
            .displayName("Location")
            .required()
            .when(() => (this.visibility && this.onSubmitValidation && this.isMandatory));

        builder.on(this.questionInstance);
    }

    detached(): void {
        while (this.observers.length > 0) {
            this.observers.pop().dispose();
        }
    }

    @computedFrom("questionInstance.locationType", "questionTemplate.configurationOptions")
    get showSingleSelect(): boolean {
        let multiSelectOption = this.questionTemplate.configurationOptions.find(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.MultiSelect);
        return (!multiSelectOption || !multiSelectOption.boolValue) && !!this.questionInstance.locationType && +this.questionInstance.locationType !== SharedDto.Constants.LocationType.All;
    }

    @computedFrom("questionInstance.locationType", "questionTemplate.configurationOptions")
    get showMultiSelect(): boolean {
        let multiSelectOption = this.questionTemplate.configurationOptions.find(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.MultiSelect);
        return multiSelectOption && multiSelectOption.boolValue && !!this.questionInstance.locationType && +this.questionInstance.locationType !== SharedDto.Constants.LocationType.All;
    }

    @computedFrom("questionInstance.answers.length", "localityList")
    get answerToString(): string {
        if (this.questionInstance.answers && this.questionInstance.answers.length > 0) {
            var descriptions = this.localityList.filter(y => this.questionInstance.answers.indexOf(y.localityId) > -1).map(y => y.name);
            return descriptions.join(", ");
        }
        return "";
    }
}
