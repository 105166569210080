import { customAttribute, autoinject } from "aurelia-framework";

@autoinject
@customAttribute("optional")
export class OptionalAttribute {

    private _flagSet: boolean = false;

    constructor(private readonly element: Element) { }

    bind() {
        if (!this._flagSet)
            this.element.innerHTML += `<span class="text-muted ml-1" style="font-weight:400;">- Optional</span>`;
        this._flagSet = true;
    }
}
