export class AbnValueConverter {
    toView(value: string) {
        if (value === null || value === undefined) { return value; }
        //strip out any other formatting
        var formattedValue = value.replace(/\s+/g, '').trim();

        //add spaces where long enough
        if (formattedValue.length > 2) {
            formattedValue = formattedValue.substr(0, 2) + " " + formattedValue.substr(2);
        }
        if (formattedValue.length > 6) {
            formattedValue = formattedValue.substr(0, 6) + " " + formattedValue.substr(6);
        }
        if (formattedValue.length > 10) {
            formattedValue = formattedValue.substr(0, 10) + " " + formattedValue.substr(10);
        }

        return formattedValue;
    }
}