//todo: make this a simple es6 function export instead of a class.
const symbolsDictionary: Map<number, number> = new Map<number, number>();
const symbols: number[] = `0123456789ABCDEFGHJKLMNPQRTUVWXY`.split('').map(y => y.charCodeAt(0))
for (
    let i = 0; i < symbols.length; i++) {
    symbolsDictionary.set(symbols[<number>i], i);
}

const numberOfParts = 3;
const partLength = 4;

//this function only validates completed number parts
export function valdiateVoucherParts(code: string): boolean {
    // split in the different parts
    let parts: string[] = [];
    let tempCode = code;
    while (tempCode.length > 0) {
        parts.push(tempCode.substr(0, partLength));
        tempCode = tempCode.substr(partLength);
    }

    // validate each part
    for (
        let i = 0; i < parts.length; i++) {
        let part = parts[<number>i];
        // check this part has 4 chars
        if (part.length != partLength) {
            continue;
        }
        // split out the data and the check
        let data = part.substr(0, partLength - 1);
        let check = part.substr(partLength - 1, 1).charCodeAt(0);
        if (check != checkDigitAlg1(data, i + 1)) {
            return false;
        }
    }
    // everything looked ok with this code
    return true;
}

export function partiallyValidateVoucherNumber(code) {
    // uppercase the code, replace OIZS with 0125 and remove hyphens
    code = code.toUpperCase()
        .replace(/O/gi, '0')
        .replace(/I/gi, `1`)
        .replace(/Z/gi, `2`)
        .replace(/S/gi, `5`)
        .replace(/_/gi, ``)
        .replace(/-/gi, '');
    let result = valdiateVoucherParts(code);
    console.log("validation result: ", result)
    return result;
}

export function validateVoucherNumber(code: string): boolean {
    if (!code) {
        return false;
    }

    // uppercase the code, replace OIZS with 0125 and remove hyphens
    code = code.toUpperCase()
        .replace(/O/gi, '0')
        .replace(/I/gi, `1`)
        .replace(/Z/gi, `2`)
        .replace(/S/gi, `5`)
        .replace(/-/gi, '');

    // split in the different parts
    let parts: string[] = [];
    let tempCode = code;
    while (tempCode.length > 0) {
        parts.push(tempCode.substr(0, partLength));
        tempCode = tempCode.substr(partLength);
    }

    // make sure we have been given the same number of parts as we are expecting
    if (parts.length !== numberOfParts) {
        return false;
    }

    return valdiateVoucherParts(code);
}

function checkDigitAlg1(data: string | null, check: number): number {
    // check's initial value is the part number (e.g. 3 or above)
    // loop through the data chars
    var charChodeArray = data.split('').map(y => y.charCodeAt(0));
    for (let code of charChodeArray) {
        let k = symbolsDictionary.get(code);
        check = (check * 19) + k;
    }
    return symbols[check % (symbols.length - 1)];
}
