import { bindable, BindingEngine, autoinject, bindingMode } from "aurelia-framework";
import { Subscription } from "aurelia-event-aggregator";
import "./text-editor.less";

import tinymce from "tinymce/tinymce";
import 'tinymce/themes/silver/theme';
import 'tinymce/plugins/paste/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/anchor/plugin';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/wordcount/plugin';
import 'tinymce/plugins/autolink/plugin';
import 'tinymce/plugins/fullscreen/plugin';
import 'tinymce/plugins/imagetools/plugin';
import 'tinymce/plugins/media/plugin';
import 'tinymce/plugins/print/plugin';
import 'tinymce/plugins/template/plugin';
import { MergeField } from "./model/merge-field";
import { InternalLink } from "./model/internal-link";
import { ControlIdGenerator } from "../../utils/control-id-generator";
import { some } from "lodash";

import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin.css';
// var contentCss = require("tinymce/skins/content/default/content.css");
// var contentUiCss = require('tinymce/skins/ui/oxide/content.css');

@autoinject
export class TextEditor {

    @bindable({ defaultBindingMode: bindingMode.oneTime }) id: string;
    cachedValue: string;
    editor: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: string;

    @bindable() height = 200;
    convertUrls = false;
    menuBar = false;
    toolBar = "undo redo | styleselect | bold forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | " +
        " link plugin_sample insert_image | print | spellchecker | fullscreen";
    plugins = ['paste', 'link', 'anchor', 'code', 'image', 'lists', 'wordcount', 'autolink', 'fullscreen', 'print', 'fullscreen'];
    statusBar = false;
    insertImageParams = {};
    @bindable() mergeFields: MergeField[];
    @bindable() categoriseMergeField: boolean = false;
    @bindable() internalLinks: InternalLink[];
    @bindable() readonly: boolean = false;
    @bindable() enableFontFamily: boolean;

    constructor(private readonly controlIdGenerator: ControlIdGenerator, private readonly element: Element) {

    }

    valueChanged(newValue) {
        if (this.editor !== undefined) {
            //we check newValue against cachedValue to ensure we only call setContent when the parent VM/control specifies a new value.
            //cachedValue replaces editor.getContent because the value and getContent can get out of sync between bindings.
            if (newValue !== this.cachedValue) {
                this.editor.setContent(this.value);
            }
        }
    }

    created(owningView) {
        //intercept the parent views 'removeNodes' function and destroy our editor first, then proceed with it's original removeNodes callback.
        let original = owningView.removeNodes;
        let that = this;

        owningView.removeNodes = () => {
            if (this.editor !== undefined) {
                this.editor.destroy();
            }
            original.call(owningView);
        };

        if (!this.id) {
            this.id = "rte" + this.controlIdGenerator.getNextId().toString();
        }
    }

    bind() {
        if(this.enableFontFamily) {
            this.toolBar += " | fontselect";
        }

        if (this.mergeFields) {
            if (this.toolBar.indexOf("mergefields") === -1) {
                this.toolBar = this.toolBar + " | mergefields";
            }
        }
    }

    unbind() {        
        let editor = tinymce.get(this.id.toString());        
        if(editor) {
            editor.remove();
        }
    }

    readonlyChanged(newValue) {
        if (newValue) {
            this.editor.setMode("readonly");            
            this.element.classList.add("text-editor-readonly");
        } else {
            this.editor.setMode("design");                        
            this.element.classList.remove("text-editor-readonly");
        }
    }

    attached() {
        let textEditorId = "#" + this.id.toString();

        tinymce.init({            
            selector: textEditorId,
            font_formats: 'Lato=lato;Times New Roman=times new roman,serif;Courier New=courier new,courier,monospace;' ,
            plugins: this.plugins,
            menubar: this.menuBar,
            statusbar: this.statusBar,
            content_style: "body { font-size: 14px; font-family: Lato; }",
            // toolbar: this.readonly ? false : this.toolBar,
            // the above doesn't let you easily add the toolbar back when readonly is toggled off
            // so lets just leave it there disabled. anywhere that absolutely needs to show it without
            // the toolbar can implement its own rendering with if/else.bind
            toolbar: this.toolBar,
            link_list: this.internalLinks,
            skin: false,
            content_css: false,            
            height: this.height,
            readonly: this.readonly ? true : false,
            convert_urls: this.convertUrls,
            setup: editor => {
                editor.on('init', e => {
                    this.editor = editor;
                    if (this.value == undefined) {
                        editor.setContent("");
                    } else {
                        editor.setContent(this.value);
                    }                    
                });

                editor.on('keyup change redo undo', e => {
                    //we cache the value from "getContent" because it can change between setting this.value and when the valueChanged method is called.
                    this.cachedValue = editor.getContent();
                    this.value = this.cachedValue;
                });
                //editor.insertImageParams = this.insertImageParams;

                if (this.mergeFields) {
                    if (this.categoriseMergeField) {

                        var mergeFieldCategories: Record<string, MergeField[]> = {};
                        this.mergeFields.forEach((mergeField) => {
                            var catName = mergeField.category || "Uncategorised";
                            var catMergeField = mergeFieldCategories[catName] || [];
                            catMergeField.push(mergeField);
                            mergeFieldCategories[catName] = catMergeField;
                        });

                        var items: any[] = []

                        for (let key in mergeFieldCategories) {
                            if (mergeFieldCategories.hasOwnProperty(key)) {
                                let catMergeFields = mergeFieldCategories[key]                                
                                items.push(
                                    {
                                        type: 'nestedmenuitem',
                                        text: key,
                                        getSubmenuItems: () => catMergeFields.map(x => {
                                            return {
                                                type: 'menuitem',
                                                text: x.displayName,
                                                onAction: () => editor.insertContent(x.mergeField)                                                 
                                            }
                                        })
                                    }
                                );

                            }
                        }

                        editor.ui.registry.addMenuButton('mergefields', {
                            type: 'menubutton',
                            text: 'Insert Merge Field',                            
                            fetch: (callback) => callback(items)
                        });
                    } else {
                        var showCategory = some(this.mergeFields, (i => i.category));
                        var menu = this.mergeFields.map(x => {
                            return {
                                type: 'menuitem',
                                text: showCategory && x.category != null ? x.category + "-" + x.displayName : x.displayName,
                                onAction: () => editor.insertContent(x.mergeField)
                            } as any // ts makes no kinda sense.
                        });
                        
                        editor.ui.registry.addMenuButton('mergefields', {
                            type: 'menubutton',
                            text: 'Insert Merge Field',                            
                            fetch: (callback) => callback(menu)
                        });
                    }
                }
            }


        });


        if (this.readonly) {
            this.element.classList.add("text-editor-readonly");        
            tinymce.activeEditor.getBody().setAttribute('contenteditable', "false");
        }        
    }

  
}
