import { bindable, bindingMode, customElement, computedFrom } from 'aurelia-framework';
import { ValidationController, ValidationRules } from 'aurelia-validation';
import { SharedDto } from "project/project-shared";
import { CodeListService, FormOption } from 'shared/controls/online-form/question/code-list-service';
import { ControlIdGenerator } from "../../../../utils/control-id-generator";
import { QuestionBase } from "../question-base";

@customElement('form-single-select-list')
export class FormSingleSelectList extends QuestionBase {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.ISingleSelectListAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView }) formInstance: SharedDto.OnlineForm.Application.IFormInstanceDto;

    codeOptions: FormOption[];
    activeOptions: FormOption[];
    showDropDownList: boolean = false;
    showRadioButtonList: boolean = false;
    loading: boolean = true;

    constructor(private controlIdGenerator: ControlIdGenerator,
        private controller: ValidationController,
        private codeListService: CodeListService) {
        super(controlIdGenerator);
    }

    bind() {
        super.bind();

        switch (this.questionTemplate.questionType) {
            case SharedDto.Constants.QuestionType.RadioButton:
                this.showRadioButtonList = true;
                break;
            case SharedDto.Constants.QuestionType.SingleSelectList:
                this.showDropDownList = true;
                break;
            default:
                throw new Error('Question type (' + SharedDto.Constants.QuestionType[this.questionTemplate.questionType] + ') not found');
        }

        var optionList = this.questionTemplate.configurationOptions.find(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.OptionsList);
        if (!optionList || !optionList.questionCodeListType) {
            throw new Error("Error with option list for questionId " + this.questionTemplate.questionId);
        }

        if (!this.questionInstance.questionCodeListType) {
            this.questionInstance.questionCodeListType = optionList.questionCodeListType;
        }

        var sortAlphabeticallyRule = this.questionTemplate.configurationOptions.find(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.SortAlphabetically);
        var boolValue = (sortAlphabeticallyRule) ? sortAlphabeticallyRule.boolValue : undefined;
        this.loading = true;
        this.codeListService.getDataList(this.questionTemplate.questionId, optionList.questionCodeListType, boolValue).then((data) => {
            this.codeOptions = data;
            this.activeOptions = this.codeOptions.filter(x => x.active === true);              
            this.loading = false;            
        });
    }

    attached() {
        super.attached();
    }

    setupValidation() {
        this.controller.removeObject(this.questionInstance);

        let rules = [];

        let that = this;

        rules.push(ValidationRules
            .ensure((model: SharedDto.OnlineForm.Application.ISingleSelectListAnswerDto) => model.answer)
            .required()
            .when(() => {
                return (this.visibility && this.onSubmitValidation && this.isMandatory)
            })
            .satisfies((val, model) => {
                return this.answerIsActive;
            })
            .when(() => {
                return (this.visibility && this.onSubmitValidation && this.isMandatory)
            })
            .rules[0]);




        if (rules.length > 0) {
            this.controller.addObject(this.questionInstance, rules);
        }
    }

    @computedFrom("questionInstance.answer", "activeOptions")
    get answerIsActive(): boolean {
        if(!this.questionInstance.answer || this.questionInstance.answer == "0") {
            return true;
        }     
        if(this.activeOptions) {
            let val1 = this.activeOptions.findIndex(x => this.questionInstance.answer == x.value) != -1 ;
            let val2 = !this.questionInstance.answer;            
            return val1 || val2;
        }
        return false;
    }

    @computedFrom("questionInstance.answer", "codeOptions")
    get answerDescription(): string {
        if(this.codeOptions) {
            let answer = this.codeOptions.find(x => x.value == this.questionInstance.answer);
            if(answer) {
                return answer.description;
            }
        }
        return "";
    }
}
