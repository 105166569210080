//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export module SharedDto.Forms {
	export interface IQuestionAndAnswerDto
	{
		answer: SharedDto.OnlineForm.Application.IAnswerDto;
		question: SharedDto.OnlineForm.Form.IQuestionDto;
	}
}
export module SharedDto {
	export interface IAbnDetailsDto
	{
		abn: string;
		active: boolean;
		gstRegistered: boolean;
		mainTradingName: string;
		businessName: string;
		tradingNames: string[];
		status: SharedDto.IAbnActiveStatusDto;
		abnRegisteredFrom: string;
		entityType: string;
		taxConcessions: SharedDto.ITaxConcessionDto[];
		dgrEndorsements: SharedDto.IDGRItemDto[];
		acncRegistration: SharedDto.IACNCRegistrationDto;
		businessNames: SharedDto.IActiveNameDto[];
		mainBusinessPostCode: string;
		mainBusinessState: string;
		key: any;
		asicNumber: string;
	}
	export interface IACNCRegistrationDto
	{
		from: string;
		charityType: string;
	}
	export interface ITaxConcessionDto
	{
		concession: string;
		from: string;
	}
	export interface IDGRItemDto
	{
		type: string;
		from: string;
	}
	export interface IAbnActiveStatusDto
	{
		active: boolean;
		status: string;
		effectiveFromDate?: string;
		effectToDate?: string;
	}
	export interface IActiveNameDto
	{
		name: string;
		from: string;
	}
	export interface IBankDetailsDto
	{
		bankDetailsId: number;
		description: string;
		bsb: string;
		bankInstitution: string;
		branchLocation: string;
		accountNumber: string;
		accountName: string;
	}
	export interface IAddressDto extends SharedDto.IAddressUpdateDto
	{
		countryDescription?: string;
		display?: string;
		australianStateTerritoryDescription?: string;
		isVerified: boolean;
		matchType?: string;
	}
	export interface IAddressUpdateDto
	{
		addressId: number;
		line1: string;
		line2: string;
		suburb: string;
		nonAustralianState: string;
		australianStateTerritory?: SharedDto.Constants.StateTerritory;
		postcode: string;
		countryId?: number;
	}
	export interface IEmailBccRecipientsDto
	{
		displayName: string;
		emailAddress: string;
	}
	export interface IEmailDto
	{
		subject: string;
		body: string;
		from: SharedDto.IEmailRecipientDto;
		recipients: SharedDto.IEmailRecipientDto[];
		ccRecipients: SharedDto.IEmailCcRecipientsDto[];
		bccRecipients: SharedDto.IEmailBccRecipientsDto[];
	}
	export interface IEmailCcRecipientsDto
	{
		displayName: string;
		emailAddress: string;
	}
	export interface IEmailRecipientDto
	{
		displayName: string;
		emailAddress: string;
	}
	export interface IErrorResponse
	{
		errorType: SharedDto.Constants.ErrorType;
		message: string;
		errors: string[];
	}
	export interface IJavascriptError
	{
		message: string;
		stack: string;
		url: string;
		requestedUrl: string;
		data: string;
	}
	export interface IPagedResult<T>
	{
		totalRecords: number;
		items: T[];
	}
	export interface IPagingFilter
	{
		pageSize: number;
		page: number;
		sortColumn: string;
		sortDirection: SharedDto.Constants.SortDirection;
	}
	export interface ISystemNotificationDto
	{
		systemNotificationId: number;
		message: string;
		publishDate?: string;
		unpublishDate?: string;
		versionAffected: string;
		displayLocation: any;
		displayLocationDisplay: string;
		lastModifiedBy: string;
		lastModifiedTime: string;
		active: boolean;
	}
	export interface ICreateUpdateOrganisationDocumentDto
	{
		organisationDocumentId?: number;
		organisationId: number;
		documentTitle: string;
		expiryDate?: string;
		documentTypeId?: number;
		documentTypeOther: string;
		documentFoundOnORIC: boolean;
		documentFoundOnACNC: boolean;
		expiryNotificationSentDate?: string;
		file: SharedDto.IFileUpdateDto;
	}
	export interface IOrganisationAuditSharedDto
	{
		organisationAuditId: number;
		organisationId: number;
		createdOn: string;
		organisationAuditCategoryCode: any;
		actionDescription: string;
		externalUserId?: number;
		externalUser: any;
		internalUserId?: number;
		internalUser: any;
		fileStorage: SharedDto.IFilePropertiesDto;
		userType: any;
		fullName: string;
	}
	export interface IOrganisationContactRoleDto extends SharedDto.ICodeListItemDto
	{
		additionalDetailsRequired: boolean;
	}
	export interface IOrganisationDocumentDto extends SharedDto.ICreateUpdateOrganisationDocumentDto
	{
		documentTypeDesc: string;
		fileName: string;
		lastModifiedBy: string;
		lastModifiedTime: string;
		file: SharedDto.IFilePropertiesDto;
	}
	export interface IOrganisationUserInviteSharedDto
	{
		organisationId: number;
		emailAddress: string;
		isOrganisationAdministrator: boolean;
		canViewPayment: boolean;
		notes: string;
		fileStorage: SharedDto.IFilePropertiesDto;
		access: SharedDto.IOrganisationUserAccessSharedDto[];
	}
	export interface IOrganisationUserAccessSharedDto
	{
		organisationUserAccessId?: number;
		orgUserAccessActivity: any;
		orgUserAccessActivityDesc: string;
		canCreateEditSubmit: boolean;
		canApprove: boolean;
	}
	export interface IOrganisationSharedDto
	{
		organisationId?: number;
		australianBusinessNumber: string;
		australianCompanyNumber: string;
		indigenousCorporationNumber: string;
		incorporatedAssociationNumber: string;
		vendorId: string;
		registeredTrainingOrganisationNumber: string;
		legalName: string;
		businessName: string;
		notForProfitFlag: boolean;
		legalEntityTypeId: number;
		gstRegistered: boolean;
		phoneNumber: string;
		emailAddress: string;
		fax: string;
		website: string;
		streetAddress: SharedDto.IAddressDto;
		postalSameAsStreetAddress: boolean;
		postalAddress: SharedDto.IAddressDto;
		primaryContact: SharedDto.Profiles.IOrganisationContactDto;
		primaryActivityId?: number;
		subActivityId?: number;
		primaryLocationType?: SharedDto.Constants.LocationType;
		primaryLocationTypeDescription: string;
		primaryLocationId?: number;
		secondaryLocationType?: SharedDto.Constants.LocationType;
		secondaryLocationTypeDescription: string;
		secondaryLocations: number[];
		primaryLocationDescription: string;
		secondaryLocationsDescription: string;
		durationOperatingInNTId: number;
		lastModifiedBy: string;
		lastModifiedTime: string;
	}
	export interface IOrganisationUserSharedDto
	{
		externalUserId?: number;
		organisationUserId: number;
		organisationId: number;
		givenName: string;
		familyName: string;
		fullName: string;
		emailAddress: string;
		organisationEmailIsAccountEmail: boolean;
		organisationUserStatus: any;
		organisationUserStatusDesc: string;
		lastModifiedBy: string;
		lastModifiedTime: string;
		isOrganisationAdministrator: boolean;
		canViewPayment: boolean;
		access: SharedDto.IOrganisationUserAccessSharedDto[];
	}
	export interface IValidateAbnRequest
	{
		abn: string;
		organisationId: number;
	}
	export interface IValidateAbnResponse
	{
		abn: string;
		organisationName: string;
		businessName: string;
		gstRegistered: boolean;
		phoneNumber: string;
		emailAddress: string;
		fax: string;
		website: string;
		businessAddress: SharedDto.IAddressDto;
		postalAddress: SharedDto.IAddressDto;
	}
	export interface IQuestionConfigurationDto
	{
		questionConfigurationId: number;
		questionId: number;
		configurationType: SharedDto.Constants.QuestionConfigurationType;
		boolValue?: boolean;
		numberValue?: number;
		decimalValue?: number;
		dateValue?: string;
		questionCodeListType: SharedDto.Constants.QuestionCodeListType;
		locationType: SharedDto.Constants.LocationType;
		phoneType: SharedDto.Constants.PhoneType;
		fileUploadCategory: SharedDto.Constants.FileUploadCategory;
		financialYearId?: number;
	}
	export interface IFormInstanceAndTemplateDto
	{
		formInstance: SharedDto.OnlineForm.Application.IFormInstanceDto;
		formTemplate: SharedDto.OnlineForm.Form.IFormTemplateDto;
	}
	export interface IFileSecurityDto
	{
		context: SharedDto.Constants.FileSecurityContext;
		applicationFormId?: number;
		grantRoundId?: number;
		grantDetailId?: number;
	}
	export interface ITemporaryFileDto extends SharedDto.IFileDto
	{
		guid: any;
	}
	export interface IFileDto extends SharedDto.IFilePropertiesDto
	{
		fileContents: number[];
		fileSizeBytes: number;
	}
	export interface IFilePropertiesDto extends SharedDto.IFileUpdateDto
	{
		fileSizeBytes: number;
		lastModifiedBy: string;
		lastModifiedTime: string;
	}
	export interface IFileUpdateDto
	{
		fileStorageId: number;
		fileName: string;
		guid: string;
	}
	export interface IAdvertisingMethodDto
	{
		advertisingMethodId: number;
		code: string;
		description: string;
		detailsNeeded: boolean;
		active: boolean;
	}
	export interface IApplicationProcessDto
	{
		id: number;
		code: string;
		name: string;
		description: string;
	}
	export interface IBeneficiaryDto
	{
		beneficiaryId: number;
		code: string;
		display: string;
		description: string;
		level: number;
		root: number;
		active: boolean;
		parentId?: number;
	}
	export interface ICategoryDto
	{
		categoryId: number;
		code: string;
		description: string;
		level: number;
		root: number;
		active: boolean;
		parentId?: number;
	}
	export interface ICodeListItemDto
	{
		id: number;
		code: string;
		description: string;
		active: boolean;
	}
	export interface ICodeTreeStructureDto
	{
		id: number;
		text: string;
		children: SharedDto.ICodeTreeStructureDto[];
	}
	export interface ICountryDto
	{
		countryId: number;
		code: string;
		name: string;
	}
	export interface IDocumentGroupDto extends SharedDto.ICodeListItemDto
	{
		documentTypes: SharedDto.IDocumentTypeDto[];
	}
	export interface IDocumentTypeDto extends SharedDto.ICodeListItemDto
	{
		additionalDetailsRequired: boolean;
		helpText: string;
		applicableEntities: string;
		hasExpiration: boolean;
		canRequest: boolean;
	}
	export interface IFinancialYearDto extends SharedDto.ICodeListItemDto
	{
		startingYear: number;
	}
	export interface IInitiativeDto
	{
		initiativeId: number;
		display: string;
		description: string;
		active: boolean;
		parentId?: number;
	}
	export interface ILegalEntityTypeDto extends SharedDto.ICodeListItemDto
	{
		legalEntityType: any;
	}
	export interface ILocalityFilterDto
	{
		types: SharedDto.Constants.LocalityType[];
		keyword: string;
	}
	export interface ILocalityOptionDto
	{
		localityId: number;
		name: string;
		description: string;
		alias: string;
		localityType: SharedDto.Constants.LocalityType;
		locationType?: SharedDto.Constants.LocationType;
	}
	export interface INonCashAwardTypeDto
	{
		nonCashAwardTypeId: number;
		description: string;
		active: boolean;
		lastModifiedBy: string;
		lastModifiedTime: string;
	}
	export interface IOrganisationTypeListItem
	{
		organisationTypeId: number;
		code: string;
		description: string;
		abrEntityTypeCodes: string[];
	}
	export interface IReportingRequirementTypeDto extends SharedDto.ICodeListItemDto
	{
		reportingRequirementType: SharedDto.Constants.ReportingRequirementType;
		group: SharedDto.ICodeListItemDto;
		lastModifiedBy: string;
		lastModifiedTime: string;
	}
	export interface IStructureAncestryDto extends SharedDto.IStructureBaseDto
	{
		parent: SharedDto.IStructureAncestryDto;
	}
	export interface IStructureBaseDto
	{
		structureId: number;
		agencyId: number;
		structureTypeCode: string;
		parentId?: number;
		active: boolean;
		code: string;
		description: string;
		lastModifiedBy: string;
		lastModifiedTime: string;
	}
	export interface IStructureTreeDto extends SharedDto.IStructureBaseDto
	{
		children: SharedDto.IStructureTreeDto[];
	}
}
export module SharedDto.Applications {
	export interface IApplicantNamesDto
	{
		applicantName: string;
		givenName: string;
		middleName: string;
		familyName: string;
		legalName: string;
	}
}
export module SharedDto.Reporting {
	export interface IOutcomeMeasureDto extends SharedDto.Reporting.IOutcomeMeasureUpdateDto
	{
		unitsOfMeasure: SharedDto.Reporting.IOutcomeUnitOfMeasureDto[];
	}
	export interface IOutcomeMeasureUpdateDto
	{
		outcomeMeasureId: number;
		measure: string;
		instructions: string;
		unitsOfMeasure: SharedDto.Reporting.IOutcomeUnitOfMeasureUpdateDto[];
	}
	export interface IOutcomeUnitOfMeasureDto extends SharedDto.Reporting.IOutcomeUnitOfMeasureUpdateDto
	{
		template: SharedDto.IFilePropertiesDto;
	}
	export interface IOutcomeUnitOfMeasureUpdateDto
	{
		unitOfMeasureId: number;
		unitOfMeasure: string;
		number: boolean;
		text: boolean;
		file: boolean;
		template: SharedDto.IFileUpdateDto;
	}
	export interface IOutputMeasureDto extends SharedDto.Reporting.IOutputMeasureUpdateDto
	{
		reportingRequirementOutputMeasureId: number;
		reportingRequirementId: number;
		outputGroupName: string;
		outputServiceName: string;
		outputDescription: string;
		countingExample: string;
		outputMeasure: string;
		locationDescription: string;
	}
	export interface IOutputMeasureUpdateDto
	{
		outputId?: number;
		locationId?: number;
		locationType?: SharedDto.Constants.LocationType;
		outputCodeNumber: string;
	}
}
export module SharedDto.PublicContent {
	export interface IPublicContentDetailViewDto
	{
		publicContentId: number;
		grantName: string;
		activeRoundTitle: string;
		applicationsOpen?: string;
		applicationsClose?: string;
		applyNow: any;
		canPotentiallyApplyNow: boolean;
		nonConcurrentAndStillProcessing: boolean;
		contentSlug: string;
		grantRoundSlug: string;
		applicationFormId?: number;
		roundId?: number;
		existingApplicationId?: number;
		agency: string;
		isIndividualEligible: boolean;
		isOrganisationEligible: boolean;
		overviewHtml: string;
		showGlobalMaxFunding: boolean;
		globalFundingMax?: number;
		categories: string[];
		sections: SharedDto.PublicContent.IPublicContentSectionDto[];
		existingApplications: number[];
		supplierContentTitle: string;
		supplierPublicContentId?: number;
		supplierSlug: string;
		publicContentType: SharedDto.Constants.PublicContentType;
	}
	export interface IPublicContentSectionDto
	{
		sectionName: string;
		sectionHtml: string;
	}
	export interface IPublicContentSummaryViewDto
	{
		publicContentId: number;
		title: string;
		applicationsOpen?: string;
		applicationsClose?: string;
		slug: string;
		grantRoundSlug: string;
		agency: string;
		isIndividualEligible: boolean;
		isOrganisationEligible: boolean;
		showGlobalMaxFunding: boolean;
		globalFundingMax?: number;
		overviewHtml: string;
		categories: string[];
		publicContentType: SharedDto.Constants.PublicContentType;
		publicContentTypeDescription: string;
		thirdParty: boolean;
		supplierContentTitle: string;
		supplierPublicContentId?: number;
	}
}
export module SharedDto.SupplierPortal {
	export interface ISupplierPortalSearchRequestDto extends SharedDto.IPagingFilter
	{
		grantDetailId: number;
		keyword: string;
		localityIds: number[];
		categoryIds: number[];
	}
	export interface ISupplierPortalDetailDto
	{
		supplierId: number;
		supplierName: string;
		aboutSection: string;
		website: string;
		contactPhone: string;
		contactEmail: string;
		singleLineAddress: string;
		mondayTradingHours: string;
		tuesdayTradingHours: string;
		wednesdayTradingHours: string;
		thursdayTradingHours: string;
		fridayTradingHours: string;
		saturdayTradingHours: string;
		sundayTradingHours: string;
		socialMediaLinks: string[];
		categories: string[];
		locations: string[];
	}
	export interface ISupplierPortalSummaryDto
	{
		supplierId: number;
		supplierName: string;
		aboutSection: string;
		categories: string[];
	}
}
export module SharedDto.Profiles {
	export interface IOrganisationContactDto
	{
		organisationContactId: number;
		titleId: number;
		givenName: string;
		familyName: string;
		phone: string;
		mobile: string;
		email: string;
		fax: string;
		organisationContactRoleId: number;
		roleOther: string;
		lastModifiedBy: string;
		lastModifiedTime: string;
	}
}
export module SharedDto.OnlineForm.Form {
	export interface IFormTemplateDto
	{
		steps: SharedDto.OnlineForm.Form.IStepDto[];
		dependencyRules: SharedDto.OnlineForm.Form.IDependencyDto[];
		formType: any;
	}
	export interface IApplicationFormTemplateDto extends SharedDto.OnlineForm.Form.IFormTemplateDto
	{
		department: string;
		showIntroPage: boolean;
		introContent: string;
		privacyStatementHtml: string;
		declarationStatementHtml: string;
		adminBodyQuestionId: number;
		grantName: string;
		systemSetProjectServiceTitle: boolean;
	}
	export interface IFormListDto
	{
		formId: number;
		formStatus: any;
		title: string;
		description: string;
		createdById: number;
		createdOn: string;
		lastModifiedBy: string;
		lastModifiedTime: string;
	}
	export interface ISectionDto
	{
		sectionId: number;
		stepId: number;
		name: string;
		showName: boolean;
		sortOrder: number;
		repeatable: boolean;
		repeatMin?: number;
		repeatMax?: number;
		visible: boolean;
		navigation: boolean;
		contentTop: string;
		contentBottom: string;
		addSectionButtonOverrideText: string;
		removeSectionButtonOverrideText: string;
		questions: SharedDto.OnlineForm.Form.IQuestionDto[];
	}
	export interface IStepDto
	{
		stepId: number;
		formId: number;
		description: string;
		sortOrder: number;
		visible: boolean;
		contentTop: string;
		contentBottom: string;
		sections: SharedDto.OnlineForm.Form.ISectionDto[];
	}
	export interface IOptionItemDto
	{
		optionItemId: number;
		questionId: number;
		sortOrder: number;
		description: string;
		active: boolean;
	}
	export interface IQuestionDto
	{
		questionId: number;
		sectionId: number;
		questionType: SharedDto.Constants.QuestionType;
		mandatory: boolean;
		repeatable: boolean;
		repeatMin?: number;
		repeatMax?: number;
		questionText: string;
		helpText: string;
		helpTextLocation?: SharedDto.Constants.HelpTextLocation;
		prepopulatedByIndividualProfile: boolean;
		prepopulatedByOrganisationProfile: boolean;
		systemPrepopulatedField?: SharedDto.Constants.SystemPrepopulatedField;
		systemQuestion?: SharedDto.Constants.SystemQuestion;
		sortOrder: number;
		visible: boolean;
		contentTop: string;
		contentBottom: string;
		lockAnswer: boolean;
		configurationOptions: SharedDto.IQuestionConfigurationDto[];
	}
	export interface IDependencyCriteriaDto
	{
		formQuestionId: number;
		criteriaOperation: SharedDto.Constants.CriteriaOperation;
		optionItemId?: number;
		boolValue?: boolean;
		numberValue?: number;
		decimalValue?: number;
		dateValue?: string;
		codeItemId?: number;
	}
	export interface IDependencyDto
	{
		formDependencyId: number;
		formStepId?: number;
		formSectionId?: number;
		formQuestionId?: number;
		visible: boolean;
		logicOperator: SharedDto.Constants.LogicalOperator;
		criteria: SharedDto.OnlineForm.Form.IDependencyCriteriaDto[];
	}
}
export module SharedDto.OnlineForm.Application {
	export interface IApplicationDeclarationDto
	{
		fullName: string;
		declared: boolean;
		location: string;
		titleId?: number;
		givenName: string;
		familyName: string;
		dateOfBirth?: string;
		dateOfDeclaration?: string;
	}
	export interface IApplicationFormDto
	{
		applicationId: number;
		formTemplateId?: number;
		applicationNumber: string;
		createdByAgencyId?: number;
		createdByInternalUserId?: number;
		createdByInternalUser: string;
		formProfileType: SharedDto.Constants.FormProfileType;
		status: SharedDto.Constants.ApplicationFormStatus;
		declaration: SharedDto.OnlineForm.Application.IApplicationDeclarationDto;
		documentDeclaration: SharedDto.IFilePropertiesDto;
		applicationsOpen: boolean;
		rowVersion: number[];
		profileId?: number;
		originalProfileId?: number;
		adminBodyOrgId?: number;
		formInstance: SharedDto.OnlineForm.Application.IFormInstanceDto;
		additionalInformationRequest: SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestSharedDto[];
	}
	export interface IExistingApplicationDto
	{
		individualProfileId?: number;
		organisationId?: number;
		applicationForm: SharedDto.OnlineForm.Application.IApplicationFormDto;
		formTemplate: SharedDto.OnlineForm.Form.IApplicationFormTemplateDto;
	}
	export interface IFormInstanceQuestionGroupDto
	{
		templateQuestionId: number;
		visible: boolean;
		sortOrder: number;
		allowAdditionalInformation: boolean;
		questions: SharedDto.OnlineForm.Application.IAnswerDto[];
	}
	export interface IFormInstanceSectionDto
	{
		sectionId: number;
		templateSectionId: number;
		sortOrder: number;
		visible: boolean;
		questionGroups: SharedDto.OnlineForm.Application.IFormInstanceQuestionGroupDto[];
	}
	export interface IFormInstanceSectionGroupDto
	{
		sectionId: number;
		templateSectionId: number;
		visible: boolean;
		sortOrder: number;
		sections: SharedDto.OnlineForm.Application.IFormInstanceSectionDto[];
	}
	export interface IFormInstanceStepDto
	{
		stepId: number;
		templateStepId: number;
		visible: boolean;
		sortOrder: number;
		sectionGroups: SharedDto.OnlineForm.Application.IFormInstanceSectionGroupDto[];
	}
	export interface IFormInstanceDto
	{
		formInstanceId: number;
		formId: number;
		steps: SharedDto.OnlineForm.Application.IFormInstanceStepDto[];
	}
	export interface IBusinessAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		abn: string;
		businessName: string;
		legalName: string;
	}
	export interface IPersonAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		givenName: string;
		middleName: string;
		lastName: string;
	}
	export interface IOpeningHoursAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		monday: string;
		tuesday: string;
		wednesday: string;
		thursday: string;
		friday: string;
		saturday: string;
		sunday: string;
	}
	export interface IChildAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		givenName: string;
		middleName: string;
		lastName: string;
		dateOfBirth?: string;
		schoolId?: number;
		previousSchoolId?: number;
		upn: string;
	}
	export interface IAddressAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		line1: string;
		line2: string;
		suburb: string;
		australianStateTerritory?: SharedDto.Constants.StateTerritory;
		nonAustralianState: string;
		countryId?: number;
		postCode: string;
	}
	export interface IContactAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		contactName: string;
		phoneNumber: string;
		emailAddress: string;
		applicationContactRoleId?: number;
	}
	export interface IDateAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		answer?: string;
	}
	export interface IDateRangeAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		dateFrom?: string;
		dateTo?: string;
	}
	export interface IFileUploadAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		file: SharedDto.IFilePropertiesDto;
		documentSource: SharedDto.Constants.ApplicationDocumentSource;
		comment: string;
		organisationDocument: SharedDto.ICreateUpdateOrganisationDocumentDto;
		profileFileId?: number;
		profileDocumentTitle: string;
		profileDocumentTypeDesc: string;
	}
	export interface ILocationAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		answers: number[];
		locationType?: SharedDto.Constants.LocationType;
	}
	export interface IDecimalAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		answer?: number;
	}
	export interface IMultiLineTextAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		answer: string;
	}
	export interface IMultiSelectListAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		selectedOptionItemIds: string[];
		questionCodeListType: SharedDto.Constants.QuestionCodeListType;
	}
	export interface INumberAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		answer?: number;
	}
	export interface IAnswerDto
	{
		questionType: SharedDto.Constants.QuestionType;
		questionId: number;
		templateQuestionId: number;
	}
	export interface IRequestedFundingAmountDto
	{
		financialYearId: number;
		financialYearDescription: string;
		amount?: number;
		recurringAmount?: number;
	}
	export interface IRequestedFundingAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		answers: SharedDto.OnlineForm.Application.IRequestedFundingAmountDto[];
	}
	export interface ISingleLineTextAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		answer: string;
	}
	export interface ISingleSelectListAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		answer: string;
		questionCodeListType: SharedDto.Constants.QuestionCodeListType;
	}
	export interface IAbnLookupAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		abn: string;
		key?: any;
		details: SharedDto.IAbnDetailsDto;
		retrievedBy: string;
		retrievedOn?: string;
	}
	export interface IYesNoAnswerDto extends SharedDto.OnlineForm.Application.IAnswerDto
	{
		answer?: boolean;
	}
}
export module SharedDto.FeaturedGrant {
	export interface IExternalHomePageFeaturedGrantDto
	{
		label: string;
		image: SharedDto.IFileDto;
		title: string;
		description: string;
		grantRoundId: number;
		publicContentId: number;
		slug: string;
		featuredGrantId: number;
	}
}
export module SharedDto.Applications.AdditionalInfo {
	export interface IAdditionalInformationRequestItemSharedDto extends SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestItemUpdateSharedDto
	{
		requestQuestionTypeDescription: string;
		answerText: string;
		answerBoolean?: boolean;
		attachment: SharedDto.IFilePropertiesDto;
		actionedByAgencyDisplayName: string;
		actionedByAgencyComment: string;
		actionedByAgencyDate?: string;
		actionedByAgencyFileStorage: SharedDto.IFilePropertiesDto;
	}
	export interface IAdditionalInformationRequestItemUpdateSharedDto
	{
		additionalInformationRequestItemId?: number;
		requestQuestionType: any;
		questionText: string;
		answerText: string;
		answerBoolean?: boolean;
		attachment: SharedDto.IFilePropertiesDto;
	}
	export interface IAdditionalInformationRequestSharedDto extends SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestUpdateSharedDto
	{
		requestStatusDescription: string;
		requestStatus: SharedDto.Constants.AdditionalInformationRequestStatus;
		submittedDate?: string;
		submittedBy: string;
		responseDate?: string;
		respondedBy: string;
		dueDate?: string;
		sendReminderBefore: number;
		additionalInformationRequestItems: SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestItemSharedDto[];
		canEdit: boolean;
	}
	export interface IAdditionalInformationRequestUpdateSharedDto
	{
		additionalInformationRequestId?: number;
		description: string;
		visibleToAssessors: boolean;
		formInstanceId?: number;
		formInstanceAnswerId?: number;
		formQuestionId?: number;
	}
}
export module SharedDto.Constants {
	export enum ErrorType { 
		NotFound = 0, 
		LoginFailed = 1, 
		TwoFactorAuthRequired = 2, 
		InvalidClientId = 3, 
		PermissionDenied = 4, 
		ChangePasswordRequired = 5, 
		InvalidRequest = 6, 
		UnprocessableEntity = 7, 
		InternalServerError = 8, 
		UnsupportedMediaType = 9, 
		UnparseableResult = 10, 
		Unauthorised = 11, 
		UnspecifiedError = 12, 
		ValidationErrors = 13, 
		VersionMismatch = 14, 
		ConcurrentAccess = 15, 
		GrantsGeneralError = 16, 
	}
	export enum SortDirection { 
		Asc = 1, 
		Desc = 2, 
		None = 3, 
	}
	export enum LocalityType { 
		Region = 1, 
		SubRegion = 2, 
		Locality = 3, 
		Suburb = 4, 
		AboriginalCommunity = 5, 
		Municipality = 6, 
		Shire = 7, 
		GovernmentRegion = 8, 
	}
	export enum QuestionType { 
		SingleLineText = 1, 
		MultiLineText = 2, 
		YesNoToggle = 3, 
		RadioButton = 4, 
		SingleSelectList = 5, 
		MultiSelectList = 6, 
		FileUpload = 7, 
		Date = 8, 
		DateRange = 9, 
		Money = 10, 
		Decimal = 11, 
		Number = 12, 
		PhoneNumber = 13, 
		Email = 14, 
		URL = 15, 
		Address = 16, 
		Location = 17, 
		CheckBoxList = 18, 
		RequestedFundingAmount = 19, 
		Contact = 20, 
		Child = 21, 
		SocialMediaLink = 22, 
		OpeningHours = 23, 
		TextOnly = 24, 
		RequiredDocument = 25, 
		Person = 26, 
		Business = 27, 
		ABNLookup = 28, 
	}
	export enum HelpTextLocation { 
		ToolTip = 1, 
		PlaceHolder = 2, 
		BelowAnswer = 3, 
		AboveAnswer = 4, 
	}
	export enum CriteriaOperation { 
		Equals = 1, 
		LessThan = 2, 
		GreaterThan = 3, 
	}
	export enum LogicalOperator { 
		And = 1, 
		Or = 2, 
	}
	export enum QuestionConfigurationType { 
		MinDate = 1, 
		MaxDate = 2, 
		MinimumYearOffset = 3, 
		MaximumYearOffset = 4, 
		MinInteger = 5, 
		MaxInteger = 6, 
		MinCharacters = 7, 
		MaxCharacters = 8, 
		MinDecimal = 9, 
		MaxDecimal = 10, 
		SortAlphabetically = 11, 
		MinSelectedOptions = 12, 
		MaxSelectedOptions = 13, 
		OptionsList = 14, 
		AllowedLocalityType = 15, 
		MultiSelect = 16, 
		FundingFinancialYear = 17, 
		SpecifyContactRole = 18, 
		DecimalPlaces = 19, 
		FileUploadCategory = 20, 
		ShowSelectSchool = 21, 
		ShowSelectPreviousSchool = 22, 
		ShowSelectDateOfBirth = 23, 
		MaximumAge = 24, 
		MinimumAge = 25, 
		ShowSelectMiddleName = 26, 
		ShowSelectUPN = 27, 
		DocumentType = 28, 
		VoucherDefinitionDataField = 29, 
	}
	export enum StateTerritory { 
		NT = 1, 
		ACT = 2, 
		NSW = 3, 
		QLD = 4, 
		SA = 5, 
		TAS = 6, 
		VIC = 7, 
		WA = 8, 
	}
	export enum PublicContentSectionType { 
		WhoCanApply = 1, 
		WhoCantApply = 2, 
		AvailableFunding = 3, 
		WhatCanBeFunded = 4, 
		WhatCantBeFunded = 5, 
		BeforeYouApply = 6, 
		HowToApply = 7, 
		AfterYouApply = 8, 
		KeyDates = 9, 
		SupportingDocuments = 10, 
		FurtherInformation = 11, 
		UsefulLinks = 12, 
		ContactDetails = 13, 
		ApplyNow = 14, 
	}
	export enum QuestionCodeListType { 
		OptionItems = 1, 
		Title = 2, 
		DurationInNT = 3, 
		OrganisationContactRole = 4, 
		LegalEntityType = 5, 
		BusinessActivityCategory = 6, 
		BusinessActivityCategoryMoreSpecific = 7, 
		Beneficiary = 8, 
		ApplicationContactRole = 9, 
		Schools = 10, 
		Suppliers = 11, 
		SupplierCategories = 12, 
		SupplierLocations = 13, 
		RoundVouchers = 14, 
		ReviewOutcomes = 15, 
		EligibilityOutcomes = 16, 
	}
	export enum PhoneType { 
		AustralianPhoneNumber = 1, 
		InternationalPhoneNumber = 2, 
	}
	export enum ApplicationDocumentSource { 
		Profile = 1, 
		Upload = 2, 
		Comment = 3, 
		ExternalSystem = 4, 
		NoSelection = 5, 
	}
	export enum RegulatoryAuthority { 
		ACNC = 1, 
		ORIC = 2, 
	}
	export enum ReportingRequirementType { 
		Invoice = 1, 
		SignedAgreement = 2, 
		ProgressReport = 3, 
		DeliveryMaterials = 4, 
		PerformanceReport = 5, 
		BeneficiarySurvey = 6, 
		QuarterlyMeeting = 7, 
		AnnualMeeting = 8, 
		FinalMeeting = 9, 
		SiteVisit = 10, 
		OutcomeMeasures = 11, 
		OutputMeasures = 12, 
		VoucherRedemption = 13, 
		RequiredDocument = 14, 
	}
	export enum ApplicationFormStatus { 
		Draft = 1, 
		Withdrawn = 2, 
		WithdrawnTerminal = 3, 
		Expired = 4, 
		ToBeDeleted = 5, 
		RequiresAdminBodyEndorsement = 6, 
		AdminBodyEndorsed = 7, 
		AdminBodyRequiresUpdates = 8, 
		ExternalSubmittedForApproval = 9, 
		ExternalApproved = 10, 
		Submitted = 11, 
		ReviewRequired = 12, 
		ReviewInProgress = 13, 
		ReviewComplete = 14, 
		EligibilityRequired = 15, 
		EligibilityInProgress = 16, 
		EligibilityApprovalRequired = 17, 
		EligibilityApprovalRequested = 18, 
		EligibilityComplete = 19, 
		SportsVoucherAutomatchRequired = 20, 
		VoucherAssessmentRequired = 21, 
		VoucherAssessmentInProgress = 22, 
		VoucherAssessmentApprovalRequired = 23, 
		VoucherAssessmentApprovalRequested = 24, 
		VoucherAssessmentAwaitingSupplierRegistration = 25, 
		VoucherAssessmentComplete = 26, 
		AssessmentStepRequired = 27, 
		AssessmentStepInProgress = 28, 
		AssessmentStepComplete = 29, 
		AssessmentOutcomeRequired = 30, 
		AssessmentOutcomeInProgress = 31, 
		AssessmentOutcomeComplete = 32, 
		ApprovalPhase = 33, 
		RequiresFundingRecommendation = 34, 
		AwaitingApproval = 35, 
		PendingAgreement = 36, 
		AgreementSent = 37, 
		DoNotProceed = 38, 
		Unsuccessful = 39, 
		UnsuccessfulNonTerminal = 40, 
		Successful = 41, 
		PendingAcquittal = 42, 
		PaymentsCompleteOutstandingRequirements = 43, 
		Declined = 44, 
		NotApproved = 45, 
		OnHold = 46, 
		UpdatesPending = 47, 
		Complete = 48, 
		CompleteFullyAcquitted = 49, 
		CompleteApplicantWithdrawn = 50, 
		CompleteOutstandingAcquittal = 51, 
		CompleteFundingWithdrawn = 52, 
	}
	export enum ApplicationHistoryType { 
		StatusChange = 1, 
		ApplicationCreated = 2, 
		ApplicationSaved = 3, 
		Assigned = 4, 
		ConflictOfInterest = 5, 
		Automatch = 6, 
		PutOnHold = 7, 
		TakeOffHold = 8, 
	}
	export enum TargetLateInvitationTransition { 
		Start = 1, 
		Decline = 2, 
		Expire = 3, 
		Withdraw = 4, 
		Reopen = 5, 
	}
	export enum TargetLateInvitationStatus { 
		Open = 1, 
		Declined = 2, 
		Started = 3, 
		Expired = 4, 
		Withdrawn = 5, 
	}
	export enum TargetApplicationType { 
		Targeted = 1, 
		Late = 2, 
	}
	export enum AssessmentCheckStatus { 
		Pending = 1, 
		InProgress = 2, 
		Completed = 3, 
	}
	export enum AssessmentCheckResponseStatus { 
		AwaitingPreviousStep = 1, 
		Pending = 2, 
		InProgress = 3, 
		Completed = 4, 
		ConflictOfInterest = 5, 
	}
	export enum FileUploadCategory { 
		Document = 1, 
		Image = 2, 
		Video = 3, 
		Audio = 4, 
		Presentation = 5, 
		Spreadsheet = 6, 
		Compressed = 7, 
	}
	export enum FormProfileType { 
		Individual = 1, 
		Organisation = 2, 
	}
	export enum LocationType { 
		All = 1, 
		Communities = 2, 
		Locations = 3, 
		LocalGovtAreas = 4, 
	}
	export enum SystemQuestion { 
		ProjectServiceTitle = 1, 
		ProjectStartDate = 2, 
		ProjectEndDate = 3, 
		FundsRequested = 4, 
		DeliveryLocationsSingle = 5, 
		DeliveryLocationsMultiple = 6, 
		BenefittingLocationPrimary = 7, 
		BenefittingLocationSecondary = 8, 
		BenefittingLocationsMultiple = 9, 
		AdministeringBody = 10, 
		AdministeringBodyEmail = 11, 
		AdministeringBodyName = 12, 
		AdministeringBodyABN = 13, 
		PrimaryContact = 14, 
		OtherContacts = 15, 
		OrganisationPrimaryContactRole = 16, 
		OrganisationPrimaryContactRoleOther = 17, 
		SupplierLocations = 18, 
		SupplierServices = 19, 
		SupplierListName = 20, 
		SupplierListAbout = 21, 
		SupplierListAddress = 22, 
		SupplierListPhone = 23, 
		SupplierListEmail = 24, 
		SupplierListWebsite = 25, 
		SupplierListSocialMediaLink = 26, 
		SupplierListBusinessHours = 27, 
		SportsVoucherEligibilityChildQuestion = 28, 
		SportsVoucherEligibilityChildRankScore = 29, 
		SportsVoucherEligibilityChildOID = 30, 
		SportsVoucherEligibilityChildVouchers = 31, 
		NominatedSupplierSelect = 32, 
		NominatedSupplierBusiness = 33, 
		ReviewOutcome = 34, 
		ReviewInternalComments = 35, 
		ReviewAttachments = 36, 
		ReviewCommentsToApplicant = 37, 
		EligibilityOutcome = 38, 
		EligibilityInternalComments = 39, 
		EligibilityAttachments = 40, 
		EligibilityCommentsToApplicant = 41, 
		VoucherIssuedFor = 42, 
		VoucherAmount = 43, 
	}
	export enum SystemPrepopulatedField { 
		ProjectServiceTitle = 1, 
		SportsVoucherOnboardId = 2, 
		SportsVoucherAutomatchRank = 3, 
	}
	export enum PublicContentType { 
		Applicant = 1, 
		ThirdPartySupplier = 2, 
		ThirdPartyApplicant = 3, 
	}
	export enum AdditionalInformationRequestStatus { 
		Saved = 1, 
		NotStarted = 2, 
		Draft = 3, 
		Responded = 4, 
		OverDue = 5, 
	}
	export enum FileSecurityContext { 
		Application = 1, 
		Round = 2, 
		Grant = 3, 
		Payment = 4, 
		Public = 5, 
		Administrator = 6, 
	}
}
