import { autoinject } from "aurelia-framework";

import { IApplicationFormService } from "shared/interfaces/iapplication-form-service";
import { ProfileService } from "services/profile-service";
import { Dto } from "project/project";
import { OrganisationDocumentService } from "services/organisation-document-service";

import { Config } from "config/config";
import { RequestHandler } from "./request-handler";
import { SharedDto } from "project/project-shared";

/**
 * An external-website implementation of application-wide functions
 */
@autoinject
export class ApplicationFormService implements IApplicationFormService {
    private individual: boolean = null;
    private preview: boolean = false;

    constructor(
        private readonly profileService: ProfileService,
        private readonly organisationDocumentService: OrganisationDocumentService,
        private readonly requestHandler: RequestHandler) {

    }
    
    public get isPreview(): boolean {
        return this.preview;
    }

    public set isPreview(val: boolean) {
        this.preview = val;
    }
    
    public get allowFileUploads() {
        return !this.preview;
    }
    
    public get isIndividual(): boolean {
        if (this.individual == null) {
            throw new Error("individual/organisation state has not been set");
        }
        return this.individual;
    }

    public set isIndividual(val: boolean) {
        this.individual = val;
    }

    public organisationDocuments(): Promise<SharedDto.IOrganisationDocumentDto[]> {
        if (this.individual) {
            //throw new Error("Individual profiles cannot have organisation documents");
            // they can't, but it might be an assessor viewing them so lets just give empty array
            return Promise.resolve([]);
        }
        return this.profileService.getActiveProfile().then(profile => {
            return this.organisationDocumentService.getOrganisationDocumentsByOrganisation(profile.profileIdentifier);
        });
    }

    public generateAttachmentDownloadUrl(applicationNumber: string, fileId: number) : Promise<string> {    
        return this.requestHandler.get<string>(`application/${applicationNumber}/attachment/${fileId}`).then(link => {
            return Promise.resolve(`${Config.baseUrl}application/file/${link}/download`);
        });
    }

}
