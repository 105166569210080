import { autoinject, bindable, bindingMode, customElement } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { SharedDto } from "project/project-shared";
import { DataService } from "services/data-service";
import { DateComparison, DateOfBirthRule } from '../../custom-validation';

@customElement('form-declaration')
@autoinject
export class FormDeclaration {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) declaration: SharedDto.OnlineForm.Application.IApplicationDeclarationDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) onSubmitValidation: boolean;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) readonly: boolean = false;
    @bindable() formTemplate: SharedDto.OnlineForm.Form.IApplicationFormTemplateDto;
    showStep = true;
    titleList: SharedDto.ICodeListItemDto[];

    nameInput: HTMLInputElement;

    constructor(private readonly dataService: DataService) { }

    bind() {
        this.dataService.getTitles().then((tl: SharedDto.ICodeListItemDto[]) => {
            this.titleList = tl;
        });
        this.setupValidation();
    }

    setupValidation() {
        ValidationRules
            .ensure((a: SharedDto.OnlineForm.Application.IApplicationDeclarationDto) => a.fullName).maxLength(400)
            .ensure((a: SharedDto.OnlineForm.Application.IApplicationDeclarationDto) => a.location).maxLength(400)
            .ensure((a: SharedDto.OnlineForm.Application.IApplicationDeclarationDto) => a.givenName).maxLength(150)
            .ensure((a: SharedDto.OnlineForm.Application.IApplicationDeclarationDto) => a.familyName).maxLength(150)

            .ensure((a: SharedDto.OnlineForm.Application.IApplicationDeclarationDto) => a.fullName).required()
            .when(() => this.onSubmitValidation)
            .ensure((a: SharedDto.OnlineForm.Application.IApplicationDeclarationDto) => a.declared).required().equals(true)
            .when(() => this.onSubmitValidation)
            .ensure((a: SharedDto.OnlineForm.Application.IApplicationDeclarationDto) => a.location).required()
            .when(() => this.onSubmitValidation)
            .ensure((a: SharedDto.OnlineForm.Application.IApplicationDeclarationDto) => a.titleId).displayName("Title").required()
            .when(() => this.onSubmitValidation)
            .ensure((a: SharedDto.OnlineForm.Application.IApplicationDeclarationDto) => a.givenName).required()
            .when(() => this.onSubmitValidation)
            .ensure((a: SharedDto.OnlineForm.Application.IApplicationDeclarationDto) => a.familyName).required()
            .when(() => this.onSubmitValidation)

            .ensure((a: SharedDto.OnlineForm.Application.IApplicationDeclarationDto) => a.dateOfBirth).required()
            .when(() => this.onSubmitValidation)
            .ensure((a: SharedDto.OnlineForm.Application.IApplicationDeclarationDto) => a.dateOfBirth).satisfiesRule(DateOfBirthRule)
            .ensure((a: SharedDto.OnlineForm.Application.IApplicationDeclarationDto) => a.dateOfDeclaration).required()
            .when(() => this.onSubmitValidation)
            .ensure((a: SharedDto.OnlineForm.Application.IApplicationDeclarationDto) => a.dateOfDeclaration).satisfiesRule(DateComparison, "<", o => {
                return new Date().getTime();
            }, "ignored")
            .withMessage("Declaration must be today or a date in the past")
            .on(this.declaration);
    }
}
