import { DialogService } from "aurelia-dialog";
import { autoinject, singleton } from "aurelia-dependency-injection";
import { PLATFORM } from "aurelia-pal";
import { ConfirmModal } from ".//confirm-modal";

PLATFORM.moduleName("./confirm-modal")

@singleton()
@autoinject
export class Confirm {

    constructor(private readonly dialogService: DialogService) {

    }

    confirm(message: string, title?: string, okButtonText?: string, cancelButtonText?: string, warnings?: string[]): Promise<boolean> {
        return this.dialogService.open({
            viewModel: ConfirmModal,
            model: {
                message: message,
                title: title,
                okButtonText: okButtonText,
                cancelButtonText: cancelButtonText,
                warnings: warnings
            }
        }).whenClosed<boolean>(result => {
            if (result.wasCancelled) {
                return false;
            }
            return true;
        });
    }
    
    continue(message: string) {
        return this.dialogService.open({
            viewModel: ConfirmModal,
            model: {
                message: message,
                title: null,
                okButtonText: "Continue",
                showCancel: false,
            }
        }).whenClosed<boolean>(result => {
            if (result.wasCancelled) {
                return false;
            }
            return true;
        });
    }
}