import { bindingMode, Disposable } from "aurelia-binding";
import { autoinject } from "aurelia-dependency-injection";
import { bindable, customElement } from "aurelia-templating";
import * as $ from 'jquery';
import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";
import "selectize";
import { AddressService } from "services/address-service";
import { v4 as uuid } from "uuid";
import "../../shared/controls/select-input/selectize-plugin-clear";

@customElement("address-search")
@autoinject
export class AddressSearch {

    @bindable({ defaultBindingMode: bindingMode.toView }) searchInputMode: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView }) title: string = "Address";
    @bindable({ defaultBindingMode: bindingMode.toView }) noPoBox: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView }) required: boolean = false;//just used for styling

    //set address when selecting a result, or new up an empty model when manually entering an address.
    //"required" can be applied to the model to simplify validation when searching.
    @bindable({ defaultBindingMode: bindingMode.twoWay }) address: SharedDto.IAddressDto = null;
    @bindable({defaultBindingMode: bindingMode.twoWay}) debugLog: string[] = [];
    originalAddress: SharedDto.IAddressDto = null;

    //track when we're searching, simply to prevent going to manual mid-search.
    searching: boolean = false;
    results: SharedDto.IAddressDto[];
    allResults: SharedDto.IAddressDto[] = [];

    selectElement: Element;
    selectizeApi: Selectize.IApi<any, any>;
    selectedValuesObserver: Disposable;


    constructor(private readonly addressService: AddressService,
        private readonly element: Element) { }


    bind() {        
    }

    attached() {        
        this.init();
    }

    init() {
        let that = this;
        let requestNo = 1;
        //careful when experimenting with this cocktail of settings that get remote data sources working
        $(this.selectElement).selectize({
            valueField: 'uuid',
            labelField: 'description',
            plugins: ['clear_button'],
            openOnFocus: false,
            selectOnTab: true,
            score: function (search) {
                return function (item) {
                    return 1;
                };
            },
            loadThrottle: 300,
            closeAfterSelect: true,            
            load: async function (query, callback) {

                that.selectizeApi.clearOptions();

                if (!query.length) {
                    that.selectizeApi.close();
                    return callback();
                }

                that.searching = true;
                that.addressService.searchAddress(query).then(async data => {                    
                    that.debugLog.push('results updated')
                    that.results = data;
                    //add a proxy unique ID so selectize works.
                    data.forEach(x => (x as any).uuid = uuid());
                    for (let address of data) {
                        (address as any).uuid = uuid();
                        (address as any).description = that.formatAddress(address);
                    }
                    that.allResults = that.allResults.concat(that.results);
                    
                    that.debugLog.push('uuids assigned')
                    return callback(data);
                }).error(() => {
                    return callback();
                }).finally(() => {
                    that.searching = false;
                });
            },
        });

        this.selectizeApi = (this.selectElement as any).selectize;

        //before attaching handlers, add an option and select it to represent current state...
        if (this.address !== null) {            
            (this.address as any).uuid = uuid();
            (this.address as any).description = this.formatAddress(this.address);
            this.selectizeApi.addOption(this.address);
            this.selectizeApi.setValue((this.address as any).uuid);
            if(!this.address.suburb || !this.address.postcode || !this.address.australianStateTerritory || !this.address.countryId || !this.address.line1) {
                this.searchInputMode = false;
            }
        }

        this.selectizeApi.on('change', (value) => { 
            this.debugLog.push('item selected')
            this.itemSelected(value); 
        });
        this.selectizeApi.on("item_remove", (value) => { 
            this.debugLog.push('item removed')
            this.itemRemoved();         
        });
        this.selectizeApi.on("clear", () => { 
            this.debugLog.push('cleared')
            this.itemRemoved(); 
        });

        //modify selectizes generated input control to avoid chromes really persistent autocomplete functionality
        //wrapped in timeout to ensure selectize has initialzied and still check for null to be extra safe.
        setTimeout(() => {
            let inputelement = this.element.querySelector(".selectize-input input[type=select-one]") as HTMLInputElement;
            if (inputelement) {
                inputelement.autocomplete = "new-password";
            }
        }, 100);
    }

    public changeToManual() {
        this.debugLog.push('changing to manual')
        this.originalAddress = this.address;
        this.debugLog.push('original address: ' + this.formatAddress(this.originalAddress));
        this.address = {} as SharedDto.IAddressDto;
        this.searchInputMode = false;
    }

    public changeToSearch() {
        /*note: this order is important - if the address input is still 
        rendered while setting adress to null, we'll just get an empty 
        object with undefined properties due to binding*/
        this.debugLog.push('changing to search');
        this.searchInputMode = true;
        this.address = null;
        this.address = this.originalAddress;
        this.debugLog.push('restored address: ' + this.formatAddress(this.address));
    }

    private formatAddress(address: SharedDto.IAddressDto): string {
        if(!address) {
            return "";
        }
        let result = address.line1;

        if (address.line2) {
            result += ", " + address.line2;
        }

        if (address.suburb) {
            result += ", " + address.suburb;
        }

        if (address.australianStateTerritory) {
            result += ", " + Dto.Constants.StateTerritory[address.australianStateTerritory];
        }

        if (address.postcode) {
            result += " " + address.postcode;
        }

        return result;
    }

    private itemSelected(item) {     
        this.debugLog.push('looking for item', item)
        //map object assign, without the selectize properties
        this.selectizeApi.focus();
        let value = this.results.find(x => (x as any).uuid == item);
        if(!value) {
            this.debugLog.push('item not found in results')            
            value = this.allResults.find(x => (x as any).uuid == item);
            if(!value) {
                // sometimes we find nothing due to timing
                this.debugLog.push('item not found in all results either, huh')    
                return;            
            }
        }
        let newAddress = Object.assign({}, value as any);
        this.debugLog.push(`found address: ${newAddress.description}`)
        delete newAddress.uuid;
        delete newAddress.description;
        this.address = newAddress;        
    }

    private itemRemoved() {
        this.address = null;
        this.selectizeApi.close();
        this.selectizeApi.clearOptions();        
    }

    detached() {
        if (this.selectizeApi) {
            this.selectizeApi.destroy();
        }
        if (this.selectedValuesObserver) {
            this.selectedValuesObserver.dispose();
        }
    }
}
