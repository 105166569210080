
export class HighlightValueConverter {

    toView(value: string, textToHighlight: string) {
        if (!value) {
            return value;
        }
        if (!textToHighlight) {
            return value;
        }

        if (value.toLowerCase().indexOf(textToHighlight.toLowerCase()) >= 0) {
            var parts = this.splitAndKeepDelimiters(value, textToHighlight);
            for (var i = 0; i < parts.length; i++) {
                if (parts[i].toLowerCase() == textToHighlight.toLowerCase()) {
                    parts[i] = `<span class='text-highlight'>${parts[i]}</span>`
                }
            }
            var result =  parts.join("");
            return result;
        }

        return value;
    }

    splitAndKeepDelimiters(value:string, delimiter:string): string[] {
        var result = [];
        var start = 0, index;
        while ((index = value.toLowerCase().indexOf(delimiter.toLowerCase(), start)) != -1) {
            result.push(value.substr(start, index - start));
            result.push(value.substr(index, delimiter.length));
            start = index + delimiter.length;
        }
        result.push(value.substr(start));
        return result;
    }
}