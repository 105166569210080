import { autoinject } from "aurelia-dependency-injection";
import { RequestHandler } from "services/request-handler";
import { ProfileService } from "services/profile-service";
import { SharedDto } from "project/project-shared";
import { Dto } from "project/project";
import { DownloadFileService } from "shared/utils/download-file-service";

@autoinject
export class AdditionalInfoService {
    constructor(private readonly requestHandler: RequestHandler, 
        private readonly profileService: ProfileService,
        private readonly downloadFileService: DownloadFileService) {
    }

    getAdditionalInfoList(filter: Dto.AdditionalInfo.IAdditionalInfoRequestFilterDto): Promise<SharedDto.IPagedResult<Dto.AdditionalInfo.IAdditionalInfoRequestListDto>> {
        return this.profileService.getActiveProfile().then(profile => {
            if (profile.profileType == "Organisation") {
                filter.organisationId = profile.profileIdentifier;
            }
            else {
                filter.organisationId = null;
            }
            return this.requestHandler.post<Dto.AdditionalInfo.IAdditionalInfoRequestFilterDto, SharedDto.IPagedResult<Dto.AdditionalInfo.IAdditionalInfoRequestListDto>>('rfi', filter);
        })
    }

    getAdditionalInfoRequest(applicationId: number, additionalInformationRequestId: number): Promise<Dto.AdditionalInfo.IAdditionalInformationRequestDto> {
        return this.orgIdQuery().then(query => {
            return this.requestHandler.get<Dto.AdditionalInfo.IAdditionalInformationRequestDto>(`rfi/${applicationId}/${additionalInformationRequestId}${query}`);
        });
    }

    getRespondedAdditionalInfoRequest(applicationId: number): Promise<Dto.AdditionalInfo.IAdditionalInformationRequestDto> {
        return this.requestHandler.get<Dto.AdditionalInfo.IAdditionalInformationRequestDto>(`rfi/${applicationId}/assessment`);
    }

    saveAdditionalInfoRequest(applicationId: number, additionalInformationRequestId: number, model: Dto.AdditionalInfo.IAdditionalInformationRequestItemUpdateDto[]): Promise < any > {
        return this.orgIdQuery().then(query => {
            return this.requestHandler.post(`rfi/${applicationId}/${additionalInformationRequestId}${query}`, model);
        });
    }

    saveAndSubmitAdditionalInfoRequest(applicationId: number, additionalInformationRequestId: number, model: Dto.AdditionalInfo.IAdditionalInformationRequestItemUpdateDto[]): Promise < any > {
        return this.orgIdQuery().then(query => {
            return this.requestHandler.post(`rfi/${applicationId}/${additionalInformationRequestId}/submit${query}`, model);
        });
    }

    downloadQuestionDocument(applicationId: number, additionalInformationRequestId: number, document: SharedDto.IFilePropertiesDto): Promise < void> {
        return this.orgIdQuery().then(query => {
            return this.requestHandler.get<SharedDto.IFileDto>(`rfi/${applicationId}/${additionalInformationRequestId}/submitted-file/${document.fileStorageId}${query}`);
        }).then(response => {
            return this.downloadFileService.downloadFromBlob(response);
        });
    }

    downloadSubmittedAgencyDocument(applicationId: number, additionalInformationRequestId: number, additionalInformationRequestItemId: number, document: SharedDto.IFilePropertiesDto): Promise < void> {
        return this.orgIdQuery().then(query => {
            return this.requestHandler.get<SharedDto.IFileDto>(`rfi/${applicationId}/${additionalInformationRequestId}/submitted-by-agency/${additionalInformationRequestItemId}/${document.fileStorageId}`);
        }).then(response => {
            return this.downloadFileService.downloadFromBlob(response);
        });
    }

    private orgIdQuery(): Promise<string> {
        return this.profileService.getActiveProfile().then(activeProfile => {
            let organisationId = activeProfile.profileType == "Organisation" ? activeProfile.profileIdentifier : null;
            let query = '';
            if (organisationId != null) {
                query = `?organisationId=${organisationId}`;
            }
            return query;
        });
    }
}