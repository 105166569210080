import { ValidationController, ValidationControllerFactory } from "aurelia-validation";
import { DataService } from "./services/data-service";
import { ValidationRenderer } from "./shared/utils/validation-renderer";

export class ModuleBase {
    protected dataService: DataService;

    constructor(dataService: DataService) {
        this.dataService = dataService;
    }
}

/** custom elements that need to declare their own validation scope should inherit from this class */
export class ValidationBase {
    protected controller: ValidationController;

    constructor(controllerFactory: ValidationControllerFactory,
        enableScroll: boolean = true) {

        this.controller = controllerFactory.createForCurrentScope();
        this.controller.addRenderer(new ValidationRenderer(enableScroll));
    }
}

/** Top-level pages/elements that have a form submit/save should extend from this class */
export class ValidationModuleBase extends ValidationBase {

    constructor(
        controllerFactory: ValidationControllerFactory,
        enableScroll: boolean = true) {

        super(controllerFactory, enableScroll);
    }
}
