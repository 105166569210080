import { bindingMode, computedFrom } from "aurelia-binding";
import { bindable } from "aurelia-templating";
import { SharedDto } from "project/project-shared";

export class QHeadHelpCustomElement {

    @bindable({ defaultBindingMode: bindingMode.toView }) template: SharedDto.OnlineForm.Form.IQuestionDto;

    @computedFrom("template.helpTextLocation")
    get showHelp(): boolean {
        //show above answer if null for whatever reason
        return !this.template.helpTextLocation || this.template.helpTextLocation == SharedDto.Constants.HelpTextLocation.AboveAnswer;
    };

}
