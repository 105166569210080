import { bindable, bindingMode } from 'aurelia-framework';

import { SharedDto } from "project/project-shared";
import { ControlIdGenerator } from "../../../utils/control-id-generator";
import { computedFrom } from 'aurelia-binding';
import { ValidationRules } from 'aurelia-validation';

export abstract class QuestionBase {
    @bindable({ defaultBindingMode: bindingMode.toView }) questionTemplate: SharedDto.OnlineForm.Form.IQuestionDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) questionIndex: number;
    @bindable({ defaultBindingMode: bindingMode.toView }) visible: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) stepVisibility: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) sectionVisibility: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) onSubmitValidation: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) sortOrder: number;
    @bindable() delete;
    @bindable() canDelete: boolean;

    questionInstance: SharedDto.OnlineForm.Application.IAnswerDto;
    questionId: string;

    constructor(controlIdGenerator: ControlIdGenerator) {
        this.questionId = 'questionInstance-' + controlIdGenerator.getNextId();
    }

    bind(bindingContext: Object = null, overrideContext: Object = null) {
        //removing this could result in Changed methods being called in child questions. Be mindful if you are going to remove this.
    }

    attached() {
        this.setupValidation();
    }

    setupValidation() {
        console.log('ERROR: Question type ' + SharedDto.Constants.QuestionType[this.questionTemplate.questionType] + ' has not setup a setupValidation() method');
    }

    detached() {
        ValidationRules.off(this.questionInstance);
    }

    @computedFrom('questionTemplate.helpTextLocation', 'questionTemplate.helpText')
    get placeHolderHelpText() {
        return this.questionTemplate !== undefined && this.questionTemplate.helpTextLocation == SharedDto.Constants.HelpTextLocation.PlaceHolder
            ? this.questionTemplate.helpText
            : "";
    }

    @computedFrom('visible', 'stepVisibility', 'sectionVisibility')
    get visibility(): boolean {
        return this.visible && this.stepVisibility && this.sectionVisibility;
    }

    @computedFrom("questionTemplate")
    get isMandatory() {
        return this.questionTemplate.mandatory || (this.questionTemplate.repeatable && this.questionTemplate.repeatMin < this.questionIndex);
    }
}
