import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Store } from 'utils/store';
import { BroadcastEvents } from "models/broadcast-events"

@autoinject
export class AuthManager {

    constructor(private eventAggregator: EventAggregator,
        private store: Store) {
    }

    get isLoggedIn(): boolean {
        try {
            const rememberMe = this.store.getl<boolean>(Store.keyRememberMe);
            if (rememberMe) {
                return (this.store.getl<string>(Store.keyRefreshToken) || '') !== '';
            }
            return (this.store.gets<string>(Store.keyAccessToken) || '') !== '';
        } catch (_) {
            this.clearAuthTokens();
            return false;
        }
    }

    get accessToken(): string {
        try {
            const accessToken = this.store.gets<string>(Store.keyAccessToken);
            if (accessToken != null) {
                return accessToken;
            }
            return null;
        } catch (_) {
            this.clearAuthTokens();
            return null;
        }
    }

    set accessToken(value: string) {
        try {
            this.store.sets(Store.keyAccessToken, value);
        } catch (_) {
            this.clearAuthTokens();
        }
    }

    get refreshToken(): string {
        try {
            const rememberMe = this.store.getl<boolean>(Store.keyRememberMe);
            let refreshToken: string;
            if (rememberMe) {
                refreshToken = this.store.getl<string>(Store.keyRefreshToken);
            } else {
                refreshToken = this.store.gets<string>(Store.keyRefreshToken);
            }
            if (refreshToken != null) {
                return refreshToken;
            }
            return null;
        } catch (_) {
            this.clearAuthTokens();
            return null;
        }
    }

    set refreshToken(value: string) {
        try {
            const rememberMe = this.store.getl<boolean>(Store.keyRememberMe);
            if (rememberMe) {
                this.store.setl<string>(Store.keyRefreshToken, value);
            } else {
                this.store.sets<string>(Store.keyRefreshToken, value);
            }
        } catch (_) {
            this.clearAuthTokens();
        }
    }

    logout() {
        this.clearAuthTokens();
        this.eventAggregator.publish(BroadcastEvents.loginStateChanged);
    }

    login(payload: IAuthResponse) {
        this.refreshToken = payload.refresh_token;
        this.accessToken = payload.access_token;
        this.eventAggregator.publish(BroadcastEvents.loginStateChanged);
    }

    private clearAuthTokens() {
        this.store.removel(Store.keyRefreshToken);
        this.store.removel(Store.keyRememberMe);
        this.store.removes(Store.keyRefreshToken);
        this.store.removes(Store.keyAccessToken);
    }
}
