import { bindable, bindingMode, customElement } from 'aurelia-framework';
import { ValidationController, ValidationRules } from "aurelia-validation";

@customElement('password-strength')
export class PasswordStrength {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) password: string;

    passwordStrengthPercentage: string;
    passwordStrengthMessage: string;
    barColour: string;

    upperCase = new RegExp('[A-Z]');
    lowerCase = new RegExp('[a-z]');
    numbers = new RegExp('[0-9]');
    special = new RegExp('^[a-zA-Z0-9- ]*$');

    passedMinLengthRule: boolean;
    passedUpperCaseRule: boolean;
    passedLowerCaseRule: boolean;
    passedNumberRule: boolean;
    passedSpecialCharacterRule: boolean;

    constructor(private readonly validationController: ValidationController) {

    }

    bind() {
        ValidationRules
            .ensure((model: PasswordStrength) => model.password)
            .satisfies((value: string, model: PasswordStrength) => {
                //if no password, pass true. parent view model can declare required() if needed.
                if (!this.password) return true;

                var rulesMet = 0;
                if (this.passedNumberRule) {
                    rulesMet++;
                }
                if (this.passedUpperCaseRule) {
                    rulesMet++;
                }
                if (this.passedLowerCaseRule) {
                    rulesMet++;
                }
                if (this.passedSpecialCharacterRule) {
                    rulesMet++;
                }
                return rulesMet >= 3 && this.passedMinLengthRule;
            }).withMessage("Password does not meet complexity requirements")
            .on(this);
    }

    passwordChanged(password) {

        var strengthPercentage = 0;

        if (password.length >= 10) {
            this.passedMinLengthRule = true;
            strengthPercentage = strengthPercentage + 55;
        } else {
            this.passedMinLengthRule = false;
        }

        if (this.numbers.test(password)) {
            this.passedNumberRule = true;
            strengthPercentage = strengthPercentage + 15;
        } else {
            this.passedNumberRule = false;
        }

        if (this.upperCase.test(password)) {
            this.passedUpperCaseRule = true;
            strengthPercentage = strengthPercentage + 15;
        } else {
            this.passedUpperCaseRule = false;
        }

        if (this.lowerCase.test(password)) {
            this.passedLowerCaseRule = true;
            strengthPercentage = strengthPercentage + 15;
        } else {
            this.passedLowerCaseRule = false;
        }

        if (!this.special.test(password)) {
            this.passedSpecialCharacterRule = true;
            strengthPercentage = strengthPercentage + 15;
        } else {
            this.passedSpecialCharacterRule = false;
        }

        if (strengthPercentage <= 15) {
            this.passwordStrengthMessage = 'Bad';
            this.barColour = 'Red';
        }

        if (strengthPercentage > 25 && strengthPercentage <= 45) {
            this.passwordStrengthMessage = 'Weak';
            this.barColour = 'Yellow';
        }

        if (strengthPercentage > 45 && strengthPercentage <= 75) {
            this.passwordStrengthMessage = 'Good';
            this.barColour = 'Orange';
        }

        if (strengthPercentage >= 100) {
            this.passwordStrengthMessage = 'Strong';
            this.barColour = 'Green';
        }

        this.passwordStrengthPercentage = strengthPercentage.toString();
        // this.validationController.validate()
    }
}
