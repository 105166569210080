import { autoinject } from 'aurelia-framework';

import { RequestHandler } from "./request-handler";
import { IFileService } from 'shared/interfaces/ifile-service';

@autoinject
export class FileService implements IFileService {
    constructor(private requestHandler: RequestHandler) {
    }

    uploadFile(file: File): Promise<string> {
        var formData = new FormData();
        formData.append('file', file);
        return this.requestHandler.processRequest<string>({
            url: 'file',
            options: {
                method: "post",
                body: formData
            }
        });
    }

    deleteFile(guid: string): Promise<void> {
        // return this.requestHandler.delete<void>(`file/${guid}`);
        return this.requestHandler.delete<void>(`file/${guid}`);
    }
}