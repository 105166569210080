import { autoinject } from "aurelia-dependency-injection";
import { Router } from "aurelia-router";
import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";
import { ProfileService } from "../../../../services/profile-service";

@autoinject
export class IndividualBankDetailsList {
    // organisation: Dto.IOrganisationDto;
    // organisationId: number;
    bankDetails: SharedDto.IBankDetailsDto[] = [];

    constructor(private readonly router: Router,
        private readonly profileService: ProfileService){
    }

    activate() {
        // this.organisationId = params.organisationId;

        let promises = [
            // this.organisationService.getOrganisation(this.organisationId).then(organisation => {
            //     this.organisation = organisation;
            // }),
            this.profileService.getIndividualBankDetails().then(data => {
                this.bankDetails = data;
            })
        ];

        return Promise.all(promises);
    }

    edit(bankDetailId: number) {
        this.router.navigateToRoute("IndividualBankDetailsEdit", { bankDetailsId: bankDetailId });
    }
    
    // remove(bankDetailId: number) {
    //     this.profileService.deleteIndividualBankDetails(bankDetailId).then(result => {
    //        
    //             if (!result)
    //             {
    //                
    //             }
    //        
    //             this.profileService.getIndividualBankDetails().then(data => {
    //                 this.bankDetails = data;
    //             });
    //         }
    //     );
    // }
    
    add() {
        this.router.navigateToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.IndividualBankDetailsEdit], { bankDetailsId: "new" });
    }
}