
//you cannot use navigation strategies to automatically navigate someone down child routers based on query params, so we have to go with full URL generation for now using a service like this.
//once this is merged in: https://github.com/aurelia/router/issues/483 we can go back to using navigation strategies

export function generateGrantUrl(contentIdentifier: string | number) {
    return `/grants/${contentIdentifier}`;
}

export function generateApplyUrl(contentIdentifier: string | number, roundIdentifier: string | number) {
    return `/grants/${contentIdentifier}/apply/${roundIdentifier}`;
}

export function generateRedemptionUrl(supplierId: number) {
    return `vouchers/suppliers/${supplierId}/redemptions`;
}

export function generateApplicationUrl(applicationId: number) {
    return `/manage/application/${applicationId}`
}
