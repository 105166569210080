import { singleton } from "aurelia-framework";
import * as toastr from "toastr";
import { Message, MessageType } from "utils/messages";

@singleton()
export class Notifier {

    initialViewLoaded = false;
    delayedToasts: Function[] = [];

    constructor() {
        //toasts are queued until the first page is loaded (shell.ts::activate()) to avoid them getting removed from the page by aurelia.
        //probably a better way to implement this, but can't find it right now...
    }

    private opts = {
        closeButton: true,
        debug: false,
        newestOnTop: true,
        progressBar: true,
        positionClass: "toast-top-right",
        preventDuplicates: false,
        showDuration: 150,
        hideDuration: 150,
        timeOut: 10000,
        extendedTimeOut: 10000,
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "fadeIn",
        hideMethod: "fadeOut"
    } as ToastrOptions;

    viewLoaded() {
        this.initialViewLoaded = true;

        this.delayedToasts.forEach(x => {
            x();
        })
    }

    clear() {
        this.delayedToasts = Array<Function>();
        toastr.remove();
    }

    info(message: string, title?: string) {
        this.toast(message, MessageType.Info, title);
    }

    success(message: string, title?: string) {
        this.toast(message, MessageType.Success, title);
    }

    warning(message: string, title?: string) {
        this.toast(message, MessageType.Warning, title);
    }

    error(message: string, title?: string) {
        this.toast(message, MessageType.Error, title);
    }

    standardMessage(message: Message) {
        this.toast(message.message, message.type);
    }

    private toast(message: string, type: MessageType, title?: string) {
        var fn;
        switch (type) {
            case MessageType.Success:
                fn = toastr.success;
                break;

            case MessageType.Info:
                fn = toastr.info;
                break;

            case MessageType.Warning:
                fn = toastr.warning;
                break;

            case MessageType.Error:
            default:
                fn = toastr.error;
                break;
        }
        if (this.initialViewLoaded) {
            fn(message, title, this.opts);
        } else {
            this.delayedToasts.push(() => {
                fn(message, title, this.opts);
            });
        }
    }
}
