import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { SharedDto } from "project/project-shared";
import { GenericPhoneNumberRule, StartsWithHttpOrHttps, WebsiteAddressRule } from 'shared/controls/custom-validation';
import { ControlIdGenerator } from 'shared/utils/control-id-generator';
import { QuestionBase } from "../question-base";

@autoinject
export class FormSingleLineTextCustomElement extends QuestionBase {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.ISingleLineTextAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;

    showSingleLineText: boolean = false;
    showUrl: boolean = false;
    showEmail: boolean = false;
    showPhone: boolean = false;

    constructor(private controlIdGenerator: ControlIdGenerator) {
        super(controlIdGenerator);
    }

    bind() {
        super.bind();
    }

    attached() {
        super.attached();
        switch (this.questionTemplate.questionType) {
            case SharedDto.Constants.QuestionType.SingleLineText:
                this.showSingleLineText = true;
                break;
            case SharedDto.Constants.QuestionType.URL:
            case SharedDto.Constants.QuestionType.SocialMediaLink:
                this.showUrl = true;
                break;
            case SharedDto.Constants.QuestionType.Email:
                this.showEmail = true;
                break;
            case SharedDto.Constants.QuestionType.PhoneNumber:
                this.showPhone = true;
                break;
            default:
                throw new Error('Question type (' + SharedDto.Constants.QuestionType[this.questionTemplate.questionType] + ') not found');
        }
    }

    setupValidation() {
        ValidationRules.off(this.questionInstance);

        var builder = ValidationRules.ensure((a: any) => a.a).required().when(a => false);

        var minCharacterRule = this.questionTemplate.configurationOptions.find(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.MinCharacters);
        if (minCharacterRule) {
            builder.ensure((model: SharedDto.OnlineForm.Application.ISingleLineTextAnswerDto) => model.answer)
                .minLength(minCharacterRule.numberValue)
                .when((x) => this.visibility);
        }

        var maxCharacterRule = this.questionTemplate.configurationOptions.find(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.MaxCharacters);
        if (maxCharacterRule) {
            builder.ensure((model: SharedDto.OnlineForm.Application.ISingleLineTextAnswerDto) => model.answer)
                .maxLength(maxCharacterRule.numberValue)
                .when((x) => this.visibility);
        }

        //        if (this.questionTemplate.questionType == SharedDto.Constants.QuestionType.PhoneNumber) {
        //            builder.ensure((model: SharedDto.OnlineForm.Application.IApplicationSingleLineTextQuestionDto) => model.answer)
        //                .minLength(10).maxLength(10).satisfiesRule(GenericPhoneNumberRule)
        //                .when((x) => this.visibility);
        //        }

        if (this.questionTemplate.questionType == SharedDto.Constants.QuestionType.Email) {
            builder.ensure((model: SharedDto.OnlineForm.Application.ISingleLineTextAnswerDto) => model.answer)
                .email()
                .when((x) => this.visibility);
        }

        if (this.questionTemplate.questionType == SharedDto.Constants.QuestionType.URL || this.questionTemplate.questionType === SharedDto.Constants.QuestionType.SocialMediaLink) {
            builder.ensure((model: SharedDto.OnlineForm.Application.ISingleLineTextAnswerDto) => model.answer)
                .satisfiesRule(WebsiteAddressRule)
                .when((x) => this.visibility);
        }

        builder.ensure((model: SharedDto.OnlineForm.Application.ISingleLineTextAnswerDto) => model.answer)
            .required()
            .when(() => {
                return (this.visibility && this.onSubmitValidation && this.isMandatory);
            });

        builder.on(this.questionInstance);
    }
}
