import { autoinject, bindable, bindingMode, computedFrom, Disposable } from 'aurelia-framework';
import { SharedDto } from 'project/project-shared';
import { ControlIdGenerator } from 'shared/utils/control-id-generator';
import { ValidationController, ValidationRules } from '../../../../../../node_modules/aurelia-validation';
import { DeepObserver } from '../../../../utils/deep-observer';
import { PersonNameRule } from '../../../custom-validation';
import { CodeListService } from '../code-list-service';
import { QuestionBase } from "../question-base";

@autoinject
export class FormContact extends QuestionBase {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.IContactAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;
    id: number;

    private subscriptions: Disposable[] = [];
    applicationContactRoles: SharedDto.ICodeListItemDto[];
    loadingRoles = true;

    constructor(private controlIdGenerator: ControlIdGenerator,
        private readonly codeListService: CodeListService,
        private readonly deepObserver: DeepObserver,
        private readonly validationController: ValidationController) {
        super(controlIdGenerator);

        this.id = controlIdGenerator.getNextId();
    }

    bind() {
        super.bind();
        //get application contact roles
        this.codeListService.getApplicationContactRoles(true).then(data => {
            this.applicationContactRoles = data;
            this.loadingRoles = false;
        })
    }

    attached() {
        super.attached();
        this.subscriptions.push(
            this.deepObserver.observe(this, "questionInstance", () => {
                //triger revalidation of the rules attached to 'this' (all fields mandatory when one field entered)
                //this is mostly to hide the validation message if it ever appears, as soon as it's no longer the case.
                if (this.validationController.errors.findIndex(x => x.object === this)) {
                    this.validationController.validate({
                        object: this
                    });
                }
            })
        );
    }

    unbind() {
        while (this.subscriptions.length) {
            this.subscriptions.pop().dispose();
        }
    }

    setupValidation() {
        ValidationRules.off(this.questionInstance);

        ValidationRules
            .ensure((contact: SharedDto.OnlineForm.Application.IContactAnswerDto) => contact.contactName)
            .maxLength(150)
            .satisfiesRule(PersonNameRule)
            .required()
            .when(() => this.visibility && this.onSubmitValidation && this.isMandatory)

            .ensure((contact: SharedDto.OnlineForm.Application.IContactAnswerDto) => contact.emailAddress)
            .maxLength(150)
            .email()
            .required()
            .when(() => this.visibility && this.onSubmitValidation && this.isMandatory)

            .ensure((contact: SharedDto.OnlineForm.Application.IContactAnswerDto) => contact.phoneNumber)
            .required()
            .when(() => this.visibility && this.onSubmitValidation && this.isMandatory)

            .ensure((contact: SharedDto.OnlineForm.Application.IContactAnswerDto) => contact.applicationContactRoleId)
            .required()
            .when(() => this.visibility && this.onSubmitValidation && this.isMandatory && this.roleRequired)

            .on(this.questionInstance)

        ValidationRules.off(this);

        ValidationRules
            .ensure((vm: FormContact) => vm.questionInstance)
            .satisfies(() => {
                if (this.oneFieldEntered && !this.allFieldsEntered) {
                    return false;
                }
                return true;
            })
            .when(() => this.visibility && this.onSubmitValidation && !this.isMandatory)
            .withMessage("You must completely fill in the contact details or clear them.")
            .on(this)
    }

    @computedFrom("questionTemplate.mandatory", "onSubmitValidation", "questionInstance.contactName", "questionInstance.emailAddress", "questionInstance.phoneNumber", "questionInstance.applicationContactRoleId")
    get mandatory(): boolean {
        // some comtact fields are mandatory when:
        // 1. submitting, if questionTemplate.mandatory is true
        // 2. saving or submitting, if some fields are filled out (i.e. you can't enter line 1 without a suburb)
        return (
            (this.isMandatory && this.onSubmitValidation) ||
            (!this.isNullOrEmpty(this.questionInstance.contactName) ||
                !this.isNullOrEmpty(this.questionInstance.emailAddress) ||
                !this.isNullOrEmpty(this.questionInstance.phoneNumber) ||
                !this.questionInstance.applicationContactRoleId)
        );
    }

    @computedFrom("questionInstance.contactName", "questionInstance.emailAddress", "questionInstance.phoneNumber", "questionInstance.applicationContactRoleId")
    get oneFieldEntered(): boolean {
        return (!!this.questionInstance.contactName || !!this.questionInstance.emailAddress ||
            !!this.questionInstance.phoneNumber || (!this.roleRequired || !!this.questionInstance.applicationContactRoleId));
    }

    @computedFrom("questionInstance.contactName", "questionInstance.emailAddress", "questionInstance.phoneNumber", "questionInstance.applicationContactRoleId")
    get allFieldsEntered(): boolean {
        return (!!this.questionInstance.contactName && !!this.questionInstance.emailAddress &&
            !!this.questionInstance.phoneNumber && (!this.roleRequired || !!this.questionInstance.applicationContactRoleId));
    }

    @computedFrom("questionTemplate.configurationOptions")
    get roleRequired(): boolean {
        return this.questionTemplate.configurationOptions.findIndex(x => x.configurationType === SharedDto.Constants.QuestionConfigurationType.SpecifyContactRole && x.boolValue == true) > -1;
    }

    private isNullOrEmpty(str: string) {
        return str == null || str.length == 0;
    }
}
