import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { SharedDto } from "project/project-shared";
import { GenericPhoneNumberRule, StartsWithHttpOrHttps, WebsiteAddressRule } from 'shared/controls/custom-validation';
import { ControlIdGenerator } from 'shared/utils/control-id-generator';
import { QuestionBase } from "../question-base";

@autoinject
export class FormTextBlock extends QuestionBase {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.ISingleLineTextAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;


    constructor(private controlIdGenerator: ControlIdGenerator) {
        super(controlIdGenerator);
    }

    bind() {
        super.bind();
    }

    attached() {
        super.attached();
    }

    setupValidation() {
        ValidationRules.off(this.questionInstance);
    }
}
