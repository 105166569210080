import { DialogService } from "aurelia-dialog";
import { autoinject, PLATFORM } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { IApplyNowModalConditionsEnum, IApplyNowModalParams } from "grants/modals/apply-now-modal/apply-now-modal";
import { ILoginOrRegisterModalParams } from "grants/modals/login-or-register-modal/login-or-register-modal";
import { ActiveProfile } from "models/active-profile";
import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";
import { ProfileService } from "services/profile-service";
import { Confirm } from "shared/controls/confirm-modal/confirm";
import { DownloadFileService } from "shared/utils/download-file-service";
import { Messages } from "utils/messages";
import { Notifier } from "./notifier";
import { RequestHandler } from "./request-handler";

@autoinject
export class ApplicationService {

    constructor(private readonly requestHandler: RequestHandler,
        private readonly profileService: ProfileService,
        private readonly dialogService: DialogService,
        private readonly confirm: Confirm,
        private readonly notifier: Notifier,
        private readonly downloadFileService: DownloadFileService,
        private readonly logger: Logger) { }

    //a bit hacky but since this service is a singleton it can be used as a temporary memory store for the response between page transitions
    public tempTransitionResponse: any;

    getBlankApplication(publicContentId: number, grantRoundId: number): Promise<SharedDto.OnlineForm.Application.IExistingApplicationDto> {
        return this.orgIdQuery().then(query => {
            return this.requestHandler.get<SharedDto.OnlineForm.Application.IExistingApplicationDto>(
                `application/new/${publicContentId}/${grantRoundId}${query}`);
        });
    }

    getBlankApplicationBySlugOrId(publicContentIdentification: number | string, grantRoundIdentification: number | string): Promise<SharedDto.OnlineForm.Application.IExistingApplicationDto> {
        return this.orgIdQuery().then(query => {
            return this.requestHandler.get<SharedDto.OnlineForm.Application.IExistingApplicationDto>(
                `application/new/${publicContentIdentification}/${grantRoundIdentification}${query}`);
        });
    }

    createApplicationBySlug(form: SharedDto.OnlineForm.Application.IApplicationFormDto, publicContentIdentifier: number | string, grantRoundIdentifier: number | string): Promise<number> {
        return this.orgIdQuery().then(query => {

            return this.requestHandler.post<SharedDto.OnlineForm.Application.IApplicationFormDto, number>(
                `application/slug/${publicContentIdentifier}/${grantRoundIdentifier}${query}`, form);
        });
    }

    downloadApplicationAsPdf(applicationId: number): Promise<void> {
        var promise = new Promise<void>((resolve, reject) => {
            this.requestHandler.get<SharedDto.IFileDto>(`application/get-pdf/${applicationId}`).then(response => {
                this.downloadFileService.downloadFromBlob(response);
                resolve();
            }).catch(error => {
                this.logger.error(error);
                reject(error);
            });
        });

        return promise
    }

    getExistingApplication(applicationId: number): Promise<Dto.Application.IExternalExistingApplicationDto> {
        return this.orgIdQuery().then(query => {
            return this.requestHandler.get<Dto.Application.IExternalExistingApplicationDto>(
                `application/${applicationId}${query}`);
        });
    }

    createApplication(form: SharedDto.OnlineForm.Application.IApplicationFormDto, publicContentId: number, grantRoundId: number): Promise<number> {
        debugger;
        return this.orgIdQuery().then(query => {
            return this.requestHandler.post<SharedDto.OnlineForm.Application.IApplicationFormDto, number>(
                `application/${publicContentId}/${grantRoundId}${query}`, form);
        });
    }

    updateApplication(form: SharedDto.OnlineForm.Application.IApplicationFormDto, applicationId: number): Promise<Dto.Application.IApplicationUpdateResponseDto> {
        let model: Dto.OnlineForm.Application.IApplicationFormSubmissionDto = {
            application: form
        };

        return this.requestHandler.put<Dto.OnlineForm.Application.IApplicationFormSubmissionDto, Dto.Application.IApplicationUpdateResponseDto>(`application/${applicationId}`, model);
    }

    getApplications(searchRequest: Dto.Application.IExternalApplicationRequestDto): Promise<SharedDto.IPagedResult<Dto.Application.IExternalApplicationResponseDto>> {
        return this.profileService.getActiveProfile().then(activeProfile => {
            searchRequest.organisationId = activeProfile.profileType == "Organisation" ? activeProfile.profileIdentifier : null;
            return this.requestHandler.post<Dto.Application.IExternalApplicationRequestDto, SharedDto.IPagedResult<Dto.Application.IExternalApplicationResponseDto>>(
                `application/list`, searchRequest);
        });
    }

    transitionApplication(applicationId: number, transition: Dto.Application.IApplicationTransitionRequestDto): Promise<Dto.Application.IApplicationSubmissionResponseDto> {
        return this.profileService.getActiveProfile().then((profile) => {
            transition.organisationId = undefined;
            if (profile.profileType == "Organisation") {
                transition.organisationId = profile.profileIdentifier;
            }

            return this.requestHandler.put<Dto.Application.IApplicationTransitionRequestDto, Dto.Application.IApplicationSubmissionResponseDto>(
                `application/${applicationId}/transition`, transition);
        });
    }

    ApplicationActions(applicationId: number, organisationId: number) {
        return this.requestHandler.get<Dto.Application.IApplicationActionsDto>(
            `application/${applicationId}/actions${this.orgIdAsQuery(organisationId)}`);
    }

    getApplicationHistory(applicationId: number): Promise<Dto.Application.IApplicationFormHistoryDto[]> {
        return this.requestHandler.get<Dto.Application.IApplicationFormHistoryDto[]>(
            `application/${applicationId}/history`);
    }

    async loggedInWithCorrectProfileCheck(profile: ActiveProfile, model: SharedDto.PublicContent.IPublicContentDetailViewDto,
        userDetails: Dto.IUserResponse, isNew: boolean, availableOrganisationProfiles: Dto.IUserOrganisationProfile[]): Promise<IApplyCheckResult> {

        //isNew means the user just created an organisation profile successfully, so don't ask the user if they're sure they have the right org profile
        if (isNew) {
            return { result: 'valid', redirectUrl: null };
        }

        //this will return false if the user is not logged in and doesn't choose to redirect to the login page
        let loggedInCheckResult = await this.loginOrRegister(profile, model);
        if (loggedInCheckResult.result !== 'valid') {
            return loggedInCheckResult;
        }

        if ((model.isIndividualEligible && !model.isOrganisationEligible) && profile.profileType == "Individual") {
            // individual eligible and you are login as an Individual
            return { result: 'valid', redirectUrl: null };
        }

        var applyNowCondition: IApplyNowModalConditionsEnum;
        if (model.isIndividualEligible && model.isOrganisationEligible) {
            // both organisations and individuals are eligible and you are login as individual or organisation
            applyNowCondition = IApplyNowModalConditionsEnum.OrganisationIndividualCanApply
        } else if ((model.isIndividualEligible && !model.isOrganisationEligible) && profile.profileType == "Organisation") {
            // individual eligible and you are login as an organisation
            applyNowCondition = IApplyNowModalConditionsEnum.OnlyIndividualCanApply
        } else if ((!model.isIndividualEligible && model.isOrganisationEligible)) {
            // TP-585 Applying -There is no option to Register an organisation when applying as new organisation to SV/LTS supplier round
            // no longer checking if you only have 1 organisation because users needs to have the option to register a new organisation
            applyNowCondition = IApplyNowModalConditionsEnum.OnlyOrganisationCanApply
        } else if (!model.isIndividualEligible && !model.isOrganisationEligible) {
            // this should never occur
            this.notifier.standardMessage(Messages.serverError);
            this.logger.error('Content has no eligible applicant types', model);
            return { result: 'cancelled', redirectUrl: null };
        }

        return this.dialogService.open({
            viewModel: PLATFORM.moduleName('grants/modals/apply-now-modal/apply-now-modal'),
            model: {
                profile: profile,
                applyNowCondition: applyNowCondition,
                content: model,
                availableOrganisationProfiles: availableOrganisationProfiles
            } as IApplyNowModalParams,
        }).whenClosed<IApplyCheckResult>(result => {
            if (result.wasCancelled) {
                return { result: 'cancelled', redirectUrl: null };
            }
            return result.output;
        });
    }

    async loginOrRegister(profile: ActiveProfile, model: SharedDto.PublicContent.IPublicContentDetailViewDto): Promise<IApplyCheckResult> {
        if (profile) {
            return { result: 'valid', redirectUrl: null };
        }

        var applicationFormState: IApplyNowModalConditionsEnum;
        if (model.isIndividualEligible && model.isOrganisationEligible) { // both organisations and individuals are eligible and you are login as individual or organisation
            applicationFormState = IApplyNowModalConditionsEnum.OrganisationIndividualCanApply
        } else if (!model.isIndividualEligible && model.isOrganisationEligible) { // organisation eligible and you are login as individual
            applicationFormState = IApplyNowModalConditionsEnum.OnlyOrganisationCanApply
        } else if (model.isIndividualEligible && !model.isOrganisationEligible) {
            applicationFormState = IApplyNowModalConditionsEnum.OnlyIndividualCanApply;
        } else if (!model.isIndividualEligible && !model.isOrganisationEligible) { // both organisations and individuals not eligible
            this.confirm.confirm(`Error in the application, please contact your grant administrator`);
            this.logger.error('Error in the application, please contact your grant administrator', model);
            return;
        }
        
        return this.dialogService.open({
            viewModel: PLATFORM.moduleName('grants/modals/login-or-register-modal/login-or-register-modal'),
            model: {
                applicationFormState: applicationFormState,
                content: model
            } as ILoginOrRegisterModalParams,
        }).whenClosed<IApplyCheckResult>((result) => {
            if (result.wasCancelled) {
                return { result: 'cancelled', redirectUrl: null }
            }
            return result.output;
        });
    }

    private orgIdAsQuery(organisationId: number) {
        let query = '';
        if (organisationId != null) {
            query = `?organisationId=${organisationId}`;
        }
        return query;
    }

    private orgIdQuery(): Promise<string> {
        return this.profileService.getActiveProfile().then(activeProfile => {
            let organisationId = activeProfile.profileType == "Organisation" ? activeProfile.profileIdentifier : null;
            let query = '';
            if (organisationId != null) {
                query = `?organisationId=${organisationId}`;
            }
            return query;
        });
    }
}

export interface IApplyCheckResult {
    result: 'valid' | 'redirect' | 'cancelled';
    redirectUrl: string;
}
