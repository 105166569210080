import { autoinject, bindable, bindingMode, computedFrom } from "aurelia-framework";
import { SharedDto } from "project/project-shared";

@autoinject
export class QLabelCustomElement {
    @bindable({ defaultBindingMode: bindingMode.toView }) id;
    @bindable({ defaultBindingMode: bindingMode.toView }) template: SharedDto.OnlineForm.Form.IQuestionDto;

    @computedFrom("template.helpTextLocation", "template.helpText")
    get showHelp(): boolean {
        return this.template.helpTextLocation == SharedDto.Constants.HelpTextLocation.ToolTip && !!this.template.helpText;
    }
}
