import { autoinject } from "aurelia-framework";
import { RequestHandler } from "services/request-handler";
import { SharedDto } from "project/project-shared";
import { IOnlineFormService } from "shared/interfaces/ionline-form-service";
import { DownloadFileService } from "shared/utils/download-file-service";

@autoinject
export class OnlineFormService implements IOnlineFormService {

    constructor(private readonly requestHandler: RequestHandler,
        private readonly downloadFileService: DownloadFileService) { }

    getOrganisationFormTemplate(formId: number): Promise<SharedDto.OnlineForm.Form.IApplicationFormTemplateDto> {
        return this.requestHandler.get<SharedDto.OnlineForm.Form.IApplicationFormTemplateDto>(`form/${formId}/preview/organisation`);
    }

    getIndividualFormTemplate(formId: number): Promise<SharedDto.OnlineForm.Form.IApplicationFormTemplateDto> {
        return this.requestHandler.get<SharedDto.OnlineForm.Form.IApplicationFormTemplateDto>(`form/${formId}/preview/individual`);
    }

    getNewIndividualForm(formId: number): Promise<SharedDto.OnlineForm.Application.IApplicationFormDto> {
        return this.requestHandler.get<SharedDto.OnlineForm.Application.IApplicationFormDto>(`form/${formId}/individual`);
    }

    getNewOrganisationForm(formId: number): Promise<SharedDto.OnlineForm.Application.IApplicationFormDto> {
        return this.requestHandler.get<SharedDto.OnlineForm.Application.IApplicationFormDto>(`form/${formId}/organisation`);
    }

    getOptionItems(questionId: number): Promise<SharedDto.OnlineForm.Form.IOptionItemDto[]> {
        return this.requestHandler.get<SharedDto.OnlineForm.Form.IOptionItemDto[]>(`form/${questionId}/option-items`, 60);
    }

    getDeclarationDocument(applicationFormId: number) {
        return this.requestHandler.get<SharedDto.IFileDto>(`application/${applicationFormId}/declaration-document`)
            .then(response => {
                this.downloadFileService.downloadFromBlob(response);
            }).catch(error => {
                console.log(error);
            });
    }
}
