import { bindable, customElement, bindingMode, computedFrom, observable } from 'aurelia-framework';
import './currency-change.less'

@customElement('currency-change')
export class CurrencyChange {

    @bindable() precision: number = 2;
    @bindable() digits: number = 18;

    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: number;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) previousValue: number;

    constructor() { }

    @computedFrom("value", "previousValue")
    get change() : number
    {
        return (Math.abs(this.previousValue - this.value));
    }
    
}
