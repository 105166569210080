import { Disposable, BindingEngine } from 'aurelia-binding';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, bindingMode, customElement } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Dto } from 'project/project';
import { SharedDto } from "project/project-shared";
import { StepVisibilityChannelName } from "../form-utils";


@customElement('form-step')
@autoinject
export class FormStep {
    @bindable({ defaultBindingMode: bindingMode.toView }) stepTemplate: SharedDto.OnlineForm.Form.IStepDto;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) stepInstance: SharedDto.OnlineForm.Application.IFormInstanceStepDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) onSubmitValidation: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView }) formInstance: SharedDto.OnlineForm.Application.IFormInstanceDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) formTemplate: SharedDto.OnlineForm.Form.IFormTemplateDto;
    @bindable() sectionAddedCallback: () => void;
    @bindable() formControlId: number;
    sectionGroupTemplates: SharedDto.OnlineForm.Form.ISectionDto[] = [];
    @bindable({ defaultBindingMode: bindingMode.oneTime }) router: Router;
    @bindable({ defaultBindingMode: bindingMode.toView }) additionalInformationRequest: SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestSharedDto[];
    @bindable({ defaultBindingMode: bindingMode.twoWay }) allowAdditionalInfoRequests: boolean = false;

    private subscriptions: Disposable[] = [];

    constructor(private eventAggregator: EventAggregator, private readonly bindingEngine: BindingEngine) {
    }

    bind() {       
        if (this.stepInstance.templateStepId != this.stepTemplate.stepId) {
            // hint: look at the parent to figure out how this binding got mixed up
            throw new Error("The given application step does not match the template step.");
        }

        //Make sure objects are in the right order. 
        // Note: Seems silly doing it this way, but it makes the sorting, and by extention the triggering of events work correctly...go figure
        var theOrder = this.stepInstance.sectionGroups.slice(0);
        theOrder.sort((a, b) => a.sortOrder - b.sortOrder);
        this.stepInstance.sectionGroups = theOrder;


        // we need to match section group templates with the same index as stepInstance.sectionGroups
        this.buildSection();
    }

    attached() {
        this.subscriptions.push(this.eventAggregator.subscribe(StepVisibilityChannelName(this.formControlId, this.stepInstance.templateStepId), (visibleStatus: boolean) => {
            this.stepInstance.visible = visibleStatus;
        }));

        this.subscriptions.push(this.bindingEngine.collectionObserver(this.stepInstance.sectionGroups).subscribe((cr) => {
            this.buildSection();
        }));
    }

    detached() {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }

    private buildSection() {
        var sgt: SharedDto.OnlineForm.Form.ISectionDto[] = [];

        for (let i = 0; i < this.stepInstance.sectionGroups.length; i++) {            
            let sectionGroupInstance = this.stepInstance.sectionGroups[i];            
            sgt.push(this.stepTemplate.sections.find(s => s.sectionId == sectionGroupInstance.templateSectionId));
        }

        this.sectionGroupTemplates = sgt;
    }
}
