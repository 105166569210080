import { RenderInstruction, ValidateResult } from 'aurelia-validation';
import offset from "document-offset";
import inViewport from "in-viewport";
import scrollIntoView from "scroll-into-view";

export class ValidationRenderer {
    enableScroll: boolean = true;

    constructor(enableScroll?: boolean) {
        if (enableScroll == undefined) {
            this.enableScroll = true;
        } else {
            this.enableScroll = enableScroll;
        }
    }

    render(instruction: RenderInstruction) {
        for (let { result, elements } of instruction.unrender) {
            for (let element of elements) {
                this.remove(element, result);
            }
        }

        for (let { result, elements } of instruction.render) {
            // TODO: Test for result.valid required due to what seems to be a bug introduced in aurelia-validation v1.0.0-beta.
            if (!result.valid) {
                for (let element of elements) {
                    this.add(element, result);
                }
            }
        }

        if (instruction.render && this.enableScroll) {
            var highest = { topOffset: undefined, element };
            for (var instr of instruction.render) {
                if (instr.result.valid) {
                    continue;
                }
                for (var element of instr.elements) {
                    if (element instanceof HTMLInputElement) {
                        if ((element as HTMLInputElement).type.toLowerCase() == "hidden") {
                            element = element.parentElement;
                        }
                    }
                    var theOffset = offset(element);
                    if (theOffset != null && (highest.topOffset === undefined || theOffset.top < highest.topOffset)) {
                        highest.topOffset = theOffset.top;
                        highest.element = element;
                    }
                }
            }
            if (highest.element && !inViewport(highest.element)) {
                //work around for comment nodes.
                while (highest.element.nodeName == "#comment") {
                    highest.element = highest.element.parentElement;
                }
                scrollIntoView(highest.element);
            }
        }
    }

    private add(element: Element, result: ValidateResult) {
        //attempts to put has-error on parent form-group. if no parent form-group, applies has-error to element.

        //for most of this to work, we need to get an element and not a commentnode.
        if (element.nodeName == "#comment") {
            element = this.getNodeParentElement(element.parentNode);
        }

        if (element && element.classList) {
            var parent = element.closest(".form-group");
            if (parent) {
                parent.classList.add("has-error");
            } else {
                element.classList.add('has-error');
            }
        }

        const message: any = document.createElement('div');
        message.className = 'validation-message-div';
        message.textContent = result.message;
        message.id = `validation-message-${result.id}`;
        let inputGroupParent = element.closest(".input-group");
        if (inputGroupParent !== null) {
            let formGroupParent = element.closest(".form-group");
            if (formGroupParent !== null) {
                formGroupParent.appendChild(message);
            } else {
                element.parentNode.parentNode.appendChild(message);
            }
        } else if (element.parentNode.nodeName.toLowerCase() === 'label') {
            const div = document.createElement('div');
            div.id = message.id;
            message.id = '';
            div.appendChild(message);
            element.parentNode.parentNode.appendChild(div);
        } else {
            element.parentNode.appendChild(message);
        }
    }

    //this is a work around for IE, as it only implements parentElement for elements and not nodes (#comment is a Node...)
    private getNodeParentElement(node: Node) {
        while (!(node.parentNode instanceof HTMLElement)) {
            node = node.parentNode;
        }
        return node as Element;
    }

    private remove(element: Element, result: ValidateResult) {

        if (element.nodeName == "#comment") {
            element = this.getNodeParentElement(element.parentNode);
        }
        let removeHasError = () => {
            if (element && element.classList) {
                element.classList.remove('has-error');
                var parent = element.closest(".form-group");
                if (parent) {
                    parent.classList.remove('has-error');
                }
            }
        }
        if (!element.parentElement) { return; } // IE, sometimes, for some unknown reason.

        let inputGroupParent = element.closest(".input-group");
        if (inputGroupParent !== null) {
            let formGroupParent = element.closest(".form-group");
            if (formGroupParent !== null) {
                let message = formGroupParent.querySelector(`#validation-message-${result.id}`);
                if (message !== null) {
                    formGroupParent.removeChild(message);
                }
                removeHasError();
                return;
            }
            //weird check to safe guard against rendering for an element that was removed from the DOM....
            if (element.parentElement.parentNode.nodeType !== 11) {
                const message: any = element.parentElement.parentElement.querySelector(`#validation-message-${result.id}`);
                if (element.parentElement && element.parentElement.parentElement) {
                    if (message) {
                        element.parentElement.parentElement.removeChild(message);
                        removeHasError();
                    }
                }
            }
        } else if (element.parentElement.nodeName.toLowerCase() === 'label') {
            const message: any = element.parentElement.parentElement.querySelector(`#validation-message-${result.id}`);
            if (message) {
                element.parentElement.parentElement.removeChild(message);
                removeHasError();
            }
        } else {
            const message: any = element.parentElement.querySelector(`#validation-message-${result.id}`);
            if (message) {
                element.parentElement.removeChild(message);
                removeHasError();
            }
        }
    }
}
