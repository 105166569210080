import { bindingMode, computedFrom, observable } from "aurelia-binding";
import { autoinject } from "aurelia-framework";
import { bindable } from "aurelia-templating";
import { ValidationRules } from "aurelia-validation";
import { SharedDto } from "project/project-shared";
import { ControlIdGenerator } from "shared/utils/control-id-generator";
import { QuestionBase } from "../question-base";
import { isValidABN } from "abnacn-validator";
import { UtilityService } from "services/utility-service";

@autoinject
export class FormBusiness extends QuestionBase {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.IBusinessAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;
    id: string;

    @observable abn: string;

    checkingAbr: boolean = false;
    abnValidated: boolean = false;
    notActive: boolean = false;
    setup: boolean = false;

    constructor(controlIdGenerator: ControlIdGenerator,
                private readonly utilsService: UtilityService) {
        super(controlIdGenerator);
        this.id = controlIdGenerator.getNextId().toString();
    }

    abnChanged() {
        if(!this.setup) {
            return;
        }

        this.notActive  = false;
        this.abnValidated = false;
        this.questionInstance.abn = this.abn;

        if(this.abnFormatValid) {
            return this.abrLookupValidation();
        }
    }

    bind() {
        this.abn = this.questionInstance.abn;
        if(this.abnFormatValid && this.questionInstance.legalName && this.questionInstance.businessName) {
            this.abnValidated = true;
        }
        this.setup = true;
    }

    async attached() {
        super.attached();
        if(this.abnFormatValid) {
            if (this.lookupAlreadyPerformed()){
                this.abnValidated = true;
            }
            else {
                await this.abrLookupValidation();
            }
        }
    }

    lookupAlreadyPerformed() : boolean {
        return !!this.questionInstance.businessName || !!this.questionInstance.legalName
    }

    setupValidation() {
        ValidationRules
            .ensure((q: SharedDto.OnlineForm.Application.IBusinessAnswerDto) => q.abn)
            .required()
            .when(() => this.visibility && this.onSubmitValidation && this.isMandatory)

            .ensure((q: SharedDto.OnlineForm.Application.IBusinessAnswerDto) => q.businessName)
            .maxLength(150)

            .ensure((q: SharedDto.OnlineForm.Application.IBusinessAnswerDto) => q.legalName)
            .required()
            .when(() => this.visibility && this.onSubmitValidation && this.isMandatory && this.notActive)
            .maxLength(150)

            .on(this.questionInstance);

        ValidationRules
            .ensure((vm: FormBusiness) => vm.questionInstance)
            .satisfies(() => {
                if (this.oneFieldEntered && !this.allFieldsEntered) {
                    return false;
                }
                return true;
            })
            .when(() => this.visibility && this.onSubmitValidation && !this.isMandatory && !this.abnValidated)
            .withMessage("You must completely fill in the business details or clear them.")
            .ensure((vm: FormBusiness) => vm.checkingAbr)
            .satisfies(() => !this.checkingAbr)
            .withMessage("ABR lookup still in progress")
            .on(this)
    }

    detached() {
        super.detached();
        ValidationRules.off(this);
    }

    clearExistingValidation() {

    }

    async abrLookupValidation() {
        this.checkingAbr = true;
        try {
            let result = await this.utilsService.abrLookup(this.abn);
            if(result && result.active) {
                if(!this.questionInstance.businessName) {
                    this.questionInstance.businessName = result.businessName;
                }
                this.questionInstance.legalName = result.mainTradingName;
                this.abnValidated = true;
            }
            else if(result && !result.active) {
                this.notActive = true;
                this.questionInstance.businessName = null;
                this.questionInstance.legalName = null;
                this.abnValidated = false;
            }
            this.checkingAbr = false;
        }
        catch(ex) {
            // the lookup failed for some reason, cleanup and let the user enter manually
            this.notActive = false;
            this.abnValidated = false;
            this.questionInstance.businessName = null;
            this.questionInstance.legalName = null;
            this.checkingAbr = false;
        }
    }

    @computedFrom("questionInstance.businessName", "questionInstance.legalName", "questionInstance.abn")
    get oneFieldEntered(): boolean {
        return !!this.questionInstance.businessName || !!this.questionInstance.legalName || !!this.questionInstance.abn;
    }

    @computedFrom("questionInstance.businessName", "questionInstance.legalName", "questionInstance.abn")
    get allFieldsEntered(): boolean {
        return !!this.questionInstance.businessName && !!this.questionInstance.legalName && !!this.questionInstance.abn;
    }

    @computedFrom("abn")
    get abnFormatValid(): boolean {
        return this.abn && this.abn.length == 11 && isValidABN(this.abn);
    }

}
