import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";
import { IApplyCheckResult } from "services/application-service";
import { generateApplyUrl } from "services/url-generators";
import { IApplyNowModalConditionsEnum } from "../apply-now-modal/apply-now-modal";

export interface IOrganisationIndividualCanApplyModalParams {
    applicationFormState: IApplyNowModalConditionsEnum;
    content: SharedDto.PublicContent.IPublicContentDetailViewDto;
}

@autoinject
export class OrganisationIndividualCanApplyModal {
    dividerWord: string = "OR";
    applicationFormState: IApplyNowModalConditionsEnum;
    redirect: string;

    individual: IApplyNowModalConditionsEnum = IApplyNowModalConditionsEnum.OnlyIndividualCanApply;
    organisation: IApplyNowModalConditionsEnum = IApplyNowModalConditionsEnum.OnlyOrganisationCanApply;

    constructor(public readonly dialogController: DialogController,
        private readonly router: Router) { }

    activate(params: IOrganisationIndividualCanApplyModalParams) {
        this.applicationFormState = params.applicationFormState;
        this.redirect = generateApplyUrl(params.content.contentSlug, params.content.grantRoundSlug)
    }

    register(applicationState: IApplyNowModalConditionsEnum) {
        let currentRoute = this.router.currentInstruction.fragment;
        let queryString = this.router.currentInstruction.queryString;

        if (this.redirect) {
            currentRoute = this.redirect;
        }

        this.applicationFormState = applicationState;
        let registerUrl = this.router.generate(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Register], { redirect: currentRoute, q: queryString, application: this.applicationFormState.toString(), isNew: 1 });
        let checkResult: IApplyCheckResult = {
            result: 'redirect',
            redirectUrl: registerUrl
        }
        this.dialogController.ok(checkResult);
    }

    confirm() {
        let checkResult: IApplyCheckResult = { result: 'valid', redirectUrl: null }
        this.dialogController.ok(checkResult);
    }
}
