import { DialogController } from "aurelia-dialog";
import { autoinject, computedFrom } from "aurelia-framework";
import { Router } from "aurelia-router";
import { ActiveProfile } from "models/active-profile";
import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";
import { IApplyCheckResult } from "services/application-service";
import { ProfileService } from "services/profile-service";
import { generateApplyUrl } from "services/url-generators";

export interface IApplyNowModalParams {
    profile: ActiveProfile;
    applyNowCondition: IApplyNowModalConditionsEnum;
    content: SharedDto.PublicContent.IPublicContentDetailViewDto;
    availableOrganisationProfiles: Dto.IUserOrganisationProfile[];
}

export enum IApplyNowModalConditionsEnum {
    OnlyIndividualCanApply = 1,
    OnlyOrganisationCanApply = 2,
    OrganisationIndividualCanApply = 3
}

@autoinject
export class ApplyNowModal {
    dividerWord: string;

    profile: ActiveProfile;
    userDetails: Dto.IUserResponse;
    availableOrganisationProfiles: Dto.IUserOrganisationProfile[];
    nonAvailableOrganisationProfiles: Dto.IUserOrganisationProfile[];
    isThereNonAvailableOrganisationProfiles: boolean;

    applyNowCondition: IApplyNowModalConditionsEnum;
    title: string;
    subtitle: string = "";
    description: string;

    redirect: string;

    canIndividual: IApplyNowModalConditionsEnum = IApplyNowModalConditionsEnum.OnlyIndividualCanApply;
    canOrganisation: IApplyNowModalConditionsEnum = IApplyNowModalConditionsEnum.OnlyOrganisationCanApply;
    canIndividualOrganisation: IApplyNowModalConditionsEnum = IApplyNowModalConditionsEnum.OrganisationIndividualCanApply;

    constructor(public readonly dialogController: DialogController,
        private readonly router: Router,
        private readonly profileService: ProfileService) {
        this.dividerWord = "OR";
    }

    async activate(params: IApplyNowModalParams) {
        this.profile = params.profile;
        this.applyNowCondition = params.applyNowCondition;
        this.availableOrganisationProfiles = params.availableOrganisationProfiles;

        this.redirect = generateApplyUrl(params.content.contentSlug, params.content.grantRoundSlug);
        this.userDetails = await this.profileService.getAccount();
        this.nonAvailableOrganisationProfiles = this.getNonAvailableOrganisationProfiles(this.availableOrganisationProfiles);
        this.isThereNonAvailableOrganisationProfiles = this.nonAvailableOrganisationProfiles.length ? true : false;
    }

    attached() {
        this.applyNowState()
    }

    getNonAvailableOrganisationProfiles(organisations: Dto.IUserOrganisationProfile[]) : Dto.IUserOrganisationProfile[] {
        var nonAvailableOrganisationProfiles:Dto.IUserOrganisationProfile[] = [];
        var organisationIds:number[] = [];
        
        organisations.forEach(x => {
            organisationIds.push(x.organisationId)
        }); 

        this.userDetails.userOrganisationProfiles.forEach(x => {
            if(!organisationIds.includes(x.organisationId)) {
                nonAvailableOrganisationProfiles.push(x)
            }
        });

        return nonAvailableOrganisationProfiles;
    }

    applyNowState() {
        switch (this.applyNowCondition) {
            case IApplyNowModalConditionsEnum.OnlyIndividualCanApply:
                this.title = "Only individuals can apply";
                this.subtitle = "You are currently logged in with your organisation profile for " + this.profile.profileDisplayName;
                this.description = "";
                break;
            case IApplyNowModalConditionsEnum.OnlyOrganisationCanApply:
                this.title = "Only organisations can apply";
               
                if (this.userDetails.userOrganisationProfiles.length <= 0) {
                    this.subtitle += "To apply as an organisation you must register the organisation details";
                } else {
                    this.subtitle += "Select or create an organisation to apply";
                }

                this.description = "";
                break;
            case IApplyNowModalConditionsEnum.OrganisationIndividualCanApply:
                this.title = "Who are you applying for?";
                this.subtitle = "This grant is open to individuals and organisations.";
                this.description = "Select who you are applying for:";
                break;
            default:
                break;
        }
    }

    async switchToPersonalAccount() {
        var user = await this.profileService.getAccount()
        await this.profileService.determineActiveProfile(user);
        let checkResult: IApplyCheckResult = { result: 'valid', redirectUrl: null };
        this.dialogController.ok(checkResult);
    }

    setOrganisationActiveProfile(organisation: Dto.IUserOrganisationProfile) {
        this.profileService.setActiveProfile({
            profileType: "Organisation",
            profileIdentifier: organisation.organisationId,
            profileDisplayName: organisation.legalName,
            profileUserFullname: this.userDetails.fullName,
            profileUserId: this.userDetails.accountId,
            profileAccountStatus: this.userDetails.accountStatus,
            allowedRoutes: organisation.allowedRoutes
        });

        let checkResult: IApplyCheckResult = { result: 'valid', redirectUrl: null };
        this.dialogController.ok(checkResult)
    }

    registerAnOrganisation() {
        let registerUrl = this.router.generate(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.OrganisationProfile], { organisationId: 'new', redirect: this.redirect });
        let checkResult: IApplyCheckResult = { result: 'redirect', redirectUrl: registerUrl };
        this.dialogController.ok(checkResult);
    }

    cancel() {
        this.dialogController.cancel();
    }

    modalOk() {
        let checkResult: IApplyCheckResult = { result: 'valid', redirectUrl: null };
        this.dialogController.ok(checkResult);
    }

    @computedFrom("userDetails.userOrganisationProfiles")
    get hasOrganisation(): boolean {
        return this.userDetails.userOrganisationProfiles.length <= 0
    }

}
