import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, bindable, bindingMode, computedFrom } from "aurelia-framework";
import { Router } from "aurelia-router";
import { AuthManager } from "auth/auth-manager";
import { ActiveProfile } from "models/active-profile";
import { BroadcastEvents } from "models/broadcast-events";
import { Dto } from "project/project";
import { Notifier } from "services/notifier";
import { ProfileService } from "services/profile-service";
import { ROUTE_APPLICATIONS } from "utils/router-constants";

@autoinject
export class LoginView {

    @bindable() isLoggedIn: boolean;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) userDetails: Dto.IUserResponse;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) activeProfile: ActiveProfile = null;
    @bindable() hasMultipleProfiles: boolean;
    @bindable() theRouter: Router;

    routeApplications = ROUTE_APPLICATIONS;
    subscriptions: Subscription[] = [];

    constructor(private authManager: AuthManager,
        private eventAggregator: EventAggregator,
        private profileService: ProfileService,
        private notifier: Notifier,
        private router: Router) {

    }

    attached() {
        this.isLoggedIn = this.authManager.isLoggedIn;
        this.updateUserDetails();
        this.getActiveProfile();
        this.subscriptions.push(this.eventAggregator.subscribe(BroadcastEvents.profileChanged, () => { this.updateUserDetails(); }));
        this.subscriptions.push(this.eventAggregator.subscribe(BroadcastEvents.profileRefreshed, () => { this.updateUserDetails(); }));
    }

    getActiveProfile() {
        this.profileService.getActiveProfile().then(activeProfile => {
            this.activeProfile = activeProfile;
        });
        this.hasMultipleProfiles = this.userDetails && this.userDetails.userOrganisationProfiles.length > 0;
    }

    unbind() {
        while (this.subscriptions.length) {
            this.subscriptions.pop().dispose();
        }
    }

    isActive(organisation: Dto.IUserOrganisationProfile) {
        return this.activeProfile && this.activeProfile.profileType === "Organisation" &&
            this.activeProfile.profileIdentifier === organisation.organisationId;
    }

    setIndividualActiveProfile() {
        this.profileService.setActiveProfile({
            profileType: "Individual",
            profileIdentifier: this.userDetails.accountId,
            profileDisplayName: null,
            profileUserFullname: this.userDetails.fullName,
            profileUserId: this.userDetails.accountId,
            profileAccountStatus: this.userDetails.accountStatus,
            allowedRoutes: null
        });
    }

    setOrganisationActiveProfile(organisation: Dto.IUserOrganisationProfile) {
        this.profileService.setActiveProfile({
            profileType: "Organisation",
            profileIdentifier: organisation.organisationId,
            profileDisplayName: organisation.legalName,
            profileUserFullname: this.userDetails.fullName,
            profileUserId: this.userDetails.accountId,
            profileAccountStatus: this.userDetails.accountStatus,
            allowedRoutes: organisation.allowedRoutes
        });
    }

    login() {
        let currentRoute = this.router.currentInstruction.fragment;
        const queryString = this.router.currentInstruction.queryString;

        if (currentRoute === "/login") {
            return;
        }
        if (currentRoute === "/register-request" || currentRoute === "/recover-password") {
            currentRoute = "/welcome";
        }

        this.router.navigateToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Login], { redirect: currentRoute, q: queryString });
    }

    register() {
        this.router.navigateToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Register]);
    }

    logout() {
        this.authManager.logout();
        this.notifier.info("You are now logged out.");
        this.router.navigateToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Welcome]);
    }

    @computedFrom("authManager.isLoggedIn")
    get showAuthNavs(): boolean {
        return this.authManager.isLoggedIn;
    }

    private updateUserDetails(): void {
        this.isLoggedIn = this.authManager.isLoggedIn;
        if (this.authManager.isLoggedIn) {
            this.profileService.getAccount().then((data: Dto.IUserResponse) => {
                this.userDetails = data;
                this.getActiveProfile();
            }).catch(() => {
                this.userDetails = null;
                this.activeProfile = null;
            });
        } else {
            this.userDetails = null;
            this.activeProfile = null;
        }
    }

    @computedFrom("activeProfile.profileUserFullname")
    get profileUserFullname(): string {
        if (this.activeProfile === null) {
            return null;
        }
        if (this.activeProfile.profileUserFullname.length < 20) {
            return this.activeProfile.profileUserFullname;
        }
        return this.activeProfile.profileUserFullname.substring(0, 15) + "...";
    }
}
