import { DialogService } from "aurelia-dialog";
import { EventAggregator } from "aurelia-event-aggregator";
import { HttpClient, json } from "aurelia-fetch-client";
import { autoinject } from "aurelia-framework";
import { NavigationInstruction, Router } from "aurelia-router";
import { AuthManager } from "auth/auth-manager";
import { Config } from "config/config";
import { BroadcastEvents } from "models/broadcast-events";
import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";
import { ConcurrentAccessError } from "shared/controls/concurrent-access-error";
import { PermissionError } from "shared/controls/permission-error";
import { ValidationError } from "shared/controls/validation-error";
import { VersionMismatchService } from "shared/utils/version-mismatch-service";
import { Notifier } from "./notifier";

class CachedResponse {
    fetchedOn: Date;
    responsePromise: Promise<Response>;
}

@autoinject
export class RequestHandler {

    cache: { [url: string]: CachedResponse; } = {};
    constructor(private readonly authManager: AuthManager,
        private readonly eventAggregator: EventAggregator,
        private readonly httpClient: HttpClient,
        private readonly router: Router,
        private readonly notifier: Notifier,
        private readonly dialogService: DialogService,
        private readonly versionMismatchService: VersionMismatchService) {
    }
    
    /**
     * Get the resource at the given the URL.
     * @param url url to 'get' from
     */
    get<TResponse>(url: string, cacheAge?: number): Promise<TResponse> {
        return this.processRequest<TResponse>({ url: url, options: { method: 'get', headers: { 'Content-Type': 'application/json' } } }, false, cacheAge);
    }

    /**
     * Post (e.g. ADD) the resource to the given URL, and return the given type
     * @param url
     * @param body
     */
    post<TRequest, TResponse>(url: string, body?: TRequest): Promise<TResponse> {
        let request: IRequest = { url: url, options: { method: 'post', headers: { 'Content-Type': 'application/json' } } };
        if (body) {
            request.options.body = json(body);
        }
        return this.processRequest<TResponse>(request);
    }

    /**
     * Patch (e.g. partial update) the resource to the given URL, and return the given type
     * @param url
     * @param body
     */
    patch<TRequest, TResponse>(url: string, body?: TRequest): Promise<TResponse> {
        let request: IRequest = { url: url, options: { method: 'patch', headers: { 'Content-Type': 'application/json' } } };
        if (body) {
            request.options.body = json(body);
        }
        return this.processRequest<TResponse>(request);
    }

    /**
     * Put (e.g. UPDATE) the resource at the given URL, and return the given type
     * @param url
     * @param body
     */
    put<TRequest, TResponse>(url: string, body?: TRequest): Promise<TResponse> {
        let request: IRequest = { url: url, options: { method: 'put', headers: { 'Content-Type': 'application/json' } } };
        if (body) {
            request.options.body = json(body);
        }
        return this.processRequest<TResponse>(request);
    }

    /**
     * Delete the resource at the given URL
     * @param url
     */
    delete<TResponse>(url: string): Promise<TResponse> {
        return this.processRequest<TResponse>({ url: url, options: { method: 'delete', headers: { 'Content-Type': 'application/json' } } });
    }

    processRequest<T>(request: IRequest, isRetry?: boolean, cacheAge?: number): Promise<T> {
        let url = request.url;
        let isGetRequest = (request.options == null || request.options.method == null || request.options.method.toLowerCase() === "get");

        if (isGetRequest) {
            url += (url.indexOf("?") === -1) ? "?" : "&";
            url += Math.round(new Date().getTime() / 1000).toString();
        }

        const req = new Request(`${Config.baseUrl}${url}`, request.options);
        req.headers.append('ClientVersion', Config.applicationVersion);

        const accessToken = this.authManager.accessToken;
        if (accessToken != null) {
            req.headers.append("Authorization", `Bearer ${accessToken}`);
        }

        var fetchPromise: Promise<Response>;

        if (cacheAge != null && isGetRequest) {
            fetchPromise = this.fetchOrCache(cacheAge, request.url, () => this.httpClient.fetch(req));
        } else {
            fetchPromise = this.httpClient.fetch(req);
        }

        return fetchPromise
            .then((response: Response) => {
                // we can't reuse Reponse objects, so clone it before it's ever used
                return (cacheAge && isGetRequest) ? response.clone() : response;
            }).then((response) => {
                return this.versionMismatchService.checkVersion(response);
            })
            .then((response) => {
                return this.checkResponse(response);
            }).then((response: Response) => {
                return response.text().then(t => {
                    return t ? JSON.parse(t) : null;
                })
            }).catch((reason: any) => {
                // WARNING: this retries PUT/POST/DELETE requests too, which may not be
                // desirable. auth regularly fails if you only enable this for get requests though.
                if ((reason.status === 401 || reason.status === 403) && !isRetry) {
                    return this.refreshAuthToken().then((data: boolean) => {
                        if (data) {
                            return this.processRequest<T>(request, true);
                        }
                    });
                } else if (reason.status === 403 && isRetry) {
                    throw new PermissionError("User does not have required permissions to access this resource.");
                } else {
                    if (!reason.json) {
                        throw new Error(reason);
                    }
                    return reason.json().then((error: SharedDto.IErrorResponse) => {
                        if (error.errorType == SharedDto.Constants.ErrorType.ValidationErrors) {
                            throw new ValidationError(error.message, error.errors);
                        }
                        else if (error.errorType == SharedDto.Constants.ErrorType.ConcurrentAccess) {
                            throw new ConcurrentAccessError(error.message);
                        }
                        else if (error.hasOwnProperty('error_description')) {
                            throw new Error(error['error_description']);
                        }
                        else if (error.hasOwnProperty('message')) {
                            throw new Error(error.message);
                        }
                        throw new Error("Unspecified error.");
                    });
                }
            });
    }

    nextRouteInstruction: NavigationInstruction;

    //cache the refresh token promise for subsequence callers until it is finished to avoid multiple calls
    private refreshTokenPromise: Promise<boolean> = null;

    refreshAuthToken(): Promise<boolean> {
        const refreshToken = this.authManager.refreshToken;

        if (refreshToken == null) {
            this.authManager.logout();
            throw new Error("No refresh token to refresh user session.");
        }

        let redirectToLogin = () => {
            this.authManager.logout();
            this.eventAggregator.publish(BroadcastEvents.loginStateChanged);
            this.dialogService.closeAll();

            if (this.router && this.nextRouteInstruction) {
                //this is needed if request handler is called during activate() where the current instruction is not set for the route.
                //the router authorise pipeline step provides us this information.
                const currentRoute = this.nextRouteInstruction.fragment;
                const queryString = this.nextRouteInstruction.queryString;
                this.router.navigateToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Login], { redirect: currentRoute, q: queryString });
                return;
            }
            if (this.router && this.router.currentInstruction) {
                const currentRoute = this.router.currentInstruction.fragment;
                const queryString = this.router.currentInstruction.queryString;
                this.router.navigateToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Login], { redirect: currentRoute, q: queryString });
                return;
            }
        }

        if (this.refreshTokenPromise == null) {
            this.refreshTokenPromise = this.httpClient.fetch(`${Config.baseUrl}token`, {
                method: "post",
                body: `refresh_token=${refreshToken}&grant_type=refresh_token&client_id=${Config.clientId}`
            }).then((response: Response) => {
                if (response.status === 400) {
                    throw new Error("Logged Out");
                }
                return response.json<IAuthResponse>();
            }).then((data: IAuthResponse) => {
                this.authManager.refreshToken = data.refresh_token;
                this.authManager.accessToken = data.access_token;
                this.eventAggregator.publish(BroadcastEvents.loginStateChanged);
                return { refreshed: true };
            }).catch(error => {
                this.notifier.warning("Your session has expired. You need to log into the system again.");
                redirectToLogin();
                return { error: error }
            }).then((result: any) => {
                // this code runs no matter what happened to the promise above
                this.refreshTokenPromise = null;

                if (result && result.refreshed) {
                    return true;
                }
                else {
                    if (result.error instanceof Error) {
                        throw result.error;
                    }
                    throw new Error("Logged Out");
                }
            });
        }

        return this.refreshTokenPromise;
    }

    private checkResponse(response: Response): Response {
        if (response.ok) {
            return response;
        }
        throw response;
    }

    private fetchOrCache(cacheSeconds: number, url: string, fetchRequest: () => Promise<any>): Promise<any> {
        let cached: CachedResponse = this.cache[url];
        if (cached) {
            if ((new Date().getTime() - cached.fetchedOn.getTime()) > cacheSeconds * 1000) {
                delete this.cache[url];
                cached = undefined;
            }
        }
        if (cached == undefined) {
            cached = this.cache[url] = {
                responsePromise: fetchRequest(),
                fetchedOn: new Date()
            };
        }
        return cached.responsePromise;
    }

    private bustCache(url: string) {
        if (this.cache[url] !== undefined) {
            delete this.cache[url];
        }
    }
}

interface IRequest {
    url: string;
    options?: RequestInit;
}
