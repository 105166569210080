import { BindingEngine, bindingMode, Disposable, observable } from "aurelia-binding";
import { autoinject } from "aurelia-dependency-injection";
import { bindable } from "aurelia-templating";
import { ControlIdGenerator } from "../../utils/control-id-generator";

@autoinject
export class CheckboxList {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedValues: string[];
    @bindable({ defaultBindingMode: bindingMode.toView }) options: CheckboxListOption[];
    @bindable({ defaultBindingMode: bindingMode.oneTime }) name = "checkbox-list";
    @bindable({ defaultBindingMode: bindingMode.twoWay }) disabled: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) inline: boolean = false;
    @bindable() displayProperty: string = "description";
    @bindable() idProperty: string = "id";

    @observable() internalOptions: CheckboxListOption[];
    internalOptionSelectedObservers: Disposable[] = [];
    controlIdGenerator: ControlIdGenerator;

    constructor(private readonly bindingEngine: BindingEngine) {
        this.controlIdGenerator = new ControlIdGenerator();
    }

    bind() {
        if (this.name == "checkbox-list") {
            console.warn("You should specify a custom name for the checkbox list to ensure its id's are all unique.");
        }
    }

    attached() {
        this.mapOptionsToInternal(this.options);
    }

    optionsChanged(newValue, oldValue) {
        this.mapOptionsToInternal(newValue);
    }

    private mapOptionsToInternal(options) {
        if (options) {
            this.internalOptions = options.map((option) => {
                var intOption = new CheckboxListOption();
                intOption.id = option[this.idProperty];
                intOption.description = option[this.displayProperty];
                intOption.selected = this.selectedValues ? this.selectedValues.indexOf(intOption.id) > -1 : false;
                intOption.controlId = this.controlIdGenerator.getNextId();
                return intOption;
            })
        } else {
            this.internalOptions = null;
        }
    }

    selectedValuesChanged(newValue, oldValue) {
        //update internal options with new selected states
        if (this.internalOptions) {
            this.internalOptions.forEach(intOption => {
                intOption.selected = newValue ? newValue.indexOf(intOption.id) > -1 : false;
            });
        }
    }

    internalOptionsChanged(newValue: CheckboxListOption[], oldValue: CheckboxListOption[]) {
        //dispose old observers
        if (this.internalOptionSelectedObservers) {
            while (this.internalOptionSelectedObservers.length > 0) {
                this.internalOptionSelectedObservers.pop().dispose();
            }
        }
        //setup new observers.
        if (newValue) {
            newValue.forEach(option => {
                var subscription = this.bindingEngine.propertyObserver(option, "selected").subscribe(() => { this.updateSelected(this.internalOptions); })
                this.internalOptionSelectedObservers.push(subscription);
            })
        }
    }

    private updateSelected(options) {
        this.selectedValues = options.filter(x => x.selected).map(x => x.id);    }
}

export class CheckboxListOption {
    id: string;
    description: string;
    selected: boolean;
    controlId: number;
}
