import { autoinject } from "aurelia-dependency-injection";
import { Config } from "config/config";
import { SharedDto } from "project/project-shared";
import { RequestHandler } from "services/request-handler";
import { DownloadFileService } from "shared/utils/download-file-service";
// this needs a factory, so only inject the aurelia stuff, the rest is provided later
// this is here because I need this function to be available both for internal and external site.

@autoinject
export class SharedRequestAdditionalInformationServices {
    constructor(private readonly requestHandler: RequestHandler, private readonly downloadFileService: DownloadFileService) {

    }
    
    getAdditionalInfoRequest(applicationId:number, additionalInformationRequestId: number): Promise<SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestSharedDto> {
        return this.requestHandler.get(`application/${applicationId}/additional-info/${additionalInformationRequestId}`);
    }

    createAndSubmitAdditionalInfoRequest(applicationId:number, model: SharedDto.Applications.AdditionalInfo.IAdditionalInformationRequestSharedDto): Promise<number> {
        return this.requestHandler.put(`application/${applicationId}/additional-info/submit`, model);
    }

    getAttachmentDocumentUrl(applicationId: number, additionalInformationRequestId: number, additionalInformationRequestItemId: number, attachmentId: number): string {
        return `${Config.baseUrl}application/${applicationId}/additional-info/${additionalInformationRequestId}/request-item/${additionalInformationRequestItemId}/attachment/${attachmentId}`;
    }

    downloadAdditionalInformationRequestAttachment(applicationId: number, additionalInformationRequestId: number, additionalInformationRequestItemId: number, attachmentId: number) {
        let url = `application/${applicationId}/additional-info/${additionalInformationRequestId}/request-item/${additionalInformationRequestItemId}/attachment/${attachmentId}`;        
        return this.requestHandler.get<SharedDto.IFileDto>(url)
            .then(response => {
                this.downloadFileService.downloadFromBlob(response);
            }).catch(error => {
                console.log(error);
            });
    }

    downloadAdditionalInformationRequestSubmittedByAgencyAttachment(applicationId: number, additionalInformationRequestId: number, additionalInformationRequestItemId: number, attachmentId: number) {
        let url = `application/${applicationId}/additional-info/${additionalInformationRequestId}/request-item/${additionalInformationRequestItemId}/actioned-by-agency/attachment/${attachmentId}`;        
        return this.requestHandler.get<SharedDto.IFileDto>(url)
            .then(response => {
                this.downloadFileService.downloadFromBlob(response);
            }).catch(error => {
                console.log(error);
            });
    }
}
