import { autoinject, PLATFORM } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { AssessmentService } from "services/assesment-service";
import { SharedDto } from "project/project-shared";
import { Dto } from "project/project";
import { Notifier } from "services/notifier";
import { AssessmentCheckStatusLabelClassMap } from "shared/utils/assessments/assessment-status-label-map";
import { DialogService } from "aurelia-dialog";
import { IAdditionalInformationModalParams } from "./additional-information-modal";
import { DeepObserver } from "shared/utils/deep-observer";

@autoinject
export class AssessmentList {

    searching: boolean;
    filter: Dto.Assessment.IApplicationAssessmentSearchFilterDto = {
        page: 1,
        pageSize: 10,
        sortDirection: SharedDto.Constants.SortDirection.Asc,
        sortColumn: null,
        applicationNumber: null
    };
    result: SharedDto.IPagedResult<Dto.Assessment.IApplicationAssessmentSearchResultItemDto>;

    filterWatcher: any;

    constructor(private readonly router: Router,
        private readonly logger: Logger,
        private readonly notifier: Notifier,
        private readonly dialogService: DialogService,
        private readonly assessmentService: AssessmentService, private readonly deepObserver: DeepObserver) {

    }

    activate() {
        return this.search();
    }

    viewAssessmentUrl(assessmentCheckId: number) {
        return this.router.generate("AssessmentsView", { assessmentCheckId: assessmentCheckId });
    }

    viewApplicationUrl(applicationId: number) {
        return this.router.generate("ApplicationView", { applicationId: applicationId });
    }

    viewAdditionalInfo(applicationId: number){
        this.dialogService.open({
            viewModel: PLATFORM.moduleName("assessments/additional-information-modal"),
            model: {
                applicationId: applicationId
            } as IAdditionalInformationModalParams
        });
    }

    bind() {
        this.filterWatcher = this.deepObserver.observe(this, "filter", () => {
            this.search();
        })
    }
    
    detached() {
        if (this.filterWatcher) {
            this.filterWatcher.dispose();
        }
    }

    search(): Promise<any> {
        this.searching = true;
        return this.assessmentService.searchAssessments(this.filter).then(data => {
            this.result = data;
        }).catch(error => {
            this.notifier.error("An error occurred while trying to retrieve the list of assessments assigned to you.");
            this.logger.error(error);
        }).then(() => {
            this.searching = false;
        });
    }

    getStatusLabelClass(item: Dto.Assessment.IApplicationAssessmentSearchResultItemDto): string {
        return AssessmentCheckStatusLabelClassMap(item.assessmentCheckResponseStatus);
    }

    viewApplication(applicationId: number) {
        window.open(this.router.generate("ApplicationView", { applicationId }), '_blank');
    }
}