import { computedFrom } from "aurelia-binding";
import { bindable } from "aurelia-templating";

//note this control follows the NTG Brand Guidelines as close as possible. Requirements scattered throughout as comments.
export class DepartmentBlock {

    lines: number = 1;

    //configurable line height to scale how big this branding will be.
    @bindable() lineHeight: number = 20;//px
    @bindable() department: string;
    //note we need a seperate property to store the rendered department to avoid recursion in the change handler of the bound department
    renderedDepartment: string;

    departmentChanged(newValue, oldValue) {
        if (!newValue) { return; }

        this.renderedDepartment = newValue;

        let departmentOfString = "department of ";

        if (newValue.toLowerCase().indexOf(departmentOfString) === 0) {
            //encapsulate everything except 'department of' in <strong>
            this.renderedDepartment = this.renderedDepartment.insert(departmentOfString.length, "<strong>");
            this.renderedDepartment = this.renderedDepartment.insert(this.renderedDepartment.length, "</strong>");

        } else {
            this.renderedDepartment = `<strong>${this.renderedDepartment}</strong>`
        }

        let contentsInStrong = this.renderedDepartment.substring(this.renderedDepartment.indexOf("<strong>") + "<strong>".length, this.renderedDepartment.indexOf("</strong>"));

        //REQUIREMENT: 'DEPARTMENT OF' is to sit on a line of its own unless the department name is only one word eg. Health
        //if more than 1 word in <strong>, add BR before it.
        if (newValue.toLowerCase().indexOf(departmentOfString) === 0 && contentsInStrong.split(' ').length > 1) {
            this.renderedDepartment = this.renderedDepartment.insert(this.renderedDepartment.indexOf("<strong>"), "<br />");
        }

        //REQUIREMENT The Department name is to sit under ‘DEPARTMENT OF’ and may be split into 2 or 3 lines depending on space.
        //magic that inserts a <br> at the first white space found after 14 characters. taken from: https://stackoverflow.com/a/40862575
        let broken = contentsInStrong.replace(/(.{12}[^ ]* )/g, "$1<br />");
        this.renderedDepartment = this.renderedDepartment.slice(0, this.renderedDepartment.indexOf("<strong>") + "<strong>".length) + broken + "</strong>";

        //TODO: REQUIREMENT: The Department name must not exceed four lines of text.
        //how will this be implemented? Increase the break points for new lines? Ellipsis overflow?...

        //to get the number of lines of text, count instances of <br> + 1 for the original line.
        this.lines = (this.renderedDepartment.match(/<br \/>/g) || []).length + 1;
    }

    @computedFrom("lines", "lineHeight")
    get textContentStyle(): any {
        //REQUIREMENT: The top of the Department name must never extend higher than the division of colours in the colour bar.
        //use number of lines to determine how high the orange line should go
        let orangeHeightUnit = this.lines <= 2 ? 3 : this.lines + 1;
        let style = {
            'position': 'relative',
            'border-left': '10px solid #e06e1d',
            'padding-left': `${this.lineHeight}px`,
            'font-size': `${this.lineHeight}px`,
            'line-height': `${this.lineHeight}px`,
            //REQUIREMENT: Department name is to be written in all caps, in both regular and black font weights.
            'text-transform': 'UPPERCASE',
            'height': (orangeHeightUnit * this.lineHeight) + "px"
        };
        return style;
    }

    @computedFrom("lines", "lineHeight")
    get blackBarStyle(): any {
        //calculate how high the black line should be drawn based on the orange line
        let orangeHeightUnit = this.lines <= 2 ? 3 : this.lines + 1;
        var style = {
            'border-left': '10px solid black',
            'height': null
        };

        /* REQUIREMENT: The colour bar is broken into two sections. The colour bar can be divided at a ratio of approximately 1:1 or 1:2 
        depending on the number of text lines in the department name, or the position of the NTG logo. */
        if (orangeHeightUnit > 3) {
            //1:2 ratio of black to orange
            style.height = (orangeHeightUnit * this.lineHeight) * 0.5 + "px";
        } else {
            //1:1 ratio of black to orange
            style.height = (orangeHeightUnit * this.lineHeight) + "px";
        }

        return style;
    }
}