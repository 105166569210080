import { inject, DOM, bindable, customElement, bindingMode, computedFrom } from 'aurelia-framework';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { SharedDto } from "project/project-shared";
import { QuestionBase } from "../question-base";
import { ControlIdGenerator } from "../../../../utils/control-id-generator";

@customElement('form-multiline-text')
export class FormMultilineText extends QuestionBase {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.IMultiLineTextAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;

    maxAllowedCharacters: number;

    constructor(private controller: ValidationController, private controlIdGenerator: ControlIdGenerator) {
        super(controlIdGenerator);
    }

    bind() {
        super.bind();
    }

    attached(){
        super.attached();
    }

    setupValidation(){
        this.controller.removeObject(this.questionInstance);
        
        let rules = [];

        var minCharacterRule = this.questionTemplate.configurationOptions.find(x=>x.configurationType == SharedDto.Constants.QuestionConfigurationType.MinCharacters);
        if(minCharacterRule){
            rules.push(ValidationRules.ensure((model : SharedDto.OnlineForm.Application.IMultiLineTextAnswerDto) => model.answer)
            .minLength(minCharacterRule.numberValue)
            .when((x) => this.visibility)
            .rules[0]);    
        }

        var maxCharacterRule = this.questionTemplate.configurationOptions.find(x=>x.configurationType == SharedDto.Constants.QuestionConfigurationType.MaxCharacters);
        if(maxCharacterRule){
            this.maxAllowedCharacters = maxCharacterRule.numberValue;
            rules.push(ValidationRules.ensure((model : SharedDto.OnlineForm.Application.IMultiLineTextAnswerDto) => model.answer)
            .maxLength(maxCharacterRule.numberValue)
            .when((x) => this.visibility)
            .rules[0]);    
        }

        rules.push(ValidationRules
            .ensure((model: SharedDto.OnlineForm.Application.IMultiLineTextAnswerDto) => model.answer)
                .required()
                .when(() => (this.visibility && this.onSubmitValidation && this.isMandatory))
                .rules[0]);

        if(rules.length>0){
            this.controller.addObject(this.questionInstance,rules);
        }
    }
}
