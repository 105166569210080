import { BindingEngine, Disposable } from "aurelia-binding";
import { EventAggregator } from 'aurelia-event-aggregator';
import { inject } from "aurelia-dependency-injection";
import * as moment from "moment";
import { StepVisibilityChannelName, SectionVisibilityChannelName, QuestionVisibilityChannelName } from "./form-utils";
import { SharedDto } from "project/project-shared";
import { Rules } from "aurelia-validation";

// this needs a factory, so only inject the aurelia stuff, the rest is provided later

interface QuestionTemplateInfo {
    sectionRepeatable: boolean;
    question: SharedDto.OnlineForm.Form.IQuestionDto
}

interface QuestionAndSection {
    sectionIndex: number;
    question: SharedDto.OnlineForm.Application.IAnswerDto;
}

@inject(BindingEngine, EventAggregator)
export class FormDependencyService implements Disposable {

    private questionsWatchers: Disposable[] = [];
    private questionCriteria: DependencyCriteria[] = [];

    constructor(
        private bindingEngine: BindingEngine,
        private eventAggregator: EventAggregator,
        private formInstance: SharedDto.OnlineForm.Application.IFormInstanceDto,
        private dependencyRule: SharedDto.OnlineForm.Form.IDependencyDto,
        private questionTemplates: QuestionTemplateInfo[],
        private formControlId: number) {


        //This needs to be done before SetupObservers() as it uses this string when events need to be sent. 

        this.dependencyRule.criteria.forEach(criteria => {
            let questionTemplateInfo = this.FindQuestionTemplate(criteria.formQuestionId);
            if (questionTemplateInfo.sectionRepeatable) {
                if (!this.CriteriaAndDependantInSameSection(criteria.formQuestionId, this.dependencyRule.formQuestionId)) {
                    return;
                }
                else {

                }
            }
            var questions = this.FindQuestions(criteria.formQuestionId);
            for (var question of questions) {
                this.questionCriteria.push(
                    new DependencyCriteria(question.question, criteria, questionTemplateInfo.question.questionType, question.sectionIndex)
                );
            }
        });

        this.SetupObservers();
    }

    dispose(): void {
        while (this.questionsWatchers.length > 0) {
            this.questionsWatchers.pop().dispose();
        }
    }

    RunRule(channelName: string) {
        console.log('running rule', channelName);
        var evalResult = this.EvalRule(channelName);
        if (evalResult == true) {
            this.eventAggregator.publish(channelName, this.dependencyRule.visible);
        }
        else {
            this.eventAggregator.publish(channelName, !this.dependencyRule.visible);
        }
    }

    private SetupObservers() {        
        this.dependencyRule.criteria.forEach(criteria => {
            var questions = this.FindQuestions(criteria.formQuestionId);
            var questionTemplate = this.FindQuestionTemplate(criteria.formQuestionId);
            for (var question of questions) {
                let channelName = this.GetEventChannelName(questionTemplate.sectionRepeatable ? question.sectionIndex : null);
                switch (questionTemplate.question.questionType) {
                    case SharedDto.Constants.QuestionType.YesNoToggle:
                        this.CreateQuestionWatcher(question.question, "answer", channelName)
                        break;
                    case SharedDto.Constants.QuestionType.Money:
                        this.CreateQuestionWatcher(question.question, "answer", channelName)
                        break;
                    case SharedDto.Constants.QuestionType.Number:
                        this.CreateQuestionWatcher(question.question, "answer", channelName)
                        break;
                    case SharedDto.Constants.QuestionType.Decimal:
                        this.CreateQuestionWatcher(question.question, "answer", channelName)
                        break;
                    case SharedDto.Constants.QuestionType.RadioButton:
                        this.CreateQuestionWatcher(question.question, "answer", channelName);
                        break;
                    case SharedDto.Constants.QuestionType.SingleSelectList:
                        this.CreateQuestionWatcher(question.question, "answer", channelName)
                        break;
                    case SharedDto.Constants.QuestionType.CheckBoxList:
                        this.CreateQuestionWatcher(question.question, "selectedOptionItemIds", channelName);
                        // seems weird right? should be the same as below but the way the checkbox-list custom element is built,
                        // it replaces the property rather than adds to the collection - so we need to watch the property.
                        break;
                    case SharedDto.Constants.QuestionType.MultiSelectList:
                        this.CreateQuestionWatcherCollection((question.question as SharedDto.OnlineForm.Application.IMultiSelectListAnswerDto).selectedOptionItemIds, channelName)
                        break;
                    case SharedDto.Constants.QuestionType.Date:
                        this.CreateQuestionWatcher(question.question, "answer", channelName)
                        break;
                    default:
                        throw new Error('Question type of ' + questionTemplate.question.questionType + 'is now allowed in SetupObservers');
                }
            }
        });
    }

    private CreateQuestionWatcher(question: SharedDto.OnlineForm.Application.IAnswerDto, propertyName: string, channelName: string) {
        var watcher = this.bindingEngine
            .propertyObserver(question, propertyName)
            .subscribe(() => {
                this.RunRule(channelName);
            });
        this.questionsWatchers.push(watcher);
    }

    private CreateQuestionWatcherCollection(collection: any[], channelName: string) {
        var watcher = this.bindingEngine
            .collectionObserver(collection)
            .subscribe(() => {
                this.RunRule(channelName);
            });
        this.questionsWatchers.push(watcher);
    }

    private GetEventChannelName(sectionId: number = null): string {
        if (this.dependencyRule.formStepId) {
            return StepVisibilityChannelName(this.formControlId, this.dependencyRule.formStepId);
        }
        if (this.dependencyRule.formSectionId) {
            return SectionVisibilityChannelName(this.formControlId, this.dependencyRule.formSectionId);
        }
        if (this.dependencyRule.formQuestionId) {
            return QuestionVisibilityChannelName(this.formControlId, this.dependencyRule.formQuestionId, sectionId);
        }
        throw new Error('Could not get online forms message channel name.');
    }

    private FindQuestion(questionId: number): SharedDto.OnlineForm.Application.IAnswerDto {
        for (var step of this.formInstance.steps) {
            for (var sectionGroup of step.sectionGroups) {
                if (sectionGroup.sections.length > 1) {
                    //Rules are not allowed in repeating sections.
                    // they will be, but not now. 
                    // also if this is a break they're apparently not allowed in a step AFTER a repeating section
                    // which seems silly, so lets continue instead.
                    // they are now but this is staying here because who knows what will break if i remove it
                    continue;
                }
                for (var questionIndex = 0; questionIndex < sectionGroup.sections[0].questionGroups.length; questionIndex++) {
                    var questionGroup = sectionGroup.sections[0].questionGroups[questionIndex];
                    if (questionGroup.templateQuestionId == questionId) {
                        return questionGroup.questions[0];
                    }
                }
            }
        }
        return null;
    }

    private FindQuestions(questionId: number): QuestionAndSection[] {
        var ret: QuestionAndSection[] = [];
        for (var step of this.formInstance.steps) {
            for (var sectionGroup of step.sectionGroups) {
                for (var i = 0; i < sectionGroup.sections.length; i++) {
                    let section = sectionGroup.sections[i];
                    for (var questionIndex = 0; questionIndex < section.questionGroups.length; questionIndex++) {
                        var questionGroup = section.questionGroups[questionIndex];
                        if (questionGroup.templateQuestionId == questionId) {
                            ret.push({ sectionIndex: i, question: questionGroup.questions[0] })
                        }
                    }
                }
            }
        }
        return ret;
    }


    private CriteriaAndDependantInSameSection(criteriaId: number, dependencyId: number) {
        let criteria = this.FindQuestionTemplate(criteriaId).question;
        let dependency = this.FindQuestionTemplate(dependencyId).question;

        return criteria.sectionId == dependency.sectionId;
    }

    private FindQuestionTemplate(questionId: number): QuestionTemplateInfo {
        return this.questionTemplates.find((question: QuestionTemplateInfo): boolean => {
            return question.question.questionId == questionId;
        });
    }

    private EvalRule(channelName: string): boolean {
        for (var cIndex = 0; cIndex < this.questionCriteria.length; cIndex++) {
            let template = this.FindQuestionTemplate(this.dependencyRule.formQuestionId);
            let criteriaChannelName: string = null;
            if (template && template.sectionRepeatable) {
                criteriaChannelName = this.GetEventChannelName(this.questionCriteria[cIndex].sectionIndex)
            }
            else {
                criteriaChannelName = this.GetEventChannelName();
            }
            if (criteriaChannelName != channelName) {
                continue;
            }

            var evalResult = this.questionCriteria[cIndex].Eval();

            switch (this.dependencyRule.logicOperator) {
                case SharedDto.Constants.LogicalOperator.And:
                    //first false we return false
                    if (evalResult == false) {
                        return false;
                    }
                    break;
                case SharedDto.Constants.LogicalOperator.Or:
                    //First true result we can return true.
                    if (evalResult == true) {
                        return true;
                    }
                    break;
                default:
                    throw new Error('Logical operator ' + this.dependencyRule.logicOperator + 'is not supported');
            }
        }

        switch (this.dependencyRule.logicOperator) {
            case SharedDto.Constants.LogicalOperator.And:
                //all the rules matched.
                return true;
            case SharedDto.Constants.LogicalOperator.Or:
                //No rules matched
                return false;
            default:
                throw new Error('Logical operator ' + this.dependencyRule.logicOperator + 'is not supported');
        }
    }
}

class DependencyCriteria implements Disposable {


    constructor(private question: SharedDto.OnlineForm.Application.IAnswerDto,
        private criteria: SharedDto.OnlineForm.Form.IDependencyCriteriaDto,
        private questionType: SharedDto.Constants.QuestionType,
        public sectionIndex: number) {

        if (!question) {
            throw new Error('Question could not be found');
        }

        if (question.templateQuestionId != criteria.formQuestionId) {
            throw new Error('QuestionGroup found has the wrong template question id');
        }
    }

    dispose(): void {
        this.question = null;
        this.criteria = null;
        this.questionType = null;
    }

    public Eval(): boolean {
        switch (this.questionType) {
            case SharedDto.Constants.QuestionType.YesNoToggle:
                return this.EvalYesNoQuestion(<SharedDto.OnlineForm.Application.IYesNoAnswerDto>this.question, this.criteria);
            case SharedDto.Constants.QuestionType.Money:
                return this.EvalDecimalQuestion(<SharedDto.OnlineForm.Application.IDecimalAnswerDto>this.question, this.criteria);
            case SharedDto.Constants.QuestionType.Decimal:
                return this.EvalDecimalQuestion(<SharedDto.OnlineForm.Application.IDecimalAnswerDto>this.question, this.criteria);
            case SharedDto.Constants.QuestionType.Number:
                return this.EvalNumberQuestion(<SharedDto.OnlineForm.Application.INumberAnswerDto>this.question, this.criteria);
            case SharedDto.Constants.QuestionType.SingleSelectList:
            case SharedDto.Constants.QuestionType.RadioButton:
                return this.EvalSingleSelectQuestion(<SharedDto.OnlineForm.Application.ISingleSelectListAnswerDto>this.question, this.criteria);
            case SharedDto.Constants.QuestionType.CheckBoxList:
            case SharedDto.Constants.QuestionType.MultiSelectList:
                return this.EvalMultiSelectQuestion(<SharedDto.OnlineForm.Application.IMultiSelectListAnswerDto>this.question, this.criteria);
            case SharedDto.Constants.QuestionType.Date:
                return this.EvalDateQuestion(<SharedDto.OnlineForm.Application.IDateAnswerDto>this.question, this.criteria);
            default:
                throw new Error('Question Type ' + this.questionType + ' is not implemented yet for EvalRule()');
        }
    }


    private EvalSingleSelectQuestion(questionInstance: SharedDto.OnlineForm.Application.ISingleSelectListAnswerDto, criteria: SharedDto.OnlineForm.Form.IDependencyCriteriaDto): boolean {
        var valueToCheckAgainst: number;
        if (criteria.optionItemId) {
            valueToCheckAgainst = criteria.optionItemId;
        }

        if (criteria.codeItemId) {
            valueToCheckAgainst = criteria.codeItemId;
        }

        if (valueToCheckAgainst) {
            switch (criteria.criteriaOperation) {

                case SharedDto.Constants.CriteriaOperation.Equals:
                    return questionInstance.answer == valueToCheckAgainst.toString();
                default:
                    throw new Error('Operation ' + criteria.criteriaOperation + 'is not allowed for a SingleSelectListQuestion question.');
            }
        }
    }

    private EvalMultiSelectQuestion(questionInstance: SharedDto.OnlineForm.Application.IMultiSelectListAnswerDto, criteria: SharedDto.OnlineForm.Form.IDependencyCriteriaDto): boolean {
        var valueToCheckAgainst: number;
        if (criteria.optionItemId) {
            valueToCheckAgainst = criteria.optionItemId;
        }

        if (criteria.codeItemId) {
            valueToCheckAgainst = criteria.codeItemId;
        }

        if (valueToCheckAgainst) {
            switch (criteria.criteriaOperation) {
                case SharedDto.Constants.CriteriaOperation.Equals:
                    //return questionInstance.selectedOptionItemIds.includes(valueToCheckAgainst);
                    return questionInstance.selectedOptionItemIds.find(so => so == valueToCheckAgainst.toString()) != undefined;
                default:
                    throw new Error('Operation ' + criteria.criteriaOperation + 'is not allowed for a MultiSelectListQuestion question.');
            }
        }
    }

    private EvalYesNoQuestion(questionInstance: SharedDto.OnlineForm.Application.IYesNoAnswerDto, criteria: SharedDto.OnlineForm.Form.IDependencyCriteriaDto): boolean {
        switch (criteria.criteriaOperation) {
            case SharedDto.Constants.CriteriaOperation.Equals:
                return questionInstance.answer == criteria.boolValue;
            default:
                throw new Error('Operation ' + criteria.criteriaOperation + 'is not allowed for a YesNo question.');
        }
    }

    private EvalDecimalQuestion(questionInstance: SharedDto.OnlineForm.Application.IDecimalAnswerDto, criteria: SharedDto.OnlineForm.Form.IDependencyCriteriaDto): boolean {
        switch (criteria.criteriaOperation) {
            case SharedDto.Constants.CriteriaOperation.Equals:
                return questionInstance.answer == criteria.decimalValue;
            case SharedDto.Constants.CriteriaOperation.GreaterThan:
                return questionInstance.answer > criteria.decimalValue;
            case SharedDto.Constants.CriteriaOperation.LessThan:
                return questionInstance.answer < criteria.decimalValue;
            default:
                throw new Error('Operation ' + criteria.criteriaOperation + 'is not allowed for a Decimal question.');
        }
    }

    private EvalDateQuestion(questionInstance: SharedDto.OnlineForm.Application.IDateAnswerDto, criteria: SharedDto.OnlineForm.Form.IDependencyCriteriaDto): boolean {
        let questionDate = moment(questionInstance.answer);
        if (questionDate.isValid()) {
            let criteriaDate = moment(criteria.dateValue);
            switch (criteria.criteriaOperation) {
                case SharedDto.Constants.CriteriaOperation.Equals:
                    return questionDate.isSame(criteriaDate, 'day');
                case SharedDto.Constants.CriteriaOperation.GreaterThan:
                    return questionDate.isAfter(criteriaDate, 'day');
                case SharedDto.Constants.CriteriaOperation.LessThan:
                    return questionDate.isBefore(criteriaDate, 'day');
                default:
                    throw new Error('Operation ' + criteria.criteriaOperation + 'is not allowed for a Decimal question.');
            }
        } else {
            return false;
        }
    }

    private EvalNumberQuestion(questionInstance: SharedDto.OnlineForm.Application.INumberAnswerDto, criteria: SharedDto.OnlineForm.Form.IDependencyCriteriaDto): boolean {
        switch (criteria.criteriaOperation) {
            case SharedDto.Constants.CriteriaOperation.Equals:
                return questionInstance.answer == criteria.numberValue;
            case SharedDto.Constants.CriteriaOperation.GreaterThan:
                return questionInstance.answer > criteria.numberValue;
            case SharedDto.Constants.CriteriaOperation.LessThan:
                return questionInstance.answer < criteria.numberValue;
            default:
                throw new Error('Operation ' + criteria.criteriaOperation + 'is not allowed for a Number question.');
        }
    }
}
