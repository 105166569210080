import { SharedDto } from "project/project-shared";

export function AssessmentCheckStatusLabelClassMap(applicationStatus: SharedDto.Constants.AssessmentCheckResponseStatus): string {
    switch (applicationStatus) {
        case SharedDto.Constants.AssessmentCheckResponseStatus.Completed:
            return "status-label label label-success";
        case SharedDto.Constants.AssessmentCheckResponseStatus.InProgress:
            return "status-label label label-info";
        case SharedDto.Constants.AssessmentCheckResponseStatus.Pending:
        case SharedDto.Constants.AssessmentCheckResponseStatus.AwaitingPreviousStep:
            return "status-label label label-default";
    }
}