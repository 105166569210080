import { autoinject } from "aurelia-framework";
import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";
import { RequestHandler } from "./request-handler";
import { Config } from "config/config";
import { Logger } from "aurelia-logging";
import { DownloadFileService } from "shared/utils/download-file-service";

@autoinject
export class AssessmentService {

    constructor(private readonly requestHandler: RequestHandler,
        private readonly downloadFileService: DownloadFileService,
        private readonly logger: Logger) { }

    searchAssessments(request: Dto.Assessment.IApplicationAssessmentSearchFilterDto):
        Promise<SharedDto.IPagedResult<Dto.Assessment.IApplicationAssessmentSearchResultItemDto>> {

        return this.requestHandler.post(`assessment/search`, request);
    }

    getAssessment(assessmentCheckId: number): Promise<Dto.Assessment.IAssessmentCheckResponseDto> {
        return this.requestHandler.get(`assessment/${assessmentCheckId}`);
    }

    saveAssessment(assessmentCheckId: number, request: Dto.Assessment.IAssessmentCheckResponseUpdateDto, submit: boolean): Promise<any> {
        return this.requestHandler.post(`assessment/${assessmentCheckId}${submit ? '/submit' : ''}`, request);
    }

    conflictOfInterest(assessmentCheckId: number, comment: string): Promise<any> {
        return this.requestHandler.post(`assessment/${assessmentCheckId}/conflict`, comment);
    }

    downloadHelpDocument(assessmentCheckId: number, fileId: number): void {
        this.requestHandler.get<SharedDto.IFileDto>(`assessment/${assessmentCheckId}/help-document/${fileId}`).then(response => {
            this.downloadFileService.downloadFromBlob(response);
        }).catch(error => {
            this.logger.error(error);
        });
    }

    downloadFundingRecomendationAttachment(assessmentCheckId: number): void {
        this.requestHandler.get<SharedDto.IFileDto>(`assessment/${assessmentCheckId}/funding-recommendation-document`).then(response => {
            this.downloadFileService.downloadFromBlob(response);
        }).catch(error => {
            this.logger.error(error);
        });
    }

    downloadNumericResponseAttachment(assessmentCheckId: number, numericResponseId: number): void {
        this.requestHandler.get<SharedDto.IFileDto>(`assessment/${assessmentCheckId}/numeric-question/${numericResponseId}/attachment`).then(response => {
            this.downloadFileService.downloadFromBlob(response);
        }).catch(error => {
            this.logger.error(error);
        });
    }

    downloadQAResponseAttachment(assessmentCheckId: number, qaResponseId: number): void {
        this.requestHandler.get<SharedDto.IFileDto>(`assessment/${assessmentCheckId}/question-answer-question/${qaResponseId}/attachment`).then(response => {
            this.downloadFileService.downloadFromBlob(response);
        }).catch(error => {
            this.logger.error(error);
        });
    }
}