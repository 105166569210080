import { autoinject, singleton } from "aurelia-dependency-injection";

import { IAddressService } from "shared/interfaces/iaddress-service";
import { SharedDto } from "project/project-shared";
import { Dto } from "project/project";
import { RequestHandler } from "services/request-handler";

@autoinject
@singleton()
export class UtilityService {
    constructor(private readonly requestHandler: RequestHandler) {

    }

    getSystemNotifications(): Promise<Array<SharedDto.ISystemNotificationDto>> {
        return this.requestHandler.get<Array<SharedDto.ISystemNotificationDto>>('systemadmin/notifications/internal');
    }

    abrLookup(abn: string): Promise<SharedDto.IAbnDetailsDto> {
        return this.requestHandler.get<SharedDto.IAbnDetailsDto>(`utils/abn-lookup/${abn}`);
    }

}