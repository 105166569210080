import { bindable, bindingMode, customElement, OverrideContext } from 'aurelia-framework';
import { ValidationController, ValidationRules } from 'aurelia-validation';
import { DateFormatValueConverter } from 'shared/value-converters/date-format-value-converter';
import { SharedDto } from '../../../../../project/project-shared';
import { ControlIdGenerator } from "../../../../utils/control-id-generator";
import { ApplicationFormCustomElement } from '../../application-form';
import { QuestionBase } from "../question-base";

@customElement('form-date')
export class FormDate extends QuestionBase {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.IDateAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;

    comparatorQuestion: SharedDto.OnlineForm.Application.IDateAnswerDto = null;

    constructor(private controller: ValidationController, private controlIdGenerator: ControlIdGenerator, private dateValueConverter: DateFormatValueConverter) {
        super(controlIdGenerator);
    }

    bind(bindingContext: Object, overrideContext: OverrideContext) {
        if (this.questionTemplate.systemQuestion == SharedDto.Constants.SystemQuestion.ProjectEndDate) {
            var context = overrideContext.parentOverrideContext;
            while (context != null && !(context.bindingContext instanceof ApplicationFormCustomElement)) {
                context = context['__parentOverrideContext'];
                if (context != null) {
                    context = context.parentOverrideContext;
                }
            }

            if (context != null) {
                try {
                    var form = context['__parentOverrideContext'].bindingContext;

                    var question = form.findSystemQuestionTemplate(SharedDto.Constants.SystemQuestion.ProjectStartDate);
                    this.comparatorQuestion = form.findQuestionAnswer(question.questionId);
                }
                catch (e) {
                    // do nothing here. its possible this is being accessed in some new readonly context so validation isn't important anyway
                }
            }
        }

        super.bind();
    }

    attached() {
        super.attached();
    }

    setupValidation() {
        this.controller.removeObject(this.questionInstance);

        let rules = [];

        var minDateRule = this.questionTemplate.configurationOptions.find(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.MinDate);
        if (minDateRule) {
            rules.push(ValidationRules.ensure((model: SharedDto.OnlineForm.Application.IDateAnswerDto) => model.answer)
                .satisfies((value, model) => {
                    if (!model.answer) {
                        return true;//validation doesnt fire if field is empty
                    }
                    var minDate = new Date(minDateRule.dateValue);
                    var valueEntered = new Date(model.answer);
                    return minDate.getTime() < valueEntered.getTime();
                })
                .when((x) => this.visibility)
                .withMessage("Date must be after " + this.dateValueConverter.toView(minDateRule.dateValue, 'date'))
                .rules[0]);
        }

        var maxDateRule = this.questionTemplate.configurationOptions.find(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.MaxDate);
        if (maxDateRule) {
            rules.push(ValidationRules.ensure((model: SharedDto.OnlineForm.Application.IDateAnswerDto) => model.answer)
                .satisfies((value, model) => {
                    if (!model.answer) {
                        return true;//validation doesnt fire if field is empty
                    }
                    var maxDate = new Date(maxDateRule.dateValue);
                    var valueEntered = new Date(model.answer);
                    return maxDate.getTime() > valueEntered.getTime();
                })
                .when((x) => this.visibility)
                .withMessage("Date must be before " + this.dateValueConverter.toView(maxDateRule.dateValue, 'date'))
                .rules[0]);
        }

        rules.push(ValidationRules
            .ensure((model: SharedDto.OnlineForm.Application.IDateAnswerDto) => model.answer)
            .required()
            .when(() => (this.visibility && this.onSubmitValidation && this.isMandatory))
            .rules[0]);

        if (this.comparatorQuestion != null) {
            rules.push(ValidationRules.ensure((model: SharedDto.OnlineForm.Application.IDateAnswerDto) => model.answer)

                .satisfies((value, model) => {
                    if (!model.answer) {
                        return true;//validation doesnt fire if field is empty
                    }
                    var minDate = new Date(this.comparatorQuestion.answer);
                    var valueEntered = new Date(model.answer);


                    return minDate.getTime() < valueEntered.getTime();
                })
                .when((x) => this.visibility)
                .withMessage("Date must be after Project Commencement Date.").rules[0])
        }

        if (rules.length > 0) {
            this.controller.addObject(this.questionInstance, rules);
        }
    }
}
