import { inject, DOM, bindable, customElement, bindingMode, computedFrom } from 'aurelia-framework';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { SharedDto } from "project/project-shared";
import { QuestionBase } from "../question-base";
import { ControlIdGenerator } from "../../../../utils/control-id-generator";

@customElement('form-number')
export class FormNumber extends QuestionBase {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.INumberAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;

    constructor(private controller: ValidationController, private controlIdGenerator: ControlIdGenerator) {
        super(controlIdGenerator);
    }

    bind() {
        super.bind();
    }

    attached(){
        super.attached();
    }

    setupValidation(){
        this.controller.removeObject(this.questionInstance);
        let rules = [];

        var minIntegerRule = this.questionTemplate.configurationOptions.find(x=>x.configurationType == SharedDto.Constants.QuestionConfigurationType.MinInteger);
        if(minIntegerRule){
            rules.push(ValidationRules.ensure((model : SharedDto.OnlineForm.Application.INumberAnswerDto) => model.answer)
            .satisfies((value: number, object: SharedDto.OnlineForm.Application.INumberAnswerDto) => {
                if(!value){
                    return true;
                }
                return value >= minIntegerRule.numberValue
                })
            .when((x) => this.visibility)
            .withMessage("Answer is less than minimum amount of " + minIntegerRule.numberValue)
            .rules[0]);    
        }

        var maxIntegerRule = this.questionTemplate.configurationOptions.find(x=>x.configurationType == SharedDto.Constants.QuestionConfigurationType.MaxInteger);
        if(maxIntegerRule){
            rules.push(ValidationRules.ensure((model : SharedDto.OnlineForm.Application.INumberAnswerDto) => model.answer)
            .satisfies((value: number, object: SharedDto.OnlineForm.Application.INumberAnswerDto) => {
                if(!value){
                    return true;
                }
                return value <= maxIntegerRule.numberValue
                })
            .when((x) => this.visibility)
            .withMessage("Answer is more than maximum amount of " + maxIntegerRule.numberValue)
            .rules[0]);    
        }

        rules.push(ValidationRules
            .ensure((model: SharedDto.OnlineForm.Application.INumberAnswerDto) => model.answer)
                .required()
                .when(() => (this.visibility && this.onSubmitValidation && this.isMandatory))
                .rules[0]);

        if(rules.length>0){
            this.controller.addObject(this.questionInstance,rules);
        }
    }
}
