import { autoinject } from "aurelia-framework";

import { RequestHandler } from "./request-handler";
import { Config } from "config/config";
import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";

@autoinject
export class AuthService {
    constructor(private readonly requestHandler: RequestHandler) {
    }

    login(username: string, password: string, authToken: string): Promise<IAuthResponse> {
        return this.requestHandler.processRequest<IAuthResponse>({
            url: "token",
            options: {
                method: "post",
                headers: { 'Content-Type': 'application/json' },
                body: authToken === null
                    ? `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&grant_type=password&client_id=${Config.clientId}`
                    : `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&authtoken=${authToken}&grant_type=password&client_id=${Config.clientId}`
            }
        });
    }

    requestPasswordReset(model: Dto.IPasswordResetRequest): Promise<void> {
        return this.requestHandler.post<Dto.IPasswordResetRequest, void>("security/passwordreset", model);
    }

    getPasswordReset(uniqueCode: string): Promise<void> {
        return this.requestHandler.get<void>(`security/passwordreset/${uniqueCode}`);
    }

    updatePasswordFromReset(uniqueCode: string, password: string, recaptchaResponse: string): Promise<void> {
        const model: Dto.IChangePasswordFromResetRequest = {
            password: password,
            recaptchaResponse: recaptchaResponse
        };

        return this.requestHandler.post<Dto.IChangePasswordFromResetRequest, void>(`security/updatepassword/${uniqueCode}`, model);
    }

    updatePassword(oldPassword: string, newPassword: string): Promise<void> {
        const model: Dto.IChangePasswordRequest = {
            oldPassword: oldPassword,
            newPassword: newPassword
        };

        return this.requestHandler.post<Dto.IChangePasswordRequest, void>("profile/account/updatepassword", model);
    }

    needPasswordChange(email:string): Promise<boolean> {
        return this.requestHandler.get<boolean>(`security/need-password-change/${email}`);
    }
}
