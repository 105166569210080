import { autoinject, singleton } from "aurelia-dependency-injection";

import { IAddressService } from "shared/interfaces/iaddress-service";
import { SharedDto } from "project/project-shared";
import { Dto } from "project/project";
import { RequestHandler } from "services/request-handler";

@autoinject
@singleton()
export class AddressService implements IAddressService {
    constructor(private readonly requestHandler: RequestHandler) {

    }

    validateAddresses(model: SharedDto.IAddressUpdateDto): Promise<Dto.IAddressValidationDto> {
        return this.requestHandler.post<SharedDto.IAddressUpdateDto, Dto.IAddressValidationDto>(`utils/validateaddress`, model);
    }

    searchAddress(query:string): Promise<SharedDto.IAddressDto[]> {
        var addressSearch = encodeURIComponent(query);
        return this.requestHandler.get<SharedDto.IAddressDto[]>(`utils/search-address?searchterm=${addressSearch}`);
    }
}