import { bindingMode, computedFrom } from "aurelia-binding";
import { bindable } from "aurelia-templating";
import { SharedDto } from "project/project-shared";

export class QTailHelpCustomElement {

    @bindable({ defaultBindingMode: bindingMode.toView }) template: SharedDto.OnlineForm.Form.IQuestionDto;

    @computedFrom("template.helpTextLocation")
    get showHelp(): boolean {
        return this.template.helpTextLocation == SharedDto.Constants.HelpTextLocation.BelowAnswer;
    };
    
}
