import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, singleton } from "aurelia-framework";
import { NavigationInstruction } from "aurelia-router";
//import _clone from 'lodash/clone';

@singleton()
@autoinject
export class BreadcrumbService {

    subscriptions: Subscription[] = [];
    homeRoute: string;
    crumbs: BreadCrumbItem[];

    constructor(private eventAggregator: EventAggregator) {
        this.crumbs = [];
        this.subscriptions.push(this.eventAggregator.subscribe("router:navigation:success", (c) => {
            this.processInstruction(c.instruction);
        }));
    }

    getParentInstructions(instruction) {
        return instruction.parentInstruction ? this.getParentInstructions(instruction.parentInstruction).concat([instruction]) : [instruction];
    }

    processInstruction(navInstruction: NavigationInstruction) {
        var instructions: NavigationInstruction[] = [];
        this.crumbs = [];
        const parentInstructions = this.getParentInstructions(navInstruction.router.currentInstruction);
        instructions = parentInstructions
            .slice(0, parentInstructions.length - 1)
            .concat(navInstruction.router.currentInstruction.getAllInstructions())
            .filter(instruction => instruction.config.settings && instruction.config.title);
        
        instructions.forEach((instruction) => {
            let breadCrumb = new BreadCrumbItem();
            const params = Object.assign({}, instruction.params);
            delete params.childRoute;

            breadCrumb.title = instruction.config.title;
            breadCrumb.routeConfig = instruction.config;
            breadCrumb.href = instruction.router.generate(instruction.config.name, params);

            if (instruction.config.settings.breadcrumb) {
                this.crumbs.push(breadCrumb);
            }

            //logic below for inserting configured parent breadcrumbs on the same router level
            let parent = instruction.config.settings.parent;
            let parentBreadcrumbs = [];
            while (parent) {
                var parentRouteConfig = instruction.router.routes.find(x => x.name == parent);

                if(!parentRouteConfig) {
                    break
                }
                
                let breadCrumb = new BreadCrumbItem();
                breadCrumb.title = parentRouteConfig.title;

                let newParams = {};
                let requiredParams = this.identifyPathParams(parentRouteConfig.route);
                if (requiredParams.length > 0) {
                    for (let paramName of requiredParams) {
                        newParams[paramName] = instruction.params[paramName];
                    }
                }

                breadCrumb.href = instruction.router.generate(parentRouteConfig.name, newParams);

                if (breadCrumb.href.indexOf("?") > -1) {
                    breadCrumb.href = breadCrumb.href.substr(0, breadCrumb.href.indexOf('?'));
                }

                parentBreadcrumbs.splice(0, 0, breadCrumb);

                if (parent == parentRouteConfig.settings.parent) {
                    break; //infinite loops?
                }

                parent = parentRouteConfig.settings.parent;
            }
            
            this.crumbs.splice(this.crumbs.length - 1, 0, ...parentBreadcrumbs);
        });
    }

    identifyPathParams(routes: string | string[]): string[] {
        let result = [];
        
        if (!(routes instanceof Array)) {
            routes = [routes];
        }
        
        for (let route of routes) {
            let matches = route.match(/:\w+/gi);
            if (matches && matches.length > 0) {
                for (let match of matches) {
                    result.push(match.replace(":", ""));
                }
            }
        }

        return result;
    }
}

export class BreadCrumbItem {
    href: string;
    title: string;
    //added in config so we have an mutable object reference so when we render the title, if a view model changes the title it is reflected in the breadcrumb
    routeConfig: any;
}
