import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { Router } from "aurelia-router";
import { ValidationRules } from "aurelia-validation";

@autoinject
export class ChangePasswordForm {
    isBusy: boolean;

    @bindable({ defaultBindingMode: bindingMode.twoWay }) oldPassword: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) newPassword: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) confirmPassword: string;

    bind() {
        ValidationRules
            .ensure((model: ChangePasswordForm) => model.oldPassword)
                .required()
            .ensure((model: ChangePasswordForm) => model.newPassword)
                .required()
            .ensure((model: ChangePasswordForm) => model.confirmPassword)
                .required()
                .satisfies((value: string, object: ChangePasswordForm) => {
                    return value === object.newPassword;
                }).withMessage('Passwords do not match.')
            .on(this);
    }
}