import { autoinject } from "aurelia-framework";
import { Dto } from "project/project";
import { ValidationModuleBase } from "base-class";
import { ControllerValidateResult, validateTrigger, ValidationControllerFactory, ValidationRules } from "aurelia-validation";
import { DataService } from "services/data-service";
import { Recaptcha } from "controls/recaptcha";
import { AuthService } from "services/auth-service";
import { Router } from "aurelia-router";
import { Notifier } from "services/notifier";
import { ClientValidationError } from "shared/utils/client-validation-error";
import { getDialogVM } from "shared/controls/dialog-helper";
import { ProfileService } from "services/profile-service";

@autoinject
export class ChangePassword extends ValidationModuleBase {
    isBusy: boolean;
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;

    constructor(controllerFactory: ValidationControllerFactory,
        private readonly authService: AuthService,
        private readonly router:Router,
        private readonly profileService: ProfileService,
        private readonly notifier: Notifier) {
        
        super(controllerFactory);
    }

   activate() {
       
   }

   updatePassword() {
        this.isBusy = true;

        this.controller.validate().then((result: ControllerValidateResult) => {
            if (result.valid) {
                this.authService.updatePassword(this.oldPassword, this.newPassword).then(() => {
                    this.isBusy = false;
                    this.resetPasswordFields();

                    this.profileService.getActiveProfile(true).then(x => {
                        this.router.navigate(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Welcome])
                        this.notifier.success('Your password has been updated.');
                    })
                    
                }).catch((error: Error) => {
                    this.notifier.error(error.message);
                    this.isBusy = false;
                });
            } else {
                this.isBusy = false;
            }
        }).catch((error: Error) => {
            this.notifier.error(error.message);
            this.isBusy = false;
        });
    }    

    private resetPasswordFields() {
        this.oldPassword = "";
        this.newPassword = "";
        this.confirmPassword = "";
    }
}