import { autoinject } from "aurelia-framework";
import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";
import { RequestHandler } from "services/request-handler";
import { base64toBlob } from "shared/utils/base-64-to-blob";

let FileSaver = require('file-saver');

@autoinject
export class DownloadFileService {

    constructor(private requestHandler: RequestHandler) {
    }

    downloadFromBlob(file: SharedDto.IFileDto) {
        var blob = base64toBlob(file.fileContents, null);
        FileSaver.saveAs(blob, file.fileName);
    }

    async downloadFile(securityContext: SharedDto.IFileSecurityDto | null, fileId: number) {
        let response = await this.requestHandler.post<SharedDto.IFileSecurityDto, SharedDto.IFileDto>(`file/${fileId}/download`, securityContext);
        return this.downloadFromBlob(response);
    }

}