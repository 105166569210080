import { autoinject } from "aurelia-dependency-injection";
import { DialogController } from "aurelia-dialog";
import { ValidationControllerFactory } from "aurelia-validation";
import { ValidationModuleBase } from "base-class";
import { Notifier } from "services/notifier";
import { SharedDto } from "project/project-shared";
import { AssessmentService } from "services/assesment-service";

@autoinject
export class ConflictOfInterestModal extends ValidationModuleBase {

    comments: string;
    isBusy: boolean;

    assessmentCheckId: number;

    constructor(
        private readonly notifier: Notifier,
        validationControllerFactory: ValidationControllerFactory,
        private readonly assessmentService: AssessmentService,
        public readonly dialogController: DialogController) {
        super(validationControllerFactory)
    }

    activate(modal:{assessmentCheckId: number}) {
        this.assessmentCheckId = modal.assessmentCheckId;
    }

    attached() {

    }

    submit(){
        this.isBusy = true;

        this.assessmentService.conflictOfInterest(this.assessmentCheckId, this.comments)
        .then(()=>{
            this.notifier.success("Conflict of interest has been loged.");
            return this.dialogController.ok();
        }).catch((error: SharedDto.IErrorResponse)=>{
            this.isBusy = false;
            this.notifier.error('Unable to update');
        }).then(()=>{
            this.isBusy = false;
        });
    }
}

export class ConflictOfInterestModalModel{
    assessmentCheckId: number;
}