import { singleton } from "aurelia-framework";

@singleton()
export class ControlIdGenerator {
    private currentId;
    constructor(){
        this.currentId = 0;
    }

    getNextId():number{
        return this.currentId++;
    }
}