//this is for binding links to HREFs and avoiding them being treated as relative to the current site
export class EnsureHttpValueConverter {
    toView(value: string) {
        if (!value) { return value; }

        if(value.indexOf("https://") > -1 || value.indexOf("http://") > -1){
            return value;
        }
        return "http://" + value;
    }
}
