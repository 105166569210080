import { inject, DOM, bindable, customElement, bindingMode, Disposable, BindingEngine } from 'aurelia-framework';
import { ValidationControllerFactory } from 'aurelia-validation';

import { ValidationBase } from '../../../base-class';
import { ControlIdGenerator } from "../../utils/control-id-generator";

@inject(ValidationControllerFactory, DOM.Element, ControlIdGenerator, BindingEngine)
@customElement('yesno')
export class YesNo extends ValidationBase {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) id: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) name: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) model: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) disabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView }) yesText: string = "Yes";
    @bindable({ defaultBindingMode: bindingMode.toView }) noText: string = "No";

    private subscriptions: Disposable[] = [];

    // Note: autoinject not used.
    constructor(controllerFactory: ValidationControllerFactory,
        private readonly element: Element,
        private readonly controlIdGenerator: ControlIdGenerator,
        private readonly bindingEngine: BindingEngine) {
        super(controllerFactory);
        this.element = element;
    }

    created() {
        if (!this.id) {
            this.id = this.controlIdGenerator.getNextId().toString();
        }
    }

    attached() {
        this.subscriptions.push(
            this.bindingEngine.propertyObserver(this, "name").subscribe(
                () => {
                    this.forceUiUpdate();
                }
            )
        );
    }

    detached() {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }

    setValue(value: boolean) {
        this.model = value;
        return this.element.dispatchEvent(DOM.createCustomEvent("blur", null));
    }

    forceUiUpdate() {
        var val = this.model;
        this.setValue(!val);
        this.setValue(val);
    }

}
