import { autoinject, bindable } from "aurelia-framework";
import { Disposable, BindingEngine } from "aurelia-binding";
import { ValidationRules } from "aurelia-validation";

import { SharedDto } from "project/project-shared";
import { DataService } from "services/data-service";
import { Notifier } from "services/notifier";
import { observable } from "aurelia-binding";
import { bindingMode } from "aurelia-binding";

import { ControlIdGenerator } from 'shared/utils/control-id-generator';

type DocumentLocation = "ORIC" | "ACNC" | "Upload";

@autoinject
export class OrganisationDocument {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) model: SharedDto.ICreateUpdateOrganisationDocumentDto;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) uploadInProgress: boolean = false;
    @bindable registrations: SharedDto.Constants.RegulatoryAuthority[] = [
        SharedDto.Constants.RegulatoryAuthority.ACNC,
        SharedDto.Constants.RegulatoryAuthority.ORIC
    ];
    @bindable organisationId: number = 0;
    @bindable allowUpload: boolean | string = true;
    @bindable mandatory: boolean = true;
    @bindable fixedDocumentType: number = null;
    @bindable existingDocuments: SharedDto.IOrganisationDocumentDto[] = [];
    
    @bindable() id: string;

    documentTypesList: SharedDto.IDocumentTypeDto[];
    @observable documentLocation: DocumentLocation;

    @bindable mode: "Update" | "Add";

    acncRegistered: boolean;
    oricRegistered: boolean;

    docTypeWatcher: Disposable;
    docTypeAdditionalInfo: boolean;

    loading: boolean = true;

    constructor(
        private readonly dataService: DataService,
        private readonly bindingEngine: BindingEngine,
        private readonly controlIdGenerator: ControlIdGenerator) {
    }

    bind() {
        this.loading = true;
        if (!this.id) {
            this.id = this.controlIdGenerator.getNextId().toString();
        }

        this.allowUpload = this.allowUpload === "true" || this.allowUpload === true;
        if (!this.registrations || this.registrations.length < 1) {
            this.documentLocation = "Upload";
        }
        if (this.registrations) {
            this.acncRegistered = this.registrations.indexOf(SharedDto.Constants.RegulatoryAuthority.ACNC) > -1;
            this.oricRegistered = this.registrations.indexOf(SharedDto.Constants.RegulatoryAuthority.ORIC) > -1;
        }
        
        this.dataService.getDocumentTypes().then((response) => {
            this.documentTypesList = response;            
        }).then(() => {
            if (this.model != null) {
                this.mode = "Update";
                this.docTypeAdditionalInfo = this.additionalInfoRequired(this.model);
                if (this.model.documentFoundOnACNC) {
                    this.documentLocation = "ACNC";
                }
                else if (this.model.documentFoundOnORIC) {
                    this.documentLocation = "ORIC";
                } else {
                    this.documentLocation = "Upload";
                }
            } else {
                this.mode = "Add";
                this.model = {} as SharedDto.ICreateUpdateOrganisationDocumentDto;
                this.model.organisationId = this.organisationId;
                this.model.expiryDate = '';
                if(this.fixedDocumentType) {
                    this.model.documentTypeId = this.fixedDocumentType;
                    this.docTypeAdditionalInfo = this.additionalInfoRequired(this.model);
                }
            }
        })
        .then(() => {
            this.docTypeWatcher = this.bindingEngine.propertyObserver(this.model, "documentTypeId").subscribe(() => {
                if(!this.loading) {
                    this.docTypeAdditionalInfo = this.additionalInfoRequired(this.model);
                }
            });
        })
        .then(() => {
            ValidationRules
            .ensure((model: SharedDto.ICreateUpdateOrganisationDocumentDto) => model.documentTitle).required().when(model => this.mandatory)
            .ensure(model  => model.documentTypeId)
                .required()
                .when(model => this.mandatory)
            .ensure(model  => model.documentTypeOther).required()
                .when(model => this.additionalInfoRequired(model) && this.mandatory)

                .withMessage("Please specify further details about the type of document you are uploading.")
            .ensure(model  => model.file).required()
                .when(model => this.documentLocation === "Upload" && this.mode === "Add" && this.mandatory && <boolean>this.allowUpload)
                .withMessage("Please upload the document before proceeding.")
            .on(this.model);

        ValidationRules
            .ensure((vm: OrganisationDocument) => vm.documentLocation).required().when(model => this.mandatory)
            .on(this);
        })
        .then(() => {
            this.loading = false;            
        })
    }

    additionalInfoRequired(model: SharedDto.ICreateUpdateOrganisationDocumentDto): boolean {
        if (this.documentTypesList == null) { return false; }
        var docType = this.documentTypesList.find(x => x.id == model.documentTypeId);
        return docType && docType.additionalDetailsRequired;
    }

    documentLocationChanged(newValue?: DocumentLocation, oldValue?: DocumentLocation) {
        if (this.model == null) { return; }
        this.model.documentFoundOnACNC = (newValue === "ACNC");
        this.model.documentFoundOnORIC = (newValue === "ORIC");
        if (newValue === "ACNC" || newValue === "ORIC") {
            this.model.file = null;
        }
    }

    detached() {
        this.docTypeWatcher.dispose();
    }
}