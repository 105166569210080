import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { ControllerValidateResult, ValidationControllerFactory, ValidationRules } from "aurelia-validation";
import { ValidationModuleBase } from "base-class";
import { Notifier } from "services/notifier";
import { AuthService } from "services/auth-service";

@autoinject
export class ChangePasswordEdit extends ValidationModuleBase {

    isBusy: boolean;
    oldPassword: string = "";
    newPassword: string = "";
    confirmPassword: string = "";

    constructor(controllerFactory: ValidationControllerFactory,
        private notifier: Notifier,
        private authService: AuthService) {
        super(controllerFactory);
    }
    
    updatePassword() {
        this.isBusy = true;

        this.controller.validate().then((result: ControllerValidateResult) => {
            if (result.valid) {
                this.authService.updatePassword(this.oldPassword, this.newPassword).then(() => {
                    this.isBusy = false;
                    this.resetPasswordFields();
                    this.notifier.success('Your password has been updated.');
                }).catch((error: Error) => {
                    this.notifier.error(error.message);
                    this.isBusy = false;
                });
            } else {
                this.isBusy = false;
            }
        }).catch((error: Error) => {
            this.notifier.error(error.message);
            this.isBusy = false;
        });
    }    

    private resetPasswordFields() {
        this.oldPassword = "";
        this.newPassword = "";
        this.confirmPassword = "";
    }
}
