import { autoinject, bindable } from 'aurelia-framework';
import { SharedBroadcastEvents } from 'shared/utils/SharedBroadcastEvents';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SharedDto } from 'project/project-shared';
import { DownloadFileService } from 'shared/utils/download-file-service';

@autoinject
export class FileDisplay {
    @bindable detail;
    @bindable deleteFile;
    @bindable retry;
    @bindable isUploading;
    @bindable readonly;
    @bindable fileStorageId;
    @bindable securityContext: SharedDto.IFileSecurityDto;

    parent: any;

    constructor(private readonly eventAggregator: EventAggregator, private readonly fileService: DownloadFileService) {
    }

    bind(bindingContext, overrideContext) {
        this.parent = bindingContext;        
    }

    attached() {

    }

    async download() {
        await this.fileService.downloadFile(this.securityContext, this.fileStorageId);
        //a parent element (application-form/online-form) should handle this and trigger the download
        //this.eventAggregator.publish(SharedBroadcastEvents.downloadApplicationDocument, { fileStorageId: this.fileStorageId });
    }
}