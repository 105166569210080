import { BindingEngine, bindingMode, Disposable } from "aurelia-binding";
import { autoinject } from "aurelia-dependency-injection";
import { bindable, customElement } from "aurelia-templating";
import * as $ from 'jquery';
import "selectize";
import "./selectize-plugin-clear";

@customElement("select-input")
@autoinject
export class SelectInput {

    @bindable({ defaultBindingMode: bindingMode.toView }) options: any[] = [];
    @bindable({ defaultBindingMode: bindingMode.oneTime }) multiselect: boolean = false;

    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedValue: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedValues: any[];

    @bindable({ defaultBindingMode: bindingMode.oneTime }) displayProperty: string = "description";
    @bindable({ defaultBindingMode: bindingMode.oneTime }) valueProperty: string = "id";
    @bindable({ defaultBindingMode: bindingMode.oneTime }) placeholder: string = "";
    @bindable({ defaultBindingMode: bindingMode.oneTime }) sortProperty: string; //default on bind to displayProperty if not set

    @bindable({ defaultBindingMode: bindingMode.twoWay }) disabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) closeAfterSelect: boolean = false;

    @bindable({ defaultBindingMode: bindingMode.oneTime }) canCreateOption: boolean = false;

    selectElement: Element;
    selectizeApi: Selectize.IApi<any, any>;
    optionsObserver: Disposable;
    selectedValuesObserver: Disposable;

    constructor(private readonly bindingEngine: BindingEngine) {

    }

    focus() {
        this.selectizeApi.focus();
    }

    attached() {
        let plugins = [];
     
        plugins.push('clear_button');

        if (this.multiselect) {
            plugins.push('remove_button');
        }

        if (!this.sortProperty) {
            this.sortProperty = this.displayProperty;
        }

        $(this.selectElement).selectize({
            plugins: plugins,
            selectOnTab: true,
            allowEmptyOption: true,
            placeholder: this.placeholder,
            disabledField: 'disable',
            sortField: [{ field: this.sortProperty, direction: 'asc' }, { field: '$order' }],
            maxItems: this.multiselect ? null : 1,
            closeAfterSelect: this.closeAfterSelect,
            // persist needs to be false when users are allowed to create their own option
            // please see description of persist and create in the documentation for further reading
            // https://github.com/selectize/selectize.js/blob/master/docs/usage.md
         //   persist: this.canCreateOption ? false : true, 
            create: this.canCreateOption ? function(input) {
                return {
                    value: input,
                    text: input
                }
            } : false
        });

        this.initSelectizeApi();
       
        if (this.disabled) {
            this.selectizeApi.disable();
        }
        this.optionsChanged();

        //trigger changed events so the model gets synced with the control
        if (this.multiselect) {
            this.selectedValuesChanged();
        } else {
            this.selectedValueChanged();
        }
    }

    private initSelectizeApi() {
        this.selectizeApi = (<any>this.selectElement).selectize;
        this.selectizeApi.on('change', (value) => { this.itemSelected(value); });
        this.selectizeApi.on("item_remove", (value) => { this.itemRemoved(value); });
    }

    disabledChanged(newValue: boolean) {
        if (newValue) {
            this.selectizeApi.disable();
        } else {
            this.selectizeApi.enable();
        }
    }

    private itemSelected(value) {        
      
        if (value instanceof Array) {
            //multi select - update based on selection mutations (do not reassign array, as this could break observers)
            if (!this.selectedValues) {
                this.selectedValues = [];                
            }
            if (value.length === 0) {
                this.selectedValues.splice(0, this.selectedValues.length);                
            }
      
            //only add values that aren't already added:
            for (let newValue of value) {
           
                //if the value property is numeric, cast string back to number
                if (this.valueIsNumeric && !this.canCreateOption) {
                    newValue = +newValue;   
                }
                if (this.selectedValues.indexOf(newValue) < 0) {
                    this.selectedValues.push(newValue);
                }
            }
        } else {
            //if the value property is numeric, cast string back to number
            if (this.valueIsNumeric && value !== "") {
                value = +value;
            }
            this.selectedValue = value;
        }
    }

    private itemRemoved(value) {        
        if (this.valueIsNumeric) {
            value = +value;
        }
        if (this.multiselect) {
            if (this.selectedValues.indexOf(value) > -1) {
                this.selectedValues.splice(this.selectedValues.indexOf(value), 1);
            }
        } else {
            if (this.selectedValue !== null) {
                this.selectedValue = null;
            }
        }
    }

    selectedValueChanged() {
        //ensure is selected in the selectize control
        if ((this.selectedValue === null || this.selectedValue === undefined) && this.selectizeApi.items && this.selectizeApi.items.length > 0) {
            //new value is null/undefined and items are selected, so clear
            this.selectizeApi.clear();
        } else if (this.selectedValue !== null && this.selectedValue !== undefined) {
            // check if selectizeApi is define
            if(!this.selectizeApi) {
                return
            }
            //new value exists, add it if it isn't already
            if (this.selectizeApi.items.findIndex(x => x[this.valueProperty] === this.selectedValue) === -1) {
                //clear any old selections, then add
                this.selectizeApi.addItem(this.selectedValue, false);
            }
        }
    }

    selectedValuesChanged() {
        if (this.selectedValuesObserver) {
            this.selectedValuesObserver.dispose();
        }

        // clearing ends up clearing the clearing the selectedValues completely, if they've already been set.
        // thanks to calling 'item selected' with no values
        // this.selectizeApi.clear();
        // so lets just clear the items property.        
        this.selectizeApi.items = [];        

        //sync with selectize control
        if (this.selectedValues) {
            for (let value of this.selectedValues) {
                //sync selectize control
                let valueString = value;
                if (this.valueIsNumeric) {
                    valueString = value.toString();
                }
                if (this.selectizeApi.items.indexOf(valueString) === -1) {
                    this.selectizeApi.addItem(value, true);
                }
            }
        }
        //set up observers and handle mutations as well
        this.registerSelectedValuesObserver();
    }

    private registerSelectedValuesObserver() {
        if (this.selectedValues && this.selectedValues instanceof Array) {
            this.selectedValuesObserver = this.bindingEngine.collectionObserver(this.selectedValues).subscribe((changes) => {

                for (let change of changes) {
                    if (change.addedCount > 0) {
                        for (let i = 0; i < change.addedCount; i++) {
                            let addedValue = this.selectedValues[(change.index + i)];
                            //sync selectize control
                            if (this.selectizeApi.items.indexOf(addedValue) === -1) {
                                this.selectizeApi.addItem(addedValue, true);
                            }
                        }
                    }
                    for (let removedValue of change.removed) {
                        //sync selectize control
                        //note selectizeApi.items are strings so if using numeric values, convert to string before comparison.
                        let removedValueString = removedValue;
                        if (this.valueIsNumeric) {
                            removedValueString = removedValue.toString();
                        }
                        if (this.selectizeApi.items.indexOf(removedValueString) > -1) {
                            this.selectizeApi.removeItem(removedValue, true);
                        }
                    }
                }
            });
        }
    }

    bind() {
        //note: removing bind() will cause Changed events to trigger on init, which will break optionsChanged()
        if (!this.sortProperty) {
            this.sortProperty = this.displayProperty;
        }
        this.registerSelectedValuesObserver();
    }

    detached() {
        if (this.selectizeApi) {
            this.selectizeApi.destroy();
        }
        if (this.optionsObserver) {
            this.optionsObserver.dispose();
        }
        if (this.selectedValuesObserver) {
            this.selectedValuesObserver.dispose();
        }
    }

    optionsChanged() {
        if (!this.selectizeApi) {
            return;
        }

        this.syncOptions();
        //manage colelction observer below to listen  for mutations against newly assigned options collection
        if (this.optionsObserver) {
            this.optionsObserver.dispose();
        }
        if (this.options && this.options instanceof Array) {
            //set up a new observer if options are not null
            this.optionsObserver = this.bindingEngine.collectionObserver(this.options).subscribe((changes) => {
                for (let change of changes) {
                    if (change.addedCount > 0) {
                        //from the index they were added, cycle through and add the objects to selectize based on addedCount
                        for (let i = 0; i < change.addedCount; i++) {
                            this.selectizeApi.addOption({
                                value: this.options[change.index + i][this.valueProperty],
                                text: this.options[change.index + i][this.displayProperty]
                            });
                        }
                    }
                    for (let removedObject of change.removed) {
                        //remove them by object.value property
                        this.selectizeApi.removeOption(removedObject[this.valueProperty]);
                    }
                }
                this.selectizeApi.refreshItems();
            });
        }
    }

    private syncOptions() {
        //add options manually via API since aurelia's repeater on option gets messed up by selectize
        this.selectizeApi.clearOptions();
        if (this.options && this.options.length > 0) {
            for (let option of this.options) {
                let opt = {
                    value: option[this.valueProperty],
                    text: option[this.displayProperty]
                }
                this.selectizeApi.addOption(opt);
            }
        }
        //this.selectizeApi.refreshItems();
    }

    get valueIsNumeric(): boolean {
        if (this.options && this.options.length > 0) {
            return typeof this.options[0][this.valueProperty] == 'number';
        }
        return false;
    }
}
