import { autoinject } from "aurelia-dependency-injection";
import { bindable } from "aurelia-templating";
import "./inline-table-field.less";

@autoinject
export class InlineTableField {
    @bindable prefix: string;
    @bindable label: string;
    @bindable value: string;
}
