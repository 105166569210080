import { Router, RouteConfig, NavigationInstruction, RedirectToRoute } from "aurelia-router";
import { autoinject, PLATFORM } from "aurelia-framework";
import { AssessmentService } from "services/assesment-service";
import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";
import { ConflictOfInterestModal, ConflictOfInterestModalModel } from "./conflict-of-interest-modal";
import { DialogService } from "aurelia-dialog";
import { AuthManager } from "auth/auth-manager";

PLATFORM.moduleName('./conflict-of-interest-modal')

@autoinject
export class AssessmentsView {

    assessmentCheckId: number;
    model: Dto.Assessment.IAssessmentCheckResponseDto;


    constructor(private readonly router: Router,
        private readonly dialogService: DialogService,
        private readonly assessmentService: AssessmentService,
        private readonly authManager: AuthManager) {

    }

    canActivate(params: { assessmentCheckId: number }, routeConfig: RouteConfig, navigationInstruction: NavigationInstruction) {
        if (!this.authManager.isLoggedIn) {
            let currentRoute = navigationInstruction.parentInstruction.fragment;
            let queryString = navigationInstruction.queryString;
            return new RedirectToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Login], { redirect: currentRoute, q: queryString });
        }

        return true;
    }

    activate(params: { assessmentCheckId: number }, routeConfig: RouteConfig, navigationInstruction: NavigationInstruction) {
        this.assessmentCheckId = params.assessmentCheckId;
        return this.assessmentService.getAssessment(this.assessmentCheckId).then(data => {
            this.model = data;
        });
    }

    edit() {
        this.router.navigateToRoute("AssessmentsEdit", { assessmentCheckId: this.assessmentCheckId });
    }

    backToList() {
        this.router.navigateToRoute("AssessmentsList");
    }

    conflict() {
        var modalModel = new ConflictOfInterestModalModel();
        modalModel.assessmentCheckId = this.assessmentCheckId;

        this.dialogService.open({
            model: modalModel,
            viewModel: ConflictOfInterestModal
        }).whenClosed(result => {
            if (!result.wasCancelled) {
                this.backToList();
            }
        });
    }

    downloadHelpDocument(helpAttachment: SharedDto.IFilePropertiesDto): void {
        this.assessmentService.downloadHelpDocument(this.assessmentCheckId, helpAttachment.fileStorageId);
    }

    downloadQuestionAttachment(question: Dto.Assessment.IAssessmentCheckResponseQuestionDto): void {
        this.assessmentService.downloadQAResponseAttachment(this.assessmentCheckId, question.assessmentCheckResponseQAId);
    }

    downloadNumericQuestionAttachment(question: Dto.Assessment.IAssessmentCheckResponseNumericDto): void {
        this.assessmentService.downloadNumericResponseAttachment(this.assessmentCheckId, question.assessmentCheckResponseNumericId);
    }

    downloadOutcomeAttachment(): void {
        this.assessmentService.downloadFundingRecomendationAttachment(this.assessmentCheckId);
    }

    viewApplication() {
        window.open(this.router.generate("ApplicationView", { applicationId: this.model.applicationId }), '_blank');
    }
}