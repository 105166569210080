import { autoinject } from "aurelia-framework";

import { Dto } from "project/project";
import { RequestHandler } from "./request-handler";
import { SharedDto } from "project/project-shared";
import { DownloadFileService } from "shared/utils/download-file-service";

@autoinject
export class OrganisationDocumentService {

    constructor(private readonly requestHandler: RequestHandler, private readonly downloadFileService: DownloadFileService) {
    }

    getOrganisationDocument(documentId): Promise<SharedDto.IOrganisationDocumentDto> {
        return this.requestHandler.get<SharedDto.IOrganisationDocumentDto>(
            `profile/organisation-document/${documentId}`);
    }

    getOrganisationDocumentsByOrganisation(organisationId): Promise<SharedDto.IOrganisationDocumentDto[]> {
        return this.requestHandler.get<SharedDto.IOrganisationDocumentDto[]>(
            `profile/organisation-document/organisation/${organisationId}`);
    }

    createOrganisationDocument(model: SharedDto.ICreateUpdateOrganisationDocumentDto): Promise<void> {
        return this.requestHandler.post<SharedDto.ICreateUpdateOrganisationDocumentDto, void>('profile/organisation-document', model);
    }

    updateOrganisationDocument(model: SharedDto.ICreateUpdateOrganisationDocumentDto): Promise<void> {
        return this.requestHandler.put<SharedDto.ICreateUpdateOrganisationDocumentDto, void>(
            'profile/organisation-document', model);
    }

    deleteOrganisationDocument(organisationDocumentId: number): Promise<void> {
        return this.requestHandler.delete<void>(`profile/organisation-document/${organisationDocumentId}`);
    }

    downloadOrganisationDocument(document: SharedDto.IOrganisationDocumentDto): Promise<void> {
        return this.requestHandler.get<SharedDto.IFileDto>(`profile/organisation-document/download/${document.organisationDocumentId}`)
            .then(response => {
                this.downloadFileService.downloadFromBlob(response);
            }).catch(error => {
                console.log(error);
            });
    }
}