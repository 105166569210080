import { autoinject, Disposable } from "aurelia-framework";
import { RedirectToRoute, Router } from "aurelia-router";
import { AuthManager } from "auth/auth-manager";
import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";
import { DataService } from "services/data-service";
import { Notifier } from "services/notifier";
import { SubscriptionService } from "services/subscription-service";
import { Messages } from "utils/messages";
import { ValidationModuleBase } from "base-class";
import { ValidationControllerFactory, ValidationRules } from "aurelia-validation";
import { ClientValidationError } from "shared/utils/client-validation-error";
import { HasElementsRule } from "shared/controls/custom-validation";
import { ProfileService } from "services/profile-service";
import { ActiveProfile } from "models/active-profile";

interface ISelectedCategory extends SharedDto.ICodeListItemDto {
    selected?: boolean
}

@autoinject
export class SubcriptionEdit extends ValidationModuleBase {
    categories: ISelectedCategory[] | ISelectedCategory[];
    options: Dto.ISubscriptionOptionsDto;
    entity: "individual" | "organisation" | "both" = "both";

    watchers: Disposable[] = [];
    busy: boolean = false;

    activeProfile: ActiveProfile;

    constructor(private readonly authManager: AuthManager,
        private readonly subscriptionService: SubscriptionService,
        private readonly dataService: DataService,
        private readonly notifier: Notifier,
        private readonly router: Router,
        private readonly profileService: ProfileService,
        validationControllerFactory: ValidationControllerFactory) {
        super(validationControllerFactory, false);
    }

    canActivate(params: { unsubscribe?: boolean }, routeConfig, navigationInstruction) {
        if (!this.authManager.isLoggedIn) {
            let currentRoute = navigationInstruction.fragment;
            let queryString = navigationInstruction.queryString;
            return new RedirectToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Login], { redirect: currentRoute, q: queryString });
        }

        return true;
    }

    activate(params: { unsubscribe?: boolean, orgId?: number, individual?: boolean }, routeConfig, navigationInstruction) {
        this.options = {} as Dto.ISubscriptionOptionsDto; // stops aurelia validation issue when navigating before loading options
        let unsubscribe = params.hasOwnProperty('unsubscribe');

        // always need categories first
        return this.dataService.getCategoryByLevel(1).then(categories => {
            this.categories = categories;
        }).then(() => {
            return this.profileService.getActiveProfile().then(activeProfile => {
                this.activeProfile = activeProfile;
            });
        }).catch(() => {
            this.notifier.error("Unable to retrieve active profile.");
        }).then(() => {
            if (unsubscribe) {
                // can't use this.unsubscribeAll because it requires categories to be loaded...
                let options: Dto.ISubscriptionOptionsDto = {
                    categoryIds: [],
                    receiveEmails: false,
                    subscribeIndividual: false,
                    subscribeOrganisation: false,
                    organisationId: params.orgId
                }
                return this.subscriptionService.updateSubscriptionOptions(options);
            }
        }).then(() => {
            if (unsubscribe) {
                this.notifier.success(`${params.orgId ? 'Organisation has been' : 'You have been'} unsubscribed from all emails.`);
            }
        }).catch(() => {
            if (unsubscribe) {
                this.notifier.error(`An error occurred unsubscribing ${params.orgId ? 'organisation' : 'you'} from our emails. Please try again.`);
            }
        }).then(() => {
            if (unsubscribe) {
                // renavigate to remove the ?unsubscribe query string
                this.router.navigateToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Welcome]);
                return;
            }
            if(this.activeProfile.profileType == 'Individual')
            {
                return this.subscriptionService.getSubscriptionOptions();
            }
            return this.subscriptionService.getOrganisationSubscriptionOptions(this.activeProfile.profileIdentifier);
        }).then(subscriptionOptions => {
            if (unsubscribe) {
                return;
            }
            this.options = subscriptionOptions;
            if (subscriptionOptions.subscribeIndividual && subscriptionOptions.subscribeOrganisation) {
                this.entity = "both";
            }
            else if (subscriptionOptions.subscribeIndividual) {
                this.entity = "individual";
            }
            else if (subscriptionOptions.subscribeOrganisation) {
                this.entity = "organisation";
            }
            else {
                this.entity = "both"; // nothing means both
            }

            subscriptionOptions.categoryIds.forEach(s => {
                let category = this.categories.find(c => c.id == s);
                if (category != null) {
                    // in case the DB changes
                    category.selected = true;
                }
            });
        });
    }

    bind() {
        ValidationRules.ensure((o: Dto.ISubscriptionOptionsDto) => o.categoryIds)
            .satisfiesRule(HasElementsRule).when(o => o.receiveEmails).withMessage("Please choose at least one category")
            .on(this.options);
    }

    unsubscribeAll(): Promise<void> {
        this.categories.forEach(b => b.selected = false);
        this.options.receiveEmails = false;
        return this.save();
    }

    save(): Promise<void> {
        this.busy = true;
        this.options.categoryIds = this.categories.filter(c => c.selected).map(c => c.id);
        this.options.subscribeIndividual = this.entity == "individual" || this.entity == "both";
        this.options.subscribeOrganisation = this.entity == "organisation" || this.entity == "both";
        this.options.organisationId = this.activeProfile.profileType == 'Organisation' ? this.activeProfile.profileIdentifier : null;

        return this.controller.validate().then(result => {
            if (!result.valid) {
                throw new ClientValidationError(result);
            }
            this.notifier.clear();
            return this.subscriptionService.updateSubscriptionOptions(this.options);
        }).then(() => {
            this.notifier.success('Your subscription preferences have been updated');
        }).catch(error => {
            if (error instanceof ClientValidationError) {
                return;
            }
            this.notifier.standardMessage(Messages.serverError);
        }).then(() => {
            this.busy = false;
        });
    }

    optin() {
        if (this.options.receiveEmails && this.categories.filter(c => c.selected).length == 0) {
            this.categories.forEach(c => c.selected = true);
        }
    }
}