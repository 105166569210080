import { bindable, bindingMode, BindingEngine, Disposable, autoinject, computedFrom } from "aurelia-framework";
import { RenderedError } from "aurelia-validation";

import { SharedDto } from "project/project-shared";

@autoinject
export class NavStepCustomElement {
    @bindable({ defaultBindingMode: bindingMode.toView }) step: SharedDto.OnlineForm.Application.IFormInstanceStepDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) template: SharedDto.OnlineForm.Form.IStepDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) errorCount: number;
    sectionGroupTemplates: SharedDto.OnlineForm.Form.ISectionDto[] = [];

    @bindable selectStep; // call binding
    @bindable selectSectionGroup; // call binding

    subscriptions: Disposable[] = [];

    constructor(private readonly bindingEngine: BindingEngine){

    }

    bind() {
        this.buildNav();
        this.subscriptions.push(this.bindingEngine.propertyObserver(this.step, "sectionGroups").subscribe(() => {
            this.buildNav();
        }));
    }

    detached() {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }

    private buildNav(){
        var sgt: SharedDto.OnlineForm.Form.ISectionDto[] = [];
        // we need to match section group templates with the same index as stepInstance.sectionGroups
        for (let i = 0; i < this.step.sectionGroups.length; i++) {
            let sectionGroupInstance = this.step.sectionGroups[i];            
            sgt.push(this.template.sections.find(s => s.sectionId == sectionGroupInstance.templateSectionId));
        }
        this.sectionGroupTemplates = sgt;
    }
}
