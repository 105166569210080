import { autoinject } from "aurelia-framework";
import { DialogController } from 'aurelia-dialog';

@autoinject
export class ConfirmModal {

    message: string;
    title: string;
    okButtonText: string = "Yes"
    cancelButtonText: string = "No"
    warnings: string[];
    showCancel: boolean = true;

    constructor(public controller: DialogController) {

    }

    activate(data: { title?: string, message: string, okButtonText?: string, cancelButtonText?: string, warnings: string[], showCancel?: boolean }) {
        this.message = data.message;
        this.title = data.title || "Confirmation";
        this.warnings = data.warnings;
        if (data.okButtonText) {
            this.okButtonText = data.okButtonText;
        }
        if (data.cancelButtonText) {
            this.cancelButtonText = data.cancelButtonText;
        }
        if(data.showCancel === false) {
            this.showCancel = false;
        }
    }

    ok() {
        this.controller.ok();
    }

    cancel() {
        this.controller.cancel();
    }
}
