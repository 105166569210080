import { autoinject } from "aurelia-framework";
import { RequestHandler } from "./request-handler";
import { Dto } from "project/project";

@autoinject
export class SubscriptionService {
    constructor(private readonly requestHandler: RequestHandler) {

    }
    
    getSubscriptionOptions(): Promise<Dto.ISubscriptionOptionsDto> {
        return this.requestHandler.get<Dto.ISubscriptionOptionsDto>('subscriptions');
    }

    getOrganisationSubscriptionOptions(organisationId: number): Promise<Dto.ISubscriptionOptionsDto> {
        return this.requestHandler.get<Dto.ISubscriptionOptionsDto>(`subscriptions/organisation/${organisationId}`);
    }

    updateSubscriptionOptions(subscriptionOptions: Dto.ISubscriptionOptionsDto): Promise<void> {
        return this.requestHandler.post<Dto.ISubscriptionOptionsDto, void>('subscriptions', subscriptionOptions);
    }
}