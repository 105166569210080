import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { ControllerValidateResult, validateTrigger, ValidationControllerFactory, ValidationRules } from "aurelia-validation";
import { ValidationModuleBase } from "base-class";
import { BroadcastEvents } from "models/broadcast-events";
import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";
import { DataService } from "services/data-service";
import { Notifier } from "services/notifier";
import { ProfileService } from "services/profile-service";
import { AddressInput } from "shared/controls/address/address";
import { DateOfBirthRule, NotAPOBox, NumericRule, PersonNameRule } from "shared/controls/custom-validation";
import { ValidationError } from "shared/controls/validation-error";
import { DeepObserver } from "shared/utils/deep-observer";
import { Router } from "aurelia-router";
import { addressArrayToString } from 'shared/utils/address-utils';
import { ChangeUsername } from "account-management/manage-account/change-username";

@autoinject
export class ChangeUsernameEdit extends ValidationModuleBase {

    isBusy: boolean;
    newEmailAddress: string;
    private newUsernameRules: ValidationRules[];
    profile: Dto.IPersonalProfileDto;

    constructor(controllerFactory: ValidationControllerFactory,
        private notifier: Notifier,
        private profileService: ProfileService,
        private eventAggregator: EventAggregator) {
        super(controllerFactory);

        this.controller.validateTrigger = validateTrigger.manual;
    }

    activate(){
        var profilePromise = this.profileService.getIndividualProfile().then((data: Dto.IPersonalProfileDto) => {
            this.profile = data;
        }).catch((error: Error) => {
            this.notifier.error(error.message);
        });
    }

    attached() {
        ValidationRules
            .ensure((m: ChangeUsername) => m.newEmailAddress).email().required().maxLength(254)
            .on(this);
    }

    updateUsername() {
        this.isBusy = true;
        this.controller.validate({ rules: this.newUsernameRules, object: this }).then((result: ControllerValidateResult) => {
            if (result.valid) {
                this.profileService.updateUsername(this.newEmailAddress).then(() => {
                    this.isBusy = false;
                    this.newEmailAddress = "";
                    this.notifier.success("An email has been sent to your current email address to confirm this change.");
                }).catch((error: Error) => {
                    this.notifier.error(error.message);
                    this.isBusy = false;
                });
            } else {
                this.isBusy = false;
            }
        }).catch((error: SharedDto.IErrorResponse) => {
            this.isBusy = false;
        });
    }
}
