/// <reference path="../typings/index.d.ts"/>

import { Aurelia, PLATFORM } from "aurelia-framework";
import * as LogManager from "aurelia-logging";
import { SetupCustomValidation } from "./shared/controls/custom-validation";
import { ServerAppender } from "./utils/server-appender";
import { ConsoleAppender } from "aurelia-logging-console";

import 'selectize/dist/css/selectize.css';
import "../assets/css/splash.less";
import "../assets/css/styles.less";
import "../assets/css/animations/animations.less";

//old browser polyfills
import * as Bluebird from "bluebird";
import "whatwg-fetch";
import "element-closest";
import "console-polyfill";
import { Config } from "config/config";
import { VersionMismatchService } from "shared/utils/version-mismatch-service";
import "utils/string-insert";
import "mmenu-light";//can't use the TS files until we update typescript.
import 'array-flat-polyfill';

Bluebird.config({ longStackTraces: false /*ENV === "development"*/, warnings: false });

export async function configure(aurelia: Aurelia) {
    aurelia.use
        .standardConfiguration()
        .plugin(PLATFORM.moduleName("aurelia-animator-css"))
        .plugin(PLATFORM.moduleName("aurelia-validation"))
        .plugin(PLATFORM.moduleName("aurelia-dialog"))
        .feature(PLATFORM.moduleName("controls/index"))
        .feature(PLATFORM.moduleName("shared/controls/index"));

    SetupCustomValidation();

    await aurelia.start().then(
        () => {
            if (ENV === "development") {
                LogManager.addAppender(new ConsoleAppender());
                LogManager.setLevel(LogManager.logLevel.error);
            } else {
                LogManager.addAppender(aurelia.container.get(ServerAppender));
                LogManager.setLevel(LogManager.logLevel.error);
            }
            var mismatchService = aurelia.container.get(VersionMismatchService);
        });

    await aurelia.setRoot(PLATFORM.moduleName("shell"));
}
