import { autoinject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { Dto } from "project/project";
import { AdditionalInfoService } from "services/additional-info-service";
import { SharedDto } from "project/project-shared";

@autoinject
export class AdditionalInformationModal{
    model: Dto.AdditionalInfo.IAdditionalInformationRequestDto;
    applicationId: number;
        
    constructor(public readonly dialogController: DialogController, 
        private readonly additionalInfoService: AdditionalInfoService) {
    }

    activate(params: IAdditionalInformationModalParams){
        this.applicationId = params.applicationId; 
        return this.additionalInfoService.getRespondedAdditionalInfoRequest(this.applicationId).then((data) => {
            this.model = data;
        });
    }

    downloadDocument(document: SharedDto.IFilePropertiesDto) {
        this.additionalInfoService.downloadQuestionDocument(this.applicationId, this.model.additionalInformationRequestId, document);
    }
}
export interface IAdditionalInformationModalParams {
    applicationId: number
}