import { Logger } from "aurelia-logging/dist/aurelia-logging";
import { RequestHandler } from "services/request-handler";
import { json } from 'aurelia-fetch-client';
import { autoinject, inject } from "aurelia-dependency-injection";
import { SharedDto } from "project/project-shared";
import { AuthManager } from "auth/auth-manager";

@autoinject
export class ServerAppender {
    constructor(private readonly requestHandler: RequestHandler, private readonly authManager: AuthManager) {
        //additional handling for errors that happen outside the aurelia framework.
        window.onerror = function (message, source, line, column, error) {
            var report: SharedDto.IJavascriptError = {
                message: message as string,
                stack: error ? error.stack : '',
                url: window.location.href,
                requestedUrl: '',
                data: ''
            };

            requestHandler.post('error', report);
            console.error(error);
        };
    }

    debug(logger: Logger, data: any) {
        //do nothing
    }

    error(logger: Logger, messageOrError: string | Error, ...rest: any[]) {
        console.log(messageOrError);
        if (messageOrError instanceof Error) {
            var report: SharedDto.IJavascriptError = {
                message: messageOrError.message,
                stack: messageOrError.stack,
                url: window.location.href,
                requestedUrl: (<any>messageOrError).requestedUrl,
                data: ''
            };
            this.requestHandler.post(`error`, report);
            return;
        }

        var report: SharedDto.IJavascriptError = {
            message: messageOrError,
            stack: '',
            url: window.location.href,
            requestedUrl: '',
            data: JSON.stringify(rest)
        };
        this.requestHandler.post(`error`, report);
    }

    info(logger: Logger, data: any) {
        //do nothing
    }

    warn(logger: Logger, data: any) {
        //do nothing
    }
}