import { autoinject, computedFrom, PLATFORM } from "aurelia-framework";
import { Router, RouterConfiguration, NavigationInstruction, RedirectToRoute, RouteConfig, Next } from "aurelia-router";

import { Config } from "./config/config";
import { Dto } from "./project/project";

import { RequestHandler } from "./services/request-handler";
import { AuthManager } from "./auth/auth-manager";
import { Notifier } from "./services/notifier";
import { BreadcrumbService } from "shared/controls/breadcrumb/breadcrumb-service";
import { ProfileService } from "services/profile-service";
import { ROUTE_APPLICATIONS, ROUTE_GRANTS_DIRECTORY, ROUTE_VOUCHERS } from "utils/router-constants";
import { SupplierRoute } from "grants/suppliers/supplier-router";

@autoinject
export class Shell {

    router: Router;
    environmentLabel: string = ReleaseInfo.environmentLabel;
    appVersion: string = ReleaseInfo.version;
    appLastUpdated: Date = ReleaseInfo.lastUpdated;

    constructor(private notifier: Notifier,
        private breadCrumbService: BreadcrumbService//No not remove this. Its needed here so that the service can be set up and listen to the first route event. 
    ) {

        breadCrumbService.homeRoute = Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Welcome];
    }

    attached() {
        //todo: can probably be replaced by an event published by event aggregator to tell the notifier that aurelia has loaded an initial page.
        this.notifier.viewLoaded();
    }

    @computedFrom("router.isNavigating", "this.router.currentInstruction.config.name")
    get showNavLoader(): boolean {
        return this.router.isNavigating
            && this.router.currentInstruction.config.name !== Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Welcome];
    }

    configureRouter(config: RouterConfiguration, router: Router) {

        var layoutView = PLATFORM.moduleName("layouts/default-layout.html"); // has to be relative to src/
        var layoutViewModel = PLATFORM.moduleName("layouts/default-layout"); // has to be relative to src/

        config.title = Config.appName;
        config.options.pushState = true;

        config.addPipelineStep('authorize', GrantsAuthorizeStep);
        config.addPipelineStep('postRender', GrantsPostNavigationRender);

        // put all top level routes in a variable so we can easily convert sub-routes to top level routes
        // might be also a good idea to break this huge list of routes to their individual folders
        let rts = (<RouteConfig[]>([
            {
                route: ["", "welcome"],
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Welcome],
                nav: false,
                //title: "GrantsNT",
                moduleId: PLATFORM.moduleName("./welcome", "grants-directory"),
                settings: {
                    auth: false
                }
            },
            {
                name: ROUTE_GRANTS_DIRECTORY,
                route: "grants",
                nav: true,
                title: "Search",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./grants/router", "grants-directory"),
                settings: {
                    breadcrumb: true,
                    //parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Welcome],
                    auth: false
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.FAQ],
                route: "/help/FAQ",
                nav: false,
                title: "Frequently Asked Questions",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/FAQ", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Help],
                    breadcrumb: true,
                    auth: false
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.HelpIntroduction],
                route: "/help/introduction",
                nav: false,
                title: "GrantsNT Introduction",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/introduction", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Help],
                    breadcrumb: true,
                    auth: false
                }
            },
            //Help Setup Section
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.SetupAccount],
                route: "/help/setupAccount",
                nav: false,
                title: "Setup Account",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/setup/setupLanding", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Help],
                    breadcrumb: true,
                    auth: false
                }
            },
            // {
            //     name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.AccountRegistration],
            //     route: "/help/accountRegistration",
            //     nav: false,
            //     title: "Account Registration",
            //     layoutView: layoutView,
            //     layoutViewModel: layoutViewModel,
            //     moduleId: PLATFORM.moduleName("./help/help/setup/accountRegistration", "grants-directory"),
            //     settings: {
            //         parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.SetupAccount],
            //         breadcrumb: true,
            //         auth: false
            //     }
            // },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ManageProfileAccess],
                route: "/help/manageProfileAccess",
                nav: false,
                title: "Manage profile access",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/setup/manageProfileAccess", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.SetupAccount],
                    breadcrumb: true,
                    auth: false
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.CreateManageOrgAccess],
                route: "/help/createOrganisationProfile",
                nav: false,
                title: "Create organisation profile",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/setup/createOrgProfile", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.SetupAccount],
                    breadcrumb: true,
                    auth: false
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.OrgUserAccess],
                route: "/help/orgUserAccess",
                nav: false,
                title: "Organisation user access",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/setup/orgUserAccess", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.SetupAccount],
                    breadcrumb: true,
                    auth: false
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.JoinAnOrg],
                route: "/help/joinAnOrganisation",
                nav: false,
                title: "Joining an organisation",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/setup/joinAnOrg", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.SetupAccount],
                    breadcrumb: true,
                    auth: false
                }
            },
            //Apply for grant section
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.GrantApplication],
                route: "/help/grantApplications",
                nav: false,
                title: "Apply for Grant",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/applyForGrant/applyForGrantLanding", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Help],
                    breadcrumb: true,
                    auth: false
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.GrantSearch],
                route: "/help/grantSearching",
                nav: false,
                title: "Search NTG Grants",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/applyForGrant/grantSearch", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.GrantApplication],
                    breadcrumb: true,
                    auth: false
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.GrantEnquiry],
                route: "/help/grantEnquiry",
                nav: false,
                title: "Enquire on a Grant",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/applyForGrant/grantEnquiry", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.GrantApplication],
                    breadcrumb: true,
                    auth: false
                }
            },

            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Subscription],
                route: "/help/grantSubscriptions",
                nav: false,
                title: "Subscription",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/applyForGrant/grantSubscription", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.GrantApplication],
                    breadcrumb: true,
                    auth: false
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.SubmitApplication],
                route: "/help/applyForGrant",
                nav: false,
                title: "Apply for a grant",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/applyForGrant/applyForGrant", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.GrantApplication],
                    breadcrumb: true,
                    auth: false
                }
            },
            //Manage Grants
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ManageGrant],
                route: "/help/manageGrants",
                nav: false,
                title: "Manage Grant",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/manageGrant/manageGrantLanding", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Help],
                    breadcrumb: true,
                    auth: false
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ApplicationManagement],
                route: "/help/applicationManagement",
                nav: false,
                title: "Application Management",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/manageGrant/applicationManagement", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ManageGrant],
                    breadcrumb: true,
                    auth: false
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ViewAwardedGrants],
                route: "/help/viewAwardedGrants",
                nav: false,
                title: "Application Management",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/manageGrant/viewAwarded", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ManageGrant],
                    breadcrumb: true,
                    auth: false
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Reports],
                route: "/help/reporting",
                nav: false,
                title: "Reporting and acquittal",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/manageGrant/reporting", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ManageGrant],
                    breadcrumb: true,
                    auth: false
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.UpdateCorrespondence],
                route: "/help/correspondence",
                nav: false,
                title: "Correspondence",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/manageGrant/correspondence", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ManageGrant],
                    breadcrumb: true,
                    auth: false
                }
            }, {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ROI],
                route: "/help/requestForInformation",
                nav: false,
                title: "Agency Requests for Information",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/manageGrant/roi", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ManageGrant],
                    breadcrumb: true,
                    auth: false
                }
            },
            //Computer help
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.PasswordAndComputerProblems],
                route: "/help/PasswordAndComputerProblems",
                nav: false,
                title: "Password and Computer Problems",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help/compHelp/compHelp", "grants-directory"),
                settings: {
                    parent: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Help],
                    breadcrumb: true,
                    auth: false
                }
            },






            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.OrganisationInviteAccept],
                route: "profile/organisation-invite/:organisationUserId/accept/:token",
                nav: false,
                title: "Organisation Invite",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./profile/organisation/invite-response", "org-invite"),
                settings: {
                    acceptingInvite: true,
                    breadcrumb: true
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.OrganisationInviteReject],
                route: "profile/organisation-invite/:organisationUserId/reject/:token",
                nav: false,
                title: "Organisation Invitation",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./profile/organisation/invite-response", "org-invite"),
                settings: {
                    acceptingInvite: false,
                    breadcrumb: true
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Login],
                route: "login",
                nav: false,
                title: "Login",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./login-registration/login", "login-registration"),
                settings: {
                    breadcrumb: true,
                    auth: false
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.RegisterRequest],
                route: "register-request",
                nav: false,
                title: "Register",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./login-registration/register-request", "login-registration"),
                settings: {
                    breadcrumb: true,
                    auth: false
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Register],
                route: "register",
                nav: false,
                title: "Register",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./account-registration/registration/registration", "login-registration"),
                settings: {
                    breadcrumb: true,
                    auth: false
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.RecoverPassword],
                route: "recover-password",
                nav: false,
                title: "Recover Password",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./login-registration/recover-password", "login-registration"),
                settings: {
                    breadcrumb: true,
                    auth: false
                }
            },
            /*     {
                     name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Register],
                     route: "register/:registrationCode",
                     nav: false,
                     title: "Registration",
                     layoutView: layoutView,
                     layoutViewModel: layoutViewModel,
                     moduleId: PLATFORM.moduleName("./login-registration/register", "login-registration"),
                     settings: { breadcrumb: true }
                 }, */
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ResetPassword],
                route: "reset-password/:id",
                nav: false,
                title: "Reset Password",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./account-management/password-reset", "login-registration"),
                settings: { breadcrumb: true }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ProfileSelect],
                route: "profile-select",
                nav: false,
                title: "Select Profile",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./login-registration/profile-select", "login-registration"),
                settings: { breadcrumb: true }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ManageAccount],
                route: "account",
                nav: false,
                title: "Manage Account",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./account-management/manage", "profile-management"),
                settings: { breadcrumb: true }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ManageAccountChangeUsernameConfirm],
                route: "confirm-new-username/:token",
                nav: false,
                title: "Confirm New Username",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./account-management/manage-account/confirm-new-username", "profile-management"),
                settings: {}
            },
            {
                route: ['change-password'], 
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ChangePasswordRequired],
                moduleId: PLATFORM.moduleName("./login-registration/change-password"),
                title: "Change password",
                nav: false,
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                settings: {
                    breadcrumb: true,
                    auth: true
                }
            },
            {
                name: ROUTE_APPLICATIONS,
                route: "manage",
                nav: true,
                title: "Applications",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./manage/manage", "applications"),
                settings: {
                    breadcrumb: false, auth: true
                }
            },
            {
                name: ROUTE_VOUCHERS,
                route: "vouchers",
                nav: true,
                title: "Vouchers",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("vouchers/router", "vouchers"),
                settings: {
                    breadcrumb: false, auth: true
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.AssessorInviteAccept],
                route: "profile/assessor-invite/:externalAssessorId/accept/:token",
                nav: false,
                title: "Assessor Invitation",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./account-management/assessor/assessor-invite-response", "assessor-invite"),
                settings: {
                    acceptingInvite: true
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.AssessorInviteDecline],
                route: "profile/assessor-invite/:externalAssessorId/decline/:token",
                nav: false,
                title: "Assessor Invitation",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./account-management/assessor/assessor-invite-response", "assessor-invite"),
                settings: {
                    acceptingInvite: false
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.IndividualProfile],
                route: "account",
                nav: false,
                title: "My account",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("profile/individual/detail"),
                settings: { breadcrumb: true }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.OrganisationProfile],
                route: "profile/organisation/:organisationId",
                nav: false,
                title: "Organisation Profile",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("profile/organisation/organisation-profile", "organisation-management"),
                settings: { breadcrumb: true, create: true }
            },
            {
                name: "Assessments",
                route: "assessments",
                nav: false,
                title: "Assessments",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("assessments/assessments"),
                settings: { breadcrumb: true, create: true }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Help],
                route: "help",
                nav: true,
                title: "Help",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("./help/help", "grants-directory"),
                settings: {
                    breadcrumb: true,
                    auth: false
                }
            },
            {
                name: Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.IndividualSubscriptions],
                route: "profile/subscriptions",
                nav: false,
                title: "Subscriptions",
                layoutView: layoutView,
                layoutViewModel: layoutViewModel,
                moduleId: PLATFORM.moduleName("profile/subscriptions"),
                settings: { breadcrumb: true, create: true }
            },
           
        ]));

        rts = rts.concat(SupplierRoute);

        config.map(rts);

        config.mapUnknownRoutes('./welcome');
        config.fallbackRoute('');
        this.router = router;
    }
}

@autoinject
class GrantsAuthorizeStep {

    constructor(
        private readonly requestHandler: RequestHandler,
        private readonly authManager: AuthManager,
        private readonly profileService: ProfileService,
        private readonly router: Router,
        private readonly notifier: Notifier) {
    }

    run(navigationInstruction: NavigationInstruction, next: Next): Promise<any> {
        this.requestHandler.nextRouteInstruction = navigationInstruction;

        var currentRoute = navigationInstruction.fragment;
        var queryString = navigationInstruction.queryString;

        if (!navigationInstruction.config.settings) {
            return next();
        }

        // Removed this, we will need to check everytime when the user is login and needs to change password
        // the code below by pass that process if a page do not require auth
        //    if (navigationInstruction.config.settings.auth === false) {
        //        return next();
        //    }

        if (navigationInstruction.config.settings.auth === true && !this.authManager.isLoggedIn) {
            this.notifier.warning("You need to log in to view this page.");
            return next.cancel(new RedirectToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Login], { redirect: currentRoute, q: queryString }));
        }

        return this.profileService.getActiveProfile().then((profile) => {
            if (this.authManager.isLoggedIn
                && profile.profileAccountStatus == Dto.Constants.AccountStatus.ChangePassword 
                && navigationInstruction.config.name != Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ChangePasswordRequired]) {         
                this.notifier.error("Password change required");
                return next.cancel(new RedirectToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ChangePasswordRequired]))
            }
            
            if (profile != null && profile.profileType == "Organisation") {
                var canAccess = true;
                var navigation = navigationInstruction.getAllInstructions();

                navigation.some((item) => {
                    var page = Dto.Constants.ExternalPageRoutes[item.config.name];
                    if (page != null && profile.allowedRoutes && profile.allowedRoutes.indexOf(page) == -1) {
                        canAccess = false;
                        return true;
                    }
                });

                if (!canAccess) {
                    if (this.authManager.isLoggedIn) {
                        this.notifier.error("An unexpected error occurred while loading the page.");
                        return next.cancel(new RedirectToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Welcome]));
                    } else {
                        this.notifier.clear();
                        this.notifier.error('Your session has expired and you will need to login again');
                        return next.cancel(new RedirectToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.Login], { redirect: currentRoute, q: queryString }));
                    }
                } else {
                    return next();
                }
            } else {
                return next();
            }
        });
    }
}

class GrantsPostNavigationRender {
    run(navigationInstruction: NavigationInstruction, next: Next) {

        //some jQuery to hide the main nav collapse when navigation completes. Otherwise the user would have to do it
        var mainNavCollapseQuery = $("#main-nav-collapse");
        if (mainNavCollapseQuery.length > 0) {
            var mainNavCollapse = mainNavCollapseQuery[0];
            if (mainNavCollapse.classList.contains("in")) {
                mainNavCollapse.classList.remove("in");
            }
        }

        //also scroll to the top of the page.
        window.scrollTo(0, 0);

        return next();
    }
}
