export class Message {
    constructor(public message: string, public type: MessageType) { }
}

export enum MessageType {
    Success,
    Info,
    Warning,
    Error
}

export class Messages {

    static get recordUpdatedSuccess(): Message {
        return new Message("Record successfully updated.", MessageType.Success);
    }

    static get recordCreatedSuccess(): Message {
        return new Message("Record created successfully.", MessageType.Success);
    }

    static get recordDeletedSuccess(): Message {
        return new Message("Record successfully deleted.", MessageType.Success);
    }

    static get unableToUpdate(): Message {
        return new Message("Unable to update record.", MessageType.Error);
    }

    static get validationHasErrors(): Message {
        return new Message("There were errors validating this form. Please correct any errors and try again.", MessageType.Error);
    }

    static get serverError(): Message {
        return new Message("An unexpected error has occurred.", MessageType.Error);
    }

    static get downloadFailed(): Message {
        return new Message("The request to download the document failed.", MessageType.Error);
    }

    // TODO: Make sure this isn't used anywhere before the end arrives :)
    static get notImplementedYet(): Message {
        return new Message("Not implemented yet...", MessageType.Info);
    }
    
}
