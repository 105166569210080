export class LengthValueConverter {
    toView(value: string, length: number = 100) {
        if (value == null) {
            return value;
        }
        if (value.length > length) {
            value = value.substring(0, length) + '...';
        }
        return value;
    }
}
