import { autoinject } from "aurelia-framework";
import { ApplicationService } from "services/application-service";
import { Dto } from "project/project";
import { ApplicationFormCustomElement } from "shared/controls/online-form/application-form";
import { ApplicationFormService } from "services/application-form-service";
import { PermissionError } from "shared/controls/permission-error";
import { Notifier } from "services/notifier";
import { SharedDto } from "project/project-shared";

@autoinject
export class ApplicationView {

    applicationId: number;

    existingApplication: Dto.Application.IExternalExistingApplicationDto;
    applicationForm: ApplicationFormCustomElement;

    isGeneratingPdf: boolean = false;

    constructor(private readonly applicationService: ApplicationService,
        private readonly applicationFormService: ApplicationFormService,
        private readonly notifier: Notifier) {
    }

    activate(params: { applicationId: number }) {
        let promises = [];

        this.applicationId = params.applicationId;

        this.applicationFormService.isIndividual = true;

        promises.push(
            this.applicationService.getExistingApplication(this.applicationId).then(form => {
                this.existingApplication = form;
            }).catch(error => {
                if (error instanceof PermissionError) {
                    this.notifier.error("You do not have the required permissinos to view this application.")
                }
                throw error;
            })
        );

        return Promise.all(promises);
    }

    exportAsPdf() {
        this.isGeneratingPdf = true;
        this.applicationService.downloadApplicationAsPdf(this.applicationId).then(() => {
            this.isGeneratingPdf = false;
        });
    }
    

    findSystemQuestionTemplate(code: SharedDto.Constants.SystemQuestion): SharedDto.OnlineForm.Form.IQuestionDto {
        var temp = [].concat.apply([], this.existingApplication.formTemplate.steps.map(x => x.sections.map(y => y.questions)));
        var templateQuestions = [].concat.apply([], temp);
        var questionTemplate = (templateQuestions as SharedDto.OnlineForm.Form.IQuestionDto[])
            .find(x => x.systemQuestion == code)

        return questionTemplate;
    }

    findQuestionAnswer(questionId) {
        for (var step of this.existingApplication.applicationForm.formInstance.steps) {
            for(var sg of step.sectionGroups) {
                for(var sec of sg.sections) {
                    for(var qg of sec.questionGroups) {
                        for(var q of qg.questions) {
                            if(q.templateQuestionId == questionId) {
                                return q;
                            }
                        }
                    }
                }
            }
        }
    }
}
