import { inject, DOM, bindable, customElement, bindingMode, computedFrom } from 'aurelia-framework';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { SharedDto } from "project/project-shared";
import { QuestionBase } from "../question-base";
import { ControlIdGenerator } from "../../../../utils/control-id-generator";

@customElement('form-yesno-toggle')
export class FormYesNoToggle extends QuestionBase {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.IYesNoAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;

    constructor(private controller: ValidationController, private controlIdGenerator: ControlIdGenerator) {
        super(controlIdGenerator);
    }

    bind() {
        super.bind();
    }
    
    attached(){
        super.attached();
    }

    setupValidation(){
        this.controller.removeObject(this.questionInstance);
        
        let rules = [];
        rules.push(ValidationRules
            .ensure((model:  SharedDto.OnlineForm.Application.IYesNoAnswerDto) => model.answer).required()
                .when(()=> this.visibility && this.onSubmitValidation && this.questionTemplate.mandatory)
                .rules[0]);

        if(rules.length>0){
            this.controller.addObject(this.questionInstance,rules);
        }
    }
}
