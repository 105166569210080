import { autoinject } from "aurelia-dependency-injection";
// this needs a factory, so only inject the aurelia stuff, the rest is provided later

@autoinject
export class FormNavigationServices {
    
    getNextIndex(buttonNavigationState: IButtonNavigation, hasIntroduction: boolean):number {
        return hasIntroduction ? buttonNavigationState.nextStepIndex + 1 : buttonNavigationState.nextStepIndex;;
    }

    getCurrentIndex(hasIntroduction:boolean, nextIndex?:number, previousIndex?:number):number {
        if(hasIntroduction) {
            if(nextIndex) return nextIndex + 1;
            if(previousIndex) return previousIndex;
        } else {
            if(nextIndex) return nextIndex;
            if(previousIndex) return previousIndex;
        }
    }

    highlightFirstStep(sideBar: JQuery) {
        if(sideBar) {
            sideBar[0].classList.add('nav-step');
        }
    }

    highlightLastStep(sideBar: JQuery) {
        sideBar[sideBar.length - 1].classList.add('nav-step');
    }

    highlightNextStep(buttonNavigationState: IButtonNavigation, sideBar: JQuery, hasIntroduction: boolean) {
        if (!buttonNavigationState.next) {
            return;
        }

        this.removeAllHightlight(sideBar);

        let nextIndex = this.getNextIndex(buttonNavigationState, hasIntroduction); 
        if (buttonNavigationState.next == "Introduction") {
            this.highlightFirstStep(sideBar)
        } else if (buttonNavigationState.next == "Declaration") {
            this.highlightLastStep(sideBar)
        } else if(buttonNavigationState.next == "RequiredDocuments") {
            sideBar[sideBar.length -2].classList.add('nav-step');
        }
        else {
            sideBar[nextIndex].classList.add('nav-step');
        }
    }

    highlightPreviousStep(buttonNavigationState: IButtonNavigation, sideBar: JQuery) {
        if (!buttonNavigationState.previous) {
            return;
        }

        this.removeAllHightlight(sideBar);

        if (buttonNavigationState.previous == "Introduction") {
            this.highlightFirstStep(sideBar)
        } else if (buttonNavigationState.previous == "Declaration") {
            this.highlightLastStep(sideBar)
        } else if(buttonNavigationState.previous == "RequiredDocuments") {
            sideBar[sideBar.length -2].classList.add('nav-step');
        } else {
            sideBar[buttonNavigationState.previousStepIndex + 1].classList.add('nav-step');
        }
    }

    highlightJumpStep(buttonNavigationState: IButtonNavigation, sideBar: JQuery, hasIntroduction: boolean) {
        let nextIndex = this.getNextIndex(buttonNavigationState, hasIntroduction);
        let previousIndex = buttonNavigationState.previousStepIndex + 1;
        let index = this.getCurrentIndex(hasIntroduction, nextIndex, previousIndex);
       
        this.removeAllHightlight(sideBar);
        if (buttonNavigationState.previous == "Step" && buttonNavigationState.next == "Step") {
            sideBar[nextIndex - 1].classList.add('nav-step');
        } else if(buttonNavigationState.previous == "Step" && buttonNavigationState.next == "Declaration") {
            if(hasIntroduction)
            sideBar[index + 1].classList.add('nav-step');
            else
            sideBar[index].classList.add('nav-step');
        } else if( (buttonNavigationState.previous == "Introduction" || buttonNavigationState.previous == null) && buttonNavigationState.next == "Step") {
            if(sideBar)
            sideBar[nextIndex - 1].classList.add('nav-step');
        } 
    }

    removeAllHightlight(sideBar: JQuery) {
        if(sideBar) {
            let stepCount = sideBar.length;
            for (var i = 0; i < stepCount; i++) {
                sideBar[i].classList.remove('nav-step')
            }
        }
    }
}

interface IButtonNavigation {
    previous: "Introduction" | "RequiredDocuments" | "Declaration" | "Step" | null;
    previousStepIndex?: number;
    next: "Introduction" | "RequiredDocuments" | "Declaration" | "Step" | null;
    nextStepIndex?: number;
}
