// From https://www.danyow.net/google-recaptcha-with-aurelia/

import {inject, noView, bindable} from 'aurelia-framework';

const recaptchaCallbackName = 'setRecaptchaReady';
const ready = new Promise((resolve) => {
    return window[recaptchaCallbackName] = resolve;
});

declare var grecaptcha: any;

// https://developers.google.com/recaptcha/docs/display#explicit_render
let script: any = document.createElement('script');
script.src = `https://www.google.com/recaptcha/api.js?onload=${recaptchaCallbackName}&render=explicit`;
script.async = true;
script.defer = true;
document.head.appendChild(script);

@noView()
@inject(Element)
export class Recaptcha {

    element: Element;

    @bindable verified;
    @bindable theme = 'light';
    @bindable sitekey;

    private widgetId: any;
    private isSet: boolean;

    constructor(element: Element) {
        this.element = element;
        this.isSet = false;
    }

    attached() {
        if (this.sitekey != null) {
            this.init();
        }
    }

    reset() {
        if (this.isSet && this.widgetId != null) {
            grecaptcha.reset(this.widgetId);
            this.isSet = false;
        }
    }

    private init() {

        ready.then(() => {
            this.widgetId = grecaptcha.render(this.element, {
                'sitekey': this.sitekey,
                'theme': this.theme,
                'callback': (data: string) => {
                    this.isSet = true;
                    this.verified(data);
                },
                'expired-callback': () => {
                    this.verified("");
                }
            });
            return this.widgetId;
        });
    }
}
