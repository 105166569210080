import { bindable, bindingMode, autoinject } from 'aurelia-framework';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { SharedDto } from 'project/project-shared';
import { QuestionBase } from "../question-base";
import { ControlIdGenerator } from 'shared/utils/control-id-generator';
import { DateFormatValueConverter } from 'shared/value-converters/date-format-value-converter';
import { DateComparison } from 'shared/controls/custom-validation';

@autoinject
export class FormDateRange extends QuestionBase {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) questionInstance: SharedDto.OnlineForm.Application.IDateRangeAnswerDto;
    @bindable({ defaultBindingMode: bindingMode.toView }) readonly: boolean = false;

    constructor(private controller: ValidationController, private controlIdGenerator: ControlIdGenerator, private dateValueConverter: DateFormatValueConverter) {
        super(controlIdGenerator);
    }

    bind() {
        super.bind();
    }

    attached() {
        super.attached();
    }

    detached() {
        ValidationRules.off(this.questionInstance);
    }

    setupValidation() {
        this.controller.removeObject(this.questionInstance);

        var builder = ValidationRules
                .ensure((model: SharedDto.OnlineForm.Application.IDateRangeAnswerDto) => model.dateFrom)
                .required()
                .when(model => this.visibility && this.onSubmitValidation && this.isMandatory)

                .ensure(model => model.dateTo)
                .required()
                .when(model => this.visibility && this.onSubmitValidation && this.isMandatory)

                .ensure(m => m.dateFrom)
                .satisfiesRule(DateComparison, "<=", o => o.dateTo, "Date To")
                .when(model => this.visibility);

        var minDateRule = this.questionTemplate.configurationOptions.find(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.MinDate);
        if (minDateRule) 
        {
            builder.ensure((model: SharedDto.OnlineForm.Application.IDateRangeAnswerDto) => model.dateFrom)
                    .satisfies((value, model) => {
                        if (!model.dateFrom) {
                            return true;//validation doesnt fire if field is empty
                        }
                        var minDate = new Date(minDateRule.dateValue);
                        var valueEntered = new Date(model.dateFrom);
                        return minDate.getTime() < valueEntered.getTime();
                    })
                    .when((x) => this.visibility)
                    .withMessage("Date must be after " + this.dateValueConverter.toView(minDateRule.dateValue, 'date'))
        }

        var maxDateRule = this.questionTemplate.configurationOptions.find(x => x.configurationType == SharedDto.Constants.QuestionConfigurationType.MaxDate);
        if (maxDateRule) 
        {
                builder.ensure((model: SharedDto.OnlineForm.Application.IDateRangeAnswerDto) => model.dateTo)
                    .satisfies((value, model) => {
                        if (value == null) { return true; }
                        var maxDate = new Date(maxDateRule.dateValue);
                        var valueEntered = new Date(model.dateTo);
                        return maxDate.getTime() > valueEntered.getTime();
                    })
                    .when(modal => this.visibility && maxDateRule != null && this.onSubmitValidation)
                    .withMessage("Date must be before " + this.dateValueConverter.toView(maxDateRule.dateValue, 'date'))
        }

        builder.on(this.questionInstance);
    }
}
