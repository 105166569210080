export const DurationSignalUpdate: string = "duration-signal";

export class DurationValueConverter {
    signals = [DurationSignalUpdate];

    toView(value: Date) {
        if (!value) {
            return "";
        }

        const difference = this.calculateDifference(value);

        if(difference <= 1) {
            return "just now";
        } else if(difference <= 60 && difference >= 2) { // difference is under a minutes
            return difference + " mins ago"
        } else if(difference <= 1440  && difference >= 61) { // difference is under a hour
            return this.toHours(difference) + " hours ago"
        } else if(difference <= 43200  && difference >= 1441) { // difference is under a day
            return this.toDays(difference) + " days ago"
        } else if(difference <= 518400 && difference >= 43201) { // difference is under a month
            return this.toMonths(difference) + " months ago"
        } else if(difference >= 518401) { // difference is greater than a year
            return this.toYears(difference) + " years ago"
        }
    }
    
    private calculateDifference(value: Date) {
        var dt1 = new Date(value);    
        var now = new Date();

        return this.timeDiff(dt1, now);
    }

    // get difference in minutes between to datetime 
    private timeDiff(dt1: Date, dt2: Date) {
        // To calculate the time difference of two dates
        let diffTime = Math.abs((dt2.getTime() - dt1.getTime()) / (1000 * 60));

        return Math.round(diffTime);
    }

    // convert minutes to hours
    private toHours(minutes:number): number {
        let hour = Math.ceil(minutes / 60);

        return Math.round(hour);
    }

    // convert minutes to days
    private toDays(minutes:number) {
        let day = Math.ceil(minutes / (60 * 24));

        return Math.round(day);
    }

    // convert minutes to months
    private toMonths(minutes:number) {
        let month = Math.ceil(minutes / ((60 * 24) * 30));
    
        return Math.round(month);
    }

    // convert minutes to years
    private toYears(minutes:number) {
        let year = Math.ceil(minutes / ((60 * 24) * 30) * 12);
    
        return Math.round(year);
    }
}