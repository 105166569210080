import { SharedDto } from "project/project-shared";

export function addressToSingleLineString(address: SharedDto.OnlineForm.Application.IAddressAnswerDto,
    states: SharedDto.ICodeListItemDto[], countries: SharedDto.ICodeListItemDto[]) {

    if (!address) {
        return "";
    }

    let result = address.line1;

    if (address.line2) {
        result += ", " + address.line2;
    }

    if (address.suburb) {
        result += ", " + address.suburb;
    }

    if (address.countryId) {
        let country = countries.find(x => x.id === address.countryId);
        if (country && country.code !== "au" && address.nonAustralianState) {
            result += ", " + address.nonAustralianState;
            result += ", " + country.description;
        }
        if (country && country.code === "au") {
            let state = states.find(x => x.id === address.australianStateTerritory);
            if (state) {
                result += ", " + state.description;
            }
            result += ", " + country.description;
        }
    }
    if (address.postCode) {
        result += " " + address.postCode;
    }

    return result;
}

export function addressArrayToString(address: SharedDto.IAddressDto) {
    if (!address) {
        return "";
    }

    let result = address.line1;

    if (address.line2) {
        result += ", " + address.line2;
    }

    if (address.suburb) {
        result += ", " + address.suburb;
    }

    if (address.australianStateTerritoryDescription) {
        result += ", " + address.australianStateTerritoryDescription;
    }

    if (address.countryDescription) {
        result += " " + address.countryDescription;
    }

    if (address.postcode) {
        result += " " + address.postcode;
    }

    return result;
}
