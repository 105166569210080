import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { Router } from "aurelia-router";

@autoinject
export class LeftSidebar {
    @bindable() theRouter: Router;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) toggle:boolean;

    offCanvasElement: HTMLElement;
    drawer: any;

    attached() {
        var menu = new MmenuLight(this.offCanvasElement);
        this.drawer = menu.offcanvas({ position: 'left' });
    }

    toggleChanged(newValue:boolean) {
        newValue ?  this.openFilter() : "";
    }

    openFilter(){
        this.drawer.open();
    }

}