import { autoinject } from "aurelia-dependency-injection";
import { Dto } from "project/project";
import { NumericRule } from "shared/controls/custom-validation";
import { ValidationRules, ValidationControllerFactory } from "aurelia-validation";
import { ValidationModuleBase } from "base-class";
import { Notifier } from "services/notifier";
import { Router } from "aurelia-router";
import { Logger } from "aurelia-logging";
import { SharedDto } from "project/project-shared";
import { ProfileService } from "../../../../services/profile-service";

@autoinject
export class OrganisationBankDetailsEdit extends ValidationModuleBase {

    bankDetailsId: number;
    isNew: boolean = false;
    saving: boolean = false;
    model: SharedDto.IBankDetailsDto;

    constructor(private readonly validationControllerFactory: ValidationControllerFactory,
        private readonly profileService: ProfileService,
        private readonly logger: Logger,
        private readonly notifier: Notifier,
        private readonly router: Router) {
        super(validationControllerFactory)
    }

    activate(params: {bankDetailsId: number | "new" }) {
        if (params.bankDetailsId === "new") {
            this.isNew = true;
            this.model = {
                description: null,
                accountNumber: null,
                accountName: null,
                bsb: null,
                branchLocation: null,
                bankInstitution: null,
                bankDetailsId: null
            };
        } else {
            this.bankDetailsId = +params.bankDetailsId;
            return this.profileService.getIndividualBankDetailsById(this.bankDetailsId).then(data => {
                this.model = data;
            });
        }
    }

    attached() {
        ValidationRules
            .ensure((bt: SharedDto.IBankDetailsDto) => bt.description).maxLength(400)
            .ensure((bt: SharedDto.IBankDetailsDto) => bt.accountNumber).displayName("Account Number").required().maxLength(9).satisfiesRule(NumericRule)
            .ensure((bt: SharedDto.IBankDetailsDto) => bt.accountName).displayName("Account Name").required()
            .ensure((bt: SharedDto.IBankDetailsDto) => bt.bsb).displayName("BSB").required().maxLength(6).satisfiesRule(NumericRule)
            .ensure((bt: SharedDto.IBankDetailsDto) => bt.branchLocation).displayName("Branch Location").required().maxLength(400)
            .ensure((bt: SharedDto.IBankDetailsDto) => bt.bankInstitution).displayName("Financial Institution").required().maxLength(30)
            .on(this.model)
    }

    cancel() {
        this.router.navigateToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.IndividualBankDetailsList]);
    }

    save() {
        this.controller.validate().then(result => {
            if (!result.valid) {
                return;
            }

            var savePromise: Promise<any>;
            if (this.isNew) {
                savePromise = this.profileService.addIndividualBankDetails(this.model);
            } else {
                savePromise = this.profileService.updateIndividualBankDetails(this.bankDetailsId, this.model);
            }
            savePromise.then(() => {
                this.notifier.success("The record was saved successfuly.");
                this.router.navigateToRoute(Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.IndividualBankDetailsList]);
            }).catch(error => {
                this.logger.error(error);
                this.notifier.error("An error occurred while trying to update the bank details.");
            });
        });
    }
}