import { Dto } from "project/project";

//grants directory
export const ROUTE_GRANTS_DIRECTORY = "grants-directory";

//applications
export const ROUTE_APPLICATIONS = "applications";
export const ROUTE_APPLICATION_OUTCOME_SUMMARY = "application-outcome-summary";

//vouchers && suppliers
export const ROUTE_VOUCHERS = "vouchers";
export const ROUTE_VOUCHER_DASHBOARD = "voucher-dashboard";
export const ROUTE_VOUCHER_SELECT_SUPPLIER = "voucher-select-supplier";
export const ROUTE_VOUCHER_REDEMPTION = "voucher-redemption";
export const ROUTE_VOUCHER_ENTER = "enter-vouchers";
export const ROUTE_VOUCHER_BULK_ENTRY = "bulk-enter-vouchers";
export const ROUTE_VOUCHER_SUPPLIER_VOUCHER_LIST = "supplier-voucher-list";
export const ROUTE_VOUCHER_REDEMPTION_REQUESTS = "supplier-redemption-requests";
export const ROUTE_VOUCHER_REDEMPTION_NEW_REQUEST = "supplier-redemption-new-request";
export const ROUTE_VOUCHER_REDEMPTION_REQUEST_DETAIL = "supplier-redemption-new-request-detail";
export const ROUTE_VOUCHER_SUPPLIER = "voucher-supplier-list";


// profile navigation
// export const ROUTE_APPLICATIONS_IN_PROGRESS = "applications-in-progress";
// export const ROUTE_APPLICATIONS_AWARDED_GRANTS = "applications-awarded-grants";
// export const ROUTE_APPLICATIONS_REPORTING = "applications-reporting";
// export const ROUTE_APPLICATIONS_ACQUITTAL = "applications-acquittal"
// export const ROUTE_APPLICATIONS_PAYMENTS = "applications-payments";
// export const ROUTE_APPLICATIONS_INVITE = "applications-invite";
// export const ROUTE_APPLICATIONS_CORRESPONDENCE = "applications-correspondence";
// export const ROUTE_APPLICATIONS_REQUEST_FOR_INFORMATION = "applications-request-for-information";

// "temporary" fix
export const ROUTE_APPLICATIONS_IN_PROGRESS = Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ApplicationsInProgress];
export const ROUTE_APPLICATIONS_AWARDED_GRANTS = Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ApplicationsAwardedGrants];
export const ROUTE_APPLICATIONS_AWARDED_SUPPLIERS = Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ApplicationsSupplierRegistrations];
export const ROUTE_APPLICATIONS_AWARDED_SUPPLIER = Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.AwardedSupplier];
export const ROUTE_APPLICATIONS_REPORTING = Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ApplicationsReporting];
export const ROUTE_APPLICATIONS_ACQUITTAL = Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ApplicationsAcquittal];
export const ROUTE_APPLICATIONS_PAYMENTS = Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ApplicationsPayments];
export const ROUTE_APPLICATIONS_INVITE = Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ApplicationsInvite];
export const ROUTE_APPLICATIONS_CORRESPONDENCE = Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ApplicationsCorrespondence];
export const ROUTE_APPLICATIONS_REQUEST_FOR_INFORMATION = Dto.Constants.ExternalPageRoutes[Dto.Constants.ExternalPageRoutes.ApplicationsRequestForInformation];
