//rename this to form utils?

import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";

export function StepVisibilityChannelName(formId: number, id: number): string {
    return "step-visibility-changed-" + formId + "-" + id;
}
export function SectionVisibilityChannelName(formId: number, id: number): string {
    return "section-visibility-changed-" + formId + "-" + id;
}
export function QuestionVisibilityChannelName(formId: number, id: number, sectionIndex: number = null): string {
    let channel = "question-visibility-changed-" + formId + "-" + id;
    if (sectionIndex != null) {
        channel += '-' + sectionIndex;
    }
    return channel;
}

export function findAnswerBySystemQuestion(formInstance: SharedDto.OnlineForm.Application.IFormInstanceDto,
    formTemplate: SharedDto.OnlineForm.Form.IFormTemplateDto,
    systemQuestion: SharedDto.Constants.SystemQuestion)
    : SharedDto.OnlineForm.Application.IAnswerDto {

    let questionTempltes = formTemplate.steps.flatMap(x => x.sections).flatMap(x => x.questions);
    let questionTemplate = questionTempltes.find(x => x.systemQuestion === systemQuestion);
    if (questionTemplate == null) {
        return null;
    }
    let answers = formInstance.steps.flatMap(x => x.sectionGroups).flatMap(x => x.sections).flatMap(x => x.questionGroups).flatMap(x => x.questions);
    return answers.find(x => x.templateQuestionId == questionTemplate.questionId);
}
