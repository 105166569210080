import { BindingEngine, computedFrom, Disposable } from "aurelia-binding";
import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { ControllerValidateResult, ValidationControllerFactory, ValidationRules } from "aurelia-validation";
import { ValidationModuleBase } from "base-class";
import { BroadcastEvents } from "models/broadcast-events";
import { Dto } from "project/project";
import { SharedDto } from "project/project-shared";
import { DataService } from "services/data-service";
import { Notifier } from "services/notifier";
import { ProfileService } from "services/profile-service";
import { AddressInput } from "shared/controls/address/address";
import { DateOfBirthRule, NotAPOBox, NumericRule, PersonNameRule } from "shared/controls/custom-validation";
import { ValidationError } from "shared/controls/validation-error";
import { DeepObserver } from "shared/utils/deep-observer";
import { Router } from "aurelia-router";
import { addressArrayToString } from 'shared/utils/address-utils';

@autoinject
export class ContactInformationView {
    profile: Dto.IPersonalProfileDto;
    titleList: SharedDto.ICodeListItemDto[];


    isBusy: boolean;
   
    @bindable({ defaultBindingMode: bindingMode.twoWay }) postalAddressModel: AddressInput;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) residentialAddressModel: AddressInput;

    constructor(
        private readonly profileService: ProfileService,
        private readonly dataService: DataService,
        private readonly notifier: Notifier) {
      
        this.isBusy = false;
    }

    activate() {
        var titlePromise = this.dataService.getTitles().then((data: SharedDto.ICodeListItemDto[]) => {
            this.titleList = data;
        }).catch((error: Error) => {
            this.notifier.error(error.message);
        });

        var profilePromise = this.profileService.getIndividualProfile().then((data: Dto.IPersonalProfileDto) => {
            this.profile = data;
        }).catch((error: Error) => {
            this.notifier.error(error.message);
        });
        return Promise.all([titlePromise, profilePromise]);
    }

    @computedFrom("profile.residentialAddress")
    get residentialAddress(): string {
        return addressArrayToString(this.profile.residentialAddress);
    }

    @computedFrom("profile.postalAddress")
    get postalAddress(): string {
        return addressArrayToString(this.profile.postalAddress);
    }

    @computedFrom("profile.workPhoneNumber", "profile.homePhoneNumber")
    get contactLabel(): string {
        var contactLabel = "Contact number";

        if(this.profile.workPhoneNumber) {
            contactLabel = "Mobile";
        }

        if(this.profile.homePhoneNumber) {
            contactLabel = "Mobile";
        }
        
        return contactLabel;
    }
}
