import { bindable, customElement, bindingMode, computedFrom, observable } from 'aurelia-framework';
import Inputmask from 'inputmask';
import { ControlIdGenerator } from 'shared/utils/control-id-generator';

@customElement('currency')
export class Currency {

    @bindable() precision: number = 2;
    @bindable() digits: number = 18;
    @bindable() currencyInput: HTMLInputElement;
    @bindable() id: string;
    @bindable() disabled: boolean;
    @bindable() tabindex: number;
    @bindable() placeholder: string = "0.00";

    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: number;

    @observable inputValue: number;

    constructor(private readonly controlIdGenerator: ControlIdGenerator) { }

    bind() {
        if (!this.id) {
            this.id = this.controlIdGenerator.getNextId().toString();
        }
        //ensure the value is numeric when bound.
        if (this.value != null) {
            this.value = +this.value;
            this.currencyInput.value = this.value.toString();
        }
    }

    valueChanged() {
        //if parent VM has changed the value, reflect it in the currency input        
        if (this.value && this.currencyInput.value != this.value.toString()) {
            this.currencyInput.value = this.value.toString();
        }
    }


    attached() {
        Inputmask({
            alias: 'currency',
            autoUnmask: true,
            rightAlign: false,
            digits: this.precision,
            integerDigits: this.digits,
            clearMaskOnLostFocus: true,
            unmaskAsNumber: true,
        } as any).mask(this.currencyInput);

        this.currencyInput.addEventListener("keyup", event => {
            if (this.currencyInput.value == '-') {
                //bind as null until a valid number is entered
                this.value = null;
            } else {
                this.value = +this.currencyInput.value;
            }
            this.dispatchEvent();
        });
        
    }

    dispatchEvent() {
        let customEvent = new CustomEvent('update', {
            detail: {
                value: this.value
            },
            bubbles: true
        });        
        this.currencyInput.dispatchEvent(customEvent);
    }

    @computedFrom("placeholder")
    get displayedPlaceholder(): string {
        //this getter is just a barrier between what is bound and what is displayed to catch any instances of null or undefined, or empty strings.
        if (this.placeholder === null || this.placeholder === undefined) {
            return "0.00";
        }
        return this.placeholder;
    }
}
