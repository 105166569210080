import { customAttribute, autoinject, bindable } from 'aurelia-framework';
import { bindingMode } from "aurelia-binding";
import { TaskQueue } from 'aurelia-task-queue';
import { ControlIdGenerator } from '../utils/control-id-generator';

@customAttribute('gnt-element-height')
@autoinject
export class GntElementHeight {

    @bindable({ defaultBindingMode: bindingMode.toView }) offset: number;
    @bindable({ defaultBindingMode: bindingMode.toView }) minHeight: number;

    private eventNameSpace: string;

    constructor(private element: Element, private readonly taskQueue: TaskQueue, private controlIdGenerator: ControlIdGenerator) {
        this.eventNameSpace = "gntElementHeight-" + controlIdGenerator.getNextId();
    }

    attached() {

        this.taskQueue.queueTask(() => { this.setControlsHeight(this.element, this.offset, this.minHeight); });
        $(window).on("resize."+this.eventNameSpace,(()=>{
            this.setControlsHeight(this.element, this.offset, this.minHeight);
        }));
    }

    public setControlsHeight(element: Element, heightOffset: number, minimumHeight: number){
        var viewPortHeight;
        // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
        if (typeof window.innerWidth != 'undefined') {
            viewPortHeight = window.innerHeight
        }
       
       // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
        else if (typeof document.documentElement != 'undefined'
        && typeof document.documentElement.clientWidth !=
        'undefined' && document.documentElement.clientWidth != 0) {
            viewPortHeight = document.documentElement.clientHeight
        }
       
        // older versions of IE
        else {
          viewPortHeight = document.getElementsByTagName('body')[0].clientHeight
        }

        var newheight = +viewPortHeight + +heightOffset;
        if(minimumHeight){
            if(newheight < minimumHeight){
                newheight = minimumHeight;
            }
        }

        $(element).height(newheight);
    }

    unbind() {
        
    }

    detached(){
        $(window).off("resize."+this.eventNameSpace);
    }
}
